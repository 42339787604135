export default {
  name: 'DerbyGO',
  slug: 'derbygo',
  title: 'DerbyGO',
  email: 'derbygo@mobilleo.com',
  brandingLogo: 'derbygo/derbygo-logo-white.svg',
  brandingIcon: '',
  token: JSON.parse(process.env.VUE_APP_SWITCH_URLS)['DerbyGO'],
  hex: '#013565',
  hasTravelType: false,
  hasReasonFor: false,
  spinnerType: 'geo',
  appLinks: {
    android: 'https://play.google.com/store/apps/details?id=com.mobilleo.android',
    ios: 'https://itunes.apple.com/gb/app/mobilleo/id1249273159?mt=8'
  },
  termsURL: 'https://mobilleo.s3.eu-west-2.amazonaws.com/terms.pdf',
  privacyURL: 'https://mobilleo.s3.eu-west-2.amazonaws.com/privacy.pdf',
  sociabilityUrl: 'https://www.sociability.app/derbygo',
  uniUrl: 'https://www.derby.ac.uk/life/our-campuses/derby/',
  collegeUrl: 'https://www.derby-college.ac.uk/our-colleges',
  pages: {
    login: {
      form: {
        class: 'login-form',
        email: {
          dark: false,
          color: 'primary',
          labelColor: 'primary',
          filled: false,
          class: 'login-field'
        },
        password: {
          dark: false,
          color: 'primary',
          labelColor: 'primary',
          filled: false,
          class: 'login-field'
        },
        signIn: {
          class: 'login-button login-white',
          color: 'primary',
          align: 'between',
          iconRight: 'fal fa-angle-right'
        },
        skipLogin: {
          class: 'login-button',
          color: 'primary',
          align: 'between',
          iconRight: 'fal fa-angle-right'
        },
        forgotPassword: {
          class: 'login-button text-primary',
          align: 'between',
          outline: true
        },
        validation: {
          class: 'space row col text-center text-negative strong'
        },
        createButton: {
          class: 'full-width q-my-md',
          color: 'login-green',
          size: 'lg'
        },
        completeButton: {},
        verificationButton: {
          class: 'login-button login-white',
          color: 'primary',
          align: 'center'
        }
      }
    },
    password: {
      email: {
        form: {
          class: 'login-form',
          welcomeMessage: {
            class: 'login-text'
          },
          email: {
            class: 'login-field',
            dark: false,
            color: 'primary',
            filled: false
          },
          resetButton: {
            class: 'login-button login-white',
            color: 'primary',
            text: 'Send password reset email',
            align: 'between',
            iconRight: 'fal fa-angle-right',
            size: 'md'
          },
          backButton: {
            class: 'login-button',
            align: 'between',
            flat: true,
            iconRight: 'fal fa-angle-right'
          }
        }
      },
      reset: {
        form: {
          class: 'login-form',
          welcomeMessage: {},
          email: {
            dark: false,
            color: 'white',
            filled: false
          },
          password: {
            dark: false,
            color: 'white',
            filled: false
          },
          passwordConfirmation: {
            dark: false,
            color: 'white',
            filled: false
          },
          resetButton: {
            class: 'login-button login-white',
            color: 'primary',
            align: 'between',
            flat: false,
            iconRight: 'fal fa-angle-right'
          }
        }
      }
    },
    signup: {
      complete: {
        form: {
          class: 'login-form full-width',
          welcomeMessage: {
            class: 'login-field'
          },
          email: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          organisationName: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          name: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          password: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          passwordConfirmation: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          completeButton: {
            class: 'login-button login-white',
            size: 'md',
            color: 'primary',
            align: 'between',
            iconRight: 'fal fa-angle-right'
          },
          skipLogin: {
            class: 'login-button',
            color: 'primary',
            size: 'md',
            align: 'between',
            iconRight: 'fal fa-angle-right'
          }
        }
      }
    },
    verification: {
      submitButton: {
        class: 'login-button'
      }
    }
  },
  components: {
    mGmaps: {
      markers: []
    },
    mImageModal: {
      spinnerType: 'geo',
      spinnerClass: 'loader'
    },
    mMobilityNearbyList: {
      spinnerType: 'geo',
      spinnerClass: 'loader'
    },
    mMobileAppbar: {
      toolbarTitle: {
        img: {}
      }
    }
  },
  contents: {
    'bus': {
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-bus-routes',
      icon: {
        source: 'library',
        name: 'bus'
      },
      color: {
        quasar: 'deep-purple',
        hex: '#673ab7'
      }
    },
    'tram': {
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: true,
      showInSmartSearch: false,
      showInNearby: false,
      route: 'ondemand-tram-routes',
      icon: {
        source: 'library',
        name: 'subway'
      },
      color: {
        quasar: 'indigo',
        hex: '#3f51b5'
      }
    },
    'subway': {
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: true,
      showInSmartSearch: false,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'subway'
      },
      color: {
        quasar: 'blue-grey',
        hex: '#607d8b'
      }
    },
    'rail': {
      aliases: ['trains', 'train'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-train-depart',
      icon: {
        source: 'library',
        name: 'train'
      },
      color: {
        quasar: 'blue',
        hex: '#2196f3'
      }
    },
    'bikehire': {
      aliases: null,
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: false,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'bicycle'
      },
      color: {
        quasar: 'grey-9',
        hex: '#2a2626'
      }
    },
    driving: { showInSmartSearch: true },
    'bicycle': {
      aliases: null,
      showInBookNow: true,
      showInMobilityNearby: false,
      showInSmartSearch: true,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'bicycle'
      },
      color: {
        quasar: 'grey-9',
        hex: '#2a2626'
      }
    },
    'walking': {
      aliases: null,
      showInBookNow: true,
      showInMobilityNearby: false,
      showInSmartSearch: true,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'walking'
      },
      color: {
        quasar: 'grey-9',
        hex: '#2a2626'
      }
    },
    'carclub': {
      label: 'Car Club',
      aliases: null,
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: false,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'cars'
      },
      color: {
        quasar: 'green',
        hex: '#4caf50'
      }
    },
    'rental': {
      label: 'Rental',
      aliases: ['car-hire', 'rentals'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: false,
      showInNearby: false,
      route: 'ondemand-car-hire-results',
      icon: {
        source: 'library',
        name: 'car'
      },
      color: {
        quasar: 'lime',
        hex: '#cddc39'
      }
    },
    'ridehailing': {
      label: 'Taxi',
      aliases: ['taxi', 'taxis'],
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'car'
      },
      color: {
        quasar: 'lime',
        hex: '#cddc39'
      }
    },
    'flight': {
      label: 'Airports',
      aliases: ['flights'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: false,
      showInNearby: false,
      route: 'ondemand-flights-outbound',
      icon: {
        source: 'library',
        name: 'plane'
      },
      color: {
        quasar: 'red',
        hex: '#f44336'
      }
    },
    'hotel': {
      label: 'Hotel',
      aliases: ['accommodation'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: false,
      showInNearby: false,
      route: 'ondemand-hotel',
      icon: {
        source: 'library',
        name: 'hotel'
      },
      color: {
        quasar: 'lime',
        hex: '#cddc39'
      }
    },
    'parking': {
      label: 'Parking',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'parking'
      },
      color: {
        quasar: 'blue',
        hex: '#2196f3'
      },
      type: 'parking'
    },
    'petrol': {
      label: 'Petrol',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'gas-pump'
      },
      color: {
        quasar: 'grey',
        hex: '#9e9e9e'
      }
    },
    'ev_station': {
      label: 'EV Station',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'charging-station'
      },
      color: {
        quasar: 'green-7',
        hex: '#43a047'
      }
    },
    'atm': {
      label: 'ATMs',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'money-bill-wave'
      },
      color: {
        quasar: 'green-5',
        hex: '#66bb6a'
      }
    },
    'car-wash': {
      label: 'Car wash',
      aliases: ['car_wash'],
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'car-wash'
      },
      color: {
        quasar: 'teal-7',
        hex: '#00897b'
      }
    },
    'car-repair': {
      label: 'Car repair',
      aliases: ['car_repair'],
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'car-mechanic'
      },
      color: {
        quasar: 'teal-3',
        hex: '#80cbc4'
      }
    },
    'restaurant': {
      label: 'Restaurants',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'utensils-alt'
      },
      color: {
        quasar: 'orange',
        hex: '#ff9800'
      }
    },
    'bar': {
      label: 'Bars',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'cocktail'
      },
      color: {
        quasar: 'purple',
        hex: '#9c27b0'
      }
    },
    'cafe': {
      label: 'Coffee',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'coffee'
      },
      color: {
        quasar: 'brown-8',
        hex: '#5d4037'
      }
    }
  },
  smartSearch: {
    'bikehire': {
      icon: 'directions_bike',
      color: 'deep-orange'
    },
    'walking': {
      icon: 'directions_walk',
      color: 'black'
    },
    'car_hire': {
      icon: 'directions_car',
      color: 'lime-5',
      route: 'ondemand-car-hire-results'
    },
    'rental': {
      icon: 'directions_car',
      color: 'lime-5',
      route: 'ondemand-car-hire-results'
    },
    'driving': {
      icon: 'directions_car',
      color: 'dark',
      route: null
    },
    'train': {
      icon: 'train',
      color: 'blue-8',
      route: 'ondemand-train-depart'
    },
    'rail': {
      icon: 'train',
      color: 'blue-8',
      route: 'ondemand-train-depart'
    },
    'flight': {
      icon: 'flight',
      color: 'red-8',
      route: 'ondemand-flights-outbound'
    },
    'accommodation': {
      icon: 'hotel',
      color: 'green',
      route: 'ondemand-hotel'
    },
    'hotel': {
      icon: 'hotel',
      color: 'green',
      route: 'ondemand-hotel'
    },
    'carclub': {
      icon: 'directions_car',
      color: 'green-8'
    },
    'other': {
      icon: 'directions_car',
      color: 'green-8'
    },
    'parking': {
      icon: 'local_parking',
      color: 'blue',
      route: null
    },
    'bus': {
      icon: 'directions_bus',
      color: 'purple',
      route: 'ondemand-bus-routes'
    },
    'tram': {
      icon: 'tram',
      color: 'indigo-4',
      route: 'ondemand-tram-routes'
    },
    'bicycle': {
      icon: 'directions_bike',
      color: 'grey-9'
    },
    'tube': {
      icon: 'directions_subway',
      color: 'orange',
      route: null
    },
    'ridehailing': {
      icon: 'local_taxi',
      color: 'black',
      route: 'ondemand-ride-hailing-results'
    }
  },
  mobility: [
    // Uni Bus
    {
      order: 3,
      icon: {
        resource: 'assets',
        name: 'mango.png'
      },
      color: {
        icon: 'pink',
        marker: '#000'
      },
      type: 'unibus',
      label: 'mango',
      permission: 'search.bus',
      pref: 'unibus'
    }

  ],
  bookables: [
    // Train
    {
      order: 1,
      icon: {
        resource: 'library',
        name: 'train'
      },
      color: {
        icon: 'blue',
        marker: '#2196f3'
      },
      type: 'trains',
      label: 'Train',
      permission: 'search.trains',
      pref: 'rail'
    },
    // Bus
    {
      order: 2,
      icon: {
        resource: 'library',
        name: 'bus'
      },
      color: {
        icon: 'deep-purple',
        marker: '#673ab7'
      },
      type: 'bus',
      label: 'Bus',
      permission: 'search.bus',
      pref: 'bus'
    },
    // Taxi
    {
      order: 4,
      icon: {
        resource: 'library',
        name: 'taxi'
      },
      color: {
        icon: 'yellow-12',
        marker: '#fee73b',
        text: 'grey-10'
      },
      type: 'ride-hailing',
      label: 'Taxi',
      permission: 'search.rides',
      pref: 'ridehailing'
    },
    {
      order: 5,
      icon: {
        resource: 'library',
        name: 'parking'
      },
      color: {
        icon: 'white',
        marker: '#2196f3',
        text: 'cyan-5'
      },
      type: 'parking',
      label: 'University Parking',
      permission: 'search.parking',
      pref: 'parking'
    }
  ],
  nearby: [
    {
      icon: {
        resource: 'assets',
        name: 'sociability.png'
      },
      color: {
        icon: 'pink',
        marker: '#9c27b0'
      },
      type: 'dialog',
      label: 'Sociability',
      target: 'sociability'
    },
    {
      icon: {
        resource: 'assets',
        name: 'derby-university.png'
      },
      color: {
        icon: 'black',
        marker: '#000000'
      },
      type: 'university',
      label: 'University of Derby Campus',
      target: 'https://www.derby.ac.uk/life/our-campuses/derby/'
    },
    {
      icon: {
        resource: 'assets',
        name: 'derby-college.png'
      },
      color: {
        icon: 'black',
        marker: '#000000'
      },
      type: 'college',
      label: 'Derby College Campus',
      target: 'https://www.derby-college.ac.uk/our-colleges'
    },
    {
      icon: {
        resource: 'library',
        name: 'utensils-alt'
      },
      color: {
        icon: 'orange',
        marker: '#ff9800'
      },
      type: 'restaurant',
      label: 'Restaurants'
    },
    {
      icon: {
        resource: 'library',
        name: 'cocktail'
      },
      color: {
        icon: 'purple',
        marker: '#9c27b0'
      },
      type: 'bar',
      label: 'Bars'
    },
    {
      icon: {
        resource: 'library',
        name: 'coffee'
      },
      color: {
        icon: 'brown-8',
        marker: '#5d4037'
      },
      type: 'cafe',
      label: 'Coffee'
    }
  ]
}
