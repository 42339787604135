import { Platform } from 'quasar'
import store from 'store/modules/ondemand'
import { hasRole } from 'utils/authentication'

const beforeEnter = (to, from, next) => {
  if (!store.state.stash.parameters) {
    return next({ name: 'ondemand-flights' })
  } else {
    next()
  }
}

export const mobileForm = {
  name: 'ondemand-flights-mobile',
  path: '/on-demand/flights',
  meta: {
    label: 'Flight: Search'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-flights-mobile" */ 'pages/ondemand/mobile/flight.vue')
  }
}

export const form = {
  name: 'ondemand-flights',
  path: '/on-demand/flights',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-flights" */ 'pages/ondemand/flights/index.vue')
  },
  beforeEnter (to, from, next) {
    if (Platform.is.mobile) {
      return next({
        name: 'ondemand-flights-mobile',
        params: to.params
      })
    }
    next()
  }
}

function createInboundOutbound (direction) {
  return {
    name: `ondemand-flights-${direction}`,
    path: `/on-demand/flights/${direction}`,
    meta: {
      label: `Flight: ${direction === 'outbound' ? 'Outbound' : 'Inbound' }`
    },
    components: {
      right: () =>
      import(
        /* webpackChunkName: "ondemand-flights" */ 'pages/ondemand/flights/sidebar/index.vue'
      ),
      default: () =>
      import(
        /* webpackChunkName: "ondemand-flights" */ 'pages/ondemand/flights/results/index.vue'
      )
    },
    props: {
      default: {
        direction: direction
      }
    },
    beforeEnter
  }
}

export const outbound = createInboundOutbound('outbound')

export const inbound = createInboundOutbound('return')

export const passenger = {
  name: 'ondemand-flights-passenger-information',
  path: '/on-demand/flights/passenger',
  meta: {
    label: 'Flight: Passengers'
  },
  components: {
    default: () =>
    import(
      /* webpackChunkName: "ondemand-flights" */ 'pages/ondemand/flights/passenger-info/index.vue'
    ),
    right: () =>
    import(
      /* webpackChunkName: "ondemand-flights" */ 'pages/ondemand/flights/sidebar/index.vue'
    )
  },
  beforeEnter (to, from, next) {
    if (hasRole('guest')) return next({ name: 'guest-mode' })
    else if (!store.state.stash.parameters) return next({ name: 'ondemand-flights' })
    else next()
  }
}

export const extras = {
  name: 'ondemand-flights-extras',
  path: '/on-demand/flights/extras',
  meta: {
    label: 'Flight: Extras'
  },
  components: {
    default: () =>
    import(
      /* webpackChunkName: "ondemand-flights" */ 'pages/ondemand/flights/extras/index.vue'
    ),
    right: () =>
    import(
      /* webpackChunkName: "ondemand-flights" */ 'pages/ondemand/flights/sidebar/index.vue'
    )
  },
  beforeEnter
}

export const summary = {
  name: 'ondemand-flights-summary',
  path: '/on-demand/flights/summary',
  meta: {
    label: 'Flight: Summary'
  },
  components: {
    default: () =>
    import(
      /* webpackChunkName: "ondemand-flights" */ 'pages/ondemand/flights/summary/index.vue'
    ),
    right: () =>
    import(
      /* webpackChunkName: "ondemand-flights" */ 'pages/ondemand/flights/sidebar/index.vue'
    )
  },
  beforeEnter
}

export default { form, outbound, inbound, passenger, extras, summary, mobileForm }
