import { index, get, put, post, block, unblock, resetPasswordEmail } from 'api/backoffice'
import _ from 'lodash'
import selects from './membersSelects'
import { Notify } from 'quasar'
import User from 'store/models/user'

const initialState = {
  selectedUserId: null,
  index: {},
  selects
}

const state = _.cloneDeep(initialState)

const getters = {
  getUser: state => id => {
    return _.cloneDeep(state.index[id])
  },
  getSelectedUser: state => {
    return _.cloneDeep((state.index[state.selectedUserId]))
  },
  getSelect: state => id => {
    return _.cloneDeep(id ? state.selects[id] : state.selects)
  }
}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  setSelectedUserId ({ commit }, userId) {
    commit('setSelectedUserId', userId)
  },
  loadUsers ({ commit }, { params }) {
    return index(params)
      .then(staff => {
        commit('loadUsersSuccess', staff.data)
        return staff
      })
      .catch(e => {
        commit('loadUsersFailure', e)
      })
  },
  loadUser ({ commit }, { username, params }) {
    return get(username, params)
      .then(staff => {
        commit('loadUserSuccess', staff.data)
      })
      .catch(e => {
        commit('loadUserFailure', e)
      })
  },
  createUser ({ commit }, details) {
    return post(details)
      .then(response => commit('createUserSuccess', response.data))
      .catch(response => {
        commit('createUserFailure', response.data)
      })
  },
  updateUser ({ commit }, { user }) {
    const username = user.username
    const body = { ...user.attributes, email: user.email }
    return put(username, body)
      .then(staff => {
        commit('updateUserSuccess', staff.data)
        Notify.create({
          message: `User updated successfully`,
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        commit('updateUserFailure', e)
        Notify.create({
          message: `User not updated, an error occurred`,
          color: 'negative',
          icon: 'clear'
        })
      })
  },
  blockUser ({ commit }, { user }) {
    const username = user.username
    return block(username)
      .then(() => {
        Notify.create({
          message: `User blocked`,
          color: 'positive',
          icon: 'mdi-check'
        })
        commit('blockUserSuccess', username)
      })
      .catch(e => {
        Notify.create({
          message: e.data.title,
          color: 'negative',
          icon: 'clear'
        })
        commit('blockUserFailure', e)
      })
  },
  unblockUser ({ commit }, { user }) {
    const username = user.username
    return unblock(username)
      .then(() => {
        commit('unblockUserSuccess', username)
        Notify.create({
          message: `User unblocked`,
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        commit('unblockUserFailure', e)
        Notify.create({
          message: `User not unblocked, an error occurred`,
          color: 'negative',
          icon: 'clear'
        })
      })
  },
  resetUserPassword ({ commit }, { user }) {
    resetPasswordEmail(user.email)
      .then(() => {
        commit('resetUserPasswordSuccess', user)
        Notify.create({
          message: `Password reset email sent`,
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        commit('resetUserPasswordFailure', e)
        Notify.create({
          message: `Password reset email not sent, an error occurred`,
          color: 'negative',
          icon: 'clear'
        })
      })
  }
}

const mutations = {
  reset (state) {
    for (var prop in state) {
      state[prop] = initialState[prop]
    }
  },
  setSelectedUserId (state, id) {
    state.selectedUserId = id
  },

  loadUsersSuccess (state, staff) {
    const indexed = _.keyBy(staff, m => m.username)
    User.insert({
      data: staff.map((m) => {
        return {
          ...m,
          meta: m.attributes
        }
      })
    })
    state.index = { ...state.index, ...indexed }
  },
  loadUsersFailure (state, error) {
    throw Error(error)
  },

  loadUserSuccess (state, staff) {
    state.index = { ...state.index, [staff.username]: staff }
  },
  loadUserFailure (state, error) {
    throw Error(error)
  },

  createUserSuccess (state, staff) {
    state.index = { ...state.index, [staff.username]: staff }
  },
  createUserFailure (state, error) {
    throw error
  },

  updateUserSuccess (state, staff) {
    state.index = { ...state.index, [staff.username]: staff }
  },
  updateUserFailure (state, error) {
    throw Error(error)
  },

  blockUserSuccess (state, username) {
    const blockedUser = { ...state.index[username], is_blocked: true }
    state.index = { ...state.index, [blockedUser.username]: blockedUser }
  },
  blockUserFailure (state, error) {
    throw Error(error.data.title)
  },

  unblockUserSuccess (state, username) {
    const unblockedUser = { ...state.index[username], is_blocked: false }
    state.index = { ...state.index, [unblockedUser.username]: unblockedUser }
  },
  unblockUserFailure (state, error) {
    throw Error(error)
  },

  resetUserPasswordSuccess (state, user) {
    return user
  },
  resetUserPasswordFailure (state, error) {
    throw Error(error)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
