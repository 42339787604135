<template>
  <q-card class="text-faded">
    <q-card-section>
      <div class="text-subtitle1 text-weight-medium q-mb-md">
        From what time?
      </div>
      <div v-for="(hours, label) in times" :key="label">
        <div class="text-subtitle2 text-capitalize">
          {{ label }}
        </div>
        <q-btn
          v-for="hour in hours"
          :key="hour"
          :label="`${hour}:00`"
          :color="selected == hour ? `primary` : `secondary`"
          size="md"
          flat
          class="q-my-sm q-mr-xs"
          @click="selectHour(hour)"
        />
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import date from 'utils/date-time'
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    input: {
      type: Event
    }
  },
  data () {
    return {
      date: date.newDate(),
      selected: null,
      times: {
        morning: [6, 8, 10],
        afternoon: [12, 14, 16],
        evening: [18, 20, 22]
      }
    }
  },
  methods: {
    selectHour (hour) {
      this.selected = hour
      this.$emit('input', date.toCivilDateTime(
        date.adjustDate(this.date, { hour: this.selected, minute: 0 })
      ))
    }
  }
}
</script>

<style lang="stylus" scoped>
  button.q-btn
    width 70px
</style>
