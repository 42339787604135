<template>
  <div v-if="showSheet" class="results-wrapper">
    <div class="sheet-backdrop" @click="close" />
    <div class="search-sheet" @click.stop>
      <div class="results-container">
        <q-scroll-area class="scroller">
          <div class="results">
            <m-empty-state
              v-if="!hasTyped"
              :style="{margin: 'auto'}"
              icon="search"
            >
              {{ $t('search_mobilleo') }}
            </m-empty-state>
            <template v-else>
              <m-empty-state v-if="searching" :style="{margin: 'auto'}" icon="search">
                {{ $t('loading.searching.searching') }}
              </m-empty-state>
              <m-empty-state
                v-if="!searching && noResults && hasTyped"
                :style="{margin: 'auto'}"
                icon="search"
              >
                {{ $t('no_results_found') }}
              </m-empty-state>
              <template v-if="users.length">
                <m-group-title :label="$tc('user.user', 2)" class="group-title" />
                <MSearchResultItem
                  v-for="user in users"
                  :key="user.email"
                  v-bind="user"
                  :close="close"
                  :user="user.user"
                  show-actions
                  @view="viewUser(user.user)"
                />
              </template>
              <template v-if="bookings.length">
                <m-group-title :label="$tc('booking.booking', 2)" class="group-title" />
                <MSearchResultItem
                  v-for="booking in bookings"
                  :key="booking.reference + 'b'"
                  v-bind="booking"
                  :close="close"
                  @view="viewBooking(booking.reference)"
                />
              </template>
              <template v-if="journeys.length">
                <m-group-title :label="$tc('journey.journey', 2)" class="group-title" />
                <MSearchResultItem
                  v-for="journey in journeys"
                  :key="journey.reference + 'j'"
                  v-bind="journey"
                  :close="close"
                  @view="viewJourney(journey.reference)"
                />
              </template>
              <template v-if="requests.length">
                <m-group-title :label="$tc('request', 2)" class="group-title" />
                <MSearchResultItem
                  v-for="request in requests"
                  :key="request.reference + 'r'"
                  v-bind="request"
                  :close="close"
                  @view="viewRequest(request.reference)"
                />
              </template>
              <template v-if="organisations.length">
                <m-group-title :label="$tc('organisation.organisation', 2)" class="group-title" />
                <MSearchResultItem
                  v-for="organisation in organisations"
                  :key="organisation.reference"
                  v-bind="organisation"
                  :close="close"
                  @view="viewOrganisation(organisation.reference)"
                />
              </template>
            </template>
          </div>
        </q-scroll-area>
      </div>
    </div>
  </div>
</template>

<script>
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
export default {
  mixins: [authentication],
  props: {
    hasTyped: Boolean,
    showSheet: Boolean,
    searching: Boolean,
    bookings: Array,
    users: Array,
    journeys: Array,
    requests: Array,
    organisations: Array,
    close: Function
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    noResults () {
      return !this.users.length &&
              !this.bookings.length &&
              !this.journeys.length &&
              !this.requests.length &&
              !this.organisations.length
    }
  },
  methods: {
    viewBooking (bookingRef) {
      if (this.hasPermission('organisations.bookings.index')) {
        return this.$router.push({ hash: '/booking', query: { booking: bookingRef } })
      }
      return this.$router.push(`/bookings/${bookingRef}`)
    },
    viewUser (user) {
      let path
      if (user.organisation) {
        path = `/organisations/${user.organisation.slug}/members/${user.username}`
      } else if (user.roles.includes('backoffice') || user.roles.includes('admin')) {
        path = `/sdc/${user.username}`
      } else {
        path = `/individuals/${user.username}`
      }
      return this.$router.push({ path })
    },
    viewJourney (reference) { return this.$router.push({ path: `/journeys/${reference}` }) },
    viewOrganisation (reference) {
      return this.$router.push({ path: `/organisations/${reference}` })
    },
    viewRequest (reference) {
      return this.$router.push({ path: `/request/${reference}` })
    }
  }
}
</script>
<style lang="stylus" scoped>
@keyframes fade-in-bg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.results-wrapper {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 10;
}

.sheet-backdrop {
  background: rgba(0, 0, 0, 0.5);
  animation: fade-in-bg 0.3s forwards;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
}

.search-sheet {
  background: #F5F5F5;
  position: absolute;
  top: 74px;
  right: 67px;
  width: calc(100vw - 20px);
  height: calc(100vh - 84px);
  border-radius: 3px;
}

.btn-close {
  color: black;
  position: absolute;
  right: 76px;
  top: 84px;
  z-index: 30;
}

.scroller {
  height: calc(100vh - 84px);
}

.results-container {
  height: calc(100vh - 64px);
  overflow: auto;
}

.results {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 150px;
}

.group-title {
  padding: 15px 0;
}

@media (min-width: 768px) {
  .search-sheet, .search.active {
    width: 70vw;
  }

  .results {
    width: 85%;
  }
}

@media (min-width: 1024px) {
  .search-sheet, .search.active {
    width: 60vw;
  }

  .results {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .search-sheet, .search.active {
    width: 50vw;
    max-width: 660px;
  }

  .results {
    width: 75%;
  }
}
</style>
