<template>
  <div>
    <!-- Title bar -->
    <div class="titleBar q-px-lg q-pt-md q-pb-sm row grid-title">
      <div class="col text-h6 btn-offset">
        {{ $t('profile') }}
      </div>
      <div class="col row justify-end">
        <q-btn
          icon="far fa-times"
          flat
          dense
          unelevated
          color="primary"
          :to="{hash: ''}"
        />
      </div>
    </div>
    <q-scroll-area v-if="!hasRole('guest')" class="scroller">
      <!-- Avatar -->
      <div class="avatarSection text-center">
        <q-avatar class="cursor-pointer shadow-5" size="80px" color="primary-tint">
          <m-image :src="user.picture" />
        </q-avatar>
        <div class="text-primary text-h6">
          {{ user.display_name }}
        </div>
      </div>

      <!-- Mobility Budget -->
      <div class="budgetSection text-center">
        <span v-if="showBudget">{{ withinBudget ? $t('you_are_within_budget') : $t('you_are_over_budget') }}</span>
        <div v-if="showBudget" class="budgetBox q-mx-lg q-mt-md q-mb-md bg-white rounded-borders">
          <template v-if="spend && budget">
            <div class="budgetState q-pa-sm" :class="withinBudget ? 'statePositive' : 'stateNegative'">
              {{ $t('mobility_budget.current_month') }}
            </div>
            <div class="q-pa-sm row">
              <div class="col">
                <div>{{ $tc('budget.budget') }}</div>
                <div class="text-bold">
                  {{ budget.text }}
                </div>
              </div>
              <div class="col">
                <div>{{ $t('spend') }}</div>
                <div class="text-bold">
                  {{ spend.text }}
                </div>
              </div>
            </div>
          </template>
          <div v-else class="loadingBudget">
            <q-spinner class="spinner" size="44" />
          </div>
        </div>
        <div class="q-ma-lg">
          <div class="row q-col-gutter-sm">
            <template v-for="button in mainButtons">
              <div :key="`support-${button.label}`" class="col-6">
                <q-btn
                  color="grey-3"
                  class="fit rounding"
                  :icon="`fas fa-${button.icon}`"
                  :label="button.label"
                  unelevated
                  no-caps
                  :to="button.link"
                  align="left"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
      <hr class="q-separator q-separator--horizontal col-grow q-my-sm">

      <!-- Organisation Settings -->
      <template v-if="hasRole('middleoffice') && !hasRole(['backoffice', 'admin', 'middleoffice.outsource'])">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ policyManager.sectionTitle }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <template v-for="button in policyButtons">
            <div :key="`support-${button.label}`" class="col-6 ">
              <q-btn
                color="grey-3"
                class="fit rounding"
                :icon="`fas fa-${button.icon}`"
                :label="button.label"
                unelevated
                no-caps
                align="left"
                :to="button.link"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- My Travel -->
      <template v-if="!hasRole(['backoffice', 'admin', 'middleoffice.outsource'])">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ myTravel.sectionTitle }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <template v-for="button in myTravelButtons">
            <div :key="`support-${button.label}`" class="col-6 ">
              <q-btn
                color="grey-3"
                class="fit rounding"
                :icon="`fas fa-${button.icon}`"
                :label="button.label"
                unelevated
                no-caps
                align="left"
                :to="button.link"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- Memberships -->
      <template v-if="!hasRole(['backoffice', 'admin', 'middleoffice.outsource']) && !['derbygo'].includes(partner.slug)">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ memberships.sectionTitle }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <template v-for="button in membershipsButtons">
            <div :key="`support-${button.label}`" class="col-6 ">
              <q-btn
                color="grey-3"
                class="fit rounding"
                :icon="`fas fa-${button.icon}`"
                :label="button.label"
                unelevated
                no-caps
                align="left"
                :to="button.link"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- Roles & Permissions -->
      <template v-if="hasRole('admin')">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ $t('system') }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <div class="col-6 ">
            <q-btn
              color="grey-3"
              class="fit rounding"
              icon="fas fa-user-tag"
              :label="$tc('role', 2)"
              unelevated
              no-caps
              align="left"
              :to="{ hash: '/system-settings/roles'}"
            />
          </div>
          <div class="col-6 ">
            <q-btn
              color="grey-3"
              class="fit rounding"
              icon="fas fa-ballot-check"
              :label="$tc('permission', 2)"
              unelevated
              no-caps
              align="left"
              :to="{ hash: '/system-settings/permissions'}"
            />
          </div>
        </div>
      </template>

      <!-- Support -->
      <template v-if="isNotOutsource">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ support.sectionTitle }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <template v-for="button in supportButtons">
            <div :key="`support-${button.label}`" class="col-6 ">
              <q-btn
                color="grey-3"
                class="fit rounding"
                :icon="`fas fa-${button.icon}`"
                :label="button.label"
                unelevated
                no-caps
                align="left"
                :to="button.link"
              />
            </div>
          </template>
        </div>
      </template>
    </q-scroll-area>
    <m-guest-blocker v-else />
    <div class="footer">
      <q-btn :label="$t('sign_out')" class="signout" color="primary" outline @click="logout" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import profileConfig from './config.js'
import authentication from 'mixins/authentication'
import { get as getMonthBudget } from 'api/dashboard'
import { MGuestBlocker } from 'components/'

var peekElement = null

export default {
  components: { MGuestBlocker },
  mixins: [authentication],
  data () {
    return {
      ...profileConfig,
      spend: null,
      budget: null
    }
  },
  computed: {
    ...mapGetters(['user', 'organisation', 'partner']),
    withinBudget () {
      return this.spend?.amount <= this.budget?.amount
    },
    mainButtons () {
      return this.main.buttons.filter(this.filterButton)
    },
    policyButtons () {
      return this.policyManager.buttons.filter(this.filterButton)
    },
    myTravelButtons () {
      return this.myTravel.buttons.filter(this.filterButton)
    },
    membershipsButtons () {
      return this.memberships.buttons.filter(this.filterButton)
    },
    supportButtons () {
      return this.support.buttons.filter(this.filterButton)
    },
    isNotOutsource () {
      return !this.hasRole('middleoffice.outsource')
    },
    showBudget () {
      return !['gohi', 'derbygo'].includes(this.partner.slug) && this.isNotOutsource && this.hasRole(['member', 'middleoffice'])
    }
  },
  created () {
    if (!authentication.methods.hasRole(['backoffice', 'finance', 'sdc', 'support', 'sales', 'admin'])) {
      getMonthBudget().then(res => {
        this.spend = res.data.spend
        this.budget = res.data.current_month_budget
      })
    }
  },
  mounted () {
    if (!peekElement) peekElement = document.getElementById('peek')
    peekElement.setAttribute('style', 'width: 435px; background-color: #ffffff')
  },
  beforeDestroy () {
    peekElement.setAttribute('style', 'width: 50vw; background-color: #f4f4f4')
    peekElement = null
  },
  methods: {
    filterButton (button) {
      if (button.roles) {
        if (button.roles.is && !this.hasRole(button.roles.is)) return false
        if (button.roles.not && this.hasRole(button.roles.not)) return false
      }

      if (button.partner) {
        if (button.partner.not.includes(this.partner.slug)) return false
      }

      return true
    },
    externalUrl (url) {
      if (url) window.open(url, '_blank')
    },
    logout () {
      this.$router.push({ hash: '' })
      this.$router.push({ name: 'Logout' })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .scroller
    height calc(100vh - 112px)
    width 100%

  .titleBar
    font-weight 1200
    display flex
    flex-direction column
    align-items center

  .budgetBox
    height 104px
    position relative

  .budgetState
    color white
    border-top-left-radius $generic-border-radius
    border-top-right-radius $generic-border-radius
    font-weight 300
    &.statePositive
      background-color #84cb58
    &.stateNegative
      background-color negative

  .rounding
    border-radius $generic-border-radius

  .q-btn
    font-weight 500
    font-size .75em

  .footer
    width 100%
    background-color white
    position absolute
    bottom 0
    left 0
    right 0
    height 56px
    .signout
      position absolute
      right 32px
      width 371px
      bottom 14px

  .editIcon
    position absolute
    z-index 10
    background-color white
    border-radius 100%
    font-size .25em
    line-height .25em
    padding 4px
    bottom 0
    right 0

  .loadingBudget
    .spinner
      margin 0
      position absolute
      left calc(50% - 22px)
      top calc(50% - 22px)

  .grid-title
    display: grid
    grid-template-columns: auto 21px
    justify-items: center

  .btn-offset
    padding-left: 20px
</style>
