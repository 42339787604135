import refreshAuth from '../beforeEach/0000-rerfreshAuth'

export const form = {
  name: 'ondemand-bikehire',
  path: '/on-demand/bikehire',
  meta: {
    label: 'Bike Hire: Search'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-bikehire" */ 'pages/ondemand/bikehire/index.vue')
  }
}

export const results = {
  name: 'ondemand-bikehire-results',
  path: '/on-demand/bikehire/results',
  meta: {
    label: 'Bike Hire: Docks'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-bikehire" */ 'pages/ondemand/bikehire/results.vue'),
    right: () => import(/* webpackChunkName: "ondemand-bikehire" */ 'pages/ondemand/bikehire/sidebar.vue')
  }
}

export const summary = {
  name: 'ondemand-bikehire-summary',
  path: '/on-demand/bikehire/summary',
  meta: {
    label: 'Bike Hire: Summary'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-bikehire" */ 'pages/ondemand/bikehire/summary.vue'),
    right: () => import(/* webpackChunkName: "ondemand-bikehire" */ 'pages/ondemand/bikehire/sidebar.vue')
  }
}

export const returnSummary = {
  name: 'ondemand-bikehire-return-summary',
  path: '/on-demand/bikehire/return-summary',
  meta: {
    label: 'Bike Hire: Summary'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-bikehire" */ 'pages/ondemand/bikehire/return-summary.vue'),
    right: () => import(/* webpackChunkName: "ondemand-bikehire" */ 'pages/ondemand/bikehire/sidebar.vue')
  }
}

export const login = {
  name: 'bikehire-login-brompton',
  path: '/bikehire/brompton',
  meta: {
    label: 'Bike Hire: Login'
  },
  components: {
    default: () => import(/* webpackChunkName: "bikehire-login" */ 'pages/ondemand/bikehire/brompton/index.vue')
  },
  beforeEnter: (to, from, next) => {
    refreshAuth(to, from, next)
  }
}

export default { form, results, summary, returnSummary, login }
