<template>
  <div class="invoices">
    <div class="index-list">
      <m-index-list
        ref="scroller"
        :load="load"
        :filters="showFilters ? filters : null"
        :sort="sort"
        :columns="showColumns ? columns : null"
        :show-results="showResults"
        :items="items"
        :reset-items="resetItems"
        :item-height="62"
        :height="height"
      >
        <template v-if="showFilters" v-slot:filters>
          <filters v-model="filters" />
        </template>
        <template v-slot:item="{item}">
          <slot name="card" :item="item">
            <invoice-card v-model="selectedInvoices" :item="item" @addPo="openPoModal" />
          </slot>
        </template>
      </m-index-list>
      <q-dialog
        v-model="poModal"
      >
        <q-card>
          <q-card-section>
            <div class="text-h6">
              {{ $t('purchase_order.order') }}
            </div>
            <div class="text-body1 text-faded">
              {{ $t('attach_purchase_order_to_invoice') }}
            </div>
          </q-card-section>
          <q-card-section>
            <q-input
              v-model="poValue"
              :error="$v.poValue.$invalid && $v.poValue.$dirty"
              :error-label="$t('purchase_order.required')"
            />
          </q-card-section>
          <q-card-actions align="right">
            <q-btn color="primary" :label="$t('purchase_order.submit')" @click="addPO" />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import Invoice from 'store/models/invoice'
import mIndexList from '@/MIndexList'
import filters from './filters'
import invoiceCard from './invoice-card'
import authentication from 'mixins/authentication'

import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    filters,
    mIndexList,
    invoiceCard
  },
  mixins: [authentication],
  props: {
    showFilters: {
      type: Boolean,
      default: true
    },
    showColumns: {
      type: Boolean,
      default: true
    },
    showResults: {
      type: Boolean,
      default: true
    },
    load: Function,
    resetItems: Function,
    height: {
      type: String,
      default: 'calc(100vh - 207px)'
    },
    user: String
  },
  data () {
    return {
      poModal: false,
      warningModal: false,
      showPopover: false,
      poValue: undefined,
      poInvoice: null,
      selectedInvoices: [],
      invalidInvoices: [],
      sort: {
        column: 'raised_at',
        direction: 'desc'
      },
      filters: {
        number: null,
        type: null,
        hasPo: null,
        raisedBetween: null
      },
      columns: [
        {
          label: this.$t('type'),
          value: 'type',
          sortable: true
        },
        {
          label: this.$t('invoice.number'),
          value: 'number',
          sortable: true
        },
        {
          label: this.$t('date.invoice_raised'),
          value: 'raised_at',
          sortable: true
        },
        {
          label: this.$tc('traveller')
        },
        {
          label: this.$tc('organisation.organisation')
        },
        {
          label: this.$t('purchase_order.po_number'),
          value: 'po_number',
          sortable: true
        },
        {
          label: this.$t('total_cost'),
          value: 'gross_amount',
          sortable: true
        }
      ]
    }
  },
  computed: {
    items () {
      return Invoice.query()
        .where((invoice) => {
          if (!this.user) return true
          return invoice.user === this.user
        })
        .orderBy(this.sort.column, this.sort.direction)
        .get()
    }
  },
  created () {
    var index = null
    if (!this.hasPermission('can.book.for.anyone') && !this.hasPermission('can.create.creditinvoices')) {
      index = this.columns.findIndex(r => r.label === this.$tc('organisation.organisation'))
      this.columns.splice(index, 1)
    }
  },
  methods: {
    openPoModal (number) {
      if (number) {
        this.poInvoice = number
        this.poModal = true
      }
    },
    addPO () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.poModal = false
        this.$q.dialog({
          title: `${this.$t('confirm.purchase_order_number')}: ${this.poValue}`,
          message: this.$t('purchase_order.cannot_be_changed'),
          cancel: true,
          ok: 'Confirm'
        }).onOk(() => {
          return this.$store.dispatch('entities/invoices/addPurchaseOrder', {
            invoice: this.poInvoice,
            po: this.poValue
          })
            .then(() => {
              this.$q.notify({ color: 'positive', icon: 'mdi-check', message: this.$t('purchase_order.number_added') })
            })
            .catch(() => {
              this.$q.notify({ color: 'negative', icon: 'clear', message: this.$t('error.purchase_order_number_not_added') })
            })
        })
      }
    },
    reset () {
      this.$refs.scroller.reset()
    }
  },
  validations: {
    poValue: { required }
  }
}
</script>

<style lang="stylus" scoped>
.index-head
  display flex
  margin-top 10px

.col-head
  position relative
  padding 10px 12px

.sort-icon
  position: absolute;
  top: 8px;
  font-size: 25px;

.col-head.sortable
  cursor pointer
  transition background-color .3s

  &:hover
    background: $grey-300

.dates
  margin-top -17px

.btn-wrapper
  margin-top 12px

.top-sheet
  padding 15px
  position relative
  background white
  height 125px
  z-index 100

.index-head
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr
  width calc(100% - 25px)
  position absolute
  bottom 0
  left 0
</style>
