<template>
  <peek-form
    :footer="false"
    :title="$t('favourite_addresses')"
  >
    <q-page padding>
      <address-list
        :addresses="savedLocations"
        :search="searchAddresses"
        :get-address="getAddress"
      />
    </q-page>
  </peek-form>
</template>

<script>
import { search, get as getAddress } from 'api/address'
import peekForm from 'peeks/peekForm.vue'
import AddressList from './components/list'
import { mapGetters } from 'vuex'
export default {
  components: {
    peekForm,
    AddressList
  },
  computed: {
    ...mapGetters({
      activeUser: 'entities/users/peekUser',
      authedUser: 'user',
      savedLocations: 'addresses/saved'
    })
  },
  async created () {
    await this.$store.dispatch('addresses/getSavedLocations')
  },
  methods: {
    searchAddresses: search,
    getAddress
  }
}
</script>

<style>
</style>
