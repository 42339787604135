<template>
  <gmap-map
    v-if="latitude && longitude"
    ref="map"
    :center="center"
    :zoom="zoom"
    :options="options"
    v-bind.sync="$attrs"
    v-on="$listeners"
    @click="clickMap"
  >
    <gmap-marker
      v-for="(marker, key) in markers"
      :key="marker.key || key"
      v-bind="marker"
      @click="clickMarker(marker)"
    />
    <GmapMarker
      :position="mapMarkerCoords"
      :icon="{
        url: require(`assets/markers/deviceLocation.png`)
      }"
      class="marker"
    />
    <GmapMarker
      v-for="(marker, index) in components.mGmaps.markers"
      :key="`${marker.icon}-${index}`"
      :position="{ lat: marker.position.lat, lng: marker.position.lng}"
      :icon="{ url: require(`assets/markers/${marker.icon}`) }"
      class="marker"
    />
  </gmap-map>
</template>

<script>
import store from 'store'
import { mapGetters } from 'vuex'
import { gmapApi } from 'gmap-vue'
import { hasPermission } from 'utils/authentication'
const environment = process.env.NODE_ENV

export default {
  props: {
    markers: {
      type: Array,
      default () {
        return []
      }
    },
    markerDetails: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      env: environment
    }
  },
  computed: {
    ...mapGetters({
      geoip: 'geoip',
      latLng: 'geolocate/latLng',
      requested: 'geolocate/requested',
      denied: 'geolocate/denied',
      center: 'map/getCenterAsJson',
      options: 'map/getOptions',
      zoom: 'map/getZoom',
      pos: 'geolocate/position',
      components: 'partner/components',
      selected: 'discovery/selected'
    }),
    google: gmapApi,
    $mapObject () {
      return this.$refs.map.$mapObject
    },
    mapMarkerCoords () {
      if (this?.latLng?.latitude && this?.latLng?.longitude) return { lat: this.latLng.latitude, lng: this.latLng.longitude }
      return { lat: this.geoip.latitude, lng: this.geoip.longitude }
    },
    latitude () {
      return this?.latLng?.latitude || this.geoip.latitude
    },
    longitude () {
      return this?.latLng?.longitude || this.geoip.longitude
    },
    hasMobilityCards () {
      return hasPermission('feature.mobility.nearby.cards')
    }
  },
  watch: {
    zoom: {
      handler: function (val) {
        this.$mapObject.setZoom(10)
        this.$mapObject.setZoom(val)
      },
      deep: true
    },
    markers (markers) {
      if (markers.length <= 1) return
      let bounds = new window.google.maps.LatLngBounds()
      this.markers.forEach(marker => {
        bounds.extend(
          new window.google.maps.LatLng(
            marker.position.lat,
            marker.position.lng
          )
        )
      })
      this.$refs.map.fitBounds(bounds, 10)
    },
    '$route.path': {
      handler: function (path) {
        if ((path === '/home/' || path === '/home') && this.$refs.map) {
          this.$mapObject.setZoom(15)
          this.$mapObject.panTo({
            lat: this.latLng.latitude || this.geoip.latitude,
            lng: this.latLng.longitude || this.geoip.longitude
          })
        }
      },
      deep: true,
      immediate: true
    },
    latLng: {
      deep: true,
      handler: (value) => {
        store.dispatch('map/setCenter', {
          lat: value.latitude || this.geoip.latitude,
          lng: value.longitude || this.geoip.longitude
        })
      }
    },
    options: {
      deep: true,
      handler (value, old) {
        this.$mapObject.setOptions(value)
      }
    },
    selected (val) {
      if (val && this.hasMobilityCards) {
        this.markers.filter(marker => marker.key).forEach(marker => {
          const details = this.markerDetails || this.$route.params
          const route = `${process.env.VUE_APP_API_URL}/icon/marker/${details.icon.name}.svg?width=24&height=36`
          const color = details.color.replace('#', '%23')
          const { position } = marker
          if (position.lat === val.lat && position.lng === val.lng) {
            marker.icon = `${route}&color=white&iconColor=${color}&strokeColor=${color}`
            marker.zIndex = 2
          } else {
            marker.icon = `${route}&color=${color}`
            marker.zIndex = 1
          }
        })
        store.dispatch('map/setCenter', {
          lat: val.lat,
          lng: val.lng
        })
      }
    }
  },
  created () {
    this.$store.dispatch('geolocate/get')
  },
  methods: {
    clickMap (event) {
      event.stop()
    },
    clickMarker (marker) {
      if (this.hasMobilityCards) {
        const i = this.markers.findIndex(thisMarker => thisMarker === marker)
        this.$store.dispatch('discovery/setSelected', { id: `card-${i}`, ...marker.position })
      }
    },
    getCenterAsJson () {
      return this.$mapObject.center.toJSON()
    },
    getCenterAsUrlValue () {
      return this.$mapObject.center.toUrlValue()
    }
  }
}
</script>
