<template>
  <div class="row q-col-gutter-sm tools">
    <div class="col-sm-2 qa-pt-none">
      <q-input
        v-model="filters.number"
        :label="$t('search_by_invoice_number')"
      />
    </div>
    <div class="col-sm-2 qa-pt-none">
      <q-select
        v-model="filters.hasPo"
        :options="poOptions"
        :label="$t('purchase_order.number')"
        map-options
        emit-value
        clearable
      />
    </div>
    <div class="col-sm-2 qa-pt-none">
      <q-select
        v-model="filters.type"
        :options="types"
        :label="$t('invoice.type')"
        map-options
        emit-value
        clearable
      />
    </div>
    <div class="col-sm-2 qa-pt-none">
      <m-date-range-select
        class="full-width"
        @change="filterByDate"
      />
    </div>
  </div>
</template>
<script>
import date from 'utils/date-time'
import authentication from 'mixins/authentication'

export default {
  mixins: [authentication],
  props: ['value'],
  data () {
    return {
      filters: {},
      types: [
        {
          label: this.$t('sales'),
          value: 'sales'
        },
        {
          label: 'Purchase',
          value: 'purchase'
        },
        {
          label: this.$t('sales_credit'),
          value: 'sales_credit'
        },
        {
          label: this.$t('purchase_credit'),
          value: 'purchase_credit'
        }
      ],
      poOptions: [
        {
          label: this.$t('invoice.with_value'),
          value: true
        },
        {
          label: this.$t('invoice.without_value'),
          value: false
        }
      ]
    }
  },
  watch: {
    filters: {
      deep: true,
      handler (val, old) {
        this.$emit('input', { ...val })
      }
    }
  },
  created () {
    this.filters = { ...this.value }
  },
  methods: {
    filterByDate (dates) {
      this.filters.raisedBetween = dates.start && dates.end ? `${date.toCivilDate(dates.start)} 00:00:00,${date.toCivilDate(dates.end)} 23:59:59` : null
    }
  }
}
</script>
<style lang="stylus" scoped>
.invBtn
  margin auto
</style>
