<template>
  <q-card v-if="user" class="bg-white text-dark">
    <q-list no-border separator>
      <policy-list-item
        v-for="type in contentTypes"
        :key="type.name"
        :info="type"
        :policy="policy ? policy[type.name] : null"
      />
    </q-list>
  </q-card>
</template>

<script>
import policyListItem from './policy-list-item'
export default {
  components: { policyListItem },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      contentTypes: [
        {
          name: 'flights',
          title: this.$tc('content_type.flight', 2)
        },
        {
          name: 'hotels',
          title: this.$tc('content_type.hotel', 2)
        },
        {
          name: 'rental',
          title: this.$tc('content_type.rental')
        },
        {
          name: 'ridehailing',
          title: this.$tc('content_type.taxi')
        },
        {
          name: 'trains',
          title: this.$tc('content_type.train', 2)
        }
      ]
    }
  },
  computed: {
    policy () {
      return this.user ? this.user.meta.travel_policy : null
    }
  }
}
</script>

<style>

</style>
