import { index, get, patch, post, toggleAccount, updatePricingSchedule, block, unblock, updateBrandingColor, setTransportPolicy } from 'api/organisations'
import _ from 'lodash'
import { Notify } from 'quasar'
import store from 'store'
import i18n from 'i18n'

const initialState = {
  selectedOrgId: null,
  index: {}
}

const nonFormData = [
  'attributes.pay_on_account', 'has_account',
  'has_bookings', 'is_blocked'
]

const state = _.cloneDeep(initialState)

const getters = {
  getOrg: state => id => {
    return _.cloneDeep(state.index[id])
  },
  getSelectedOrg: state => {
    return _.cloneDeep(state.index[state.selectedOrgId])
  },
  getOrgForm: state => id => {
    return _.omit(state.index[id], nonFormData)
  }
}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  setSelectedOrgId ({ commit }, orgId) {
    commit('setSelectedOrgId', orgId)
  },
  loadOrgs ({ commit }, params) {
    return index(params)
      .then(orgs => {
        commit('loadOrgsSuccess', orgs.data)
        return orgs
      })
      .catch(e => {
        commit('loadOrgsFailure', e)
      })
  },
  loadOrg ({ commit }, { orgId, params }) {
    return get(orgId, params)
      .then(org => {
        commit('loadOrgSuccess', org.data)
      })
      .catch(e => {
        commit('loadOrgFailure', e)
      })
  },
  createOrg ({ commit }, { name }) {
    return post({
      name
    })
      .then(org => {
        org = org.data
        commit('createOrgSuccess', org)
        Notify.create({
          message: i18n.t('organisation.created'),
          color: 'positive',
          icon: 'mdi-check'
        })
        return org
      })
      .catch(e => {
        commit('createOrgFailure', e)
        Notify.create({
          message: i18n.t('error.organisation_not_created'),
          color: 'negative',
          icon: 'clear'
        })
      })
  },
  updateOrg ({ commit }, { org }) {
    const orgId = org.slug
    org = _.omit(org, nonFormData)
    return patch(orgId, {
      ...org.attributes,
      name: org.name
    })
      .then(org => {
        commit('updateOrgSuccess', org.data)
        const brand = org.data.brand
        if (brand && brand.colors && brand.colors.brand) {
          var brandColor = brand.colors.brand
          var userOrg = store.getters.authentication.organisation
          if (userOrg && userOrg.slug === org.data.slug) {
            store.dispatch('setBrand', brandColor)
            store.dispatch('updateOrg', org.data)
          }
        }
        Notify.create({
          message: i18n.t('organisation.updated'),
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        commit('updateOrgFailure', e)
        Notify.create({
          message: i18n.t('error.organisation_not_updated'),
          color: 'negative',
          icon: 'clear'
        })
      })
  },
  updateOrgBranding ({ commit }, { org }) {
    const orgId = org.slug
    return new Promise((resolve, reject) => {
      updateBrandingColor(orgId, {
        ...org.brand
      })
        .then(res => {
          Notify.create({
            message: org.name ? i18n.t('updated.orgnames_branding', { name: org.name }) : i18n.t('updated.organisations_branding'),
            color: 'positive',
            icon: 'mdi-check'
          })
          resolve()
        })
    })
  },
  setAccount ({ commit }, { org, action }) {
    return toggleAccount(org, action)
      .then(res => {
        commit('setAccountSuccess', { org, action })
        Notify.create({
          message: i18n.t(`account_${action}d`),
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        commit('setAccountFailure', e)
        Notify.create({
          message: i18n.t('error.account_status_not_changed'),
          color: 'negative',
          icon: 'clear'
        })
      })
  },
  setTransportBudget ({ commit }, { slug, params }) {
    return setTransportPolicy(slug, params)
      .then(res => {
        commit('updateOrgTransportBudgetSuccess', { slug, budget: res })
        Notify.create({
          message: i18n.t('organisation.updated'),
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        Notify.create({
          message: i18n.t('error.organisation_not_updated'),
          color: 'negative',
          icon: 'clear'
        })
      })
  },
  updatePricing ({ commit }, { org, schedule }) {
    const scheduleObject = {}
    Object.keys(schedule).forEach(k => {
      scheduleObject[k] = _.pickBy({
        min: schedule[k].min,
        max: schedule[k].max,
        percentage: schedule[k].percentage !== null ? parseInt(schedule[k].percentage, 10) : null,
        uplift_percentage: schedule[k].uplift_percentage !== null ? schedule[k].uplift_percentage : null
      }, v => v !== null)
    })
    return updatePricingSchedule(org, scheduleObject)
      .then(res => {
        commit('updatePricingSuccess', { org, schedule: res.data })
        Notify.create({
          message: i18n.t('pricing_schedule_updated'),
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        Notify.create({
          message: i18n.t('error.pricing_schedule_not_updated'),
          color: 'negative',
          icon: 'clear'
        })
        commit('updatePricingFailure', e)
      })
  },

  block ({ commit }, orgId) {
    return block(orgId)
      .then(() => {
        commit('blockSuccess', orgId)
      })
      .catch(e => {
        commit('blockFailure', e)
      })
  },
  unblock ({ commit }, orgId) {
    return unblock(orgId)
      .then(() => {
        commit('unblockSuccess', orgId)
      })
      .catch(e => {
        commit('unblockFailure', e)
      })
  }
}

const mutations = {
  reset (state) {
    for (var prop in state) {
      state[prop] = initialState[prop]
    }
  },
  setSelectedOrgId (state, id) {
    state.selectedOrgId = id
  },

  loadOrgsSuccess (state, orgs) {
    const indexed = _.keyBy(orgs, m => m.slug)
    state.index = { ...state.index, ...indexed }
  },
  loadOrgsFailure (state, error) {
    throw Error(error)
  },

  loadOrgSuccess (state, org) {
    state.index = { ...state.index, [org.slug]: org }
  },
  loadOrgFailure (state, error) {
    throw Error(error)
  },

  createOrgSuccess (state, org) {
    state.index = { ...state.index, [org.slug]: org }
  },
  createUserFailure (state, error) {
    throw Error(error)
  },

  updateOrgSuccess (state, org) {
    state.index = {
      ...state.index,
      [org.slug]: {
        ...state.index[org.slug],
        ...org
      }
    }
  },
  updateOrgTransportBudgetSuccess (state, { slug, budget }) {
    Object.keys(budget).forEach(key => {
      budget[key].budget = budget[key].budget.amount
    })
    state.index = {
      ...state.index,
      [slug]: {
        ...state.index[slug],
        attributes: {
          ...state.index[slug].attributes,
          transport_policy: budget
        }
      }
    }
  },
  updateOrgFailure (state, error) {
    throw Error(error)
  },

  setAccountSuccess (state, { org, action }) {
    const val = action === 'enable'
    const o = { ...state.index[org], has_account: val }
    state.index = { ...state.index, [org]: o }
  },
  setAccountFailure (state, error) {
    throw Error(error)
  },

  updatePricingSuccess (state, { org, schedule }) {
    const o = { ...state.index[org], pricingschedule: { data: schedule } }
    state.index = { ...state.index, [org]: o }
  },
  updatePricingFailure (state, error) {
    throw Error(error)
  },
  blockSuccess (state, orgId) {
    const blocked = { ...state.index[orgId], is_blocked: true }
    state.index = { ...state.index, [blocked.slug]: blocked }
  },
  blockFailure (state, error) {
    throw error
  },

  unblockSuccess (state, orgId) {
    const unblocked = { ...state.index[orgId], is_blocked: false }
    state.index = { ...state.index, [unblocked.slug]: unblocked }
  },
  unblockFailure (state, error) {
    throw error
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
