<template>
  <peek-form
    :title="$t('organisation.profile')"
    :footer-title="$t('concierge.some_info_will_be_visible')"
    @save="submit"
  >
    <q-page v-if="resource" padding>
      <m-group-title label="Company Info" />
      <q-card class="bg-white text-dark">
        <q-card-section>
          <q-input
            v-model="resource.attributes.legal_name"
            :label="$t('name.legal')"
          />

          <q-input
            v-model="resource.attributes.telephone_number"
            type="tel"
            :label="$t('phone_number')"
          />

          <q-input
            v-model="resource.attributes.email_address"
            type="email"
            :label="$t('email_address')"
          />

          <q-input
            v-model="resource.attributes.vat_number"
            :label="$t('vat_number')"
          />

          <q-input
            v-model="resource.attributes.company_number"
            :label="$t('company_business_number')"
          />

          <q-select
            v-model="resource.attributes.number_of_employees"
            :options="employees"
            :label="$t('number_of.employees')"
            emit-value
          />

          <q-checkbox
            v-model="resource.attributes.requester_confirmation_emails"
            :label="$t('requesters_receive_booking_confirmation')"
          />
        </q-card-section>
      </q-card>

      <m-group-title :label="$t('company_contact_info')" />
      <q-card class="bbg-white text-dark q-mt-md">
        <q-card-section>
          <q-input
            v-model="resource.attributes.key_contact_name"
            :label="$t('key_contact.name')"
          />

          <q-input
            v-model="resource.attributes.key_contact_phone_number"
            type="tel"
            :label="$t('key_contact.phone')"
          />

          <q-input
            v-model="resource.attributes.key_contact_email_address"
            type="email"
            :label="$t('key_contact.email')"
          />

          <q-input
            v-model="resource.attributes.billing_contact_name"
            :label="$t('billing_contact.name')"
          />

          <q-input
            v-model="resource.attributes.billing_contact_phone_number"
            type="tel"
            :label="$t('billing_contact.phone')"
          />

          <q-input
            v-model="resource.attributes.billing_contact_email_address"
            type="email"
            :label="$t('billing_contact.email')"
          />
        </q-card-section>
      </q-card>

      <m-group-title :label="$t('billing_address')" />
      <q-card class="bg-white text-black q-mt-md">
        <q-card-section>
          <q-input
            v-model="resource.attributes.addresses[0].line_one"
            :label="$t('address_line_one')"
          />

          <q-input
            v-model="resource.attributes.addresses[0].line_two"
            :label="$t('address_line_two')"
          />

          <q-input
            v-model="resource.attributes.addresses[0].state"
            :label="$t('county')"
          />

          <q-input
            v-model="resource.attributes.addresses[0].post_code"
            :label="$t('post_code')"
          />

          <q-select
            v-model="resource.attributes.addresses[0].country"
            :options="countriesList"
            filter
            :label="$t('country')"
            emit-value
          />
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script>
import _ from 'lodash'
import store from 'store'
import countries from 'store/countries'
import peekForm from 'peeks/peekForm.vue'
export default {
  components: { peekForm },
  data () {
    return {
      files: [],
      resource: null,
      employees: [
        {
          label: this.$t('self_employed'),
          value: '1'
        },
        {
          label: this.$t('num.employees_range', { numstr: '1-10' }),
          value: '1-10'
        },
        {
          label: this.$t('num.employees_range', { numstr: '11-50' }),
          value: '11-50'
        },
        {
          label: this.$t('num.employees_range', { numstr: '51-200' }),
          value: '51-200'
        },
        {
          label: this.$t('num.employees_range', { numstr: '201-500' }),
          value: '201-500'
        },
        {
          label: this.$t('num.employees_range', { numstr: '501-1000' }),
          value: '501-1000'
        },
        {
          label: this.$t('num.employees_range', { numstr: '1001-5000' }),
          value: '1001-5000'
        },
        {
          label: this.$t('num.employees_range', { numstr: '5001-10000' }),
          value: '5001-10000'
        },
        {
          label: this.$t('num.employees_range', { numstr: '10000+' }),
          value: '10000+'
        }
      ]
    }
  },
  computed: {
    countriesList () {
      return countries.map((c) => ({ label: c.en_short_name, value: c.en_short_name }))
    }
  },
  beforeMount () {
    store.dispatch('organisations/loadOrg', {
      orgId: store.getters['organisation'].slug
    }).then(response => {
      this.resource = store.getters['organisations/getOrg'](store.getters['organisation'].slug)
      if (!Array.isArray(this.resource.attributes.addresses) || !this.resource.attributes.addresses.length) {
        this.resource.attributes = {
          ...this.resource.attributes,
          addresses: [{
            country: '',
            line_one: '',
            line_two: '',
            post_code: '',
            state: ''
          }]
        }
      }
    })
  },
  methods: {
    submit () {
      const org = _.cloneDeep(this.resource)
      const isEmpty = Object.values(org.attributes.addresses[0]).every(x => (x === null || x === ''))

      if (isEmpty) {
        org.attributes.addresses = []
      }

      return this.$store.dispatch('organisations/updateOrg', {
        org: org
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
</style>
