import QSpinnerArrow from 'components/MSpinner/Arrow'
import QSpinnerGeo from 'components/MSpinner/Geo'
import { Loading } from 'quasar'
import i18n from 'i18n'

export default {
  start ({ message, messageColor, spinnerSize, spinnerColor, partnerSlug }) {
    let spinnerType
    switch (partnerSlug) {
    case 'mobilleo':
      spinnerType = QSpinnerGeo
      break
    default:
      spinnerType = QSpinnerArrow
      break
    }
    Loading.show({
      spinner: spinnerType,
      message: message || i18n.t('loading.loading'),
      messageColor: messageColor || 'white',
      spinnerSize: spinnerSize || 150,
      spinnerColor: spinnerColor || '#ffffff'
    })
  },

  stop () {
    Loading.hide()
  }
}
