<template>
  <peek-form :footer="false" title="System Settings">
    <q-page padding>
      <m-group-title label="Roles and permissions management" />
      <q-card class="bg-white text-dark">
        <q-list separator>
          <q-item v-if="hasPermission('can.manage.system.roles')">
            <q-item-section>
              <q-item-label label>
                Roles
              </q-item-label>
              <q-item-label caption>
                View, edit, and create roles within the System.
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn :to="{ hash: '/system-settings/roles' }" flat color="primary">
                Edit
              </q-btn>
            </q-item-section>
          </q-item>
          <q-item v-if="hasPermission('can.manage.system.permissions')">
            <q-item-section>
              <q-item-label label>
                Permissions
              </q-item-label>
              <q-item-label caption>
                View, edit, and create permissions within the System.
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn :to="{ hash: '/system-settings/permissions' }" flat color="primary">
                Edit
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import authentication from 'mixins/authentication'
import peekForm from 'peeks/peekForm.vue'
export default {
  components: {
    peekForm
  },
  mixins: [ authentication ]
}
</script>

<style lang="stylus" scoped>
  .loading
    display grid
    width 100%
    height 100%
    *
      margin auto
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
  .q-item
    padding 16px
</style>
