import http from './http'

export function get (user, params) {
  return http.get(`/users/${user.username}`, { params })
}

export function put (user, params) {
  return http.put(`/users/${user.username}`, params)
}

export function post (params) {
  return http.post(`/users`, params)
}

export function remove (username) {
  return http.delete(`/users/${username}`)
}

export function avatar (user, params) {
  return http.post(`/users/${user.username}/picture`, params)
}

export function journeys (user, params) {
  return http.get(`/users/${user.username}/journeys`, {
    params: {
      ...params,
      orderBy: 'date:desc'
    }
  })
}

export function getTravelPreferences (user) {
  return http.get(`/users/${user.username}/travel-preferences`)
}
export function setTravelPreferences (user, type, payload) {
  return http.post(`/users/${user}/travel-preferences/${type}`, payload)
}

// export function journey (ref, params) {
//   return http.get(`/users/${user.username}/journeys/${ref}`, {params})
// }

export function existingJourneys (user, params) {
  return http.get(`/users/${user.username}/journeys/existing`, { params })
}

export function bookings (username, params) {
  return http.get(`/bookings`, {
    params: {
      ...params,
      user: username,
      orderBy: 'starts_at:desc'
    }
  })
}

export function savedCards (user, params) {
  return http.get(`/users/${user.username}/cards/`, {
    params: {
      limit: 100,
      ...params
    }
  })
}

export function deleteCard (user, cardRef) {
  return http.delete(`/users/${user.username}/cards/${cardRef}`)
}

export function notes (slug) {
  return http.get(`/users/${slug}/notes?orderBy=created_at:desc`)
}

export function addNote (slug, text) {
  return http.post(`/users/${slug}/notes`, { body: text })
}

export function resetPasswordEmail (email) {
  return http.post(`/password/email`, { email: email })
}

export function changePassword (username, password, currentPassword) {
  return http.post(`/users/${username}/change-password`, {
    new_password: password,
    current_password: currentPassword
  })
}
