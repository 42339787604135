<template>
  <div class="row q-col-gutter-sm">
    <div class="col-5">
      <m-select-query
        v-model="item.city"
        :label="$t('city')"
        stack-label
        :query="search"
      />
    </div>
    <div class="col-5">
      <money-field
        v-model="item.budget"
        :label="$tc('budget.budget')"
        :error="v.policy.city_budgets.$each.$iter[index].budget.$error"
        :hint="$t('permitted_cost.default')"
        @blur="v.policy.city_budgets.$touch"
      />
    </div>
    <div class="col-2" style="display: grid">
      <q-btn
        v-if="item.city && item.budget"
        round
        color="primary"
        icon="delete"
        style="margin: auto"
        @click="() => $emit('remove', index)"
      />
    </div>
  </div>
</template>

<script>
import { cities } from 'api/hotel.js'
import moneyField from 'components/MFields/money.vue'

export default {
  components: { moneyField },
  props: ['index', 'v', 'value'],
  data () {
    return {
      item: {
        ...this.value,
        city: {
          ...this.value.city
        }
      }
    }
  },
  watch: {
    item: {
      deep: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    search (text) {
      return cities({ name: text }).then(res => {
        return res.data.map(c => ({
          label: c.name,
          value: c.id
        }))
      })
    }
  }
}
</script>

<style>

</style>
