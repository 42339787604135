<template>
  <svg
    :width="size"
    :height="size"
    :class="classes"
    class="q-spinner q-spinner-geo"
    viewBox="0 0 183 193"
  >
    <defs>
      <path
        id="b"
        d="M88.2492678 90.498332c0-7.1503243-39.62455-31.2132995-56.8227939-31.2132995S.2862987 73.2597027.2862987 90.498332c0 17.238629 13.9419313 31.2133 31.1401752 31.2133s56.8227939-24.0629756 56.8227939-31.2133zm-42.3759467-.160898c0-7.9084433-6.3960406-14.3195034-14.2859566-14.3195034S17.3014078 82.4289907 17.3014078 90.337434s6.3960407 14.319503 14.2859567 14.319503c7.889916 0 14.2859566-6.4110597 14.2859566-14.319503zm45.3352374 3.2783939c7.1503244 0 31.2132995 39.6245501 31.2132995 56.8227941 0 17.198244-13.97467 31.140175-31.2132995 31.140175-17.2386293 0-31.2132995-13.941931-31.2132995-31.140175s24.0629752-56.8227941 31.2132995-56.8227941zm.1608933 42.3763211c7.9084433 0 14.3195032 6.39604 14.3195032 14.285956s-6.4110599 14.285957-14.3195032 14.285957-14.3195034-6.396041-14.3195034-14.285957c0-7.889916 6.4110601-14.285956 14.3195034-14.285956zm2.9360891-45.493817c0-7.1503243 39.6245501-31.2132995 56.8227941-31.2132995 17.198244 0 31.140175 13.9746702 31.140175 31.2132995 0 17.238629-13.941931 31.2133-31.140175 31.2133s-56.8227941-24.0629756-56.8227941-31.2133zm42.3759471-.160898c0-7.9084433 6.39604-14.3195034 14.285956-14.3195034s14.285957 6.4110601 14.285957 14.3195034-6.396041 14.319503-14.285957 14.319503c-7.889916 0-14.285956-6.4110597-14.285956-14.319503zm-45.4729295-2.0658847c7.1503244 0 31.2132995-39.6245501 31.2132995-56.8227939 0-17.1982439-13.97467-31.14017524-31.2132995-31.14017524-17.2386293 0-31.2132995 13.94193134-31.2132995 31.14017524 0 17.1982438 24.0629752 56.8227939 31.2132995 56.8227939zm.1608933-42.3763209c7.9084433 0 14.3195032-6.3960407 14.3195032-14.2859567 0-7.889916-6.4110599-14.2859566-14.3195032-14.2859566s-14.3195034 6.3960406-14.3195034 14.2859566 6.4110601 14.2859567 14.3195034 14.2859567z"
      />
      <filter
        id="a"
        width="200%"
        height="200%"
        x="-50%"
        y="-50%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="11"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.231770833 0"
          in="shadowOffsetOuter1"
        />
      </filter>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
    >

      <path
        fill="#7ED321"
        d="M88.2492678 90.498332c0-7.1503243-39.62455-31.2132995-56.8227939-31.2132995S.2862987 73.2597027.2862987 90.498332c0 17.238629 13.9419313 31.2133 31.1401752 31.2133s56.8227939-24.0629756 56.8227939-31.2133zm-42.3759467-.160898c0-7.9084433-6.3960406-14.3195034-14.2859566-14.3195034S17.3014078 82.4289907 17.3014078 90.337434s6.3960407 14.319503 14.2859567 14.319503c7.889916 0 14.2859566-6.4110597 14.2859566-14.319503z"
      />
      <path
        fill="#FFDB00"
        d="M91.2085585 93.6158279c7.1503244 0 31.2132995 39.6245501 31.2132995 56.8227941 0 17.198244-13.97467 31.140175-31.2132995 31.140175-17.2386293 0-31.2132995-13.941931-31.2132995-31.140175s24.0629752-56.8227941 31.2132995-56.8227941zm.1608933 42.3763211c7.9084433 0 14.3195032 6.39604 14.3195032 14.285956s-6.4110599 14.285957-14.3195032 14.285957-14.3195034-6.396041-14.3195034-14.285957c0-7.889916 6.4110601-14.285956 14.3195034-14.285956z"
      />
      <path
        fill="#F9414D"
        d="M94.3055409 90.498332c0-7.1503243 39.6245501-31.2132995 56.8227941-31.2132995 17.198244 0 31.140175 13.9746702 31.140175 31.2132995 0 17.238629-13.941931 31.2133-31.140175 31.2133s-56.8227941-24.0629756-56.8227941-31.2133zm42.3759471-.160898c0-7.9084433 6.39604-14.3195034 14.285956-14.3195034s14.285957 6.4110601 14.285957 14.3195034-6.396041 14.319503-14.285957 14.319503c-7.889916 0-14.285956-6.4110597-14.285956-14.319503z"
      />
      <path
        fill="#208AE4"
        d="M91.2085585 88.2715493c7.1503244 0 31.2132995-39.6245501 31.2132995-56.8227939 0-17.1982439-13.97467-31.14017524-31.2132995-31.14017524-17.2386293 0-31.2132995 13.94193134-31.2132995 31.14017524 0 17.1982438 24.0629752 56.8227939 31.2132995 56.8227939zm.1608933-42.3763209c7.9084433 0 14.3195032-6.3960407 14.3195032-14.2859567 0-7.889916-6.4110599-14.2859566-14.3195032-14.2859566s-14.3195034 6.3960406-14.3195034 14.2859566 6.4110601 14.2859567 14.3195034 14.2859567z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MSpinnerGeo',
  props: {
    color: String,
    size: {
      type: [Number, String],
      default: '1rem'
    }
  },
  computed: {
    classes () {
      if (this.color) {
        return `text-${this.color}`
      }
      return 'text-white'
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-spinner-geo
  animation q-spin 2s linear infinite
</style>
