<template>
  <peek-form :title="$t('my_travel_preferences')" :footer="false">
    <div class="q-ma-md q-pa-md">
      <m-group-title :label="$t('transport_preferences')">
        <template v-slot:sublabel>
          <p>{{ $t('enable_disable_transport_preferences') }}</p>
        </template>
      </m-group-title>
      <m-content-type-enable
        :loading="loading"
        :content-types="contentTypes"
        :org-types="orgTypes"
        :user-types="disabledContentTypes"
        :organisation="organisation"
        user
        @toggle="toggle"
      />
      <div class="text-body2 ">
        {{ $t('tooltip.disable_transport_types') }}
      </div>
    </div>
  </peek-form>
</template>
<script>
import peekForm from 'peeks/peekForm.vue'
import { mapGetters } from 'vuex'
import { getTravelPreferences, setTravelPreferences } from 'api/user'
import loading from 'utils/loading'
import store from 'store'
import { mutableContentTypes } from 'utils/contentTypes'

const partner = store.getters['partner']

export default {
  components: {
    peekForm
  },
  data () {
    return {
      modify: null,
      organisation: null,
      disabledContentTypes: [],
      userTypes: [],
      mutatedContentTypes: mutableContentTypes(),
      loading: true
    }
  },

  computed: {
    ...mapGetters({
      orgGetter: 'organisation',
      lookupOrg: 'organisations/getOrg',
      authedUser: 'user',
      contentTypes: `contentTypes/${partner.slug}`,
      partner: 'partner'
    }),
    orgTypes () {
      const enabled = []
      // If user is not part of an organisation, give them all options
      if (!this.organisation) {
        this.contentTypes.forEach(type => enabled.push(type.value))
        return enabled
      }
      const policy = this.$store.state.authentication.organisation.attributes
        .travel_policy

      Object.keys(policy).forEach(type => {
        if (policy[type].enabled) {
          enabled.push(type)
        }
      })
      return enabled
    }
  },
  async beforeMount () {
    this.loading = true
    this.organisation = this.$store.state.authentication.organisation

    const partner = store.getters.partner
    loading.start({
      message: this.$t('loading.getting.your_travel_preferences'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    await getTravelPreferences(this.authedUser).then((res) => {
      this.userTypes = res.data
      this.userTypes.forEach(type => {
        if (type.hidden) {
          this.disabledContentTypes.push(type.content)
        }
      })
    }).finally(() => {
      loading.stop()
      this.loading = false
    })
  },
  methods: {
    async toggle (type, enabled) {
      loading.start({
        message: this.$t('loading.updating.your_travel_preferences'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })
      if (enabled) {
        let payload = this.userTypes.find(elem => elem.content === type)
        if (payload) {
          payload.budget = []
          payload.budgets = []
          payload.hidden = true
          payload.preferences = []
        } else {
          payload = {
            budgets: [],
            budget: [],
            content: type,
            hidden: true,
            preferences: []
          }
        }
        await setTravelPreferences(this.authedUser.username, type, payload)
        // Change icon to disabled
        this.disabledContentTypes.push(type)
      } else {
        let payload = this.userTypes.find(elem => elem.content === type)
        if (payload) {
          payload.budget = []
          payload.budgets = []
          payload.hidden = false
          payload.preferences = []
        } else {
          payload = {
            budgets: [],
            budget: [],
            content: type,
            hidden: false,
            preferences: []
          }
        }

        await setTravelPreferences(this.authedUser.username, type, payload)
        // Change icon to enabled
        this.disabledContentTypes = this.disabledContentTypes.filter(disabledType => disabledType !== type)
      }
      const contentType = this.mutatedContentTypes[type] || type.charAt(0).toUpperCase() + type.slice(1)
      loading.stop()
      this.$q.notify({
        message: this.$t('transport_preference_type_updated', { type: contentType }),
        color: 'positive',
        icon: 'far fa-check-double'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  margin: 16px 0 !important
</style>
