
const meta = {
}

const form = {
  name: 'ondemand-derby-parking',
  path: '/on-demand/derby-parking',
  components: {
    default: () => import(/* webbpackChunkName: "ondemand-derby-parking" */ 'pages/ondemand/derby-parking/index.vue')
  },
  meta: {
    ...meta,
    label: 'Ferry: Search'
  }
}

const results = {
  name: 'ondemand-derby-parking-results',
  path: '/on-demand/derby-parking/results',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-derby-parking" */ 'pages/ondemand/derby-parking/results.vue')
  },
  meta: {
    ...meta,
    label: 'Book Parking',
    back: 'home'
  }
}

// const summary = {
//  name: 'ondemand-derby-parking-summary',
//  path: '/on-demand/derby-parking/summary',
//  components: {
//    default: () => import(/* webpackChunkName: "ondemand-derby-parking" */ 'pages/ondemand/derby-parking/summary.vue')
//  },
//  meta: {
//    ...meta,
//    label: 'Ferry: Summary'
//  },
//  beforeEnter
// }

export default { form, results }
