<template>
  <div class="overview row">
    <div class="col-5">
      {{ $t('taxi.vehicle.preferred') }}
    </div>
    <div
      class="col-6 text-dark"
    >
      {{ policy && policy.preferred_vehicle_groups ? policy.preferred_vehicle_groups.map(p => p.label).join(', ') : $t('any') }}
    </div>
    <div class="col-5">
      {{ $t('global_budget') }}
    </div>
    <div
      class="col-6 text-dark"
    >
      {{ policy && policy.global_budget ? '£' + (policy.global_budget / 100) : $t('no_budget_set') }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['policy']
}
</script>

<style lang="stylus" scoped>
.overview > div, .repeater > div
  padding 6px 0

</style>
