import Vue from 'vue'
import VueRouter from 'vue-router'
import autoImport from 'utils/autoImport'

/**
 * Register all the routes - harder than I expected...
 *
 * 1. Grab default exports (must be better way of getting resolved stuff)
 * 2. If we have a path it's a single route exported
 * 3. Otherwise it must be multiple routes exported
 */
let routes = []
autoImport(
  require.context('./routes', false, /^.*\.js$/),
  (context, fileName) => {
    context = context(fileName).default
    if (context.path) {
      routes.push(context)
    } else {
      routes = [
        ...routes,
        ...Object.values(context)
      ]
    }
  }
)
if (process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}
const Router = new VueRouter({
  /*
   * NOTE! Change Vue Router mode from quasar.conf.js -> build -> vueRouterMode
   *
   * When going with "history" mode, please also make sure "build.publicPath"
   * is set to something other than an empty string.
   * Example: '/' instead of ''
   */

  mode: process.env.VUE_APP_HISTORY_MODE || 'history',
  base: process.env.VUE_APP_PUBLIC_PATH || '/',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

/**
 * Global Guards
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#global-guards
 */
autoImport(
  require.context('./beforeEach', false, /^.*\.js$/),
  (context, fileName) => { Router.beforeEach(context(fileName).default) }
)

/**
 * Global Resolve Guards
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#global-resolve-guards
 */
autoImport(
  require.context('./beforeResolve', false, /^.*\.js$/),
  (context, fileName) => { Router.beforeResolve(context(fileName).default) }
)

/**
 * Global After Hooks
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#global-after-hooks
 */
autoImport(
  require.context('./afterEach', false, /^.*\.js$/),
  (context, fileName) => { Router.afterEach(context(fileName).default) }
)

export default Router
