import { Model } from '@vuex-orm/core'

export default class Request extends Model {
  static entity = 'requests'

  static primaryKey = 'reference'

  static fields () {
    return {
      reference: this.string(),
      type: this.string(),
      content_type: this.string(),
      status: this.string(),
      message: this.string(),
      contact_number: this.string(),
      contact_email: this.attr([]),
      booking: this.attr({}),
      organisation: this.attr({}),
      user: this.attr({}),
      requester: this.attr({}),
      assignee: this.attr({}),
      payments: this.attr([]),
      invoices: this.attr([]),
      notes: this.attr([]),
      required_by: this.attr(),
      closed_at: this.attr(null),
      created_at: this.attr({}),
      created_at_full: this.attr({}),
      updated_at: this.attr({}),
      pricingschedule: this.attr({}),
      passenger_name: this.attr(null)
    }
  }
}
