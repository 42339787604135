export default {
  support: {
    support: 'Support',
    number: '03303 11 51 35'
  },
  error: {
    error: 'Error | Errors',
    default: 'An error occurred',
    custom: '@:(error.default): {error}',
    try_again: 'Please try again',
    try_later: '@:(error.default), please try again later',
    retrieving_cards: 'An error occurred while retrieving your saved cards. Please use a new card, or try again later.',
    retrieving_journey: '@:(error.default) while retrieving your journey data. @:(error.try_later)',
    session_key_failed: 'An error occurred while communicating with sagepay. Please try again later.',
    refresh_try_again: '@:(error.default), please refresh and try again',
    account_status_not_changed: '@:(error.default). Account status not changed',
    activation_failed: 'Ticket activation failed. @:(error.try_again)',
    address_not_deleted: '@:(error.default). {address} address not deleted',
    address_not_saved: '@:(error.default). {address} address not saved',
    already_blocked: '@:(error.default). The user is already blocked',
    already_unblocked: '@(error.default). The user is already unblocked',
    booking_must_be_future: 'Your booking must be {num} minutes in the future',
    currently_active_booking: 'You currently have an active booking you must complete',
    disable_all_content_types: 'It is not possible to disable all content types',
    hotel: {
      hotel_unavailable: 'Sorry, this hotel is no longer available',
      no_online_payments: 'Sorry, this hotel is not currently accepting online payments. Please contact the concierge team',
      room_unavailable: 'Sorry, this room is no longer available'
    },
    label_invalid_characters: 'The label {label} contained invalid characters, {actionTaken}.',
    login_signup_cant_complete: 'We can\'t complete your signup. Please contact support on @:(support.number) or click the button below to send them a message',
    invalid_flight_number: 'The flight number is not a valid flight number',
    permission: {
      make_changes: 'Sorry, you don\'t have permission to make these changes'
    },
    organisation_not_created: '@:(error.default). Organisation not created',
    organisation_not_updated: '@:(error.defualt). Organisation not updated',
    password_reset_not_sent: '@:(error.default). Password reset email was not sent',
    purchase_order_number_not_added: '@:(error.default). PO number not added to invoice',
    pricing_schedule_not_updated: '@:(error.default). Pricing schedule not updated',
    processing_data: '@:(error.default) processing your data',
    removing_card: '@:(error.default) removing your card',
    retrieving_brompton: '@:(error.default) while trying to retrieve your brompton account',
    role_not_changed: '@:(error.default). Role not changed',
    search_token_expired: 'Your search token has expired. @:(error.try_again)',
    search_unsuccessful: 'Your search was not successful',
    trying_to_get_more_results: 'We\'ve encountered an issue while trying to get you more results',
    user: {
      not_blocked: '@:(error.default). User not blocked',
      not_created: '@:(error.default). User not created',
      not_invited: '@:(error.default). User not invited: {error}',
      not_unblocked: '@:(error.default). User not unblocked'
    },
    unable_to: {
      create_account: 'Unable to create account',
      find_traveller: 'Unable to find traveller',
      block_unblock: 'Unable to modify the blocked state of this user',
      retrieve_timetable: 'Unable to retrieve timetable',
      save_changes: 'Unable to save changes',
      update_label: 'Unable to update label: {error}',
      update_transport_preference: 'Unable to update transport preference'
    },
    updating_notes: '@:(error.default). Notes not updated',
    incorrect_format: '@:(error.default). Only the following formats are supported: {formats}',
    pasting_prevented: 'Pasting in this field is disabled',
    retrieving_airports: '@:(error.default): could not retrieve airports'
  },
  address_deleted: '{address} address deleted',
  address_saved: '{address} address saved',
  content_type: {
    title: 'Content type',
    bikehire: 'Bike Hire',
    carclub: 'Car Club',
    chauffeur: 'Chauffeur',
    eurostar: 'Eurostar',
    flight: 'Flight | Flights',
    hotel: 'Hotel | Hotels',
    bus: 'Bus | Buses',
    car: 'Car | Cars',
    rental: 'Rental | Rentals',
    ridehailing: 'Taxi | Taxis',
    lounge: 'Lounge',
    onwardtravel: 'Onward Travel',
    other: 'Concierge',
    concierge: 'Concierge',
    taxi: 'Taxi | Taxis',
    train: 'Train | Trains',
    tram: 'Tram | Trams',
    tube: 'Tube',
    rail: 'Train',
    walking: 'Walking',
    bicycle: 'Bicycle',
    drt: 'Demand Responsive Transport',
    ferry: 'Ferry | Ferries',
    gas_station: 'Petrol',
    parking: 'Parking',
    ev_station: 'EV Station | EV Stations',
    atm: 'ATM | ATMs',
    car_wash: 'Car Wash',
    car_repair: 'Car Repair',
    restaurant: 'Restaurant | Restaurants',
    bar: 'Bar | Bars',
    cafe: 'Cafe | Cafes',
    bp: 'BP Mobility Hub',
    srf: 'Sub Regional Flight'
  },
  abbreviation: {
    female: 'F',
    grams_per_kilometre: 'g/km',
    male: 'M',
    hours: 'h',
    minutes: 'm',
    not_applicable: 'N/A',
    kilometres: 'km'
  },
  concierge: {
    action: 'Contact Concierge',
    concierge: 'Concierge',
    enquiry: 'Enquiry',
    any_travel_query: 'Any travel query',
    airbnb_booking: 'Airbnb bookings',
    always_here: 'Our concierge team are always here to help',
    contact_email: 'Contact email for this request',
    contact_number: 'Contact number for this request',
    required_field: 'This field is required',
    flight_terminal: 'Terminal',
    general_query: 'General',
    in_touch: 'A member of the concierge team will be in touch if required. You will be informed of any additional costs',
    other_preferences: 'Other preferences',
    price_estimates: 'Price estimates',
    provide_details: 'Provide details of your request',
    restaurant_reservations: 'Restaurant reservations',
    send: 'Send request',
    some_info_will_be_visible: 'Some info will be visible to our concierge team and suppliers',
    special_requirements: 'Special requirements',
    tell_us: 'Please tell us',
    type_your_request: 'Type your request here',
    amend_cancel_title: 'What do you need to change? | What do you need to cancel?',
    what_to_cancel: 'What to cancel',
    what_to_change: 'What to change',
    who_for: 'Who is the booking for',
    why_cancel: 'Why you need to cancel (if appropriate)',
    why_change: 'Why you need to make a change (if appropriate)'
  },
  loading: {
    default: 'Hold tight',
    loading: 'Loading',
    checking: {
      drt: 'Searching for availability',
      ferry: 'Checking available dates',
      available_extras: 'Checking available extras',
      labels: 'Checking for new labels',
      hotel_availability: 'Checking hotel availability',
      room_availability: 'Checking room availability',
      your_details: 'Checking your details',
      brompton: '@:(loading.default), we\'re checking your brompton details'
    },
    smartsearch: 'Comparing the market for all modes of transport',
    confirming: {
      payment: 'Confirming payment',
      carclub_booking: 'We are confirming your selection and the pricing of this booking'
    },
    getting: {
      ticket_summary: 'Getting ticket summary',
      organisation_travel_preferences: 'Getting organisation travel preferences',
      your_travel_preferences: 'Getting your travel preferences'
    },
    searching: {
      searching: 'Searching',
      docks: 'Searching for available docks',
      carclub: '@:(loading.default), we\'re finding your local car club vehicles',
      taxi: '@:(loading.default), we\'re searching thousands of taxi providers',
      bus: '@:(loading.default), we\'re searching for buses',
      rental: '@:(loading.default), we\'re searching for car rental options',
      flight: '@:(loading.default), we\'re searching for flights',
      train: '@:(loading.default), we\'re searching for trains',
      tram: '@:(loading.default), we\'re searching for trams',
      smartsearch_individual: 'Please bear with us while we search this travel method',
      train_tickets: 'Searching for tickets'
    },
    booking_details: 'Loading booking details',
    preparing: {
      card_forms: 'Preparing card forms',
      booking: 'Preparing booking',
      payment_method: 'Preparing payment method',
      search_form: 'Preparing search form'
    },
    submitting_details: 'Submitting your details',
    processing: {
      booking: 'Your booking request is being processed',
      concierge: 'Your concierge request is being processed',
      cancellation: 'Your cancellation request is being processed',
      ticket: 'Your ticket is being requested'
    },
    updating: {
      organisation_travel_preferences: 'Updating organisation travel preferences',
      your_travel_preferences: 'Updating your personal travel preferences',
      taxi_status: 'Updating taxi status'
    },
    validating_payment: 'Validating payment',
    completing_booking: 'Completing booking'
  },
  login: {
    bp: {
      plan_your_route: 'Plan your route for an easy commute',
      want_to_join: 'Want to join bp Mobility Hub?'
    },
    mobilleo: {
      want_to_join: 'Want to join mobilleo?'
    },
    gohi: {
      want_to_join: 'Want to join Go-Hi?'
    },
    signed_up: 'Signed up, please check your email to validate your account before logging in',
    create_account_check_email: 'Please check your email to complete the creation of your account',
    enter_new_password: 'Please enter your new password',
    validation: {
      email: 'Please use a valid email address',
      passwords_do_not_match: 'Passwords do not match',
      passwords_must_match: 'Passwords must match',
      password_reset: 'Must be at least 8 characters and NOT a dictionary word',
      password_invalid: 'Password is invalid',
      last_name: 'Please provide a first and last name',
      incorrect_domain: 'We’re sorry, only users with {domain} emails can sign up for {partner}'
    },
    unverified_email: 'Your email is not verified. Please verify your email to continue',
    link_expired: 'The link has expired',
    log_in: 'Log in',
    log_in_or_join: 'Log in or join {partner} now to book and manage your journeys',
    no_password_reset: 'Please reset your password if you do not have one',
    incorrect_details: 'The login or password you entered is incorrect. Please check and try again',
    password_reset_expired: [
      'The password reset was not successful.',
      'Please ensure that the password you have entered is at least 8 characters in length and NOT a dictionary word.',
      'It could be that the password reset link you used to get here has expired.'
    ],
    password_reset_link_sent: 'We have sent you a link to reset your password',
    password_reset_success: 'Your password has been reset, please use your new password the next time you log into {partner}',
    retype_new_password: 'Retype your new password',
    send_reset_link: 'Send reset link',
    sign_up: 'Join today, it\'s free to sign up',
    verification: {
      success: 'Your email has been verified.',
      failure: 'Some of the details we received are not what we expected. Please check the link in your email and try again. If you\'re having trouble, please contact our support team on @:(support.number)',
      email_sent: 'Verification email sent',
      check_your_email: 'Please check your email and follow the verification link',
      enter_email: 'Enter your email to receive a new verification link',
      send_new: 'Send new verification email'
    },
    already_a_member: 'Already a member?',
    login_here: 'Login here',
    continue_as_guest: 'Continue as Guest'
  },
  bikehire: {
    active_booking_text: 'You currently have an active booking, and cannot book a second bike from Brompton. In order to return your bike, please use the search form above to book a return slot at a dock with an empty slot',
    validation: {
      age: 'Must be at least 18 years old',
      mobile: 'Mobile number must be in the format (44)7712345678'
    },
    book_return: 'Book return slot',
    closest_docks: 'Your closest bike pick up points',
    brompton: {
      credentials_already_stored: 'Your Brompton credentials are already stored',
      must_match_mobilleo: 'Your Brompton email address and mobile number must match your Mobilleo details to use this service through Mobilleo. The mobile number you enter here will be saved to your Mobilleo profile.',
      status: {
        'Not registered': 'You are not currently registered with Brompton on this account. Select "Add Details" below to add your details',
        'Inactive member': 'You cannot hire bikes on this Brompton account. For more details, please contact Brompton using the details below.',
        'Missing phone number': 'You are not currently registered with Brompton on this account. Select "Add Details" below to add your details',
        'Membership pending': 'Your membership is awaiting approval. If you did not already complete your registration with Brompton, please contact them using the details below.',
        'Return Pending': 'You have already booked a return slot. Please return your bike to your selected dock before booking another bike.'
      },
      bikehire: 'Brompton Bike Hire',
      show: {
        'Brompton Membership': 'Brompton Membership',
        'Log In': 'Log In',
        'Sign Up': 'Sign Up'
      },
      terms: 'By submitting your details, you agree to the terms and conditions set by Brompton, which you can read <a href="https://www.bromptonbikehire.com/page/terms-and-conditions" target="_blank" >here</a>',
      charges_estimate: 'Charges shown are an estimate only and will be subject to change depending on any amendments you make during your booking, for further information, please read Brompton’s Terms & Conditions <a href="https://www.bromptonbikehire.com/page/terms-and-conditions" target="_blank">here</a>',
      payment_estimate: 'All values shown are estimates only. Final payment will be calculated and taken by Brompton once your bike is returned.',
      contact_brompton_to_return: 'After collecting your bike, you must make a separate booking in order to reserve a dock to return the bike. You must contact Brompton on 0203 474 0700 to arrange this return booking. Failure to do so will result in additional hiring costs',
      support_number: '0203 474 0700'
    },
    no_phone_number: 'None set',
    once_signup_complete: 'Once signup is complete, it may be a number of days before you are able to use the Brompton service. Brompton will notify you once your registration is complete.',
    nearby_pickup: 'Nearby pickup locations',
    registration_link_expired: 'Your registration link has expired. If you did not already complete your registration with Brompton, please contact them using the details below.',
    return_to_place: 'Return to {place}',
    search: 'Search bike hire',
    set_details: 'Set details',
    summary_display_only: 'This summary is for display purposes only. To make a booking, please log into your Brompton account',
    want_to_search: 'Just want to search?',
    please_complete_registration: 'If you have not already done so, please complete your registration',
    membership_status: 'Bikehire Membership Status',
    add_details: 'Add details'
  },
  brompton_peek: {
    'Not registered': 'You are not currently registered with Brompton on this account. Select "Add Details" below to add your details',
    'Inactive member': 'You cannot hire bikes on this Brompton account. For more details, please contact Brompton using the details below.',
    'Missing phone number': 'You are not currently registered with Brompton on this account. Select "Add Details" below to add your details',
    'Membership pending': 'Your membership is awaiting approval. If you did not already complete your registration with Brompton, please contact them using the details below.'
  },
  for_queries_contact: 'For any queries related to your registration, please contact:',
  member_status: 'Member Status:',
  phone_number: 'Phone Number:',
  bulk_upload: {
    title: 'Instructions for New Member Account Bulk Uploads',
    download_template: 'Download Template',
    instructions: [
      'Download the CSV template by right-clicking the link DOWNLOAD TEMPLATE below, and selecting "Save link as..."',
      'Open the file (this will most likely open up in Excel or another spreadsheet application which you have installed on your computer)',
      'Using the headings as guides, from row 2 onwards in the spreadsheet, enter the details the employees you wish to create user accounts for',
      'Ensure that you capture the right data under the right headings (see example below as a guide):'
    ],
    column_notes: [
      'For Column I, ‘disable_payments’: To prevent an employee from booking their own travel, enter a ‘1’ in this field, otherwise leave empty. By entering a 1, the employee will not have any payment options available and will not be able to make any bookings themselves.',
      'Once you have captured the employee details you wish to create accounts for, save the file on your computer.',
      'Select the UPLOAD CSV button at the bottom of this screen and select the CSV file which you have saved.',
      'Once the file has been uploaded, the system will create the new accounts automatically and send a welcome email to each employee.'
    ],
    notes: [
      'Columns A,B,C,D are mandatory fields that must be captured before you upload your file',
      'Columns E onwards are not mandatory and can be left blank. Please do not delete the heading names, even if you do not capture any data underneath them'
    ],
    mandatory_headers_text: 'Please fill the following mandatory fields:',
    optional_headers_text: 'You may also add the following non-mandatory headings as well, if you wish:'
  },
  bus: {
    choose: {
      route: 'Choose bus route'
    },
    line_num: 'Bus line {num}',
    tickets_unavailable_for_purchase: 'Bus tickets currently unavailable for in-app purchase',
    ticket: {
      operator: 'Operator',
      area: 'Ticket area',
      duration: 'Duration',
      zone: 'Zone',
      class: 'Class',
      buy: 'Buy Tickets',
      buy_again: 'Buy Again',
      activate_before: 'Valid until',
      show: 'Show ticket to driver',
      activate: 'Activate'
    }
  },
  carclub: {
    add_details: 'Add carclub details',
    credentials: {
      not_set: 'Car club credentials not set',
      required: 'Car club requires you to first set your credentials before you can book.',
      required_2: 'To book a car club with us, you need to store your car club membership details with us here',
      delete: 'You have stored valid car club credentials with us. To remove them from the system, click Delete. You will be able to add them again any time.',
      removed: 'Your car club credentials have been removed',
      validated: 'Your car club credentials have been validated. You can now use car club',
      set_here: 'Set your car club credentials here'
    },
    membership: {
      membership: 'Car club membership',
      information: 'Car club membership information',
      enter_details: 'Please enter your Car Club Membership details here. These will be stored and used when you book an Enterprise Car Club with us',
      sign_in: 'Member sign in',
      sign_up: 'Become a member',
      register_here: 'If you are not currently a Car Club customer and would like to become a member, you can register for Enterprise Car Club here',
      register_directly: 'If you are not currently a Car Club customer and would like to become a member, please contact Enterprise Car Club directly'
    },
    closest_vehicles: 'Your closest car club vehicles',
    details_checked: 'Your details are checked with the supplier',
    incorrect_details: 'Incorrect driver id or password',
    minimum_time_window: 'You must start at least 30 minutes before you finish',
    narrow_results: 'Narrow results',
    partially_availabile: 'Partially available',
    fully_available: 'Fully available',
    adjust_slider: 'Adjust your booking by dragging the availability below',
    with_transmissiontype: 'With {transmissiontype} transmission',
    charges_estimate: 'Charges shown are an estimate only and may be subject to change if the vehicle is not returned either at the agreed date and time or location, if a refueling charge is incurred, optional products are added/removed or the Member Terms and Conditions are modified as agreed between Renter and Enterprise Car Club.',
    expand_view: 'To expand the view please edit the search parameters in the side bar',
    update_details: 'To update your car club details, please delete and re-add them'
  },
  drt: {
    validation: {
      time: 'No services available at that time, please check the timetable for more information',
      passengers: 'You must add at least one passenger',
      location: 'Please choose a location within the highlighted area'
    },
    register: {
      header: 'Dial M for Moray',
      blurb: 'Dial M for Moray is a door-to-door bus service (public roads only) for those unable to use existing forms of transport or who do not have a regular scheduled bus service.  This service is for everyone, regardless of age or disability.',
      instructions: 'In order to use this service, please register your details below. These details will be shared and stored on Moray Council’s database.',
      consent: 'By ticking the box below I consent to the <strong><a href="https://mobilleo.s3.eu-west-2.amazonaws.com/Dial_M_Conditions_of_carriage.pdf" target="_blank" >Terms of Carriage</a></strong>:'
    },
    booking_details: {
      predicted: 'Predicted',
      pickup: 'Pickup',
      dropoff: 'Dropoff',
      predicted_times: 'Predicted Times',
      predicted_pickup_time: '@:(drt.booking_details.predicted) @.lower:(drt.booking_details.pickup) time',
      predicted_dropoff_time: '@:(drt.booking_details.predicted) @.lower:(drt.booking_details.dropoff) time'
    },
    summary: {
      pay_on_vehicle: 'This journey has been flagged as requiring payment at the point of service. Once your booking has been confirmed, the driver will calculate your fare and take payment on the vehicle.'
    },
    mobility_assistance_needed: 'No. of passengers requiring mobility assistance',
    do_you_require_assistance: 'Do you require mobility assistance?',
    type_of_assistance_details: 'Please enter any additional mobility assistance you require',
    do_you_require_space: 'Will you require space for a wheelchair or similar?',
    mobility_type: 'Mobility Type',
    no_service: 'No service',
    no_service_available: 'The service returned no available drivers. Please try another suitable time.',
    check_our: 'Check our {0}',
    operating_hours: 'operating hours'
  },
  ferry: {
    outbound: 'Outbound',
    return: 'Return',
    northlink: {
      dangerous_goods: {
        text: 'Passengers are advised that {0} that could be adapted or intended for use as an offensive weapon are not allowed on board ship without valid lawful reason. If you require further information or intend to travel with these items please contact NorthLink Ferries for guidance.',
        link: 'dangerous goods',
        accept: 'I confirm that I have read the restrictions on dangerous goods and I accept them.'
      },
      terms: {
        text: 'Please read the {0} of travel carefully before you book to see how they affect your specific travel arrangements. The acceptance of these Terms is a condition of purchasing travel from NorthLink Ferries.',
        link: 'Terms and Conditions',
        accept: 'I confirm that I have read the Terms and Conditions of travel and I accept them.'
      }
    },
    timetable: 'Check out our '
  },
  flight: {
    booking_for: 'Flight booking for {name}',
    class: {
      preferred: 'Preferred cabin class',
      business: ' Business',
      cabin: 'Cabin class',
      economy: 'Economy',
      first: 'First',
      premium_economy: 'Premium economy'
    },
    choose: {
      flights: 'Choose flights',
      outbound: 'Choose outbound flight',
      extras: 'Choose flight extras'
    },
    seat: {
      aisle: 'Aisle',
      window: 'Window',
      wing: 'Wing'
    },
    extras: {
      optional: 'Optional extras',
      additional_baggage: 'Additional baggage',
      seats: 'Seats'
    },
    fare_rules: 'Fare rules',
    changes: 'Direct | One change | {n} changes',
    number: 'Flight number',
    outbound: 'Outbound flight',
    return: 'Return flight',
    outbound_time: 'Outbound flight time',
    maximum_changes: 'Max changes',
    no_duration_specific_settings: 'No duration specific settings'
  },
  hotel: {
    about: 'About {hotel}',
    choose: {
      hotel: 'Choose hotel to stay at',
      room_type: 'Choose room type'
    },
    options: {
      all: 'These are all room options available at this hotel',
      cheapest: 'These are the cheapest options for each room and meal type'
    },
    minimum_rating: 'Min star rating',
    name: 'Hotel name',
    room_type: 'Room type',
    rooms_from_price_per_night: '<sub class="text-grey-7 col-12">Rooms from</sub><sub class="price col-12">{price}</sub><sub class="text-grey-8 col-12" style="font-size:9px">per night</sub>',
    no_city_specific_settings: 'No city specific settings'
  },
  derby_parking: {
    permits_required: 'Parking permits are required on certain days and permit holders get reduced pricing. Permit information is available {0}',
    here: 'here',
    see_pricing: 'To see pricing and availability, tap below:',
    have_permit: 'I have a car permit',
    no_permit: 'I don\'t have a car permit',
    motorbike: 'I have a motorbike',
    motorbike_free: 'Motorcycles can park for free in the motorcycle covered bays at the top of Car Park 1 and Kedleston Road.',
    motorbike_no_permit: 'Motorcyclists do not need a permit for this and do not need to pay and display.'
  },
  rental: {
    choose_vehicle: 'Choose rental vehicle',
    no_cars_found: 'No cars found',
    or_similar: '{type} or similar',
    uk_international: 'UK & International car rental'
  },
  taxi: {
    final_fare_calculation: 'Please be aware that for Taxis, the price you have paid may be subject to a final fare calculation and subsequent invoice re-issue.  If paying by card, our Service Delivery team will be in contact to process the difference for you',
    validation: {
      form: 'Origin, destination and departure time are required'
    },
    message_to_driver: 'Message to driver',
    company: 'Taxi company',
    provider: 'Provider',
    cmac_poa: 'POA',
    vehicle: {
      preferred: 'Preferred vehicle groups',
      types: {
        taxi: {
          name: 'Taxi',
          description: 'Standard Taxi'
        },
        mpv: {
          name: 'MPV',
          description: 'MPV'
        },
        wav: {
          name: 'WAV',
          description: 'WAV'
        },
        other: {
          name: 'Other',
          description: 'Other (enter notes below)'
        }
      }
    }
  },
  train: {
    direct: 'Direct',
    outbound: 'Outbound',
    choose: {
      outbound: 'Choose outbound train',
      return: 'Choose return train',
      ticket_type: 'Choose ticket type'
    },
    options: {
      options: 'Options',
      direction: 'Direction',
      adults: 'Adults',
      environment: 'Environment',
      no_seat_reservation: 'No',
      position: 'Position',
      tickets: 'Ticket options',
      reserve_seat: 'Would you like to reserve a seat?'
    },
    class: {
      first: 'First',
      standard: 'Standard',
      preferred: 'Preferred ticket classes'
    },
    tickets_attached_to_booking: 'Tickets will be attached to your journey immediately after booking',
    tickets_collect_after_booking: 'Tickets will be ready for collection shortly after booking',
    other_tickets: 'Other available tickets',
    cannot_guarantee_preferences: 'We will request seats with your selected preferences though they cannot always be guaranteed by the train operator.',
    eticket_available: 'Eticket available',
    eticket_avoid_queues: 'Etickets help you avoid queues',
    initial_vendor: 'Initial vendor',
    route_number: 'Route number',
    seat_direction: 'Seat direction',
    seat_reservation: 'Seat reservation',
    summary: {
      ticket_collection: 'Tickets collections',
      ticket_terms: 'Ticket terms'
    },
    live_departures: 'Live departures'
  },
  tram: {
    choose: {
      route: 'Choose tram route'
    },
    tickets_unavailable_for_purchase: 'Tram tickets currently unavailable for in-app purchase',
    line: 'Tram line {num}'
  },
  tickets: {
    default: 'Bus Ticket | Bus Tickets',
    valid_from: 'Valid from',
    expires_in: 'Ticket expires in',
    need_help: 'Need help selecting a ticket?',
    view_map: 'View ticket zone map',
    ticket: 'Ticket | Tickets'
  },
  smartsearch: {
    smartsearch: 'Journey Planner',
    a_to_b: 'Trying to get from A to B?',
    plan_your_journey: 'Plan your journey with @:(smartsearch.smartsearch)',
    plan_your_journey_here: 'Plan your journey here',
    information_only: 'This travel type is for information purposes. It cannot be booked through this app',
    information_currently: 'This travel type is currently for information purposes. It cannot be booked through this app',
    suggested_route: 'We suggest this time & route. You can change it when you make a booking',
    choose_a_time: 'Choose a time',
    leave_now: 'Leave now',
    gathering_results: 'Gathering more results',
    results: '@:(smartsearch.smartsearch) Results',
    will_take: {
      default: 'Choosing this option will take',
      bus: 'Hopping on a bus will take',
      cycling: 'Go green, cycling will take',
      driving: 'Driving yourself will take',
      eurostar: 'Going with Eurostar will take',
      flight: 'Going by air will take',
      rental: 'Renting and driving will take',
      taxi: 'Going by taxi will take',
      train: 'Riding a train will take',
      tram: 'Using the tram service will take',
      tube: 'Heading underground will take',
      walking: 'Be healthy, walking will take',
      carclub: 'Using Car Club will take'
    },
    get_directions: '{action} to get map directions'
  },
  search_nearby: {
    bikehire: 'Search bikehire docks by location',
    bus: 'Search bus stops by location',
    carclub: 'Search for Enterprise Car Club location',
    flight: 'Search for specific airport',
    generic: 'Search for a specific location',
    rental: 'Search for a rental location',
    rail: 'Search for a specific train station',
    tram: 'Search tram stops by location',
    tube: 'Search for a specific tube station'
  },
  for_queries_contact_logo: 'For any queries related to your registration, please contact:',
  for_queries_contact_company: 'For any queries, please contact {company}',
  three_d_secure: '3D Secure',
  three_d_popup_should_close: 'Please wait, this popup should close soon',
  account: 'Account',
  account_enabled: 'Account enabled',
  account_disabled: 'Account disabled',
  account_details: 'Account details',
  account_settings: 'Account settings',
  active: 'Active',
  activity: 'Activity',
  activity_log: 'Activity log',
  add: 'Add',
  add_new_card: 'Add new card',
  add_note: 'Add note',
  add_notes_here: 'Add notes here',
  add_purchase_order_number: 'Add PO number',
  add_screenshot: 'Add a screenshot',
  add_to_journey: 'Add to existing journey',
  additonal_info: 'Additonal information',
  address: 'Address | addresses',
  address_line_one: 'Address 1',
  address_line_two: 'Address 2',
  address_staying_line_one: 'First line of address (of where you are staying)',
  adjust_your_travel_preferences: 'Adjust your preferences and budgets for specific content and travel',
  adjust_persons_travel_preferences: 'Adjust {name}\'s preferences and budgers for specific content and travel',
  airlines: 'Airlines',
  airport: 'Airport',
  airport_lounge: 'Airport lounge',
  all: 'All',
  all_users_added: 'All users added',
  amend: 'Amend',
  amendment: 'Amendment',
  any: 'Any',
  apply: 'Apply',
  arrival_date_and_time: 'Arrival date and time',
  arrival_time: 'Arrival time',
  arrive_at_place: 'Arrive at {place}',
  arrive_at_time: 'Arrive at destination at {time}',
  attach_labels_to_booking: 'Attach labels to your bookings',
  attach_purchase_order_to_invoice: 'Attach a purchase order number to this invoice',
  availability: 'Availability',
  availability_window: 'Availability window',
  available_invoice_template_variables: 'Available invoice template variables',
  back: 'Back',
  back_to_dashboard: 'Back to dashboard',
  back_to_organisation: 'Back to organisation',
  back_to_passengers: 'Back to Passengers',
  back_to_route: 'Back to Route',
  back_to_sign_in: 'Back to sign in',
  bank_authenticate_popup_text: 'A popup will now open to authenticate your purchase with your bank',
  bank_authorisation: 'Bank authorisation',
  best_price_for_time: 'Best price available for selected time of travel',
  best_route_for_you: 'Which is the best route for you?',
  logged_in: 'Logged in',
  bikes: 'Bikes',
  billing_address: 'Billing address',
  billing_contact: {
    email: 'Billing contact email address',
    name: 'Billing contact name',
    phone: 'Billing contact phone number'
  },
  block: 'Block',
  blocked: 'Blocked',
  book: 'Book',
  book_now: 'Book now',
  book_below: 'Book below',
  booked_at: 'Booked at',
  booking_completed: 'Booking completed',
  booking_complete: {
    booking_confirmed: 'Once your booking has been confirmed, you will be able to view your booking under Bookings and your Journey timeline in your app or desktop.',
    ticket_confirmed: 'Once your ticket has been issued, you will be able to view your ticket under the My Tickets feature in your app',
    email_details: 'Details of your request will be emailed to you and we will be in contact with you shortly',
    reference: '{partner} Ref:'
  },
  booking: {
    booking: 'Booking | Bookings',
    fee: 'Booking fee',
    information: 'Booking information',
    name: 'Booking name',
    reference: 'Booking reference',
    summary: 'Booking summary',
    breakdown: 'Breakdown',
    type: 'Booking type',
    typeof: '{type} booking',
    relevant_information: 'Relevant information about booking',
    record_of_changes: 'Historical record of all changes to this booking',
    which_for: 'Which booking is this for?',
    view: 'View booking',
    view_ticket: 'View Ticket',
    view_attachments: 'View Attachments',
    view_tickets: 'View Tickets',
    return_to_journey: 'Back to Journey',
    meal_allowance: 'Meal Allowance'
  },
  which_service_for: 'Which service do you need help with?',
  bookings_placed: 'Bookings placed',
  bp_mobility_hub: 'BP Mobility Hub',
  bp_nearby_bikehire: 'BP Mobility Hub - Brompton Bike Hire',
  bp_nearby_carclub: 'BP Mobility Hub - Enterprise Car Club',
  branding: 'Branding',
  breakdown: 'Breakdown',
  budget: {
    budget: 'Budget | budgets',
    amount_left: '{amount} left in budget',
    currently_over_amount: 'Currently £{num} over budget',
    currently_under_amount: 'Currently £{num} under budget',
    over: 'Over budget',
    over_by: 'Over budget by {amount}',
    on: 'On budget'
  },
  business_information: 'Business info',
  cancel: 'Cancel',
  cancel_a_booking: 'Cancel a booking',
  cancel_payment: 'Cancel payment',
  cancellation: 'Cancellation',
  cancellation_policy: 'Cancellation policy',
  cannot_credit_credit: 'Cannot credit a credit invoice',
  cannot_find: 'Can\'t find what you\'re looking for?',
  need_assistance: 'Still need assistance with this booking?',
  cash: 'Cash',
  change_a_booking: 'Change a booking',
  change_date_range: 'Change Date Range',
  change_date_selection: 'Change Date Selection',
  change_map_location: 'Change location of map',
  change_named_address: 'Change my {address} address',
  change_password: 'Change password',
  change_role: 'Change role',
  change_times: 'Change times',
  change_area: 'Change search area',
  change_your_password: 'Change your password',
  changes_saved: 'Your changes have been saved',
  changing_travel_preference: 'Changing travel preference',
  changing_will_affect_all_devices: 'Changing this address will affect all devices you use with {partner}',
  chargemaster: 'Chargemaster',
  chauffeur: 'Chauffeur',
  check_in: 'Check in',
  check_narrow_results: 'Please check you\'ve not narrowed the results too much',
  check_out: 'Check out',
  destination: 'Destination',
  origin: 'Origin',
  check_validation: 'Please check the highlighted fields and try again',
  choose_destination: 'Choose destination',
  choose_file: 'Choose file',
  choose_location_or_address: 'Enter a location or an address',
  choose_origin: 'Choose origin',
  choose_profile_picture: 'Choose profile picture',
  city: 'City',
  city_car_club: 'City car club',
  city_location: 'City location',
  city_restrictions: 'City restrictions',
  city_where_staying: 'City (where you are staying)',
  city_of_residence: 'Your city of residence',
  click_to_log_in: 'Click here to log in',
  close: 'Close',
  collect_from: 'Collect from',
  collect_ticket_from_station: 'Collect from any station ticket machine or kiosk',
  company_business_number: 'Company number',
  company_contact_info: 'Company contact info',
  company_mobility_manager: 'Company mobility manager',
  complex_journey: 'Complex journey',
  complete_booking: 'Complete booking',
  complete_sign_up: 'Complete sign up',
  complete_request: 'Complete Request',
  confirm: {
    confirm: 'Confirm',
    ok: 'OK',
    cancellation: 'Are you sure you want to request a cancellation?',
    block_member: 'Are you sure you want to block this member?',
    block_member_name: 'Are you sure you want to block {name}?',
    bulk_add_users: 'Are you sure you want to bulk add users?',
    bulk_add_users_message: 'They will all receive Mobilleo onboarding emails upon completion',
    cancel_payment: 'Are you sure you want to cancel using this payment method? You can always select it or another again afterwards.',
    dates: 'Confirm dates',
    information: 'Confirm information',
    passengers: 'Confirm Passengers',
    password: 'Confirm password',
    purchase_order_number: 'Confirm PO number',
    receive_marketing_brompton: 'I am happy to receive marketing and promotional material from Brompton',
    reset_password: 'Are you sure you want to reset this member\'s password?',
    reset_password_name: 'Are you sure you want to reset {name}\'s password?',
    route: 'Confirm route',
    time_selection: 'Confirm time selection',
    time_window: 'Confirm time window',
    unblock_member: 'Are you sure you want to unblock this member?',
    unblock_member_name: 'Are you sure you want to unblock {name}?',
    vehicle: 'Confirm vehicle',
    title_manage_system_access: 'Are you sure you want the following permission/role changes?'
  },
  confirmation: 'Confirmation',
  contact: {
    email: 'Contact email',
    information: 'Contact information',
    methods_and_preferences: 'Contact methods and personal preferences',
    number: 'Contact number'
  },
  contact_us_phone: 'Please contact us on @:(support.number)',
  continue: 'Continue',
  cost_centre: 'Cost centre',
  cost: {
    per_day: '{num} per day',
    per_night: '{num} per night'
  },
  country: 'Country',
  country_of_residence: 'Country of residence',
  county: 'County',
  create_account: 'Create account',
  create_journey: 'Create a new journey',
  tooltip: {
    create_labels: 'Create and modify labels for attaching to bookings and other resources',
    customise_invoice_text: 'Customise what text is displayed on your invoices',
    customise_organisation: 'Customise how members of your organisation will see the app',
    disable_transport_types: 'Transport types which you disable will become unavailable in the mobility nearby, booking and @:(smartsearch.smartsearch) features of Mobilleo',
    preview_changes: 'Fill out the information below and use the preview above to view your changes',
    preview_label: 'Fill out the information below and use the preview above to view your new label',
    set_meal_allowance: 'Enter a value above to set your company\'s hotel meal allowance policy. Once set this meal allowance will be applied with any eligible hotel rooms booked',
    before_meal_allowance: 'Prior to using this feature, please ensure that our support team has been informed, as they will need to enable this feature on your company account administration settings',
    add_booking_to_journey: 'You can add this booking to a journey here',
    bank_authorisation: 'If your bank\'s authorization page doesn\'t open within the next 10 seconds, try using the button below',
    booking_date: 'The date of travel for that booking/journey',
    booking_reference: 'The automatically generated reference for the booking being invoiced',
    customer_request_booking_change: 'The customer requests that led to changes to the booking',
    email_name_password_reset: 'This will send an email to {name} to reset their password',
    favourite_address: 'This may be used as a suggestion within a location lookup or as part of a booking form',
    image_upload: 'If you have images or screenshots that may assist our concierge team please add them. A maximum of five images allowed. Up to 2MB each',
    image_upload_2: 'You can add up to 5 screenshots or images, max size 2mb',
    invite_many_members: 'Need to invite many members? We recommend using the CSV upload option',
    member_organisation_name: 'The organisation name of the member, if applicable',
    new_journey_will_be_created: 'There are no active or upcoming journeys, a new one will be created with this booking',
    override_global_budget: 'These settings will override the global budget',
    password_memorable_phrase: 'We recommend that you use a memorable phrase as your password',
    profile_pic_supported_file: 'We support JPG, GIF or PNG to be used as profile picture',
    railcard_applied_to_all: 'This will be applied to all valid train tickets you purchase',
    recent_locations: 'Recent searches and saved addresses will appear here',
    tailor_your_choices: 'We use this information to tailor your choices',
    upload_profile_picture: 'Upload any JPG, GIF or PNG to be used as your profile picture, we will crop and resize the image you upload as required.',
    we_encrypt_data: 'We safely encrypt all your personal data and only share it as required to book your travel',
    usa_travel: 'Additional information required for travel to the USA',
    booking_cancellation: 'Request a cancellation',
    cannot_cancel_amend: 'You cannot amend or cancel a booking that has already started'
  },
  create_new: 'Create new',
  created_at: 'Created at',
  credit_this_invoice: 'Credit this invoice',
  current_location: 'Current location',
  current_password: 'Current password',
  custom_invoice_content_template: 'Custom invoice content template',
  custom_range: 'Custom range',
  customer_reviews: 'Customer reviews',
  dashboard: {
    greetings: ['Good morning', 'Good afternoon', 'Good evening'],
    location_disabled: 'Enable your location for weather and map data',
    weather_unavailable: 'Weather information currently unavailable'
  },
  data_saved_securely: 'Your data is saved securely',
  date: {
    from: 'Date from',
    invoice_raised: 'Date raised',
    birth: 'Date of birth',
    start: 'Start date',
    starts_at: 'Starts at {date}',
    end: 'End date',
    expiration: 'Expiration date',
    issue: 'Date of issue (if applicable)',
    return: 'Date of return',
    travel: 'Date of travel',
    date_time_of_travel: 'Departure date and time',
    date_time_of_pick_up: 'Pick up date and time',
    until: 'Date to',
    drop_off: 'Drop off date',
    pick_up: 'Pick up date'
  },
  from: 'From',
  time: {
    time: 'Time',
    start: 'Start time',
    end: 'End time',
    drop_off: 'Drop off time',
    pick_up: 'Pick up time',
    earlier: 'Earlier',
    later: 'Later',
    at: 'at {time}',
    select: 'Select a time',
    select_from: 'Select a time from {time}',
    from: 'Time of travel',
    return: 'Time of return',
    travel: 'Travel time',
    departure: 'Departure time'
  },
  delete: 'Delete',
  deliver_to: 'Deliver to',
  department: 'Department',
  description: 'Description',
  details: 'Details',
  view_tickets: 'View Tickets',
  directions: 'Directions',
  discover_travel_options: 'Discover travel options',
  discovery: 'Discovery',
  dismiss_notification: 'Dismiss',
  display_name: 'Display name',
  distance_away: '{distance} away',
  division: 'Division',
  documents: 'Documents',
  done: 'Done',
  download: 'Download',
  download_app: 'Download the {partner} app today',
  download_csv: 'Download CSV',
  download_pdf: 'Download PDF',
  download_template: 'Download template',
  driver_id: 'Driver id',
  driver_password: 'Driver password',
  drop_off: 'Drop off',
  drop_off_different_location: 'Drop off at a different location',
  duration: 'Duration',
  duration_hours: 'Duration in hours',
  duration_restrictions: 'Duration restrictions',
  edit: 'Edit',
  email: 'Email',
  email_address: 'Email address',
  email_confirmation_sent: 'Email confirmation sent',
  validation: {
    required: {
      email: 'Email is required'
    },
    email: 'Must be a valid email address',
    please_provide: {
      default: 'Please provide {0} for {1}',
      title: 'a title',
      first_name: 'a first name',
      last_name: 'a surname',
      country_code: 'a country',
      address: 'an address',
      city: 'a city',
      county: 'a county',
      post_code: 'a post code',
      phone: 'a phone number',
      dial_code: 'a dialling code',
      email: 'an email address'
    }
  },
  employee_identifier: 'Employee identifier',
  employee: 'Employee | Employees',
  employer_information: 'Employer information',
  enable_disable_org_transport_policy: 'Enable/disable your organisation\'s transport policy preferences below',
  enable_disable_transport_preferences: 'Enable/disable your personal travel preferences below',
  ends_here: 'Ends here',
  input: {
    arrival_airport: 'Enter arrival airport',
    city_name: 'Enter city name',
    departure_airport: 'Enter departure airport',
    destination: 'Enter destination',
    labels: 'Enter labels',
    starting_location: 'Enter starting location',
    location_airport_address: 'Enter location, airport, address or postcode',
    origin: 'Enter origin',
    pick_up_location: 'Please enter a pick up location',
    text: 'Enter text here',
    password: 'Enter your password'
  },
  pick_up: 'Pick up',
  pick_up_location: 'Pick up location',
  pick_up_postcode: 'Pick up postcode',
  drop_off_location: 'Drop off location',
  drop_off_postcode: 'Drop off postcode',
  drop_off_at: 'Drop off at',
  pick_up_from: 'Pick up from',
  enter_email_password_reset_email: 'Enter your email address and we will send you a link to reset your password',
  enter_valid_phone_number: 'Please enter valid phone number with country code',
  enterprise_bay_place: 'At the Enterprise bay at {place}',
  enterprise_car_club: 'Enterprise Car Club',
  javascript_required_new_card: 'Javascript is required to add/use new cards. Please enable it to continue',
  unable_add_new_card: 'Unable to add/use new cards',
  unsupported_browser: 'It looks like you might be trying to run {app} in an unsupported browser. Please try again in the latest version of Chrome, Firefox, Safari.',
  estimated_cost: 'Estimated cost',
  event: 'Event | events',
  exactly_as_on_document: 'Exactly as it appears on the document',
  existing_labels: 'Existing labels',
  expense: 'Expense | Expenses',
  explore_nearby: 'Explore nearby',
  amenities_nearby: 'Amenities nearby',
  favourite_addresses: 'Favourite addresses',
  feedback: 'Feedback',
  invoice_related_to_booking: 'All invoices attached to the booking',
  notes_attached_to_booking: 'All notes attached to the booking',
  files_attached_to_booking: 'All files attached to the booking e.g. ticket',
  fill_all_fields: 'Please fill all fields',
  filter_by_employee: 'Filter by employee',
  filter_by_travel_type: 'Filter by travel type',
  filter_or_new_label: 'Filter or new label',
  filter_results: 'Filter results',
  filter_modify_search: 'Change search',
  filter: 'Filter | Filters',
  find: {
    bus: 'Find a bus | Find buses',
    car: 'Find a car | Find cars',
    flight: 'Find a flight | Find flights',
    hotel: 'Find a hotel | Find hotels',
    taxi: 'Find a taxi | Find taxis',
    train: 'Find a train | find trains',
    tram: 'Find a tram | Find trams',
    dock: 'Find a dock | Find available docks',
    rental_car: 'Find hire car | Find hire cars',
    onward_travel: 'Book Onward Travel'
  },
  name: {
    full: 'Full Name',
    forename: 'First name',
    given: 'Given name | Given names',
    legal: 'Legal name',
    surname: 'Surname'
  },
  forename_required: 'First name is required',
  forgot_password: 'Forgotten password?',
  from_price_per_adult: '<small> From </small><p class="q-pb-sm"><span class="text-bold text-h5 text-black">{price}</span> / adult</p>',
  from_price_per_ticket: '<small> From </small><p class="q-pb-sm"><span class="text-bold text-h5 text-black">{price}</span> / ticket</p>',
  fuel_card: 'Fuel card',
  sex: {
    sex: 'Sex',
    female: 'Female',
    male: 'Male'
  },
  gdpr: {
    removed: 'Removed for GDPR compliance',
    reason: 'Some personally-identifiable details of the passenger have been deliberately removed or obscured on this booking for GDPR compliance.'
  },
  global_budget: 'Global budget',
  going_from: 'Starting from',
  going_from_place: 'Starting from {place}',
  going_to: 'Going to',
  going_to_place: 'Going to {place}',
  help: 'Help',
  history: 'History',
  home: 'Home',
  home_address: 'Home address',
  how_can_we_help: 'How can we help',
  how_you_prefer_to_travel: 'Let us know how you would prefer to travel',
  images_not_available: 'Images not available',
  import_members: 'Import members',
  important: 'Important',
  individual: 'Individual',
  information: 'Information',
  information_unavailable: 'Information unavailable',
  inpost_lockers: 'Inpost lockers',
  instructions: 'Instructions',
  interested_in_knowing_more: 'Interested in knowing more?',
  invite_member: 'Invite member',
  invite_another_member: 'Invite another member',
  invoice: {
    invoice: 'Invoice | Invoices',
    number: 'Number',
    settings: 'Invoice settings',
    type: 'Invoice type',
    with_value: 'With value',
    without_value: 'Without value',
    related_to_booking: 'All invoices related to booking',
    booking_name: 'The name of the booking which is being invoiced',
    journey_name: 'The name of the journey which is being invoiced',
    requester_email: 'The email of the requester on the booking being invoiced',
    requester_name: 'The name of the requester on the booking being invoiced',
    travel_reason: 'The reason for travel provided when creating the booking/journey',
    traveller_email: 'The email of the traveller on the booking being invoiced',
    traveller_name: 'The name of the traveller on the booking being invoiced'
  },
  invoicing: 'Invoicing',
  journey: {
    journey: 'Journey | Journeys',
    files_must_be_documents: 'Journey files must be documents',
    name: 'Journey name',
    info: 'Journey info',
    type: 'Journey type',
    no_journey_found: 'No {journey} found',
    upcoming: 'Upcoming journeys',
    upload_document: 'Upload a document to this journey',
    previous: 'Previous journeys',
    active: 'Active journeys',
    recent: 'Recent journeys',
    validation: {
      name: 'Please provide a journey name or attach to an existing journey'
    }
  },
  key_contact: {
    email: 'Key contact email',
    name: 'Key contact name',
    phone: 'Key contact phone number'
  },
  know_what_you_need: 'Know what you need?',
  label: {
    label: 'Label | Labels',
    colour: 'Label colour',
    description: 'Label description',
    description_tooltip: 'A short description of what the label is and/or is for. Will be shown when mousing over the label',
    see_description_tooltip: 'Move your mouse over the label to see the description if one is set',
    name: 'Label name',
    was_removed: 'It was removed',
    will_be_replaced: 'It will be replaced with {label}',
    attached: 'Successfully attached labels',
    created_attached: 'Successfully created and attached label',
    created: 'Successfully created label',
    detached: 'Successfully detached labels',
    deleted: 'Successfully deleted label',
    updated: 'Successfully updated labels',
    create: 'Create new label',
    edit: 'Edit label',
    create_tooltip: 'TIP: You can create new labels by typing in a name and pressing enter',
    existing_tooltip: 'Labels that have already been created and attached to your organisation',
    name_tooltip: 'The name of the label, this is a unique field and cannot contain spaces',
    validation: 'Labels may only contain letters, numbers, hyphens and underscores',
    create_form: 'Create label form',
    edit_form: 'Edit label form'
  },
  last_active: 'Last active',
  last_seen: 'Last seen {date}',
  lease_vehicle: 'Lease vehicle',
  leave_feedback: 'Leave feedback',
  leave_now: 'Leave now',
  leaving_at: 'Leaving at',
  lets_go: 'Let\'s go',
  location_search: 'Location search',
  incorrect_password: 'Incorrect password, please re-enter',
  lounge: {
    lounge: 'Lounge',
    buddy: 'Lounge buddy',
    club: 'Lounge club',
    no_membership: 'Not a lounge member',
    pass: 'Lounge pass'
  },
  make_sure_to_use_booking_reference: 'Make sure to use a booking reference',
  manage_labels: 'Manage labels',
  manage_mobility_budgets: 'Manage your mobility budgets',
  manage_persons_mobility_budgets: 'Manage {name}\'s mobility budget',
  manual: 'Manual',
  manual_booking: {
    label: 'Manual Booking',
    notes: 'This text will be included in the customer email',
    confirmation_modal: {
      title: 'Confirm booking request',
      field: 'Field',
      input: 'Input'
    }
  },
  meal_allowance: 'Meal allowance',
  member_since: 'Member since {date}',
  member_upload_csv_headers: 'Please check the CSV headings are email, title, first_name, last_name',
  member_upload_select_csv: 'Please select your CSV. If it contains any errors, we will display them here and no users will not be added',
  member: 'Member | Members',
  members_added: 'Members added',
  memberships: 'Memberships',
  minutes: 'Minutes',
  missing_permission: 'Missing permission',
  mobile_number: 'Mobile number',
  mobile_phone: 'Mobile',
  phone: 'Phone',
  mobility: 'Mobility',
  mobility_budget: {
    default: 'Mobility budget | Mobility budgets',
    modify_preferences: 'Modify your preferences',
    increase_flight: 'Want to increase the budget for longer flights?',
    increase_hotel: 'Want to increase the budget for certain cities?',
    unlimited: 'Unlimited',
    current_month: 'My mobility current month'
  },
  transport_budget: {
    default: 'Transport budget | Transport budgets',
    modify_preferences: 'Modify your preferences',
    unlimited: 'Unlimited'
  },
  mobility_nearby: 'Mobility nearby',
  mobilleo_privacy_and_terms: 'By placing your order, you agree to Mobilleo\'s <a target="_blank" href="{privacyURL}">Privacy Policy</a> and <a target="_blank" href="{termsURL}">Terms &amp; Conditions</a>',
  mobilleo_privacy_and_terms_carclub: 'By placing your order, you agree to both Mobilleo\'s Privacy Policy Terms & Conditions and Enterprise Car Club\'s booking Terms',
  mobilleo_privacy_and_terms_signup: 'By signing up you agree to our <strong><a target="_blank" href="https://mobilleo.s3.eu-west-2.amazonaws.com/terms.pdf">terms of use</a></strong> and <strong><a target="_blank" href="https://mobilleo.s3.eu-west-2.amazonaws.com/privacy.pdf">privacy policy</a></strong>.',
  mobilleo_report: 'Mobilleo report',
  more_from_recent_history: 'More from recent history',
  multiple_labels_selected: 'Multiple labels selected',
  my_next_journey: 'My next journey',
  my_travel: 'My travel',
  my_travel_preferences: 'My travel preferences',
  nationality: 'Nationality',
  near: 'Near',
  net_amount: 'Net',
  never: 'Never',
  never_logged_in: 'Never logged in',
  new_password: 'New password',
  no_bookings_found: 'No bookings found',
  no_budget_set: 'No budget set',
  no_documents_found: 'No documents found',
  no_existing_labels: 'No existing labels',
  no_first_class_tickets: 'No first class tickets',
  no_flights_found: 'No flights found',
  no_journeys_found: 'No journeys found',
  no_membership: 'No membership',
  no_name_for_booking: 'No name available for booking',
  no_name_for_journey: 'No name available for journey',
  no_passport: 'No passport',
  no_permission_to_search: 'You don\'t have permission to search that travel type',
  no_permission_to_view_user: 'You do not have permission to view this user',
  no_results_check_filters: 'We can\'t find any results. Check your search filters',
  no_results_found: 'No results found',
  no_reviews_available: 'No reviews available',
  no_saved_cards: 'It looks like you don\'t have any saved cards',
  no_taxis_found: 'No rides found. Please amend your search',
  no_trains_found: 'No trains found',
  no_trams_found: 'No trams found',
  no_weather_found: 'No weather found',
  none: 'None',
  none_found: 'None found',
  none_selected: 'None selected',
  not_available: 'Not available',
  note_added: 'Note added',
  notes: 'Notes',
  nothing_found: 'Nothing found',
  nothing_found_nearby: 'Nothing found nearby',
  nothing_here: 'There\'s nothing here',
  num: {
    pence: '{num} pence',
    adults: '0 adults | 1 adult | {n} adults',
    children: '0 children | 1 child | {n} children',
    infants: '0 infants | 1 infant | {n} infants',
    concessions: '0 concessions | 1 concession | {n} concessions',
    bags: 'No bags | 1 bag | {n} bags',
    bikes_available: 'No bikes available | 1 bike available | {num} / {total} available',
    changes: 'Direct | 1 change | {n} changes',
    employees_range: '{numstr} employees',
    seats: '{num} seats',
    stops: 'Direct | 1 stop | {n} stops',
    hours: 'Less than an hour | 1 hour | {n} hours',
    past_days: 'Past {num} days'
  },
  admin: {
    permissions: {
      name: 'Permission Name',
      slug: 'Permission Slug',
      slug_rules: 'Slug can not contain spaces',
      description: 'Permission Description',
      attached_roles: 'Attached Roles'
    },
    roles: {
      name: 'Role Name',
      slug: 'Role Slug',
      slug_rules: 'Slug can not contain spaces',
      level: 'Role Level',
      description: 'Role Description',
      attached_permissions: 'Attached Permissions'
    },
    peek_user_system_access_title: 'Manage {user} system access',
    user_access_change_warning: 'The current implementation requirs 1 API call per addition/removal of each permission/role.',
    user_access_total_changes: 'This change will require a total of {n} API calls(s), please be mindful of rate-limits.'
  },
  onwardtravel: {
    cmac_poa: 'POA',
    concierge: 'Onward Travel Concierge Request',
    customer_reference: 'Customer reference',
    customer_reference_hint: 'Job number e.g. D101AA001',
    no_personal_info: 'Do not include personal passenger details!',
    concierge_button: 'Click here to complete a concierge request',
    concierge_prompt: 'Click the button below to complete the booking request',
    driver_notes: {
      label: 'Note to driver',
      placeholder: 'e.g. Passenger will be wearing a red coat...'
    },
    estimated_distance: 'Estimated distance',
    estimated_distance_miles: 'Estimated distance in miles',
    estimated_trip_duration: 'Estimated trip duration',
    internal_details: 'Internal Details',
    internal_notes: {
      label: 'Internal Notes',
      placeholder: 'e.g. Customer preferred X taxi service over Y, so I booked X for them...'
    },
    passenger_details: 'Passenger details',
    passenger_mobile_number: 'Passenger\'s mobile number',
    required_by: 'Required by',
    step_choose_result: 'Choose vehicle',
    taxi_company: 'Taxi company',
    cost_per_mile: {
      over_budget: 'The estimated cost per mile for this journey is above policy',
      contact_concierge: 'Click here send this request to Mobilleo SDC',
      summary: 'Cost Per Mile Summary',
      policy: 'Cost Per Mile Policy',
      booking: 'Cost Per Mile for this Booking'
    }
  },
  rac: {
    business_area: 'Business Area',
    cost_centre: 'Cost Centre',
    vehicle: {
      type: 'Vehicle Type',
      type_hint: 'Taxi vehicle type',
      reg: 'Vehicle Registration',
      reg_hint: 'Registration number of the broken-down vehicle'
    }
  },
  hours: 'Hours',
  number_of: {
    adults: 'Number of adults',
    employees: 'Number of employees',
    people: 'Number of people',
    travellers: 'Number of travellers',
    passengers: 'Number of Passengers'
  },
  baggage_pieces: 'Baggage pieces',
  on_time: 'On time',
  one_way_return: 'One way | Return',
  open_image_new_tab: 'Open image in a new tab',
  organisation: {
    organisation: 'Organisation | organisations',
    account_number: 'Organisation account number',
    address: 'Organisation address',
    branding_colour: 'Organisation branding colour',
    branding_logo: 'Organisation branding logo',
    created: 'Organisation created',
    information: 'Organisational information, contact information and address',
    labels: 'Your organisation\'s labels',
    name: 'Organisation name',
    profile: 'Organisation profile',
    profile_details: 'Organisation profile details',
    settings: 'Organisation settings',
    updated: 'Organisation updated',
    branding: '{org}\'s branding'
  },
  origin_to_destination: '<strong>{origin}</strong> to<br><strong class="destination">{destination}</strong>',
  what3words_to_destination: '<span class="text-red">///</span><strong>{origin}</strong><br><strong>{origin_context}</strong><br>to<br><strong class="destination">{destination}</strong>',
  what3words_to_what3words: '<span class="text-red">///</span><strong>{origin}</strong><br><strong>{origin_context}</strong><br>to<br><span class="text-red">///</span><strong class="destination">{destination}</strong><br><strong>{destination_context}</strong>',
  origin_to_what3words: '<strong>{origin}</strong><br>to<br><span class="text-red">///</span><strong class="destination">{destination}</strong><br><strong>{destination_context}</strong>',
  not3words_to_destination: '<span class="text-red">///</span><strong>{origin}</strong><br>to<br><strong class="destination">{destination}</strong>',
  not3words_to_not3words: '<span class="text-red">///</span><strong>{origin}</strong><br>to<br><span class="text-red">///</span><strong class="destination">{destination}</strong>',
  origin_to_not3words: '<strong>{origin}</strong><br>to<br><span class="text-red">///</span><strong class="destination">{destination}</strong>',
  place_to_place: '{origin} to {destination}',
  other: 'Other',
  other_car_club: 'Other car club',
  parking: 'Parking',
  parking_location: 'Parking location',
  passenger: 'Passenger | Passengers',
  passenger_x: 'passenger {n}',
  passenger_information: 'Passenger information',
  passenger_name: 'Lead passenger name',
  passport: {
    passport: 'Passport',
    ensure_details: 'Ensure all details are exactly as they appear on your passport',
    expires_date: '{num} expires {date}',
    expiry: 'Passport expiry',
    information: 'Passport information',
    issuing_country: 'Passport issuing country',
    number: 'Passport number'
  },
  password: 'Password',
  password_changed: 'Password changed',
  password_reset: 'Password reset',
  pay_by: {
    account: 'Pay by account',
    card: 'Pay by card'
  },
  payment: 'Payment',
  payment_card: {
    card: 'Payment card | Payment cards',
    company: 'Company card',
    credit: 'Credit card',
    name: 'Card name',
    removed: 'Card removed',
    save: 'Save card?',
    saved: 'Saved cards'
  },
  payment_method: 'Payment method',
  payment_will_be_taken_by: 'Payment will be taken by {company} once your trip has been completed. Click below to make your booking',
  permission: 'Permission | Permissions',
  permitted_cost: {
    default: 'Cost permitted',
    flights: 'Permitted cost per flight',
    flights_total: 'Permitted total cost for flight bookings',
    hotel: 'Permitted cost per night for hotel bookings',
    rental: 'Permitted cost per day for rental bookings',
    taxi: 'Permitted cost per journey for taxi bookings',
    train: 'Permitted cost per trip for train bookings'
  },
  peek: {
    profile: {
      picture_instructions: 'Click the above, to upload a new profile image.',
      upload_limit: 'Uploads are limited to {limit}.',
      supported_formats: 'The following formats are supported:',
      uploading: 'An upload is in progress, please wait'
    }
  },
  person_needs_to_add_credentials: '{person} needs to add their credentials in their account, before you\'re able to book on their behalf',
  personalisation: 'Personalisation',
  phone_geo_code: 'Code',
  phone_number_required: 'Phone number is required',
  photos: 'Photos',
  platform_num: 'Platform {num}',
  purchase_order: {
    order: 'Purchase Order',
    po_number: 'PO number',
    number: 'Purchase order number',
    number_added: 'PO number added to invoice',
    cannot_be_changed: 'Purchase order numbers cannot be changed once submitted',
    cannot_be_set: 'PO numbers cannot be set on a credit invoice',
    required: 'A purchase order number is required',
    submit: 'Submit PO number'
  },
  pool_car: 'Pool car',
  post_code: 'Post code',
  powered_by: 'Powered by',
  preferences: 'Preferences',
  preferred_payment_method: 'Preferred payment method',
  preview_chip: 'Preview chip',
  price: 'Price',
  price_free: 'Free',
  price_per_ticket: 'Price per ticket',
  pricing_schedule_updated: 'Pricing schedule updated',
  priority_pass: 'Priority pass',
  privacy_policy: 'Privacy Policy',
  profile: 'Profile',
  profile_and_contact_information: 'Profile and contact information',
  profile_for: 'Profile for {name}',
  profile_settings: 'Profile settings',
  provide_reason_for_travel: 'Provide a reason for travel if required',
  purchase: 'Purchase',
  purchase_credit: 'Purchase credit',
  radius_from_location: 'Radius {num} {unit} from location',
  railcard_concession: {
    railcard: {
      default: 'Railcard',
      add: 'Add another railcard',
      remove: 'Remove railcard',
      more_than_adults: 'You cannot add more railcards than passengers'
    },
    concession: {
      default: 'Concession',
      add: 'Add another concession',
      remove: 'Remove concession',
      more_than_adults: 'You cannot add more concessions than adults'
    }
  },
  reason_for_travel: 'Reason for travel',
  recent_bookings: 'Recent bookings',
  recent_locations: 'Recent locations',
  tax: {
    amount: 'Tax amount',
    reduced_rate: 'Reduced rate',
    standard_rate: 'Standard rate',
    super_reduced_rate: 'Super-reduced rate',
    zero_rate: 'zero rate',
    vat: 'VAT',
    parking_rate: 'Parking rate'
  },
  reference: 'Reference',
  refresh: 'Refresh',
  refresh_to_see_changes: 'You need to refresh to see the changes',
  related: 'Related',
  remove: 'Remove',
  report_problem: 'Report a problem',
  reporting: 'Reporting',
  request_new_password: 'Click here to get a new one',
  requester: 'Requester',
  requester_email: 'Requester email',
  requester_name: 'Requester name',
  requesters_receive_booking_confirmation: 'Requesters receive booking confirmation emails',
  request: 'Request | requests',
  request_information: 'Request information',
  request_information_required: 'Request information is required',
  requests: 'Requests',
  reset: 'Reset',
  reset_password: 'Reset password',
  reset_password_email_sent: 'Password reset email sent',
  reset_persons_password: 'Reset {name}\'s password',
  restaurant: 'Restaurant | restaurants',
  results: 'Results',
  return_summary: 'Return summary',
  return_within_one_month: 'Return within one month',
  review_and_submit: 'Review and submit',
  role_changed_to: 'Role change to {role}',
  role: 'Role | Roles',
  sales: 'Sales',
  sales_credit: 'Sales credit',
  save: {
    save: 'Save',
    address: 'Save address',
    password: 'Save password',
    passport: 'Save passport',
    picture: 'Save picture'
  },
  sea: 'Sea',
  search: 'Search',
  search_mobilleo: 'Search Mobilleo',
  search_again: 'Search again',
  search_by_invoice_number: 'Search by invoice number',
  search_for_address: 'Search for an address',
  search_for_member: 'Search for a member',
  search_location: 'Search location',
  security: 'Security',
  select_an_option_to_continue: 'Select an option to continue',
  select_date_range: 'Select your date range',
  select_pickup_location: 'Select your preferred pickup location',
  select_ticket_delivery_option: 'Select ticket delivery option',
  select_vehicle: 'Select your preferred vehicle',
  self_employed: 'Self employed',
  session_time_out: 'Session time-out',
  provide_new_password: 'Please provide a new password',
  provide_old_password: 'Please provide your current password',
  show_all: 'Show all',
  show_cheapest: 'Show cheapest',
  showing: 'Showing',
  showing_all_num_flights: 'There are no flights | There is only 1 flight | Showing all {n} flights',
  showing_num_of_total_flights: 'Showing 0 of {total} flights | Showing 1 of {total} flights | Showing {num} of {total} flights',
  sign_out: 'Sign out',
  sign_up: 'Sign up',
  single_line_item: 'Single line item',
  sort_by: 'Sort by',
  spend: 'Spend',
  state: 'State',
  state_where_you_are_staying: 'State (of where you are staying)',
  status: 'Status',
  staying_at: 'Staying at',
  submit: 'Submit',
  profile_picture_updated: 'Your profile picture has been updated',
  summary: 'Summary',
  surname_required: 'Last name is required',
  switch: 'Switch',
  system: 'System',
  system_action: 'System action',
  other_travel_information: 'Other travel information',
  terms_of_use: 'Terms of use',
  thank_you: 'Thank you',
  theres_more: 'There are {num} more',
  timeline: 'Timeline',
  title: 'Title',
  title_is_required: 'Title is required',
  title_list: ['Dame', 'Dr', 'Lady', 'Lord', 'Miss', 'Mr', 'Mrs', 'Ms', 'Prof', 'Rev', 'Sir', 'Sister'],
  today: 'Today',
  tomorrow: 'Tomorrow',
  today_with_time: 'Today, {time}',
  tomorrow_with_time: 'Tomorrow, {time}',
  token_expire_logged_out: 'You have been logged out due to inactivity',
  total_cost: 'Total',
  total_cost_rental: 'Total cost for {num} rental',
  total_cost_including_fees: 'Total sales price (inc. fees & tax)',
  total_monthly_budget: 'Total monthly budget',
  transport_policy: 'Transport policy',
  transport_policy_disabled_detail: 'The selected mode is not authorised for payment by corporate account',
  transport_policy_disabled_continue: 'To continue, pay using credit card or PayPal',
  transport_preference_type_updated: 'Transport preference {type} updated',
  transport_preferences: 'Transport preferences',
  transport_type: 'Transport type',
  travel_breakdown: 'Travel breakdown',
  travel_content: 'Travel content',
  travel_documents: 'Travel documents',
  travel_preferences: 'Travel preferences',
  travel_questions: 'Travel questions',
  travel_tips: 'Travel tips',
  travel_type: 'Travel Type',
  travel_type_label: 'Personal journey',
  travel_type_business_private: 'Business | Private',
  traveller: 'Traveller | Travellers',
  traveller_with_name: 'Traveller: {name}',
  traveller_email: 'Traveller email',
  traveller_name: 'Traveller name',
  type: 'Type',
  pic_formats: '.peg .jpe .jpg .gif .png .webp only',
  unblock: 'Unblock',
  unknown: 'Unknown',
  unknown_transport_type: 'Unknown transport type',
  until: 'Until',
  to: 'to',
  update: {
    available: 'Update available',
    home_address: 'Update home address',
    picture: 'Update picture',
    profile_picture: 'Update profile picture',
    work_address: 'Update work address'
  },
  updated: {
    meal_allowance: 'Update meal allowance',
    orgnames_branding: 'Updated {name}\'s branding',
    organisations_branding: 'Updated organisation\'s branding'
  },
  upload_csv: 'Upload CSV',
  user: {
    user: 'User | users',
    blocked: 'User blocked',
    unblocked: 'User unblocked',
    created: 'User created',
    invited: 'User invited',
    validation_failed: 'Validation failed. No users added'
  },
  vat_number: 'VAT number',
  vehicle: 'Vehicle | vehicles',
  company_owned_vehicle: 'Company owned vehicle',
  vehicle_from_company: '{vehicle}<br><small>from {company}</small>',
  vehicle_registration_number: 'Vehicle reg',
  vehicle_type: 'Vehicle type',
  vehicles_in_the_area: 'Vehicles in the area',
  own_vehicle: 'My own vehicle',
  view: 'View',
  virgin_atlantic: 'Virgin Atlantic',
  weather: 'Weather',
  weather_location_prevented: 'You have prevented Mobilleo from providing you with information based on your location. If you wish to use Mobilleo discovery please change the settings in your browser to permit location tracking',
  website: 'Website',
  week_to_date: 'Week to date',
  who_is_travelling: 'Who is travelling',
  wild_bean_cafe: 'Wild Bean Cafe',
  work: 'Work',
  work_address: 'Work address',
  yes: 'Yes',
  yesterday: 'Yesterday',
  you_are_over_budget: 'You are over budget',
  you_are_within_budget: 'You are within your budget',
  your_budget: 'Your budget',
  your_phone_number: 'Your phone number',
  your_profile: 'Your profile',
  zip_code_where_staying: 'Zip code (of where you are staying)',
  current_month: 'Current Month',
  no_resource_found: 'No {resource} found',
  additional_information: 'Additional information',
  no_buses_found: 'No buses found',
  where_are_you_flying: 'Where are you flying?',
  where_would_you_like_to_stay: 'Where would you like to stay?',
  where_are_you_travelling: 'Where are you travelling to?',
  where_are_you_going: 'Where are you going?',
  when_are_you_departing: 'When are you departing?',
  when_are_you_coming_back: 'When are you coming back?',
  british_airways: 'British Airways',
  fuel: 'Fuel',
  theatre: 'Theatre',
  driving: 'Driving',
  travel_policy: 'Travel Policy',
  success: 'Success',
  per_hour: 'per hour',
  use_different_card: 'Use different card',
  unnamed_address: 'Unnamed Address',
  update_available: 'Update Available',
  know_where_youre_going: 'Know where you\'re going?',
  where_to: 'Where to?',
  select_nearby: 'Or select from nearby',
  asap: 'Now',
  upcoming: 'Upcoming',
  past: 'Past',
  starts_after: 'Starts after',
  ends_before: 'Ends before',
  click_on_map_or_type: 'tap map or type to search',
  adults: 'Adults',
  children: 'Children',
  search_by_postcode: 'Search by postcode',
  please_select_one: 'Please select one',
  terms_and_conditions: 'Terms and Conditions',
  terms_of_carriage: 'Terms of Carriage',
  public_transport_ticket: 'Buy Bus Tickets',
  signup: {
    not_a_member: 'Not a member?',
    sign_up_here: 'Sign up here'
  },
  days_of_week: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  infants: 'Infants',
  concession: 'Concessions',
  route: 'Route | Routes',
  derby: {
    sociability_introduction: 'Sociability helps disabled people find accessible places. Whatever your needs – get detailed, reliable & accurate accessibility information for local venues in the palm of your hand.',
    sociability_link: 'Go to Sociability',
    need_help: 'Need help?',
    contact_us: 'Tap here to contact the DerbyGo team'
  },
  powered_by_partner: '{partner} is brought to you by {intermediate} in partnership with FOD Mobility Group',
  contact_us: 'Contact Us',
  send: 'Send',
  feedback_form: {
    placeholder_text: 'Give as much detail as you can to enable us to help',
    detail: 'Detail',
    add_image: 'Add image',
    options: [
      'General queries',
      'Help with booking',
      'mango bus bookings',
      'MiPermit parking bookings'
    ],
    amend_placeholder: 'What do you need to change? e.g. date, time, service etc. Give as much detail as possible.\n\nA member of the team will be in touch if required and will inform you of any additional costs or refunds due.',
    amend_text: 'Use this page to amend an existing booking',
    amend_title: 'Amend Booking'
  },
  cancel_form: {
    title: 'Cancel Booking',
    subtitle: 'Use this page to cancel an existing booking',
    reference: 'Booking reference',
    placeholder: 'Please enter the details of what you would like to cancel. Give as much detail as possible.\n\nA member of the team will be in touch if required and will inform you of any additional costs or refunds due.',
    detail: 'Reason for cancellation'
  },
  parking_restrictions: 'Restrictions may apply, see details when selecting parking facilities'
}
