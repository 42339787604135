<template>
  <q-select
    v-model="model"
    v-bind="$attrs"
    :options="options"
    @input="$emit('input', model)"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String
    },
    minHour: {
      type: [Number, String],
      default: 0,
      validator (value) {
        return value >= 0 && value < 24
      }
    },
    maxHour: {
      type: [Number, String],
      default: 24,
      validator (value) {
        return value >= 0 && value < 24
      }
    },
    step: {
      type: [Number, String],
      default: 30,
      validator (value) {
        return value >= 0 && value < 60
      }
    }
  },
  data () {
    return {
      model: null
    }
  },
  computed: {
    /**
     * Magic, magic everywhere...
     *
     * Don't ask me, I've already forgot how I got this to work.
     */
    options () {
      return Array.from({
        length: 24 * 60 / this.step
      }, (v, i) => {
        let h = Math.floor(i * this.step / 60)
        let m = i * this.step - h * 60
        let min = m < 10 ? '0' + m : m
        let hrs = h < 10 ? '0' + h : h

        return {
          label: `${hrs}:${min}`,
          value: `${hrs}:${min}`,
          disable: h < this.minHour || h > this.maxHour || (h === this.maxHour && m !== 0)
        }
      }).filter(v => !v.disable)
    }
  },
  created () {
    this.model = this.value
  }
}
</script>
