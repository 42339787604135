<template>
  <peek-form :footer="false" title="Permissions">
    <div class="q-pa-md">
      <div class="row q-col-gutter-sm">
        <div class="col">
          <m-group-title label="Existing Permissions" sublabel="Permissions that have already been created in the system." />
        </div>
        <div class="col-12 col-md-5 col-xl-3 column justify-center">
          <q-btn
            :disable="loading || !hasPermission('permissions.create')"
            color="primary"
            label="Create new Permission"
            class="full-width"
            dense
            no-caps
            @click="openForm()"
          />
        </div>
      </div>
      <div class="q-my-sm">
        <q-card>
          <q-card-section>
            <q-input v-model="search" :disable="loading" :label="loading ? 'Refreshing permissions . . .' : 'Filter'" @input="filter" />
          </q-card-section>
          <q-separator />
          <q-scroll-area style="height: calc(100vh - 250px)">
            <q-list class="bg-white">
              <q-intersection v-for="(permission, index) in filteredPermissions" :key="permission.$id" transition="jump-right" class="example-item">
                <q-item clickable @click="openForm(permission.slug)">
                  <q-item-section>
                    <q-item-label class="text-capitalize">
                      {{ permission.name }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ permission.slug }}
                    </q-item-label>
                  </q-item-section>
                  <q-item-section class="text-caption">
                    {{ permission.description }}
                  </q-item-section>
                  <q-item-section side>
                    <q-btn :disable="!hasPermission('permissions.update')" color="primary" dense flat label="Edit" @click="openForm(permission.slug)" />
                  </q-item-section>
                </q-item>
                <q-separator v-if="index < filteredPermissions.length" />
              </q-intersection>
              <q-item v-if="loading">
                <q-item-section>
                  <q-item-label>
                    <q-skeleton type="text" width="200px" />
                  </q-item-label>
                  <q-item-label caption>
                    <q-skeleton type="text" width="150px" />
                  </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-skeleton type="text" width="300px" />
                </q-item-section>
                <q-item-section side>
                  <q-skeleton type="QBtn" width="34px" height="24px" />
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </q-card>
        <m-empty-state v-if="!filteredPermissions.length && !loading" />
      </div>
    </div>
  </peek-form>
</template>
<script>
import authentication from 'mixins/authentication'
import { debounce } from 'lodash'
import peekForm from 'peeks/peekForm.vue'
import Permission from 'store/models/permission'
export default {
  name: 'PeekSystemSettingsPermissions',
  components: { peekForm },
  mixins: [ authentication ],
  data () {
    return {
      loading: false,
      search: '',
      filteredPermissions: []
    }
  },
  computed: {
    permissions () {
      return Permission.all()
    }
  },
  beforeMount () {
    this.loading = true
    if (this.permissions.length) this.filteredPermissions = this.permissions
    this.$store.dispatch('entities/permissions/load').then(() => {
      this.filteredPermissions = this.permissions
      this.loading = false
    })
  },
  methods: {
    openForm (permission = null) {
      if (this.hasPermission('permissions.update')) {
        this.$router.push({
          hash: '/system-settings/permissions/form',
          query: permission ? { permission } : null
        })
      }
    },
    filter: debounce(function () {
      this.filteredPermissions = []
      this.$nextTick(() => {
        this.filteredPermissions = this.permissions.filter(perm => {
          return perm.name.includes(this.search) || perm.slug.includes(this.search)
        })
      })
    }, 750)
  }
}
</script>
<style lang="stylus" scoped>
.example-item
  height 55px
  // width 734px
</style>
