import { cloneDeep } from 'lodash'
import { nearby as getNearby } from 'api/locations'
import { departures as trainTimetable } from 'api/train'
import { timetable as busTimetable } from 'api/bus'
import { Notify } from 'quasar'
import i18n from 'i18n'
import { hasPermission } from 'utils/authentication'
import getPartnerFromHostname from 'utils/partner'

const initalState = {
  items: null,
  location: null,
  searchType: null,
  loading: false,
  departures: null,
  station: null,
  selected: null
}

const state = cloneDeep(initalState)

function getLocation (getters, fresh = false) {
  if (fresh || !state.location) {
    return getters['map/getCenterAsUrlValue']
  } else return state.location
}

const getters = {
  items: state => state.items,
  location: state => state.location,
  searchType: state => state.searchType,
  loading: state => state.loading,
  departures: state => state.departures,
  station: state => state.station,
  selected: state => state.selected
}

const actions = {
  searchNearby ({ commit, rootGetters, dispatch }, { location, searchType, fresh }) {
    const partner = getPartnerFromHostname().slug
    commit('setLoading', true)
    actions.resetItems({ commit })
    actions.resetDepartures({ commit })
    commit('saveSelected', null)
    const typeToSearch = searchType || state.searchType
    actions.resetSearchType({ commit })
    const locationToSearch = location || getLocation(rootGetters, fresh)
    if (searchType === 'bp') {
      commit('saveLocation', locationToSearch)
      commit('saveItems', [
        {
          name: i18n.t('enterprise_car_club'),
          type: 'bp-c',
          attributes: {},
          bookable: {
            path: '/on-demand/carclub'
          },
          latitude: 51.497566,
          longitude: 0.005851
        },
        {
          name: i18n.t('bikehire.brompton.bikehire'),
          type: 'bp-b',
          attributes: {},
          bookable: {
            path: '/on-demand/bikehire'
          },
          latitude: 51.497566,
          longitude: 0.005852
        },
        {
          name: i18n.t('wild_bean_cafe'),
          type: 'website',
          attributes: {
            url: 'http://bpmobilityhub.com/'
          },
          latitude: 51.497566,
          longitude: 0.005853

        },
        {
          name: i18n.t('chargemaster'),
          type: 'website',
          attributes: {
            url: 'https://bpchargemaster.com/'
          },
          latitude: 51.497566,
          longitude: 0.005854

        },
        {
          name: i18n.t('inpost_lockers'),
          type: 'website',
          attributes: {
            url: 'https://www.inpost.co.uk/'
          },
          latitude: 51.497566,
          longitude: 0.005855

        }
      ])
      commit('setLoading', false)
      commit('saveSearchType', 'bp')
      dispatch('map/setZoom', { level: 10 }, { root: true })
      dispatch('map/setZoom', { level: 15 }, { root: true })
    } else if (searchType === 'unibus') {
      commit('saveLocation', locationToSearch)
      commit('saveItems', [])
      commit('saveSearchType', 'unibus')
      commit('setLoading', false)
    } else if (searchType === 'ferry' && !hasPermission('feature.mfd1.263.ferry.flow')) {
      commit('saveLocation', locationToSearch)
      commit('saveItems', [])
      commit('saveSearchType', 'ferry-soon')
      commit('setLoading', false)
    } else if (searchType === 'parking' && partner === 'derbygo') {
      commit('saveLocation', locationToSearch)
      commit('saveItems', [
        {
          type: 'parking',
          name: 'Kedleston Road Campus',
          description: 'Kedleston Rd, Derby',
          latitude: 52.936820,
          longitude: -1.498037,
          attributes: {
            place_id: 'ChIJ4eekKCX0eUgRw3t5-13xCoQ',
            plus_code: {
              compound_code: 'XF65+2X Derby',
              global_code: '9C4WXF65+2X'
            },
            rating: 5
          }
        }
      ])
      commit('saveSearchType', 'parking')
      commit('setLoading', false)
    } else {
      getNearby({ location: locationToSearch, type: typeToSearch }, partner)
        .then(response => {
          commit('saveItems', response.data)
          commit('saveLocation', locationToSearch)
          commit('saveSearchType', typeToSearch)
          if (response.data.length > 0) {
            const { latitude, longitude } = response.data[0]
            commit('saveSelected', { id: 'card-0', lat: latitude, lng: longitude })
          }
        })
        .finally(() => {
          commit('setLoading', false)
        })
    }
  },
  setTimetable ({ commit }, item) {
    commit('setLoading', true)
    actions.resetDepartures({ commit })
    // Create a Promise base on item type
    const busOrTrain = (item) => {
      if (item.type === 'bus') {
        return busTimetable(item.attributes.atcocode).then(res => {
          // Shape bus data
          const departures = []
          res.data.departures.forEach(bus => {
            const { direction, operator, line, aimed_departure_time, best_departure_estimate } = bus

            const delay = aimed_departure_time !== best_departure_estimate

            departures.push({
              destination: direction,
              operator: operator.name,
              info: line.number,
              id: `${direction}_${aimed_departure_time}`,
              time: aimed_departure_time,
              delay,
              delay_info: best_departure_estimate
            })
          })
          return { data: departures }
        })
      } else if (item.type === 'rail') {
        return trainTimetable(item.description).then(res => {
          // Shape train data
          const departures = []
          if (!res.data || !res.data.length) {
            Notify.create({
              message: i18n.t('error.unable_to.retrieve_timetable'),
              color: 'negative',
              icon: 'clear'
            })
            return
          }
          res.data.forEach(train => {
            const { destination, operator, platform, service_id, arrives, departs, delay_reason, cancel_reason } = train

            const delay = delay_reason !== null
            const cancelled = cancel_reason !== null

            const timeString = arrives?.scheduled_at.date || departs.scheduled_at.date

            const time = timeString.slice(11, 16)

            departures.push({
              destination: destination.name,
              operator: operator.name,
              info: platform ? i18n.t('platform_num', { num: platform }) : i18n.t('ends_here'),
              id: service_id,
              time,
              delay,
              cancelled,
              delay_info: cancel_reason || delay_reason
            })
          })

          return { data: departures }
        })
      } else {
        return Promise.reject(Error('Type is neither bus or train'))
      }
    }

    return busOrTrain(item)
      .then((response) => {
        commit('saveDepartures', response.data)
        commit('saveStation', item)
      })
      .catch(err => {
        return { msg: err.message }
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  setSelected ({ commit }, item) {
    commit('saveSelected', item)
  },
  resetItems ({ commit }) {
    commit('saveItems', null)
  },
  resetLocation ({ commit }) {
    commit('saveLocation', null)
  },
  resetSearchType ({ commit }) {
    commit('saveSearchType', null)
  },
  resetDepartures ({ commit }) {
    commit('saveDepartures', null)
  },
  resetStation ({ commit }) {
    commit('saveStation', null)
  },
  resetAll ({ commit }) {
    actions.resetItems({ commit })
    actions.resetLocation({ commit })
    actions.resetSearchType({ commit })
    actions.resetDepartures({ commit })
    actions.resetStation({ commit })
  }
}

const mutations = {
  saveItems (state, items) {
    state.items = items
  },
  saveLocation (state, location) {
    state.location = location
  },
  saveSearchType (state, searchType) {
    state.searchType = searchType
  },
  saveDepartures (state, departures) {
    state.departures = departures
  },
  saveStation (state, station) {
    state.station = station
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  saveSelected (state, item) {
    state.selected = item
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
