import http from './http'
import authentication from 'mixins/authentication'

function includePricingSchedule (params) {
  if (!params) params = {}
  if (!authentication.methods.hasRole('middleoffice.outsource')) params.include = `${params.include || ''},pricingschedule`
  return params
}

export function index (params) {
  params = includePricingSchedule(params)
  return http.get(`/requests`, { params: {
    ...params
  } })
}

export function get (reference, params) {
  params = includePricingSchedule(params)
  params.include = `${params.include || ''},documents`
  return http.get(`/requests/${reference}`, {
    params: {
      ...params
    }
  })
}

export function getRequestActivity (reference, params) {
  return http.get(`/requests/${reference}/activity`, { params })
}

export function put (reference, params) {
  return http.put(`/requests/${reference}`, params)
}

export function post (params) {
  return http.post(`/requests`, params)
}

export function notes (reference, params) {
  return http.get(`/request/${reference}/notes`)
}

export function createNote (reference, params) {
  return http.post(`/request/${reference}/notes`, params)
}

export function createBooking (reference, params) {
  return http.post(`/requests/${reference}/booking`, params)
}

export function amendBooking (reference, bookingReference, params) {
  return http.post(`/request/${reference}/amend/booking`, params)
}

export function cancelBooking (reference, bookingReference, params) {
  return http.post(`/request/${reference}/cancel/booking`, params)
}

export function close (reference, params) {
  return http.post(`/requests/${reference}/close`, params)
}

export function createPayment (reference, params) {
  return http.post(`/requests/${reference}/payment`, params)
}

export function createInvoice (reference, params) {
  return http.post(`/requests/${reference}/invoices`, params)
}

export function assignUser (reference, userId) {
  return http.post(`/requests/${reference}/assign/${userId}`)
}

export function unassign (reference) {
  return http.delete(`/requests/${reference}/unassign`)
}
