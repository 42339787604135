<template>
  <peek-form :footer="false" :title="$tc('mobility_budget.default')" :subtitle="$t('how_you_prefer_to_travel')">
    <q-page padding>
      <q-card class="budget">
        <q-list>
          <q-item v-ripple clickable>
            <q-item-section>
              <q-item-label>
                {{ $tc('mobility_budget.default') }}
              </q-item-label>
              <q-item-label caption>
                {{ peekUser.monthly_budget.text }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn :to="{hash: '/user-settings/budgets'}" flat color="primary">
                {{ $t('edit') }}
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>
      <policy-card :user="peekUser" />
    </q-page>
  </peek-form>
</template>

<script>
import User from 'store/models/user'
import { mapGetters } from 'vuex'
import peekForm from 'peeks/peekForm.vue'
import policyCard from 'peeks/mobility-budget/policy-card'
export default {
  components: { peekForm, policyCard },
  computed: {
    ...mapGetters({
      activeUser: 'entities/users/peekUser',
      authedUser: 'user'
    }),
    peekUser () {
      return this.activeUser ? User.find(this.activeUser) : null
    }
  },
  created () {
    if (!this.activeUser) {
      const username = this.$route.query.username || this.authedUser.username
      this.$store.dispatch('entities/users/setPeekUser', { username })
    }
  }
}
</script>

<style scoped lang="stylus">
  .budget
    margin-bottom: 24px
</style>
