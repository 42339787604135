<template>
  <q-card class="q-px-md q-py-md">
    <div class="col">
      <div class="row q-col-gutter-md">
        <template v-if="!loading">
          <div
            v-for="(type, index) in contentTypes"
            :key="index"
            class="col-3 text-center cursor-pointer relative-position"
            :class="{ orgDisabled: isOrgDisabled(type.value), user, userDisabled: isUserDisabled(type.value)}"
            @click="!hasRole('guest') ? toggle(type) : null"
          >
            <q-avatar
              v-if="type.asset"
              v-ripple
              class="relative-position"
              size="52px"
            >
              <img :src="require(`assets/${type.asset}`)" alt="">
            </q-avatar>
            <q-avatar
              v-else
              v-ripple
              :color="type.color.icon"
              class="relative-position"
              size="52px"
              :icon="`fas fa-${type.icon}`"
            />
            <div class="text-subtitle1">
              {{ type.label }}
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="i in 4" :key="i" class="col-3 row justify-center">
            <q-skeleton type="circle" size="64px" class="col-12" />
            <div class="col-12 row justify-center">
              <q-skeleton
                height="10px"
                width="50%"
                class="q-mt-sm"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </q-card>
</template>
<script>
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
export default {
  mixins: [authentication],
  props: {
    loading: {
      type: Boolean
    },
    contentTypes: {
      type: Array,
      required: true
    },
    userTypes: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: Array
    },
    organisation: {
      type: Object
    },
    orgTypes: {
      type: Array,
      default: () => ([])
    },
    user: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner',
      travelPreferences: 'preferences'
    }),
    enabledContentTypes: {
      get () {
        return this.val
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  methods: {
    toggle (type) {
      if (!this.user) {
        if (!this.isOrgDisabled(type.value)) {
          this.$emit('toggle', type.value, true)
          this.travelPreferences[type.value].hidden = true
        } else {
          this.$emit('toggle', type.value, false)
          this.travelPreferences[type.value].hidden = false
        }
      } else {
        if (!this.isUserDisabled(type.value)) {
          this.$emit('toggle', type.value, true)
          this.travelPreferences[type.value].hidden = true
        } else {
          this.$emit('toggle', type.value, false)
          this.travelPreferences[type.value].hidden = false
        }
      }
    },
    isOrgDisabled (type) {
      return !this.orgTypes.includes(type)
    },
    isUserDisabled (type) {
      return this.userTypes.includes(type)
    }
  }
}
</script>
<style lang="stylus">
.orgDisabled, .userDisabled.user
  opacity: 0.3
  .q-avatar:after
    content ""
    position absolute
    height 4px
    background-color $grey-8
    outline 4px white solid
    top 50%
    left -8px
    right -8px
    transform translateY(-50%) rotate(45deg)

.orgDisabled.user
  display: none
</style>
