import authentication from 'mixins/authentication'

const beforeEnter = (to, from, next) => {
  authentication.methods.hasRole('backoffice') || authentication.methods.hasRole('admin') || authentication.methods.hasRole('middleoffice.outsource') ? next() : next('/dashboard')
}

export const newBooking = {
  name: 'new-request-booking',
  path: '/new/booking',
  components: {
    default: () => import(/* webpackChunkName: "requests" */'pages/request/new/booking.vue')
  },
  props: { default: true },
  beforeEnter
}

export const newAmend = {
  name: 'new-request-amend',
  path: '/new/amend',
  components: {
    default: () => import(/* webpackChunkName: "requests" */'pages/request/new/amend.vue')
  },
  props: { default: true },
  beforeEnter
}

export const newCancellation = {
  name: 'new-request-cancellation',
  path: '/new/cancellation',
  components: {
    default: () => import(/* webpackChunkName: "requests" */'pages/request/new/cancellation.vue')
  },
  props: { default: true },
  beforeEnter
}

export const index = {
  name: 'requests',
  path: '/requests',
  components: {
    default: () => import(/* webpackChunkName: "requests" */'pages/requests/index.vue')
  },
  beforeEnter
}

export const view = {
  name: 'view-request',
  path: '/request/:reference',
  components: {
    default: () => import(/* webpackChunkName: "requests" */'pages/request/view/index.vue')
  },
  props: { default: true },
  beforeEnter
}

export const book = {
  name: 'create-request-booking',
  path: '/request/:reference/book',
  components: {
    default: () => import(/* webpackChunkName: "requests" */'pages/request/request-actions/book/index.vue'),
    right: () => import(/* webpackChunkName: "requests" */'pages/request/request-actions/book/sidebar.vue')
  },
  props: { default: true },
  beforeEnter
}

export const amend = {
  name: 'create-request-amend',
  path: '/request/:reference/amend/:booking',
  components: {
    default: () => import(/* webpackChunkName: "requests" */'pages/request/request-actions/book/index.vue'),
    right: () => import(/* webpackChunkName: "requests" */'pages/request/request-actions/book/sidebar.vue')
  },
  props: { default: true },
  beforeEnter
}

export const cancel = {
  name: 'create-request-cancel',
  path: '/request/:reference/cancel/:booking',
  components: {
    default: () => import(/* webpackChunkName: "requests" */'pages/request/request-actions/cancel/index.vue'),
    right: () => import(/* webpackChunkName: "requests" */'pages/request/request-actions/book/sidebar.vue')
  },
  props: { default: true },
  beforeEnter
}

export default { newBooking, newAmend, newCancellation, index, view, book, amend, cancel }
