export const form = {
  name: 'ondemand-srf',
  path: '/on-demand/local-flight',
  meta: {
    label: 'SRF: Search'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-srf" */ 'pages/ondemand/srf/index.vue')
  }
}

export const results = {
  name: 'ondemand-srf-results',
  path: '/on-demand/local-flight/results',
  meta: {
    label: 'SRF: Results'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-srf" */ 'pages/ondemand/srf/results.vue'),
    right: () => import(/* webpackChunkName: "ondemand-srf" */ 'pages/ondemand/srf/sidebar.vue')
  }
}

export const summary = {
  name: 'ondemand-srf-summary',
  path: '/on-demand/local-flights/summary',
  meta: {
    label: 'SRF: Summary'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-srf" */ 'pages/ondemand/srf/summary.vue'),
    right: () => import(/* webpackChunkName: "ondemand-srf" */ 'pages/ondemand/srf/sidebar.vue')
  }
}

export default { form, results, summary }
