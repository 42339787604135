
import { index as getAllRequests, assignUser, unassign } from 'api/requests'

import Request from 'store/models/request'

function vm (r) {
  return {
    ...r,
    required_by: r.required_by ? r.required_by.date : null,
    closed_at: r.closed_at ? r.closed_at.date : null,
    completed_at: r.completed_at ? r.completed_at.date : null,
    created_at: r.created_at ? r.created_at.date : null,
    updated_at: r.updated_at ? r.updated_at.date : null
  }
}

const actions = {
  async loadAllRequests (context, filters) {
    try {
      const response = await getAllRequests(filters)
      const requests = response.data
      await Request.insert({ data: requests.map(vm) })
      return response.meta.pagination
    } catch (error) {
      console.log(error)
    }
  },
  setAssignee ({ commit, getters, dispatch }, params) {
    return assignUser(params.requestId, params.user.username)
      .then(res => {
        Request.update({
          where: params.requestId,
          data: {
            assignee: params.user
          }
        })
      })
  },
  unassign ({ commit, getters, dispatch }, params) {
    return unassign(params.requestId)
      .then(res => {
        Request.update({
          where: params.requestId,
          data: {
            assignee: null
          }
        })
      })
  }
}

export default {
  actions
}
