<template>
  <q-item class="relative">
    <q-item-section>
      <q-item-label label class="header">
        <div>{{ info.title }}</div>
        <q-btn :to="{hash: `/mobility-budget/${info.name}`}" flat color="primary">
          {{ $t('edit') }}
        </q-btn>
      </q-item-label>
      <q-item-label caption>
        <component :is="overview" :policy="policy" />
      </q-item-label>
    </q-item-section>
  </q-item>
</template>

<script>
import flightOverview from '../flights/overview.vue'
import hotelOverview from '../hotels/overview.vue'
import rentalOverview from '../rental/overview.vue'
import ridehailingOverview from '../ridehailing/overview.vue'
import trainOverview from '../trains/overview.vue'
export default {
  props: ['info', 'policy'],
  computed: {
    overview () {
      if (this.info.name === 'flights') {
        return flightOverview
      } else if (this.info.name === 'hotels') {
        return hotelOverview
      } else if (this.info.name === 'rental') {
        return rentalOverview
      } else if (this.info.name === 'ridehailing') {
        return ridehailingOverview
      } else if (this.info.name === 'trains') {
        return trainOverview
      }
      return false
    }
  }
}
</script>

<style lang="stylus" scoped>
.header
  display flex
  justify-content space-between
</style>
