/**
 * Check if service-worker has been updated recently.
 */
export default (to, from, next) => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistration().then(sw => {
      if (sw && sw.active) sw.update()
    })
  }
  next()
}
