<template>
  <peek-form
    :title="$tc('content_type.hotel')"
    :subtitle="$t('mobility_budget.modify_preferences')"
    :footer-title="$t('tooltip.tailor_your_choices')"
    @save="submit"
  >
    <q-page padding>
      <m-group-title :label="$tc('budget.budget')" />
      <q-card class="bg-white text-dark">
        <q-card-section>
          <money-field
            v-model="policy.global_budget"
            :label="$t('global_budget')"
            :error="$v.policy.global_budget.$error"
            :hint="$t('permitted_cost.hotel')"
            @blur="$v.policy.global_budget.$touch"
          />
        </q-card-section>
        <m-banner
          :title="$t('mobility_budget.increase_hotel')"
          :message="$t('tooltip.override_global_budget')"
          borderless
        />
        <q-card-section>
          <template v-for="(item, index) in policy.city_budgets">
            <city-budget
              :key="item.city ? item.city.value : 0"
              v-model="policy.city_budgets[index]"
              :index="index"
              :v="$v"
              class="row gutter-sm"
              @remove="removeCityBudget"
            />
          </template>
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import User from 'store/models/user'
import { mapGetters } from 'vuex'
import { requiredIf } from 'vuelidate/lib/validators'
import peekForm from 'peeks/peekForm.vue'
import moneyField from '@/MFields/money.vue'
import cityBudget from './city-budget.vue'
export default {
  components: {
    peekForm,
    moneyField,
    cityBudget
  },
  data () {
    return {
      peekUser: null,
      term: null,
      policy: {
        global_budget: null,
        city_budgets: []
      }
    }
  },
  computed: {
    ...mapGetters({
      activeUser: 'entities/users/peekUser'
    })
  },
  watch: {
    'policy.city_budgets': {
      deep: true,
      handler (val) {
        const last = val[val.length - 1]

        if (last.budget) {
          this.addCityBudget()
        }
      }
    }
  },
  created () {
    this.peekUser = User.find(this.activeUser)
    if (this.peekUser.meta.travel_policy && this.peekUser.meta.travel_policy.hotels) {
      this.policy = {
        ...this.policy,
        ...JSON.parse(JSON.stringify(this.peekUser.meta.travel_policy.hotels))
      }
    }
    if (!this.policy.city_budgets.length) {
      this.addCityBudget()
    }
  },
  validations: {
    policy: {
      global_budget: { },
      city_budgets: {
        $each: {
          city: {
            requiredIf: requiredIf(vm => !!vm.budget)
          },
          budget: {
            requiredIf: requiredIf(vm => !!vm.city)
          }
        }
      }
    }
  },
  methods: {
    addCityBudget () {
      this.policy.city_budgets.push({ city: null, budget: null })
    },
    removeCityBudget (index) {
      this.policy.city_budgets.splice(index, 1)
    },
    submit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$store.dispatch('entities/users/updateUser', {
          username: this.peekUser.username,
          attributes: {
            travel_policy: {
              ...this.peekUser.meta.travel_policy,
              hotels: {
                ...this.policy,
                city_budgets: this.policy.city_budgets.filter(b => b.city && b.budget)
              }
            }
          }
        }).then(() => {
          this.$router.back()
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
  .separator
    margin 18px 0 28px 0

  .city-header
    justify-content space-between
    align-items center
</style>
