<template>
  <div>
    <m-group-title :label="$t('booking.which_for')" class="q-mt-lg" />
    <q-card>
      <q-card-section>
        <div class="row q-col-gutter-sm">
          <div class="col-12 col-sm-4">
            <m-select-query
              v-model="request.booking"
              :label="$t('booking.reference')"
              icon="place"
              :filled="!$q.platform.is.mobile"
              :query="autocomplete"
              :error="$v.request.booking.$error"
              :error-message="$v.request.booking.$error ? $t('concierge.required_field') : ''"
            />
          </div>
          <div class="col-12 col-sm-8">
            <small>{{ $t('concierge.in_touch') }}</small>
          </div>
        </div>
      </q-card-section>
    </q-card>
    <m-group-title :label="title" class="q-mt-lg" />
    <q-card class="bg-white text-dark">
      <q-card-section>
        <div class="row">
          <div class="col-12 row">
            <q-input
              v-model="request.message"
              class="col-12 col-sm-8"
              :placeholder="$t('concierge.provide_details')"
              :filled="!$q.platform.is.mobile"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
              autogrow
              :error="$v.request.message.$error"
            />
            <div
              class="bg-white col-12 col-sm-4 q-pa-md text-caption"
              style="margin-bottom: 28px"
              :class="{
                'order-first': $q.platform.is.mobile
              }"
            >
              <strong>{{ $t('concierge.tell_us') }}:</strong>
              <ul v-if="type === 'amendment'" style="margin:0; padding:0; margin-left: 10px">
                <li>{{ $t('concierge.what_to_change') }}</li>
                <li>{{ $t('concierge.why_change') }}</li>
              </ul>
              <ul v-if="type === 'cancellation'" style="margin:0; padding:0; margin-left: 10px">
                <li>{{ $t('concierge.what_to_cancel') }}</li>
                <li>{{ $t('concierge.why_cancel') }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row q-col-gutter-sm">
          <div class="col-sm-6">
            <q-input
              v-model="request.contact_number"
              type="tel"
              :label="$t('concierge.contact_number')"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
              :error="$v.request.contact_number.$error"
            />
          </div>
          <div class="col-sm-6">
            <q-input
              v-model="request.contact_email"
              type="email"
              :label="$t('concierge.contact_email')"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
              :error="$v.request.contact_email.$error"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>

    <m-group-title :label="$t('add_screenshot')" class="q-mt-lg" />
    <file-upload-card v-model="files" />
  </div>
</template>

<script type="text/javascript">
import { pickBy } from 'lodash'
import { mapGetters } from 'vuex'
import http from 'api/http'
import fileUploadCard from './file-upload-card.vue'
import authentication from 'mixins/authentication'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Concierge',
  components: {
    fileUploadCard
  },
  mixins: [authentication],
  data () {
    return {
      request: {
        content_type: null,
        message: null,
        contact_number: null,
        contact_email: null,
        booking: null
      },
      files: [],
      people: {
        traveller: null,
        requester: null
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    type () {
      return this.$route.query.type
    },
    title () {
      if (this.type === 'amendment') {
        return this.$tc('concierge.amend_cancel_title')
      } else {
        return this.$tc('concierge.amend_cancel_title', 2)
      }
    }
  },
  watch: {
    'request.booking' (booking) {
      this.people.traveller = booking.booking
        ? {
          label: booking.booking.user.data.display_name,
          value: booking.booking.user.data.username
        }
        : null
    }
  },
  created () {
    this.request.contact_email = this.user.email
    this.request.contact_number = this.user.meta.mobile

    this.people.requester = {
      label: this.user.display_name,
      value: this.user.username
    }

    if (this.$route.query.booking) {
      return http
        .get(`/bookings/${this.$route.query.booking}`, {
          params: {
            include: 'user'
          }
        })
        .then(res => {
          this.request = {
            ...this.request,
            booking: this.formatBooking(res.data)
          }
          this.people.traveller = {
            label: res.data.user.data.display_name,
            value: res.data.user.data.username
          }
        })
    }
  },
  methods: {
    formatBooking (booking) {
      return {
        label: booking.reference,
        value: booking.reference,
        description: booking.name,
        booking
      }
    },
    autocomplete (string) {
      // This is wrong but the index bookings request in the api file has includes hard coded
      // it should just have includes passed to it. Needs to be looked at another time
      return http
        .get('/bookings', {
          params: {
            include: 'user',
            reference: string
          }
        })
        .then(bookings => {
          if (!bookings.data.length) {
            return [{
              label: this.$t('no_bookings_found'),
              value: this.$t('none_found'),
              description: this.$t('make_sure_to_use_booking_reference')
            }]
          }

          return bookings.data.map(this.formatBooking)
        })
    },
    submit () {
      this.$v.$touch()
      if (this.$v.$error) return
      const body = {
        type: this.type,
        requester: this.people.requester.value,
        user: this.people.traveller.value,
        message: this.request.message,
        contact_number: this.request.contact_number,
        contact_email: this.request.contact_email,
        booking: this.request.booking.value,
        content_type: this.request.booking.booking.type
      }

      let data = new FormData()
      Object.keys(pickBy(body)).forEach(k => {
        data.append(k, body[k])
      })

      if (this.files.length) {
        this.files.forEach(f => {
          data.append('files[]', f.file)
        })
      }

      http
        .post(`requests`, data)
        .then(response => {
          this.$router.push({
            name: 'dashboard',
            params: {
              booking_reference: response.data.reference
            }
          })
        })
        .catch(e => {
          this.$q.notify({
            message: this.$t('fill_all_fields'),
            position: 'top-right'
          })
        })
    }
  },
  validations: {
    request: {
      message: { required },
      contact_number: { required },
      contact_email: { required },
      booking: {
        value: { required }
      }
    },
    people: {
      requester: { required },
      traveller: { required }
    }
  }
}
</script>

<style lang="stylus">
.concierge-peek .q-textarea .q-field__native
  min-height 260px
</style>
