<template>
  <peek-form
    :title="$t('invoice_settings')"
    @save="submit"
  >
    <q-page padding>
      <m-invoice-customiser
        v-model="invoiceContent"
      />
    </q-page>
  </peek-form>
</template>
<script>
import peekForm from 'peeks/peekForm.vue'
import { mapGetters } from 'vuex'
import User from 'store/models/user'
export default {
  components: {
    peekForm
  },
  data () {
    return {
      invoiceContent: null
    }
  },
  computed: {
    ...mapGetters({
      peekUser: 'entities/users/peekUser'
    }),
    user: {
      get () {
        return User.find(this.peekUser)
      }
    }
  },
  created () {
    this.invoiceContent = this.user.meta.invoice_custom_content
  },
  methods: {
    submit () {
      this.$store.dispatch('entities/users/updateUser', {
        ...this.user,
        attributes: {
          invoice_custom_content: this.invoiceContent
        }
      })
    }
  }
}
</script>
