<template>
  <peek-form
    :title="$tc('content_type.taxi')"
    :subtitle="$t('mobility_budget.modify_preferences')"
    :footer-title="$t('tooltip.tailor_your_choices')"
    @save="submit"
  >
    <q-page padding>
      <m-group-title :label="$t('preferences')" />
      <q-card class="bg-white text-dark">
        <q-card-section>
          <q-select
            v-model="selectedOptions"
            :error="$v.policy.preferred_vehicle_groups.$error"
            :options="vehicleOptions"
            multiple
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            :emit-value="false"
            :label="$t('taxi.vehicle.preferred')"
            @input="(val) => {
              $v.policy.preferred_vehicle_groups.$touch()
            }"
          />
        </q-card-section>
      </q-card>
      <m-group-title :label="$tc('budget.budget')" />
      <q-card class="bg-white">
        <q-card-section>
          <money-field
            v-model="policy.global_budget"
            :label="$t('global_budget')"
            :error="$v.policy.global_budget.$error"
            :hint="$t('permitted_cost.taxi')"
            @blur="$v.policy.global_budget.$touch"
          />
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import User from 'store/models/user'
import { mapGetters } from 'vuex'
import peekForm from 'peeks/peekForm.vue'
import moneyField from '@/MFields/money.vue'
export default {
  components: {
    peekForm,
    moneyField
  },
  data () {
    return {
      peekUser: null,
      policy: {
        preferred_vehicle_groups: [],
        global_budget: null
      },
      vehicleOptions: [
        {
          label: this.$tc('content_type.taxi'),
          value: 'Taxi'
        },
        {
          label: this.$t('taxi.vehicle.types.exec.name'),
          value: 'Exec'
        },
        {
          label: this.$t('taxi.vehicle.types.mpv.name'),
          value: 'MPV'
        },
        {
          label: this.$t('other'),
          value: 'Other'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activeUser: 'entities/users/peekUser'
    }),
    selectedOptions: {
      get () {
        const vm = this
        const selectedVehicles = []
        this.vehicleOptions.forEach(o => {
          if (vm.policy.preferred_vehicle_groups.some(i => i.label === o.label)) {
            selectedVehicles.push(o)
          }
        })
        return selectedVehicles
      },
      set (val) {
        this.policy.preferred_vehicle_groups = val
      }
    }
  },
  created () {
    this.peekUser = User.find(this.activeUser)
    if (this.peekUser.meta.travel_policy && this.peekUser.meta.travel_policy.ridehailing) {
      this.policy = {
        ...this.policy,
        ...JSON.parse(JSON.stringify(this.peekUser.meta.travel_policy.ridehailing))
      }
    }
  },
  validations: {
    policy: {
      preferred_vehicle_groups: { },
      global_budget: { }
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$store.dispatch('entities/users/updateUser', {
          username: this.peekUser.username,
          attributes: {
            travel_policy: {
              ...this.peekUser.meta.travel_policy,
              ridehailing: this.policy
            }
          }
        }).then(() => {
          this.$router.back()
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
</style>
