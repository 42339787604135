<template>
  <div
    :style="{paddingTop: paddingTop || size}"
    class="text-center"
  >
    <q-icon
      :name="icon"
      :size="size"
      color="primary"
    />
    <h4
      :style="`font-size:${fontSize}px; line-height: ${fontSize * 1.5}px`"
      class="text-faded text-center"
    >
      <slot>{{ label || $t('nothing_found') }}</slot>
    </h4>
  </div>
</template>

<script>
export default {
  name: 'MEmptyState',
  props: {
    icon: {
      type: String,
      default: 'help'
    },
    paddingTop: {
      type: String
    },
    size: {
      type: String,
      default: '128px'
    },
    fontSize: {
      type: Number,
      default: 34
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
