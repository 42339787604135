import { Platform } from 'quasar'

import store from 'store/modules/ondemand'

const beforeEnter = (to, from, next) => {
  !store.state.stash.parameters ? next({ name: 'ondemand-trains' }) : next()
}

export const mobileForm = {
  name: 'ondemand-train-mobile',
  path: '/on-demand/trains',
  meta: {
    label: 'Train: Search'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-train-mobile" */ 'pages/ondemand/mobile/trains.vue')
  }
}

export const form = {
  name: 'ondemand-trains',
  path: '/on-demand/trains',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-train" */ 'pages/ondemand/train/index.vue')
  },
  beforeEnter (to, from, next) {
    if (Platform.is.mobile) {
      return next({
        name: 'ondemand-train-mobile',
        params: to.params
      })
    }
    next()
  }
}

function createDepartReturn (direction) {
  return {
    name: `ondemand-train-${direction}`,
    path: `/on-demand/train/${direction}`,
    meta: {
      label: `Train: ${direction === 'depart' ? 'Outbound' : 'Return'}`
    },
    components: {
      default: () => import(/* webpackChunkName: "ondemand-train" */ 'pages/ondemand/train/results/index.vue'),
      right: () => import(/* webpackChunkName: "ondemand-train" */ 'pages/ondemand/train/sidebar/index.vue')
    },
    props: {
      default: {
        leg: direction
      },
      right: {
        leg: direction
      }
    },
    beforeEnter
  }
}

export const departLeg = createDepartReturn('depart')

export const returnLeg = createDepartReturn('return')

export const tickets = {
  name: 'ondemand-train-tickets',
  path: '/on-demand/trains/tickets',
  meta: {
    label: 'Train: Tickets'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-train" */ 'pages/ondemand/train/tickets/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-train" */ 'pages/ondemand/train/sidebar/index.vue')
  },
  beforeEnter
}

export const options = {
  name: 'ondemand-train-options',
  path: '/on-demand/trains/options',
  meta: {
    label: 'Train: Options'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-train" */ 'pages/ondemand/train/options/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-train" */ 'pages/ondemand/train/sidebar/index.vue')
  },
  beforeEnter
}

export const summary = {
  name: 'ondemand-train-summary',
  path: '/on-demand/trains/summary',
  meta: {
    label: 'Train: Summary'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-train" */ 'pages/ondemand/train/summary/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-train" */ 'pages/ondemand/train/sidebar/index.vue')
  },
  beforeEnter
}

export default { form, departLeg, returnLeg, tickets, options, summary, mobileForm }
