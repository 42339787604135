<template>
  <span>
    <q-chip
      :label="name"
      class="display-chip text-bold"
      :class="`bg-${colour || 'primary'}`"
      v-bind="$attrs"
      @remove="$emit('remove')"
    />
    <q-tooltip v-if="description">
      {{ description }}
    </q-tooltip>
  </span>
</template>
<script>
import colours from 'mixins/colours.js'
export default {
  mixins: [colours],
  props: {
    name: String,
    description: String,
    colour: String
  }
}
</script>
