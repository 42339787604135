<template>
  <peek-form
    v-if="organisation"
    :title="$t('transport_policy')"
    :footer="false"
  >
    <div class="q-ma-lg">
      <div class="q-mx-lg">
        <m-group-title
          :label="$t('transport_policy')"
        >
          <template v-slot:sublabel>
            <p>{{ $t('enable_disable_org_transport_policy') }}</p>
          </template>
        </m-group-title>
      </div>
      <m-content-type-enable
        v-model="enabledContentTypes"
        :loading="loading"
        :content-types="availableContentTypes"
        :org-types="disabledOrgTypes"
        :organisation="organisation"
        @toggle="toggle"
      />
    </div>
  </peek-form>
</template>
<script>
import authentication from 'mixins/authentication'
import peekForm from 'peeks/peekForm.vue'
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import { travelPolicy, travelPolicyEnable, travelPolicyDisable } from 'api/organisations'
import store from 'store'
import { mutableContentTypes } from 'utils/contentTypes'

const partner = store.getters['partner']

export default {
  components: {
    peekForm
  },
  data () {
    return {
      modify: null,
      organisation: null,
      availableContentTypes: [],
      enabledContentTypes: [],
      loading: true,
      disabledOrgTypes: [],
      mutatedContentTypes: mutableContentTypes()
    }
  },
  computed: {
    ...mapGetters({
      orgGetter: 'organisation',
      lookupOrg: 'organisations/getOrg',
      authedUser: 'user',
      contentTypes: `contentTypes/${partner.slug}`,
      partner: 'partner'
    }),
    orgTypes () {
      const policy = this.$store.state.authentication.organisation.attributes.travel_policy || {}
      const enabled = []
      Object.keys(policy).forEach(type => {
        if (policy[type].enabled) {
          enabled.push(type)
        }
      })
      return enabled
    }
  },
  beforeMount () {
    this.loading = true
    if (this.$route.query && this.$route.query.slug) this.organisation = this.lookupOrg(this.$route.query.slug)
    else this.organisation = this.orgGetter
    if (!this.organisation) this.$router.push({ path: this.$route.path })

    // Check the user's role. Only expose the content types for the role they have.
    this.contentTypes.forEach(type => {
      const hasRole = authentication.methods.hasRole(type.value)
      const hasPermission = authentication.methods.hasPermission(`search.${type.value}`)
      if (hasRole || hasPermission) {
        this.availableContentTypes.push(type)
      }
    })

    // Decide enabled/disabled UI state for the content types the user should have access to.
    this.availableContentTypes.forEach(type => {
      this.enabledContentTypes.push(type.value)
    })

    const partner = store.getters.partner
    loading.start({
      message: this.$t('loading.getting.organisation_travel_preferences'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    travelPolicy(this.organisation.slug).then(res => {
      const contentTypes = res.data[0]
      let typesToRemove = []
      Object.keys(contentTypes).forEach(type => {
        if (!contentTypes[type].enabled) typesToRemove.push(type)
      })
      if (typesToRemove) this.disabledOrgTypes = this.enabledContentTypes.filter(t => !typesToRemove.includes(t))
    }).finally(() => {
      loading.stop()
      this.loading = false
    })
  },
  methods: {
    async toggle (type, enabled) {
      loading.start({
        message: this.$t('loading.updating.organisation_travel_preferences'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })
      try {
        if (enabled) {
          await travelPolicyDisable(this.organisation.slug, type)
          // Update the UI by manually updating disabledOrgTypes
          this.disabledOrgTypes = this.disabledOrgTypes.filter(enabledType => enabledType !== type)
        } else {
          await travelPolicyEnable(this.organisation.slug, type)
          // Update the UI by manually updating disabledOrgTypes
          this.disabledOrgTypes.push(type)
        }

        const contentType = this.mutatedContentTypes[type] || type.charAt(0).toUpperCase() + type.slice(1)

        this.$q.notify({
          message: this.$t('transport_preference_type_updated', { type: contentType }),
          color: 'positive',
          icon: 'far fa-check-double'
        })
      } catch (err) {
        this.$q.notify({
          message: this.$t('error.default'),
          color: 'negative',
          icon: 'fas fa-times-circle'
        })
      }

      loading.stop()
    }
  }
}
</script>
