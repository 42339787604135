import { typeToPermissionMapper } from './contentTypes'
import { hasPermission } from 'utils/authentication'

/**
 * Falsy. If the auth has a permission for the type, return true, otherwise false
 * @param {string} type
 * @returns {boolean} If the authenticated user has this permission
 */
export function isPermissionEnabled (type) {
  return hasPermission(typeToPermissionMapper(type))
}
