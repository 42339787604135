<template>
  <q-card>
    <div class="card-name">
      Loading
    </div>
    <m-icons-type
      :type="null"
      size="19px"
      rounded
    />
    <div class="search-type">
      Please wait
    </div>
    <div class="button-holder">
      <q-spinner color="primary" />
    </div>
  </q-card>
</template>

<script>
export default {

}
</script>

<style lang="stylus" scoped>
.q-card
  scroll-snap-align center
  background white
  min-width 66vw
  height 125px
  padding 5px 10px
  border-radius 15px
  display grid
  grid-template-columns auto 24px
  align-items start
  grid-template-areas "card-name type-icon" \ "search-type ." \ "buttons buttons"
  @media (min-width 768px)
    min-width 60%

.card-name
  color convert(brandColor)
  font-weight 500
  grid-area card-name
  font-size 95%

.search-type
  font-size 85%
  grid-area search-type

.button-holder
  grid-area buttons
  width 100%
  display flex
  justify-content flex-start
</style>
