<template>
  <peek-form
    :title="$t('update.profile_picture')"
    :footer-title="$t('tooltip.profile_pic_supported_file')"
    :footer="false"
  >
    <q-page padding style="text-align:center">
      <template v-if="!uploading">
        <file-upload
          ref="upload"
          v-model="files"
          accept="image/*"
          :custom-action="uploadAvatar"
          @input-file="inputFile"
          @input-filter="inputFilter"
        >
          <m-image
            key="picture"
            :src="picture"
            class="avatar drop-active"
          />
        </file-upload>
        <h6>{{ $t('peek.profile.picture_instructions') }}</h6>
        <div class="q-my-xl" />
        <q-list v-if="errorReasons.length" bordered separator class="q-mb-md">
          <q-item v-for="reason in errorReasons" :key="reason">
            <q-item-section side class="text-negative">
              <q-item-label>{{ reason }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
        <q-list separator>
          <q-item>
            <q-item-section side>
              <q-item-label>{{ $t('peek.profile.supported_formats') }}</q-item-label>
              <q-item-label header>
                {{ allowedFormats.join(", ") }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section side>
              <q-item-label>{{ $t('peek.profile.upload_limit', {limit: '2MB'}) }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </template>
      <template v-else>
        <h5>{{ $t('peek.profile.uploading') }}</h5>
      </template>
    </q-page>
  </peek-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { avatar as userAvatar } from 'api/user'
import { avatar as staffAvatar } from 'api/staff'
import { hasRole } from 'utils/authentication'
import fileUpload from 'vue-upload-component'
import mImage from 'components/MImage'
import peekForm from 'peeks/peekForm.vue'

export default {

  components: {
    peekForm,
    fileUpload,
    mImage
  },
  data () {
    return {
      uploading: false,
      files: [],
      allowedFormats: ['jpeg', 'jpe', 'jpg', 'gif', 'png', 'webp'],
      errorReasons: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    uploaded () {
      return this.files.length > 0 ? this.files[0].blob : null
    },
    picture () {
      return this.uploaded || this.$store.getters.user.picture
    }
  },
  methods: {
    async uploadAvatar (file, component) {
      const avatar = hasRole('backoffice') ? staffAvatar : userAvatar
      this.uploading = true
      let data = new FormData()
      data.append('picture', file.file)
      avatar(this.user, data).then(response => {
        this.$store.dispatch('get_user')
        this.$q.notify({
          type: 'positive',
          position: 'bottom',
          message: this.$t('profile_picture_updated')
        })
        this.$router.back()
      })
        .catch(err => {
          Object.keys(err.data.errors).forEach(errorName => {
            this.errorReasons.push(err.data.errors[errorName].join('. '))
          })
          if (this.errorReasons.length === 1) {
            this.$q.notify({
              message: this.$t('error.custom', { error: this.errorReasons[0] }),
              timeout: 5000,
              type: 'error',
              icon: 'error',
              color: 'negative',
              position: 'top-right'
            })
          }
        })
        .finally(() => {
          this.uploading = false
        })
    },
    inputFile: function (newFile, oldFile) {
      this.$refs.upload.active = 1
    },
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        const regex = new RegExp(`\\.${this.allowedFormats.join('|')}$/`, 'i')
        if (!regex.test(newFile.name)) {
          this.$q.notify({
            message: this.$t('error.incorrect_format', { formats: this.allowedFormats.join(', ') }),
            timeout: 5000,
            type: 'error',
            icon: 'error',
            position: 'top-right'
          })
          return prevent()
        }
      }

      // Create a blob field
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .avatar
    width 400px
    height 400px

  .btn-upload
    line-height 2
</style>
