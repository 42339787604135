<template>
  <peek-form :title="$tc('invoice.invoice', 2)" :footer="false">
    <template>
      <div class="invoices">
        <m-invoices
          :load="load"
          :reset-items="resetItems"
          :show-filters="false"
          :show-columns="false"
          height="calc(100vh - 450px)"
        />
      </div>
    </template>
  </peek-form>
</template>

<script>
import peekForm from '../../peekForm.vue'
export default {
  components: { peekForm },
  props: ['reference'],
  beforeMount () {
    this.resetItems()
  },
  methods: {
    async load (filters) {
      return this.$store.dispatch('entities/invoices/loadBookingInvoices', { bookingReference: this.$route.query.booking })
    },
    resetItems () {
      this.$store.dispatch('entities/invoices/deleteAll')
    }
  }
}
</script>
