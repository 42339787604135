<template>
  <div class="passenger-form">
    <label
      v-for="(value, key) in model"
      :key="key"
      class="passenger-label"
    >
      {{ $t(key) }}
      <input
        v-model.number="model[key]"
        class="passenger-input"
        type="number"
        @focus="() => model[key] = ''"
        @blur="() => !model[key] ? model[key] = 0 : null"
        @input="emitPassengers"
      >
    </label>
    <railcards
      v-if="railcardOptions"
      :railcard-options="railcardOptions"
      :adults="passengers.adults"
      type="concession"
      @update="handleConcessions"
    />
  </div>
</template>

<script>
import railcards from 'pages/ondemand/train/railcards'

export default {
  components: { railcards },
  props: ['passengers', 'railcardOptions'],
  data () {
    return {
      model: {}
    }
  },
  beforeMount () {
    this.model = this.passengers
  },
  methods: {
    emitPassengers () {
      this.$emit('update', this.model)
    },
    handleConcessions (cards) {
      this.$emit('concessions', cards)
    }
  }
}
</script>

<style lang="stylus" scoped>
.passenger-form
  margin-top 6px
  grid-column 1 / span 3
  height 171px
  overflow-y scroll
  display grid
  grid-row-gap 12px

.passenger-input
  width 100%
  padding-bottom 6px
  border-bottom 1px solid #999
  &:focus
    border-bottom 2px solid convert(brandColor)

.passenger-label
  font-size 12px

.passenger-button
  background convert(brandColor)
  color white
  border none
  text-transform uppercase
  font-size 20px
  width 100%
  border-radius 3px
  margin-bottom 24px
  line-height 1.715em
  padding 4px
  font-weight 500
  height 50px
</style>
