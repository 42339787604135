import Vue from 'vue'
import date from 'utils/date-time'
import { contextual, getAvailableContents, getContextData, getTravelModes } from 'api/search'
import _ from 'lodash'
import i18n from 'i18n'
// import { isTypeEnabled } from 'utils/contentTypes'
import { details } from 'api/location'
import { handleErrors } from 'utils/utils'

const getDetails = async (location) => {
  let lookup
  if (location.latLng) lookup = `${location.latLng.lat()},${location.latLng.lng()}`
  else if (location.value) lookup = location.value
  const { data: [result] } = await details(lookup)
  if (!result.label) result.label = result.addressLines.postcode
  return result
}

// initial state, we store it like this so we can reset easier
const initial = {
  parameters: {
    requester: null,
    user: null,
    origin: null,
    destination: null,
    depart: date.toCivilDateTime(date.addToDate(new Date(), { minutes: 5 })),
    return: null,
    adults: 1
  },
  token: null,
  results: {},
  contents: null,
  contextual: {},
  loading: false,
  errors: false,
  ongoingQueries: false,
  currentTab: null,
  mapOrigin: null,
  mapDestination: null,
  whatThreeWords: {
    origin: null,
    destination: null
  }
}

const state = _.cloneDeep(initial)

const getters = {
  parameters: (state) => state.parameters,
  contextual: state => state.contextual,
  loading: (state) => state.loading,
  results: (state) => {
    const filteredResults = { ...state.results }
    const contentTypes = Object.keys(filteredResults)

    const [cheapest] = contentTypes
      .filter(type => !['bus', 'tram'].includes(type))
      .filter(type => filteredResults[type].price)
      .sort((a, b) => {
        return filteredResults[a].price.amount - filteredResults[b].price.amount
      })

    const [fastest] = contentTypes
      .filter(type => filteredResults[type].duration)
      .sort((a, b) => {
        return filteredResults[a].duration.value - filteredResults[b].duration.value
      })

    for (const key in filteredResults) {
      filteredResults[key].tags = []
      if (key === cheapest) filteredResults[key].tags.push('cheapest')
      if (key === fastest) filteredResults[key].tags.push('fastest')
    }

    return filteredResults
  },
  contents: (state) => state.contents,
  errors: (state) => state.errors,
  ongoingQueries: (state) => state.ongoingQueries,
  currentTab: state => state.currentTab,
  mapOrigin: state => state.mapOrigin,
  mapDestination: state => state.mapDestination,
  whatThreeWords: state => state.whatThreeWords
}

const actions = {
  stash: ({ commit }, payload) => {
    commit('stash', payload)
  },
  initialQuery ({ commit, state, dispatch, rootGetters, root }) {
    const partner = rootGetters['partner']
    dispatch('ondemand/stash', { parameters: state.parameters }, { root: true })
    commit('results', {})
    commit('contextual', {})
    commit('loading')
    commit('errors', initial.errors)
    const body = {
      origin: state.parameters.origin.value,
      destination: state.parameters.destination.value,
      depart: date.toCivilDateTime(state.parameters.depart),
      adults: state.parameters.adults,
      user: state.parameters.user.value,
      requester: state.parameters.requester.value,
      ...(state.parameters.return && { return: date.toCivilDateTime(state.parameters.return) })
    }
    return getAvailableContents(body)
      .then((response) => {
        // Filter out contents that shouldn't be allowed
        const filteredContents = response.data.contents
          // .filter(type => {
          //   return isTypeEnabled(type, state.parameters.user.travel_preferences[type])
          // })
          .filter(type => {
            return partner.contents[type]?.showInSmartSearch
          })
        commit('contents', filteredContents)
        commit('errors', null)
      })
      .catch((error) => {
        console.log(error)
        commit('errors', error)
        commit('contents', null)
      })
      .finally(() => {
        commit('loaded')
        dispatch('fullQuery')
      })
  },
  fullQuery: ({ commit, state }) => {
    commit('startingFullQuery')
    setTimeout(() => commit('fullQueryDone'), 10000)
    const queueLimit = 5
    const contentsToSearch = _.cloneDeep(state.contents)
    const contentsSearched = []
    const body = {
      origin: state.parameters.origin.value,
      destination: state.parameters.destination.value,
      depart: date.toCivilDateTime(state.parameters.depart),
      adults: state.parameters.adults,
      user: state.parameters.user.value,
      requester: state.parameters.requester.value,
      ...(state.parameters.return && { return: date.toCivilDateTime(state.parameters.return) })
    }
    const searchContent = function (queueNumber) {
      const contentType = contentsToSearch.pop()
      if (!contentType) return
      getContextData(contentType, body)
        .then((response) => {
          if (response) {
            commit('pushResults', { contentType, data: response.data })
          } else {
            // Don't show in the results if there's no viable options
            state.contents = state.contents.filter(content => content !== contentType)
          }
        })
        .catch((error) => {
          commit('errors', error)
        })
        .finally(() => {
          contentsSearched.push(contentType)
          if (contentsSearched.length === state.contents.length) commit('fullQueryDone')
          searchContent(queueNumber)
        })
    }
    for (let i = 1; i <= queueLimit; i++) searchContent(i)
  },
  contextualInfo ({ commit, state }, content) {
    return contextual(state.results[content].token, content)
      .then(response => {
        const shapedData = { ...response.data }

        shapedData.route.forEach(item => {
          if (['ridehailing', 'taxi'].includes(item.type)) {
            item.title = i18n.tc('content_type.taxi')
            item.subtitle = i18n.tc('content_type.taxi')
          }
        })

        if (content === 'rental') {
          shapedData.description = i18n.tc('content_type.rental')
          shapedData.title = i18n.tc('content_type.rental')
        }

        commit('contextual', {
          ...state.contextual,
          [content]: shapedData
        })
      })
  },
  setRequester ({ commit }, payload) {
    commit('setValue', { prop: 'requester', payload })
  },
  setTraveller ({ commit }, payload) {
    commit('setValue', { prop: 'user', payload })
    commit('setValue', { prop: 'traveller', payload })
  },
  setTab ({ commit }, tab) {
    commit('setCurrentTab', tab)
  },
  reset ({ commit }) {
    commit('reset')
  },
  async setMapOrigin ({ commit }, payload) {
    if (payload) {
      const originData = await getDetails(payload)
      commit('setOrigin', { ...payload, latLng: `${originData.latitude},${originData.longitude}` })
      commit('mapOrigin', {
        label: payload.label,
        latlng: { lat: originData.latitude, lng: originData.longitude },
        w3w: payload.context
      })
    } else {
      commit('setOrigin', null)
      commit('mapOrigin', null)
    }
  },
  async setMapDestination ({ commit }, payload) {
    if (payload) {
      const destinationData = await getDetails(payload)
      commit('setDestination', { ...payload, latLng: `${destinationData.latitude},${destinationData.longitude}` })
      commit('mapDestination', {
        label: payload.label,
        latlng: { lat: destinationData.latitude, lng: destinationData.longitude },
        w3w: payload.context
      })
    } else {
      commit('setDestination', null)
      commit('mapDestination', null)
    }
  },
  whatThreeWords ({ commit }, payload) {
    commit('setWhatThreeWords', payload)
  },
  setTime ({ commit }, payload) {
    commit('depart', payload)
  },
  async travelModes ({ commit, state, dispatch, rootGetters }) {
    dispatch('ondemand/stash', { parameters: state.parameters }, { root: true })
    commit('results', {})
    commit('contextual', {})
    commit('loading')
    commit('errors', initial.errors)
    const body = {
      origin: state.parameters.origin.latLng,
      destination: state.parameters.destination.latLng
    }
    try {
      const { data: { routes } } = await getTravelModes(body)
      const contents = []
      routes.forEach(route => {
        contents.push(route.primaryMode.toLowerCase())
        const content_label = []
        switch (route.primaryMode) {
        case 'Train':
          content_label.push('public'); break
        case 'Driving':
          content_label.push('road'); break
        case 'Cycle':
          content_label.push('active'); break
        default:
          content_label.push('public')
        }
        const duration = route.legs.reduce((a, b) => { return a + b.duration }, 0)
        const price = route.legs.reduce((a, b) => { return a + b.services[0]?.price || 0 }, 0)
        const result = {
          content_label,
          content_type: route.primaryMode.toLowerCase(),
          duration: { value: (duration * 60) },
          distance: { miles: route.distance },
          price: { value: price, text: i18n.n(price / 100, 'currency') }
        }
        commit('pushResults', { contentType: route.primaryMode.toLowerCase(), data: result })
      })
      commit('contents', contents)
    } catch (err) {
      handleErrors(err)
    } finally {
      commit('loaded')
    }
  }
}

// mutations
const mutations = {
  stash (state, newStash) {
    state.parameters = { ...state.stash, ...newStash }
  },

  contents (state, data) {
    state.contents = data
  },

  pushResults (state, { contentType, data }) {
    Vue.set(state.results, contentType, data || null)
  },

  results (state, data) {
    state.results = data
  },

  contextual (state, data) {
    state.contextual = data
  },

  errors (state, errors) {
    state.errors = errors
  },

  loading (state) {
    state.loading = true
  },

  loaded (state) {
    state.loading = false
  },
  startingFullQuery (state) {
    state.ongoingQueries = true
  },
  fullQueryDone (state) {
    state.ongoingQueries = false
  },
  setCurrentTab (state, tab) {
    state.currentTab = tab
  },
  reset (state) {
    for (let f in state) {
      state[f] = _.cloneDeep(initial[f])
    }
  },
  setValue (state, { prop, payload }) {
    Vue.set(state.parameters, prop, payload)
  },
  mapOrigin (state, payload) {
    state.mapOrigin = payload
  },
  mapDestination (state, payload) {
    state.mapDestination = payload
  },
  setWhatThreeWords (state, { type, value }) {
    state.whatThreeWords[type] = value
  },
  setOrigin (state, payload) {
    state.parameters.origin = payload
  },
  setDestination (state, payload) {
    state.parameters.destination = payload
  },
  depart (state, payload) {
    state.parameters.depart = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
