<template>
  <q-dialog
    ref="dialogRef"
  >
    <q-card>
      <q-card-section>
        <div class="text-h6">
          Ferry Timetable
        </div>
      </q-card-section>
      <q-card-section>
        <q-list>
          {{ timetable }}
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    timetable: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    open () {
      this.$refs.dialogRef.show()
    }

  }
}
</script>

<style>

</style>
