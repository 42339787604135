<template>
  <div>
    <m-group-title
      :label="$t('onwardtravel.passenger_details')"
    />

    <q-card>
      <q-card-section>
        <div class="row q-col-gutter-sm">
          <div class="col-6">
            <q-input
              v-model="mainPassenger.firstName"
              dense
              :label="$t('name.forename')"
              @input="setPassenger"
            />
          </div>
          <div class="col-6">
            <q-input
              v-model="mainPassenger.surname"
              dense
              :label="$t('name.surname')"
              @input="setPassenger"
            />
          </div>
          <div class="col-6">
            <q-input
              v-model="mainPassenger.mobile"
              dense
              :label="$t('mobile_number')"
              @input="setPassenger"
            />
          </div>
          <div class="col-6">
            <q-input
              v-model="racDetails.customer_reference"
              :rules="[ val => val.length || 'This field is required' ]"
              dense
              :label="$t('onwardtravel.customer_reference')"
              :hint="$t('onwardtravel.customer_reference_hint')"
              @input="setRacDetails"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>

    <m-group-title
      :label="$t('onwardtravel.internal_details')"
    />

    <q-card>
      <q-card-section>
        <div class="row q-col-gutter-sm">
          <div class="col-6">
            <q-select
              v-model="racDetails.business_area"
              :label="$t('rac.business_area')"
              :options="businessAreaOptions"
              dense
              @input="setRacDetails"
            />
          </div>
          <div class="col-6">
            <m-cost-centre v-model="racDetails.cost_centre" dense />
          </div>
          <div class="col-6">
            <q-select
              v-model="racDetails.vehicle_type"
              dense
              :label="$t('rac.vehicle.type')"
              :hint="$t('rac.vehicle.type_hint')"
              :options="[
                { label: $t('taxi.vehicle.types.taxi.description'), value: $t('taxi.vehicle.types.taxi.name')},
                { label: $t('taxi.vehicle.types.mpv.description'), value: $t('taxi.vehicle.types.mpv.name')},
                { label: $t('taxi.vehicle.types.wav.description'), value: $t('taxi.vehicle.types.wav.name')},
                { label: $t('taxi.vehicle.types.other.description'), value: $t('taxi.vehicle.types.other.name')}
              ]"
              @input="setRacDetails"
            />
          </div>
          <div class="col-6">
            <q-input
              v-model="racDetails.vehicle_registration"
              dense
              :label="$t('rac.vehicle.reg')"
              :hint="$t('rac.vehicle.reg_hint')"
              @input="setRacDetails"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>

    <m-group-title
      :label="$t('onwardtravel.driver_notes.label')"
    />

    <q-card>
      <m-banner :message="$t('onwardtravel.no_personal_info')" :rounded="false" borderless />
      <q-card-section>
        <q-input
          dense
          :value="driverNotes"
          :placeholder="$t('onwardtravel.driver_notes.placeholder')"
          autogrow
          @input="setDriverNotes"
        />
      </q-card-section>
    </q-card>

    <m-group-title
      :label="$t('onwardtravel.internal_notes.label')"
    />

    <q-card>
      <q-card-section>
        <q-input
          dense
          :value="internalNotes"
          :placeholder="$t('onwardtravel.internal_notes.placeholder')"
          autogrow
          @input="setInternalNotes"
        />
      </q-card-section>
    </q-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import mCostCentre from 'components/MCostCentre'
export default {
  components: { mCostCentre },
  data () {
    return {
      businessAreaOptions: [
        'RAC Onward Travel Transport',
        'RAC Onward Travel Transport CP',
        'RAC Employee Patrol',
        'RAC Employee Transport',
        'RAC Breakdown Entitlement exc onward',
        '999 Team Escalations exc onward'
      ]
    }
  },
  computed: {
    ...mapGetters({
      mainPassenger: 'onwardtravel/mainPassenger',
      requester: 'onwardtravel/requester',
      selectedVehicle: 'onwardtravel/selectedVehicle',
      driverNotes: 'onwardtravel/driverNotes',
      internalNotes: 'onwardtravel/internalNotes',
      racDetails: 'onwardtravel/racDetails'
    })
  },
  created () {
    if (this.requester.organisation === 'raconwardtravel') {
      this.racDetails.business_area = 'RAC Onward Travel Transport'
      this.setRacDetails()
    }
    const selectedVehicle = this.selectedVehicle
    const vehicleType = selectedVehicle && selectedVehicle.vehicle ? selectedVehicle.vehicle.type : null
    this.racDetails.vehicle_type = 'Taxi' + (vehicleType ? ` - ${vehicleType}` : '')
  },
  methods: {
    setPassenger (value) {
      this.$store.dispatch('onwardtravel/setValue', { prop: 'mainPassenger', payload: this.mainPassenger })
    },
    setRacDetails (value) {
      this.$store.dispatch('onwardtravel/setValue', { prop: 'racDetails', payload: this.racDetails })
    },
    setDriverNotes (val) {
      this.$store.dispatch('onwardtravel/setValue', { prop: 'driverNotes', payload: val })
    },
    setInternalNotes (val) {
      this.$store.dispatch('onwardtravel/setValue', { prop: 'internalNotes', payload: val })
    }
  }
}
</script>
