<template>
  <div
    class="modal fullscreen row detail-modal flex-center q-dialog__backdrop fit"
    @mousedown.stop="checkClose"
  >
    <div id="peek" class="peek bg-white shadow-4" @click.stop>
      <component :is="component" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { camelCase } from 'lodash'

/**
 * Grab all the components so we can loop through and register them all.
 */
const componentContext = require.context('./', true, /index\.vue$/)

/**
 * Loop through all the components and register them
 */
componentContext.keys().forEach(fileName => {
  /**
   * Ignore local index.vue file
   */
  if (fileName === './index.vue') return

  const config = componentContext(fileName)
  const name = camelCase(fileName.substr(2).replace('/index.vue', '').replace('/', '-'))
  Vue.component(name, config.default || config)
})

export default {
  computed: {
    component () {
      return camelCase(this.$route.hash.substr(2).replace('/', '-'))
    }
  },
  methods: {
    checkClose (i) {
      if (i.target === this.$el) this.$router.push({ hash: '' })
    }
  }
}
</script>

<style lang="stylus">
.peek
  position absolute
  top 0
  bottom 0
  right 0
  width 50vw
  max-width 768px
  transition width .20s
  @media only screen and (max-device-width : 1024px)
    width 100vw
  .q-list
    padding 0
</style>
