import User from 'store/models/user'
import { hasPermission, hasRole } from 'utils/authentication'
import { index as getAllUsers, get as getUser, put as updateUser } from 'api/users'
import {
  index as getAllIndividuals, get as getIndividual, resetPasswordEmail as resetIndividualPassword,
  block as blockIndividual, unblock as unblockIndividual
} from 'api/individuals'
import { updateStaff, block as blockStaff, unblock as unblockStaff } from 'api/staff'
import {
  index as getAllMembers, get as getMember, resetPasswordEmail as resetMemberPassword,
  block as blockMember, unblock as unblockMember
} from 'api/members'

import { Notify, Dialog } from 'quasar'
import router from 'router'
import i18n from 'i18n'

const notificationFn = ({ error, message, color, icon }) => {
  if (!message) message = error?.data?.message || 'An unexpected error while manipulating user, please try again later'
  Notify.create({
    message: message,
    color: error ? 'negative' : color || null,
    icon: icon || error ? 'error' : null,
    position: 'top-right'
  })
}

const mapUser = (u) => {
  return {
    ...u,
    meta: u.attributes || u.meta,
    organisation: userHasOrganisation(u) ? (u.organisation.data ? u.organisation.data : u.organisation) : null
  }
}

const userHasOrganisation = (u) => {
  return u.organisation ? (Object.keys(u.organisation).length > 0) : false
}

const state = {
  peekUser: null
}

const getters = {
  peekUser: (state) => state.peekUser
}

const actions = {
  // Users related actions
  async loadUsers (context, filters) {
    try {
      const response = await getAllUsers(filters)
      const users = response.data.map(u => mapUser(u))
      await User.insert({ data: users })
    } catch (error) {
      notificationFn({ error })
    }
  },
  async loadUser (context, { username, filters }) {
    try {
      const response = await getUser(username, filters)
      const user = mapUser(response.data)
      await User.insert({ data: user })
    } catch (error) {
      notificationFn({ error })
    }
  },

  // Individuals related actions
  async loadIndividuals (context, filters) {
    try {
      const response = await getAllIndividuals(filters)
      const users = response.data.map(u => mapUser(u))
      await User.insert({ data: users })
    } catch (error) {
      notificationFn({ error })
    }
  },
  async loadIndividual (context, { username, filters }) {
    try {
      const response = await getIndividual(username, filters)
      const user = mapUser(response.data)
      await User.insert({ data: user })
    } catch (error) {
      notificationFn({ error })
    }
  },
  async resetIndividualPassword (context, { email }) {
    resetIndividualPassword(email)
      .then(() => {
        Notify.create({
          message: i18n.t('reset_password_email_sent'),
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(error => {
        notificationFn({ error })
      })
  },

  // Members related actions
  async loadMembers (context, { orgId, filters }) {
    try {
      const response = await getAllMembers(orgId, filters)
      const users = response.data.map(u => mapUser(u))
      await User.insert({ data: users })
    } catch (error) {
      notificationFn({ error })
    }
  },
  async loadMember (context, { orgId, username, filters }) {
    try {
      const response = await getMember(orgId, username, filters)
      const user = mapUser(response.data)
      await User.insert({ data: user })
    } catch (error) {
      notificationFn({ error })
    }
  },
  async resetMemberPassword (context, { email }) {
    resetMemberPassword(email)
      .then(() => {
        Notify.create({
          message: i18n.t('reset_password_email_sent'),
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(error => {
        notificationFn({ error })
      })
  },

  async getUserMethodReference (rootState, username) {
    const user = await User.find(username)
    if (user) {
      if (rootState.rootGetters.authentication.user.username === user.username) return 'self'
      else if (userHasOrganisation(user)) return 'member'
      else return 'individual'
    }
    return null
  },

  async openUserSettings (rootState, username) {
    await actions.resetPeekUser(rootState)
    if (!username) username = await rootState.rootGetters.authentication.user.username
    await actions.initalizeUser(rootState, { username, setPeek: true })
    router.push({ hash: '/user-settings', query: { username } })
  },

  async openUserPolicies (rootState, username) {
    await actions.resetPeekUser(rootState)
    if (!username) username = await rootState.rootGetters.authentication.user.username
    await actions.initalizeUser(rootState, { username, setPeek: true })
    router.push({ hash: '/mobility-budget' })
  },

  // Check if the user is already in DB and if not or if data is missing then do cool getting info stuff
  async initalizeUser (rootState, { username, filters, setPeek }) {
    var user = await User.find(username)
    if (!user) {
      // if the user doesn't exist load their basic user information.
      await actions.loadUser(this, {
        username,
        filters: {
          include: 'organisation',
          ...filters
        }
      })
      user = User.find(username)
    }

    const userMethod = await actions.getUserMethodReference(rootState, user.username)
    const userHasBudgetInfo = (user.spend && user.monthly_budget)
    const userHasMeta = !!user.meta

    switch (userMethod) {
    case 'member':
      if (!userHasBudgetInfo || !userHasMeta) {
        if (hasPermission('organisation.members.show')) {
          await actions.loadMember(this, { orgId: user.organisation.slug, username: user.username })
        } else {
          notificationFn({ error: i18n.t('missing_permission'), message: i18n.t('no_permission_to_view_user') })
        }
      }
      break
    case 'individual':
      if (!userHasBudgetInfo || !userHasMeta) {
        await actions.loadIndividual(this, { username: user.username || username })
      }
      break
    }

    if (setPeek) rootState.commit('setPeekUser', username)
    return user
  },

  async updateUser (rootState, user) {
    var dbUser = await User.find(user.username)
    let Promise = require('es6-promise-polyfill').Promise
    if (!dbUser) dbUser = await actions.initalizeUser({ username: user.username })

    const successFn = (response) => {
      notificationFn({
        message: i18n.t('changes_saved'),
        color: 'positive',
        icon: 'check'
      })
      User.insertOrUpdate({ data: response.data })
    }

    const params = { email: user.email, ...user.attributes }

    try {
      const response = hasRole('backoffice')
        ? await Promise.any([
          updateStaff(user.username, params),
          updateUser(user.username, params)
        ])
        : await updateUser(user.username, params)

      return successFn(response)
    } catch (err) {
      notificationFn({ err })
    }
  },

  async updateMonthlyBudget (rootState, { username, budget }) {
    var dbUser = await User.find(username)
    if (!dbUser) dbUser = await actions.initalizeUser({ username })

    const successFn = (response) => {
      notificationFn({
        message: i18n.t('changes_saved'),
        color: 'positive',
        icon: 'check'
      })
      User.insertOrUpdate({ data: response.data })
    }

    return updateUser(username, { monthly_budget: budget })
      .then(successFn)
      .catch(error => notificationFn({ error }))
  },

  // Send a Password reset email to user defined in provided username
  async resetPassword (context, { username }) {
    const user = await User.find(username)
    if (!user) {
      await actions.initalizeUser({ username })
    }
    if (userHasOrganisation(user)) await actions.resetMemberPassword(this, { email: user.email })
    else await actions.resetIndividualPassword(this, { email: user.email })
  },

  // Block / Unblock user specified by username
  async modifyBlockedState (rootState, { username, blockingUser }) {
    if (blockingUser !== null) {
      const user = await User.find(username)
      if (!user) await actions.initalizeUser({ username })
      const userMethod = await actions.getUserMethodReference(rootState, user.username)
      Dialog.create({
        title: i18n.t('confirm.confirm'),
        message: blockingUser ? i18n.t('confirm.block_member_name', { name: user.display_name }) : i18n.t('confirm.unblock_member_name', { name: user.display_name }),
        ok: i18n.t('confirm.confirm'),
        cancel: i18n.t('cancel')
      })
        .onOk(() => {
          if (blockingUser && !user.is_blocked) {
          // Do the stuff to block user, if the user isn't already blocked
            switch (userMethod) {
            case 'member':
              blockMember(user.organisation.slug, user.username)
                .then(() => {
                  notificationFn({
                    message: i18n.t('user.blocked'),
                    color: 'positive',
                    icon: 'mdi-check'
                  })
                  rootState.commit('updateUsers', {
                    ...user,
                    is_blocked: true
                  })
                })
                .catch(() => {
                  notificationFn({
                    message: i18n.t('error.user.not_blocked'),
                    color: 'negative'
                  })
                })
              break
            case 'individual':
              blockIndividual(user.username)
                .then(() => {
                  notificationFn({
                    message: i18n.t('user.blocked'),
                    color: 'positive',
                    icon: 'mdi-check'
                  })
                  rootState.commit('updateUsers', {
                    ...user,
                    is_blocked: true
                  })
                })
                .catch(() => {
                  blockStaff(user.username)
                    .then(() => {
                      notificationFn({
                        message: i18n.t('user.blocked'),
                        color: 'positive',
                        icon: 'mdi-check'
                      })
                      rootState.commit('updateUsers', {
                        ...user,
                        is_blocked: true
                      })
                    })
                    .catch(() => {
                      notificationFn({
                        message: i18n.t('error.user.not_blocked'),
                        color: 'negative'
                      })
                    })
                })
              break
            default:
              notificationFn({
                message: i18n.t('error.user.not_blocked')
              })
            }
          } else if (!blockingUser && user.is_blocked) {
          // Do stuff to unblock user, if the user isn't already unblocked
            switch (userMethod) {
            case 'member':
              unblockMember(user.organisation.slug, user.username)
                .then(() => {
                  notificationFn({
                    message: i18n.t('user.unblocked'),
                    color: 'positive',
                    icon: 'mdi-check'
                  })
                  rootState.commit('updateUsers', {
                    ...user,
                    is_blocked: false
                  })
                })
                .catch(() => {
                  notificationFn({
                    message: i18n.t('error.unable_to.block_unblock'),
                    color: 'negative'
                  })
                })
              break
            case 'individual':
              unblockIndividual(user.username)
                .then(() => {
                  notificationFn({
                    message: i18n.t('user.unblocked'),
                    color: 'positive',
                    icon: 'mdi-check'
                  })
                  rootState.commit('updateUsers', {
                    ...user,
                    is_blocked: false
                  })
                })
                .catch(() => {
                  unblockStaff(user.username)
                    .then(() => {
                      notificationFn({
                        message: i18n.t('user.unblocked'),
                        color: 'positive',
                        icon: 'mdi-check'
                      })
                      rootState.commit('updateUsers', {
                        ...user,
                        is_blocked: true
                      })
                    })
                    .catch(() => {
                      notificationFn({
                        message: i18n.t('error.unable_to.block_unblock'),
                        color: 'negative'
                      })
                    })
                })
              break
            default:
              notificationFn({
                message: i18n.t('error.unable_to.block_unblock')
              })
            }
          } else {
            notificationFn({
              message: blockingUser ? i18n.t('error.already_blocked') : i18n.t('error.already_unblocked')
            })
          }
        })
    }
  },

  async setPeekUser (rootState, { username }) {
    const user = await actions.initalizeUser(rootState, { username })
    await rootState.commit('setPeekUser', username)
    return user
  },

  async resetPeekUser ({ commit }) {
    await commit('setPeekUser', null)
  }
}

const mutations = {
  setPeekUser (state, username) {
    state.peekUser = username
  },
  updateUsers (state, data) {
    User.insert({ data })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
