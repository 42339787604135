var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.departures != null)?_c('div',{staticClass:"mobilityNearbyList shadow-5"},[_c('div',{staticClass:"topBar q-px-md q-pt-sm q-pb-sm row bg-primary"},[_c('div',{staticClass:"col-2"}),_c('div',{staticClass:"col-8 text-h6 text-capitalize text-center"},[_vm._v(" "+_vm._s(_vm.station.name)+" "),_c('br'),_vm._v(" ("+_vm._s(_vm.station.description)+") ")]),_c('div',{staticClass:"col-2 row justify-end"},[_c('q-btn',{attrs:{"icon":"far fa-times","flat":"","dense":"","unelevated":""},on:{"click":function($event){return _vm.$store.dispatch('discovery/resetDepartures')}}})],1)]),_c('div',{staticClass:"buttonBar col row"},[_c('q-btn',{attrs:{"label":_vm.$t('directions'),"dense":"","icon":"fas fa-directions","no-caps":"","color":"primary","outline":""},on:{"click":function($event){return _vm.openURL(("https://www.google.com/maps/dir/" + _vm.curLocation + "/" + (_vm.station.latitude) + "," + (_vm.station.longitude) + "/"))}}}),_c('q-btn',{attrs:{"label":_vm.station.type === 'bus' ? _vm.$t('view') : _vm.$t('book'),"dense":"","icon":"fas fa-ticket-alt","no-caps":"","color":"primary","unelevated":"","to":{
        name: _vm.station.type === 'rail' ? 'ondemand-trains' : ("ondemand-" + (_vm.station.type)),
        params: {
          origin: {
            label: _vm.station.name,
            value: ((_vm.station.latitude) + "," + (_vm.station.longitude))
          }
        }
      }}})],1),(!_vm.loading && _vm.departures.length)?_c('q-scroll-area',{staticStyle:{"height":"675px"}},_vm._l((_vm.departures),function(item){return _c('m-timetable-card',{key:("" + (item.id)),staticClass:"departure",attrs:{"item":item,"transition":"jump-up","once":""}})}),1):(_vm.loading)?_c('div',{staticClass:"loadingContainer"},[_c('m-spinner-geo',{staticClass:"spinner",attrs:{"size":"64px"}})],1):_c('m-empty-state',{attrs:{"size":"64px","font-size":24,"label":_vm.$t('nothing_found_nearby'),"icon":"far fa-frown"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }