<template>
  <q-avatar v-if="name === 'asset'" :size="size" :class="rounded ? 'rounded' : ''">
    <img :src="require(`assets/${getAssetPath(type)}`)" alt="">
  </q-avatar>
  <q-icon
    v-else-if="inverted"
    :name="name"
    :size="size"
    :color="color"
  />
  <div
    v-else
    :class="`icon-type ${rounded ? 'rounded' : ''} bg-${color}`"
    :style="{width: widthHeight, height: widthHeight}"
  >
    <q-icon
      :size="size"
      :name="name"
      color="white"
    />
  </div>
</template>

<script type="text/javascript">
import travelContents from 'mixins/travelContents'
export default {
  name: 'MIconsType',
  mixins: [travelContents],
  props: {
    type: {
      type: String,
      default: 'rental'
    },
    size: {
      type: String,
      default: '24px'
    },
    rounded: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    widthHeight () {
      let size = this.size.slice(0, -2)
      size *= 1.2
      return `${size}px`
    },
    name () {
      var iconName = this.getTravelContentIcon(this.type) || 'far fa-question-circle'
      return iconName
    },
    color () {
      switch (this.type) {
      case 'other':
        return this.getTravelContentColor('concierge')
      case 'ridehailing':
        return this.getTravelContentColor('taxi')
      default:
        return this.getTravelContentColor(this.type) || 'grey'
      }
    },
    asset () {
      return this.getAssetPath(this.type) || 'missing-32.png'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-icon
    display inline-flex
    width 100%
    height 100%
    font-size 250%
  .rounded
    border-radius 100% !important
  .q-avatar
    display inline-flex
    margin 0 auto
</style>
