<template>
  <div class="type-selection row justify-between q-gutter-md">
    <div class="col">
      <type-button type="question" icon="live_help" :title="$t('concierge.enquiry')" :subtitle="$t('concierge.any_travel_query')" />
    </div>
    <div class="col">
      <type-button type="amendment" icon="edit" :title="$t('amendment')" :subtitle="$t('change_a_booking')" />
    </div>
    <div class="col">
      <type-button type="cancellation" icon="cancel" :title="this.$q.platform.is.mobile ? $t('cancel') : $t('cancellation')" :subtitle="$t('cancel_a_booking')" />
    </div>
  </div>
</template>

<script>
import typeButton from './type-button'
export default {
  components: { typeButton }
}
</script>

<style lang="stylus">
.type-selection
  @media (max-width: 599px)
    .q-btn__wrapper
      padding: 3px 8px !important;
  .q-item
    padding 5px 0 !important
</style>

<style lang="stylus" scoped>
.title
  transition 0s !important
</style>
