import { index, get, put, post, block, unblock, resetPasswordEmail } from 'api/members'
import http from 'api/http'
import _ from 'lodash'
import selects from './membersSelects'
import User from 'store/models/user'
import { Notify } from 'quasar'
import i18n from 'i18n'

const initialState = {
  selectedUserId: null,
  index: {},
  selects
}

const state = _.cloneDeep(initialState)

const getters = {
  getUser: state => id => {
    return _.cloneDeep(state.index[id])
  },
  getSelectedUser: state => {
    return _.cloneDeep((state.index[state.selectedUserId]))
  },
  getSelect: state => id => {
    return _.cloneDeep(id ? state.selects[id] : state.selects)
  }
}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  setSelectedUserId ({ commit }, userId) {
    commit('setSelectedUserId', userId)
  },
  loadUsers ({ commit }, { orgId, params }) {
    return index(orgId, params)
      .then(members => {
        commit('loadUsersSuccess', members.data)
        return members
      })
      .catch(e => {
        commit('loadUsersFailure', e)
      })
  },
  loadUser ({ commit }, { orgId, username, params }) {
    return get(orgId, username, params)
      .then(member => {
        commit('loadUserSuccess', member.data)
      })
      .catch(e => {
        commit('loadUserFailure', e)
      })
  },
  inviteUser ({ commit }, { orgId, user }) {
    return post(orgId, user)
  },
  updateUser ({ commit }, { user }) {
    const orgId = user.organisation.slug
    const username = user.username
    return put(orgId, username, { email: user.email, ...user.attributes })
      .then(member => {
        commit('updateUserSuccess', member.data)
      })
      .catch(e => {
        commit('updateUserFailure', e)
      })
  },
  blockUser ({ commit }, { user }) {
    const orgId = user.organisation.slug
    const username = user.username
    return block(orgId, username)
      .then(() => {
        Notify.create({
          message: i18n.t('user.blocked'),
          color: 'positive',
          icon: 'mdi-check'
        })
        commit('blockUserSuccess', username)
      })
      .catch(e => {
        Notify.create({
          message: e.data.title,
          color: 'negative',
          icon: 'clear'
        })
        commit('blockUserFailure', e)
      })
  },
  unblockUser ({ commit }, { user }) {
    const orgId = user.organisation.slug
    const username = user.username
    return unblock(orgId, username)
      .then(() => {
        Notify.create({
          message: i18n.t('user.unblocked'),
          color: 'positive',
          icon: 'mdi-check'
        })
        commit('unblockUserSuccess', username)
      })
      .catch(e => {
        Notify.create({
          message: i18n.t('error.user.not_unblocked'),
          color: 'negative',
          icon: 'clear'
        })
        commit('unblockUserFailure', e)
      })
  },
  resetUserPassword ({ commit }, { user }) {
    resetPasswordEmail(user.email)
      .then(() => {
        Notify.create({
          message: i18n.t('reset_password_email_sent'),
          color: 'positive',
          icon: 'mdi-check'
        })
        commit('resetUserPasswordSuccess', user)
      })
      .catch(e => {
        Notify.create({
          message: i18n.t('error.password_reset_not_sent'),
          color: 'negative',
          icon: 'clear'
        })
        commit('resetUserPasswordFailure', e)
      })
  },
  changeRole ({ commit, getters }, { org, user, role }) {
    let request
    if (role === 'middleoffice') {
      request = http.post(`/organisations/${org}/members/${user}/promote`)
    } else if (role === 'member') {
      request = http.post(`/organisations/${org}/members/${user}/demote`)
    }

    return request
      .then(() => {
        commit('changeRoleSuccess', {
          user,
          role
        })
        return role
      })
      .catch(e => {
        commit('changeRoleFailure', e)
      })
  }
}

const mutations = {
  reset (state) {
    for (var prop in state) {
      state[prop] = initialState[prop]
    }
  },
  setSelectedUserId (state, id) {
    state.selectedUserId = id
  },

  loadUsersSuccess (state, members) {
    const indexed = _.keyBy(members, m => m.username)
    User.insert({ data: members.map((m) => {
      return {
        ...m,
        meta: m.attributes
      }
    }) })
    state.index = { ...state.index, ...indexed }
  },
  loadUsersFailure (state, error) {
    throw Error(error)
  },

  loadUserSuccess (state, member) {
    state.index = { ...state.index, [member.username]: member }
  },
  loadUserFailure (state, error) {
    throw Error(error)
  },

  updateUserSuccess (state, member) {
    state.index = { ...state.index, [member.username]: member }
  },
  updateUserFailure (state, error) {
    throw Error(error)
  },

  blockUserSuccess (state, username) {
    const blockedUser = { ...state.index[username], is_blocked: true }
    state.index = { ...state.index, [blockedUser.username]: blockedUser }
  },
  blockUserFailure (state, error) {
    throw Error(error)
  },

  unblockUserSuccess (state, username) {
    const unblockedUser = { ...state.index[username], is_blocked: false }
    state.index = { ...state.index, [unblockedUser.username]: unblockedUser }
  },
  unblockUserFailure (state, error) {
    throw Error(error)
  },

  resetUserPasswordSuccess (state, user) {
    return user
  },
  resetUserPasswordFailure (state, error) {
    throw Error(error)
  },

  changeRoleSuccess (state, { user, role }) {
    const u = { ...state.index[user], roles: [role] }
    state.index = { ...state.index, [user]: u }
  },
  changeRoleFailure (state, error) {
    throw Error(error)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
