<template>
  <div class="row">
    <div v-if="hasPermission('can.book.with.reasonfor')" class="col-xs-12">
      <q-card v-if="hasReasonFor" class="bg-white">
        <q-card-section :class="`bg-primary text-white`">
          {{ $t('provide_reason_for_travel') }}
        </q-card-section>
        <q-card-section
          class="column"
          style="margin-top: 16px"
        >
          <div class="col-xs-12">
            <q-input
              v-model="reason_for"
              type="text"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
              :label="$t('reason_for_travel')"
              stack-label
            />
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div v-if="hasPermission('can.attach.labels')" class="col-xs-12">
      <q-card>
        <q-card-section class="bg-primary">
          {{ $t('attach_labels_to_booking') }}
        </q-card-section>
        <q-card-section
          class="column"
          style="margin-top: 16px"
        >
          <div class="col-xs-12">
            <q-select
              ref="labelSelect"
              v-model="selectedLabels"
              :options="availableLabels"
              class="q-pb-sm"
              :label="$t('input.labels')"
              emit-value
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
              multiple
              use-input
              @filter="filterLabels"
              @keyup.enter="newLabel"
            >
              <template v-slot:selected-item="item">
                <m-label
                  :name="item.opt.name"
                  :description="item.opt.description"
                  :colour="item.opt.colour"
                  dense
                  class="cursor-pointer"
                  removable
                  @remove="removeLabel(item.index)"
                />
              </template>
              <template v-slot:before-options>
                <q-item dense>
                  <q-item-section class="text-subtitle2 text-primary">
                    {{ $t('label.create_tooltip') }}
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div class="col-xs-12">
      <q-card class="bg-white">
        <q-card-section :class="`bg-primary text-white`">
          {{ $t('tooltip.add_booking_to_journey') }}
        </q-card-section>
        <q-card-section
          class="column"
          style="margin-top: 16px"
        >
          <div class="row q-col-gutter-x-xs">
            <div class="col-xs-12">
              <div v-if="options.length">
                <q-select
                  v-model="reference"
                  :options="options"
                  :label="$t('add_to_journey')"
                  :rounded="$q.platform.is.mobile"
                  :outlined="$q.platform.is.mobile"
                  map-options
                  emit-value
                  stack-label
                  filter
                  autofocus-filter
                />
              </div>

              <div v-else class="no-existing-journeys text-grey text-center">
                {{ $t('tooltip.new_journey_will_be_created') }}
              </div>
            </div>
            <div class="col-xs-12">
              <q-input
                v-model="name"
                :max-height="150"
                :min-rows="1"
                type="text"
                :rounded="$q.platform.is.mobile"
                :outlined="$q.platform.is.mobile"
                :label="$t('create_journey')"
                stack-label
              />
            </div>
          </div>
          <div v-if="!journey.name && !journey.reference" class="journey-error">
            {{ $t('journey.validation.name') }}
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div v-if="hasPermission('can.book.with.reasonfor') & hasTravelType & showTravelType" class="col-xs-12">
      <q-card flat>
        <q-card-section>
          <q-toggle
            v-model="travelType"
            left-label
            size="lg"
            :label="$t('travel_type_label')"
            :true-value="$tc('travel_type_business_private', 2)"
            :false-value="$tc('travel_type_business_private')"
          />
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import { existingJourneys } from 'api/user'
import authentication from 'mixins/authentication'
import labelUtil from 'utils/label-utils'
import Label from 'store/models/label'
import { mapGetters } from 'vuex'

export default {
  mixins: [authentication],
  props: {
    suggested: {
      type: String,
      default: 'Journey name'
    },
    journey: Object,
    username: String,
    showTravelType: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      reason_for: null,
      options: [],
      labelFilter: null,
      selectedLabels: [],
      travelType: this.$tc('travel_type_business_private')
    }
  },
  computed: {
    ...mapGetters({
      hasTravelType: 'partner/hasTravelType',
      hasReasonFor: 'partner/hasReasonFor'
    }),
    labels () {
      return Label.all()
    },
    availableLabels () {
      return this.labels.filter((label) => {
        return !this.selectedLabels.includes(label)
      }).filter((label) => {
        if (this.labelFilter?.length) {
          return label.name.toLowerCase().includes(this.labelFilter.toLowerCase())
        }
        return true
      }).map(label => {
        return {
          label: label.name,
          value: label
        }
      }).slice(0, 5)
    },
    reference: {
      get () {
        return this.journey.reference
      },
      set (reference, old) {
        this.$emit('change', { name: null, reference })
      }
    },
    name: {
      get () {
        return this.journey.name
      },
      set (name, old) {
        this.$emit('change', { reference: null, name })
      }
    }
  },
  watch: {
    username () {
      this.getJourneys()
    },
    reason_for (val) {
      this.$emit('reasonChange', val)
    },
    selectedLabels (val) {
      this.$emit('labelChange', val.map(label => label.name))
    },
    travelType (val) {
      this.$emit('travelTypeChange', val)
    }
  },
  created () {
    this.name = this.suggested
    if (this.username) this.getJourneys()
    if (this.hasPermission('can.view.labels')) this.$store.dispatch('entities/labels/loadAllLabels')
  },
  methods: {
    newLabel () {
      var search = this.$refs.labelSelect.inputValue
      if (search?.length) {
        var newLabels = search.split(',')
        newLabels.forEach(newLabel => {
          var labelAlreadyExists = false
          newLabel = labelUtil.convertLabelToKebabCase(newLabel)

          if (labelUtil.isLabelValid(newLabel)) {
            this.availableLabels.forEach(label => {
              if (label.label.toLowerCase() === newLabel.toLowerCase()) {
                this.selectedLabels.push(label.value)
                labelAlreadyExists = true
              }
            })
            this.selectedLabels.forEach(label => {
              if (label.name.toLowerCase() === newLabel.toLowerCase()) labelAlreadyExists = true
            })
            if (!labelAlreadyExists) {
              this.selectedLabels.push({
                name: newLabel
              })
            }
          }
          search = null
          this.$refs.labelSelect.updateInputValue()
        })
      }
    },
    removeLabel (index) {
      this.selectedLabels.splice(index, 1)
    },
    getJourneys () {
      existingJourneys({ username: this.username }).then(response => {
        this.options = response.data.map(item => {
          return {
            label: item.name,
            sublabel: item.info,
            stamp: item.date.date,
            value: item.reference
          }
        })
      })
    },
    filterLabels (val, update) {
      update(() => {
        this.labelFilter = val
        if (val) this.$store.dispatch('entities/labels/searchLabels', val)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  @media (max-width 768px)
    width 100vw
    margin 0

.no-existing-journeys
  margin-top 10px

.journey-error
  font-size small
  color red
  text-align center
</style>
