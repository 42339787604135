<template>
  <q-infinite-scroll
    ref="qScroll"
    @load="scrollHandler"
  >
    <slot />
    <m-empty-state
      v-if="(pagination && pagination.total === 0)"
      :icon="icon"
      :style="{margin: 'auto'}"
    >
      {{ $t('no_resource_found', { resource }) }}
    </m-empty-state>
    <template v-slot:loading>
      <div class="column items-center">
        <q-spinner-dots class="infinite-loading" size="60" />
      </div>
    </template>
    <div v-if="error" class="error">
      <p>{{ error }}</p>
      <q-btn color="primary" :label="$t('error.try_again')" @click="tryAgain" />
    </div>
  </q-infinite-scroll>
</template>

<script>
import MEmptyState from '../MEmptyState'
export default {
  name: 'MInfiniteScroll',
  components: {
    MEmptyState
  },
  props: {
    load: {
      type: Function
    },
    page: {
      type: Number
    },
    pagination: {
      type: Object
    },
    icon: {
      type: String
    },
    resource: {
      type: String
    }
  },
  data () {
    return {
      error: null,
      erroredPage: null
    }
  },
  watch: {
    page (value, old) {
      if (value < old) {
        this.reset()
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$refs.qScroll.poll()
    })
  },
  methods: {
    reset () {
      this.$refs.qScroll.reset()
      this.$refs.qScroll.resume()
      this.$refs.qScroll.trigger()
    },
    tryAgain () {
      this.$refs.qScroll.resume()
      this.$refs.qScroll.trigger()
      this.error = null
      this.scrollHandler(this.erroredPage, () => {})
    },
    async scrollHandler (index, done) {
      if (!this.error && (!this.pagination || index <= this.pagination.total_pages)) {
        try {
          await this.load(index)
          this.error = null
          done()
        } catch (err) {
          this.error = this.$t('error.trying_to_get_more_results')
          this.erroredPage = index
          this.$q.notify({ color: 'negative', icon: 'clear', position: 'bottom', message: this.error })
          done(true)
        }
      } else {
        this.$refs.qScroll.stop()
        done()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-infinite-scroll
  width 100%
.q-infinite-scroll > .q-infinite-scroll-content
  display flex
  flex-wrap wrap
.error
  width 400px
  color black
  text-align center
  margin auto
  padding 20px

  p
    margin-bottom 15px

.infinite-loading
  margin auto
</style>
