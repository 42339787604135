import { hasPermission } from 'utils/authentication'
import store from 'store/modules/smartsearch'
import i18n from 'i18n'
import { Platform } from 'quasar'

const beforeEnter = (to, from, next) => {
  !store.state.contents ? next({ name: 'smart-search' }) : next()
}

export const form = {
  name: 'smart-search',
  path: '/smart-search',
  meta: {
    label: i18n.t('smartsearch.smartsearch')
  },
  components: {
    default: () => import(/* webpackChunkName: "smartsearch" */'pages/smart-search/index.vue')
  },
  props: { default: true },
  beforeEnter (to, from, next) {
    if (Platform.is.mobile && hasPermission('feature.smartsearch.map')) {
      return next({
        ...to,
        name: 'smart-search-mobile'
      })
    }
    next()
  }
}

export const mobileForm = {
  name: 'smart-search-mobile',
  path: '/smart-search-mobile',
  alias: '/smart-search',
  component: () => import(/* webpackChunkName: "mobile" */ 'pages/smart-search/mobile-form/layout.vue'),
  meta: {
    layout: {
      name: 'mobile',
      components: {
        appbar: false,
        footer: 'MMobileFooter'
      }
    },
    label: 'Smart Search'
  },
  beforeEnter (to, from, next) {
    if (!hasPermission('feature.smartsearch.map')) {
      return next({ name: 'smart-search' })
    }
    next()
  }
}

export const results = {
  name: 'smart-search-results',
  path: '/smart-search/results',
  meta: {
    label: i18n.t('smartsearch.results')
  },
  components: {
    default: () => import(/* webpackChunkName: "smartsearch" */'pages/smart-search/results/index.vue'),
    right: () => import(/* webpackChunkName: "smartsearch" */'pages/smart-search/sidebar/index.vue')
  },
  beforeEnter
}

export default { form, results, mobileForm }
