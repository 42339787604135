<template>
  <div v-if="departures != null" class="mobilityNearbyList shadow-5">
    <div class="topBar q-px-md q-pt-sm q-pb-sm row bg-primary">
      <div class="col-2" />
      <div class="col-8 text-h6 text-capitalize text-center">
        {{ station.name }} <br> ({{ station.description }})
      </div>

      <div class="col-2 row justify-end">
        <q-btn
          icon="far fa-times"
          flat
          dense
          unelevated
          @click="$store.dispatch('discovery/resetDepartures')"
        />
      </div>
    </div>
    <div class="buttonBar col row">
      <q-btn
        :label="$t('directions')"
        dense
        icon="fas fa-directions"
        no-caps
        color="primary"
        outline
        @click="openURL(`https://www.google.com/maps/dir/${curLocation}/${station.latitude},${station.longitude}/`)"
      />
      <q-btn
        :label="station.type === 'bus' ? $t('view') : $t('book')"
        dense
        icon="fas fa-ticket-alt"
        no-caps
        color="primary"
        unelevated
        :to="{
          name: station.type === 'rail' ? 'ondemand-trains' : `ondemand-${station.type}`,
          params: {
            origin: {
              label: station.name,
              value: `${station.latitude},${station.longitude}`
            }
          }
        }"
      />
    </div>
    <q-scroll-area v-if="!loading && departures.length" style="height: 675px">
      <m-timetable-card
        v-for="item in departures"
        :key="`${item.id}`"
        :item="item"
        transition="jump-up"
        once
        class="departure"
      />
    </q-scroll-area>
    <div v-else-if="loading" class="loadingContainer">
      <m-spinner-geo class="spinner" size="64px" />
    </div>
    <m-empty-state v-else size="64px" :font-size="24" :label="$t('nothing_found_nearby')" icon="far fa-frown" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { openURL } from 'quasar'
import MTimetableCard from 'components/MTimetableCard'

export default {
  components: {
    MTimetableCard
  },
  computed: {
    ...mapGetters({
      departures: 'discovery/departures',
      station: 'discovery/station',
      curLocation: 'discovery/location',
      loading: 'discovery/loading'
    })
  },
  methods: {
    openURL
  }
}
</script>
<style scoped lang="stylus">
  .topBar
    height 75px
  .buttonBar
    height 50px
    display flex
    justify-content center
    align-items center
    .q-btn
      width 100px
      margin 0 10px
  .mobilityNearbyList
    z-index 11
    width 800px
    height 800px
    position absolute
    background-color white
    bottom 0
    right calc(50% - 400px)
    .q-btn
      font-size .75em
      padding 2px 4px 2px 4px
  .departure
    border-top solid 1px lighten(black, 55)

  .loadingContainer
    .spinner
      position absolute
      left calc(50% - 32px)
      top calc(50% - 32px)

  @media only screen and (max-width: 800px)
    .mobilityNearbyList
      width 100%
      right 0
</style>
