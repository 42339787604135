<template>
  <peek-form :title="$t('notes')" :footer="false">
    <div v-if="!notes">
      <q-inner-loading
        :visible="!notes"
      >
        <q-spinner-dots
          size="60"
        />
      </q-inner-loading>
    </div>
    <div v-else-if="notes.length">
      <div v-for="note in notes.filter(n => n.parent_id === null)" :key="note.url">
        <note-card :note="note" />
        <q-separator />
      </div>
    </div>
    <div v-else>
      <m-empty-state
        icon="attach_file"
        style="margin: auto;"
      >
        {{ $t('no_notes_found') }}
      </m-empty-state>
    </div>
  </peek-form>
</template>

<script>
import peekForm from '../../peekForm.vue'
import { notes } from 'api/bookings'
import noteCard from './note-card'
export default {
  components: { noteCard, peekForm },
  data () {
    return {
      notes: null
    }
  },
  created () {
    this.GetNotes()
  },
  methods: {
    GetNotes () {
      notes(this.$route.query.booking)
        .then(notes => {
          this.notes = notes.data
        })
        .catch(error => {
          const message = this.$t('error.custom', { error: error?.data?.message })
          this.$q.notify({
            message,
            color: 'negative',
            icon: 'clear'
          })
        })
    }
  }
}
</script>

<style>

</style>
