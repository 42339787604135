<template>
  <q-card class="timeline-card col-12">
    <q-card-section class="smartsearch-timeline-wrapper">
      <q-timeline :color="color(type)">
        <q-timeline-entry
          v-for="(item, i) in route"
          :key="i"
          :title="item.subtitle"
          :subtitle="`${item.title} ${item.civil_time ? '| ' + item.civil_time : '' }`"
          :icon="i > 0 ? i === (route.length - 1) ? 'check' : icon(item.type) : 'navigation'"
          :color="color(item.type)"
        />
      </q-timeline>
    </q-card-section>
  </q-card>
</template>

<script>
import store from 'store'

export default {
  props: ['type', 'route'],
  methods: {
    icon (type) {
      return store.getters.partner.smartSearch[type] ? store.getters.partner.smartSearch[type].icon : null
    },
    color (type) {
      return store.getters.partner.smartSearch[type] ? store.getters.partner.smartSearch[type].color : null
    }
  }
}
</script>

<style lang="stylus">
.smartsearch-timeline-wrapper
  padding 12px 36px 0 36px!important
</style>
