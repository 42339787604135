<template>
  <m-empty-state v-if="denied" icon="location_off" class="denied">
    {{ $t('weather_location_prevented') }}
  </m-empty-state>
  <q-page v-else-if="weather && !denied" class="layout-padding">
    <div class="row gutter-sm">
      <div class="col-sm-12">
        <q-card class="bg-white">
          <q-card-title :class="`dense`">
            {{ weather.location }}
          </q-card-title>
          <q-separator />
          <q-card-section>
            <q-list v-if="weather" highlight no-border>
              <q-item v-for="(forecast, index) in weather.daily" :key="index">
                <q-item-side>
                  <q-item-label>
                    <img :src="require(`assets/weather-icons/${forecast.icon}.svg`)" height="40px">
                  </q-item-label>
                  <q-item-label caption>
                    {{ forecast.time.date | day }}
                  </q-item-label>
                </q-item-side>
                <q-item-section>
                  <q-item-label label v-text="forecast.summary" />
                  <q-item-label caption v-text="forecast.windSpeed.text" />
                </q-item-section>
                <q-item-side right>
                  <q-item-label label v-text="forecast.maximumTemperature.text" />
                  <q-item-label caption v-text="forecast.minimumTemperature.text" />
                </q-item-side>
              </q-item>
              <q-item-separator />
            </q-list>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script>
import date from 'utils/date-time'
import { mapGetters } from 'vuex'
import http from 'api/http'
export default {
  filters: {
    day (value) {
      return date.luxonFormatDate(value, 'EEE dd')
    }
  },
  data () {
    return {
      weather: null
    }
  },
  computed: {
    ...mapGetters({
      latLng: 'geolocate/latLng',
      requested: 'geolocate/requested',
      denied: 'geolocate/denied'
    })
  },
  watch: {
    latLng: {
      immediate: true,
      handler () {
        http.get(`weather`, {
          params: this.latLng
        })
          .then(response => {
            this.weather = response.data
          })
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.dispatch('geolocate/get')
    })
  }
}
</script>

<style lang="stylus" scoped>
  .denied
    max-width 60vw
    margin auto
  .dense
    padding: 8px 16px
    color: #fff
</style>
