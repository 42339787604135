export default {
  data () {
    return {
      travelContents: {
        timeline: {
          icon: 'timeline',
          text: 'timeline'
        },
        manual: {
          icon: 'radio_button_checked',
          text: 'manual'
        },
        bookings: {
          icon: 'receipt',
          text: 'bookings'
        },
        documents: {
          icon: 'attach_file',
          text: 'documents'
        },
        restaurants: {
          text: 'restaurants',
          icon: 'restaurant',
          color: 'orange'
        },
        restaurant: {
          text: 'restaurants',
          icon: 'restaurant',
          color: 'orange'
        },
        Restaurant: {
          text: 'restaurants',
          icon: 'restaurant',
          color: 'orange'
        },
        hotels: {
          text: 'hotels',
          icon: 'hotel'
        },
        bus: {
          text: 'Buses',
          icon: 'directions_bus',
          hex: '#7e57c2',
          color: 'deep-purple-5',
          background: 'backgrounds/bus.jpg'
        },
        tram: {
          text: 'Trams',
          icon: 'tram',
          color: 'indigo-4',
          hex: '#7986cb',
          background: 'backgrounds/tram.jpg'
        },
        'carclub': {
          icon: 'directions_car',
          hex: '#388e3c',
          color: 'green-8',
          background: 'backgrounds/cc.jpg'
        },
        enterprise: {
          icon: 'asset',
          asset: 'enterprise_e_logo.jpg'
        },
        'ridehailing': {
          icon: 'local_taxi',
          hex: '#000000',
          color: 'black',
          background: 'backgrounds/taxi.jpg'
        },
        'onwardtravel': {
          icon: 'local_taxi',
          hex: '#ca62ca',
          color: 'pink',
          background: 'backgrounds/taxi.jpg'
        },
        whatson: {
          text: 'events',
          icon: 'event'
        },
        weather: {
          text: 'weather',
          icon: 'cloud'
        },
        'booking': {
          icon: 'receipt',
          color: 'purple-6'
        },
        'upsell': {
          icon: 'help',
          color: 'blue-8'
        },
        'date': {
          icon: 'watch_later',
          color: 'grey-6'
        },
        'rental': {
          icon: 'directions_car',
          hex: '#73af55',
          color: 'gohi-car',
          background: 'backgrounds/carhire.png'
        },
        'Car Hire': {
          icon: 'directions_car',
          color: 'gohi-car',
          background: 'backgrounds/carhire.png'
        },
        'car_hire': {
          icon: 'directions_car',
          color: 'gohi-car',
          background: 'backgrounds/carhire.png'
        },
        'driving': {
          icon: 'directions_car',
          color: 'dark',
          background: 'backgrounds/carhire.png'
        },
        concierge: {
          color: 'teal',
          icon: 'record_voice_over'
        },
        'taxi': {
          icon: 'local_taxi',
          hex: '#fbc02d',
          color: 'yellow-8',
          background: 'backgrounds/taxi.jpg'
        },
        'taxis': {
          icon: 'local_taxi',
          hex: '#fbc02d',
          color: 'yellow-8',
          background: 'backgrounds/taxi.jpg'
        },
        'local_taxi': {
          icon: 'local_taxi',
          hex: '#fbc02d',
          color: 'yellow-8',
          background: 'backgrounds/taxi.jpg'
        },
        'chauffeur': {
          icon: 'airline_seat_recline_normal',
          hex: '#607d8b',
          color: 'blue-grey',
          background: 'backgrounds/taxi.jpg'
        },
        'lounge': {
          icon: 'local_bar',
          hex: '#ec407a',
          color: 'pink-5',
          background: 'backgrounds/flight.jpg'
        },
        'bar': {
          icon: 'local_bar',
          hex: '#ec407a',
          color: 'purple'
        },
        'Airport Lounge': {
          icon: 'local_bar',
          hex: '#ec407a',
          color: 'pink-5',
          background: 'backgrounds/flight.jpg'
        },
        'rail': {
          icon: 'train',
          hex: '#1976d2',
          color: 'blue-8',
          background: 'backgrounds/rail.jpg'
        },
        'train': {
          icon: 'train',
          hex: '#1976d2',
          color: 'blue-8',
          background: 'backgrounds/rail.jpg'
        },
        'trains': {
          icon: 'train',
          hex: '#1976d2',
          color: 'blue-8',
          background: 'backgrounds/rail.jpg'
        },
        'eurostar': {
          icon: 'train',
          hex: '#1a237e',
          color: 'indigo-10',
          background: 'backgrounds/rail.jpg'
        },
        'flight': {
          icon: 'flight',
          hex: '#d32f2f',
          color: 'red-8',
          background: 'backgrounds/flight.jpg'
        },
        'flights': {
          icon: 'flight',
          hex: '#d32f2f',
          color: 'red-8',
          background: 'backgrounds/flight.jpg'
        },
        'hotel': {
          icon: 'hotel',
          hex: '#4caf50',
          color: 'green',
          background: 'backgrounds/hotel.jpg'
        },
        'Hotels': {
          icon: 'hotel',
          hex: '#4caf50',
          color: 'green',
          background: 'backgrounds/hotel.jpg'
        },
        'parking': {
          icon: 'local_parking',
          hex: '#2196f3',
          color: 'blue',
          background: 'backgrounds/parking.jpg',
          text: 'parking'
        },
        gas_station: {
          icon: 'local_gas_station',
          color: 'pink-6'
        },
        'fuel': {
          icon: 'local_gas_station',
          color: 'pink-6'
        },
        ev_station: {
          icon: 'ev_station',
          color: 'green-7'
        },
        atm: {
          icon: 'payments',
          color: 'green-5'
        },
        car_wash: {
          icon: 'local_car_wash',
          color: 'teal-7'
        },
        car_repair: {
          icon: 'car_repair',
          color: 'teal-3'
        },
        cafe: {
          icon: 'coffee',
          color: 'brown-8'
        },
        'other': {
          icon: 'layers',
          color: 'green'
        },
        'start_end_marker': {
          icon: 'home',
          color: 'green'
        },
        'locallink': {
          icon: 'airport_shuttle',
          color: 'pink-2',
          background: 'backgrounds/local-link.jpg'
        },
        'bikehire': {
          icon: 'pedal_bike',
          color: 'gohi-bike',
          hex: '#ff6319',
          background: 'backgrounds/bike.jpg'
        },
        drt: {
          asset: 'icons/dial-m-m.svg',
          icon: 'asset',
          color: 'gohi-bus',
          hex: '#eb79fc',
          background: 'backgrounds/bus.jpg'
        },
        ferry: {
          icon: 'directions_boat',
          color: 'gohi-ferry',
          hex: '#51626f',
          background: 'backgrounds/ferry.jpg'
        },
        bp: {
          asset: 'bp-logo-icon.png',
          icon: 'asset',
          color: 'green',
          hex: '#009640'
        },
        srf: {
          icon: 'flight',
          color: 'gohi-ferry',
          hex: '#51626f',
          background: 'backgrounds/flight.jpg'
        }
      }
    }
  },
  methods: {
    getTravelContentBackground (type) {
      return this.travelContents[type] ? this.travelContents[type].background : null
    },
    getTravelContentIcon (type) {
      return this.travelContents[type] ? this.travelContents[type].icon : null
    },
    getTravelContentColor (type) {
      return this.travelContents[type] ? this.travelContents[type].color : null
    },
    getTravelContentHex (type) {
      return this.travelContents[type] ? this.travelContents[type].hex : null
    },
    getAssetPath (type) {
      return this.travelContents[type] ? this.travelContents[type].asset : null
    }
  }
}
