import { hasPermission, hasRole } from 'utils/authentication'

const beforeEnter = (to, from, next) => {
  (!hasPermission('can.book.for.anyone') && hasRole('guest')) ? next('guest-mode') : next()
}

const index = {
  name: 'bookings-index',
  path: '/bookings',
  components: {
    default: () => import(/* webpackChunkName: "bookings" */ 'pages/bookings/index.vue')
  },
  props: { default: true },
  beforeEnter
}

const show = {
  path: '/bookings/:reference',
  components: {
    default: () => import(/* webpackChunkName: "bookings" */ 'pages/bookings/index.vue')
  },
  beforeEnter,
  children: [
    {
      name: 'bookings-show',
      path: '',
      components: {
        default: () => import(/* webpackChunkName: "bookings" */ 'pages/bookings/user-bookings/show/details/index.vue')
      },
      meta: {
        label: 'Booking Details'
      }
    },
    {
      name: 'bookings-invoice',
      path: 'invoices',
      components: {
        default: () => import(/* webpackChunkName: "bookings" */ 'pages/bookings/user-bookings/show/invoices/index.vue')
      },
      meta: {
        label: 'Booking Invoices'
      }
    },
    {
      path: 'documents',
      name: 'booking-documents',
      components: {
        default: () => import(/* webpackChunkName: "bookings" */ 'pages/bookings/user-bookings/show/documents.vue')
      },
      meta: {
        label: 'Booking Attachments'
      }
    },
    {
      path: 'breakdown',
      name: 'booking-breakdown',
      components: {
        default: () => import(/* webpackChunkName: "bookings" */ 'pages/bookings/user-bookings/show/breakdown/index.vue')
      },
      meta: {
        label: 'Booking Price Breakdown'
      }
    }
  ]
}

const status = {
  name: 'bookings-status',
  path: '/bookings/:ref/status',
  components: {
    default: () => import(/* webpackChunkName: "bookings" */ 'pages/bookings/backoffice-bookings/status/layout.vue')
  }
}

export default { index, show, status }
