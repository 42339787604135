<template>
  <q-banner
    :rounded="rounded"
    v-bind="$attrs"
    :class="styleClass"
  >
    <template v-if="!$q.platform.is.mobile" v-slot:avatar>
      <q-icon :name="icon || 'info'" />
    </template>
    <div class="text-body1 text-weight-medium">
      {{ title }}
    </div>
    <div class="text-body2">
      {{ message }}
    </div>
  </q-banner>
</template>
<script>
export default {
  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    icon: {
      type: String,
      default: 'info'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'light-info'
    },
    borderless: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    styleClass () {
      const styleClass = ['q-py-md']
      if (this.color) {
        styleClass.push(`bg-${this.color}`)
        styleClass.push(`border-${this.color.includes('light') ? this.color.slice(6) : this.color}`)
      }
      if (this.borderless) {
        styleClass.push('no-border')
      }
      return styleClass
    }
  }
}
</script>
<style lang="stylus" scoped>
  .q-banner
    border-width 1px
    border-style solid
</style>
