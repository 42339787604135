<template>
  <peek-form
    :title="$t('change_your_password')"
    :footer-title="$t('tooltip.we_encrypt_data')"
    :call-to-action="$t('save.password')"
    @save="savePassword"
  >
    <q-page padding>
      <q-card class="bg-white text-black q-mt-md">
        <q-card-section>
          <q-input
            v-model="current"
            :label="$t('current_password')"
            autofocus
            type="password"
            :error="$v.current.$error"
            :error-message="$t('provide_old_password')"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            @blur="$v.current.$touch"
          />
        </q-card-section>

        <q-card-section>
          <q-input
            v-model="password"
            :label="$t('new_password')"
            type="password"
            :error="$v.password.$error"
            :error-message="$t('provide_new_password')"
            :hint="$t('login.validation.password_reset')"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            @blur="$v.password.$touch"
          />
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { changePassword as changeUserPassword } from 'api/user'
import { changePassword as changeStaffPassword } from 'api/staff'
import { hasRole } from 'utils/authentication'
import { required, minLength } from 'vuelidate/lib/validators'
import peekForm from 'peeks/peekForm.vue'
export default {
  components: {
    peekForm
  },
  data () {
    return {
      current: null,
      password: null
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'partner'
    ])
  },
  validations: {
    current: { required },
    password: { required, minLength: minLength(8) }
  },
  methods: {
    savePassword () {
      const changePassword = hasRole('backoffice') ? changeStaffPassword : changeUserPassword
      changePassword(this.user.username, this.password, this.current)
        .then(response => {
          this.$q.notify({
            type: 'positive',
            position: 'bottom',
            message: this.$t('login.password_reset_success', { partner: this.partner.name })
          })
          this.$router.back()
        })
        .catch(response => {
          this.$q.notify({
            type: 'negative',
            position: 'bottom',
            message: this.$t('incorrect_password')
          })
        })
    }
  }
}
</script>
