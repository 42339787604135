<template>
  <peek-form
    :title="$tc('content_type.train', 2)"
    :subtitle="$t('mobility_budget.modify_preferences')"
    :footer-title="$t('tooltip.tailor_your_choices')"
    @save="submit"
  >
    <q-page padding>
      <m-group-title :label="$t('preferences')" />
      <q-card class="bg-white text-dark">
        <q-card-section>
          <q-select
            v-model="selectedOptions"
            :error="$v.policy.preferred_ticket_classes.$error"
            :options="ticketOptions"
            multiple
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            :label="$t('train.class.preferred')"
            :emit-value="false"
            @change="$v.policy.preferred_ticket_classes.$touch"
          />
          <q-select
            v-model="railcard"
            :options="railcards"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            :label="$t('railcard')"
            :hint="$t('tooltip.railcard_applied_to_all')"
            clearable
          />
        </q-card-section>
      </q-card>
      <m-group-title :label="$tc('budget.budget')" />
      <q-card class="bg-white">
        <q-card-section>
          <money-field
            v-model="policy.global_budget"
            :label="$t('global_budget')"
            :error="$v.policy.global_budget.$error"
            :hint="$t('permitted_cost.train')"
            @blur="$v.policy.global_budget.$touch"
          />
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import User from 'store/models/user'
import { railcards } from 'api/train'
import peekForm from 'peeks/peekForm.vue'
import moneyField from '@/MFields/money.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    peekForm,
    moneyField
  },
  data () {
    return {
      peekUser: null,
      policy: {
        preferred_ticket_classes: [],
        global_budget: null
      },
      railcard: null,
      railcards: [],
      ticketOptions: [
        {
          label: this.$t('train.class.standard'),
          value: 'standard'

        },
        {
          label: this.$t('train.class.first'),
          value: 'first'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activeUser: 'entities/users/peekUser'
    }),
    selectedOptions: {
      get () {
        const vm = this
        const selectedTickets = []
        this.ticketOptions.forEach(o => {
          if (vm.policy.preferred_ticket_classes.some(i => i.label === o.label)) {
            selectedTickets.push(o)
          }
        })
        return selectedTickets
      },
      set (val) {
        this.policy.preferred_ticket_classes = val
      }
    }
  },
  created () {
    this.peekUser = User.find(this.activeUser)
    if (this.peekUser.meta.travel_policy && this.peekUser.meta.travel_policy.trains) {
      this.policy = {
        ...this.policy,
        ...JSON.parse(JSON.stringify(this.peekUser.meta.travel_policy.trains))
      }
    }

    if (this.peekUser.meta.railcard_code) {
      this.railcard = {
        label: this.peekUser.meta.railcard_name || null,
        value: this.peekUser.meta.railcard_code || null
      }
    }

    railcards().then(response => {
      this.railcards = response.data.map(railcard => {
        return {
          label: railcard.name,
          value: railcard.code
        }
      })
    })
  },
  validations: {
    policy: {
      preferred_ticket_classes: { },
      global_budget: { }
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$store.dispatch('entities/users/updateUser', {
          username: this.peekUser.username,
          attributes: {
            railcard_name: this.railcard ? this.railcard.label : null,
            railcard_code: this.railcard ? this.railcard.value : null,
            travel_policy: {
              ...this.peekUser.meta.travel_policy,
              trains: this.policy
            }
          }
        }).then(() => {
          this.$router.back()
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
</style>
