<template>
  <div>
    <div v-if="showTopSheet" :class="topSheetClass">
      <slot name="filters" />
      <slot name="columns">
        <columns v-model="sort" :columns="columns" />
      </slot>
    </div>
    <m-v-infinite-scroll
      v-if="showResults"
      ref="scroller"
      :load="filter"
      :height="height"
      :getter="items"
      :item-height="itemHeight"
    >
      <template v-slot:item="{item}">
        <slot name="item" :item="item" />
      </template>
    </m-v-infinite-scroll>
  </div>
</template>
<script>
import { pickBy, debounce, isEqual } from 'lodash'
import columns from './columns'

export default {
  name: 'MIndexListIndex',
  components: { columns },
  props: {
    load: Function,
    filters: [Object, Array],
    sort: [Object, Array],
    columns: [Object, Array],
    items: Array,
    height: String,
    resetItems: Function,
    itemHeight: [String, Number],
    showResults: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showTopSheet () {
      return this.filters || this.columns
    },
    topSheetClass () {
      var sheetClass = {
        'top-sheet': true,
        'shadow-1': true,
        'removeTopPadding': !this.filters || !this.columns
      }
      return sheetClass
    }
  },
  watch: {
    filters: {
      deep: true,
      handler (val, old) {
        if (!isEqual(val, old)) this.reset()
      }
    },
    sort: {
      deep: true,
      handler () {
        this.reset()
      }
    }
  },
  methods: {
    filter (offset, limit) {
      var filters = {
        page: offset,
        limit,
        ...this.filters
      }
      if (this.sort && this.sort.direction && this.sort.column) filters.orderBy = `${this.sort.column}:${this.sort.direction}`
      return this.load(pickBy(filters))
    },
    reset: debounce(function () {
      this.resetItems()
      this.$refs.scroller.reset()
    }, 1250)
  }
}
</script>
<style lang="stylus" scoped>
.top-sheet
  padding 15px 15px 0 15px
  position relative
  background white
  z-index 100

.removeTopPadding
  padding-top 0
</style>
