<template>
  <div v-if="items != null || loading" class="mobilityNearbyList shadow-5">
    <div class="topBar q-px-md q-pt-sm q-pb-sm row bg-primary">
      <div class="col text-h6 text-capitalize">
        {{ searchType }}
      </div>
      <div class="col row justify-end">
        <q-btn
          icon="far fa-times"
          flat
          dense
          unelevated
          @click="$store.dispatch('discovery/resetItems')"
        />
      </div>
    </div>
    <q-scroll-area v-if="!loading && items.length" style="height: 300px">
      <q-list>
        <q-intersection
          v-for="(item, index) in items"
          :key="`${item.type}-${index}`"
          transition="jump-up"
          once
          class="item"
        >
          <q-item>
            <q-item-section v-if="item.attributes.logo_url" side>
              <q-img :src="item.attributes.logo_url" style="width: 75px" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="primary text-weight-medium">
                {{ item.name }}
              </q-item-label>
              <q-item-label v-if="item.type === 'bikehire'" caption>
                {{ item.attributes.postcode }}
              </q-item-label>
              <q-item-label v-else caption>
                {{ item.description }}
              </q-item-label>
            </q-item-section>
            <q-item-section v-if="searchType=== 'rail' || searchType === 'bus'" side>
              <q-btn
                :label="$t('details')"
                dense
                icon="fas fa-directions"
                no-caps
                color="primary"
                outline
                @click="setDepartures(item)"
              />
            </q-item-section>
            <q-item-section v-else side>
              <q-btn
                :label="$t('directions')"
                dense
                icon="fas fa-directions"
                no-caps
                color="primary"
                outline
                @click="openURL(`https://www.google.com/maps/dir/${curLocation}/${item.latitude},${item.longitude}/`)"
              />
            </q-item-section>
            <q-item-section v-if="searchType === 'bus' || searchType === 'tram'" side>
              <q-btn
                :label="$t('view')"
                dense
                no-caps
                color="primary"

                unelevated
                :to="{
                  name: bookRoute,
                  params: {
                    origin: {
                      label: paramsLabel(item),
                      value: paramsValue(item),
                      place_id: paramsValue(item)
                    }
                  }
                }"
              />
            </q-item-section>
            <q-item-section v-else-if="item.type === 'website'" side>
              <q-btn
                :label="$t('website')"
                dense
                no-caps
                color="primary"
                unelevated
                @click="openURL(`${item.attributes.url}`)"
              />
            </q-item-section>
            <q-item-section v-else-if="item.type === 'bp-c'" side>
              <q-btn
                :label="$t('book')"
                dense
                icon="fas fa-ticket-alt"
                no-caps
                color="primary"
                unelevated
                :to="{
                  name: 'ondemand-carclub',
                  params: {
                    origin: {
                      label: $t('bp_nearby_carclub'),
                      value: `${item.latitude},${item.longitude}`
                    }
                  }
                }"
              />
            </q-item-section>
            <q-item-section v-else-if="item.type === 'bp-b'" side>
              <q-btn
                :label="$t('book')"
                dense
                icon="fas fa-ticket-alt"
                no-caps
                color="primary"
                unelevated
                :to="{
                  name: 'ondemand-bikehire',
                  params: {
                    origin: {
                      label: $t('bp_nearby_bikehire'),
                      value: `${item.latitude},${item.longitude}`
                    }
                  }
                }"
              />
            </q-item-section>
            <q-item-section v-else-if="bookRoute" side>
              <q-btn
                :label="$t('book')"
                dense
                icon="fas fa-ticket-alt"
                no-caps
                color="primary"
                unelevated
                :to="{
                  name: bookRoute,
                  params: {
                    origin: {
                      label: paramsLabel(item),
                      value: paramsValue(item),
                      place_id: paramsValue(item)
                    }
                  }
                }"
              />
            </q-item-section>
            <q-item-section v-else-if="bookRoute === null" side>
              <q-btn
                :label="$t('concierge.concierge')"
                dense
                icon="fas fa-concierge-bell"
                no-caps
                color="primary"
                unelevated
                :to="{
                  hash: '/concierge',
                  query: { type: 'question' }
                }"
              />
            </q-item-section>
            <!--<q-item-section v-if="item.type === 'ferry'" side>
              <q-btn
                label="Timetable"
                dense
                icon="fas fa-clock"
                no-caps
                color="primary"
                unelevated
                @click="viewFerryTimetable(item.attributes.code)"
              />
            </q-item-section>-->
          </q-item>
        </q-intersection>
      </q-list>
    </q-scroll-area>
    <div v-else-if="loading" class="loadingContainer">
      <div v-if="components.mMobilityNearbyList.spinnerType === 'arrow'">
        <m-spinner-arrow :class="components.mMobilityNearbyList.spinnerClass" />
      </div>
      <div v-else>
        <m-spinner-geo class="loader" />
      </div>
    </div>
    <m-empty-state v-else size="64px" :font-size="24" :label="$t('nothing_found_nearby')" icon="far fa-frown" />
    <timetable-dialog ref="timetableRef" :timetable="timetable" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { openURL } from 'quasar'
import { hasPermission } from 'utils/authentication'
import { MEmptyState } from 'components/'
import { routes as getRoutes } from 'api/ferry'
import TimetableDialog from './TimetableDialog.vue'

export default {
  components: { MEmptyState, TimetableDialog },
  data () {
    return {
      timetable: []
    }
  },
  computed: {
    ...mapGetters({
      items: 'discovery/items',
      loading: 'discovery/loading',
      searchType: 'discovery/searchType',
      curLocation: 'discovery/location',
      components: 'partner/components'
    }),
    bookRoute () {
      switch (this.searchType) {
      case 'rental':
        if (hasPermission('search.rentals')) return 'ondemand-car-hire'
        else return false
      case 'rail':
        if (hasPermission('search.trains')) return 'ondemand-trains'
        else return false
      case 'bus':
        if (hasPermission('search.bus')) return 'ondemand-bus'
        else return false
      case 'tram':
        if (hasPermission('search.tram')) return 'ondemand-tram'
        else return false
      case 'flight':
        if (hasPermission('search.flights')) return 'ondemand-flights'
        else return false
      case 'carclub':
        if (hasPermission('search.carclub')) return 'ondemand-carclub'
        else return false
      case 'bikehire':
        if (hasPermission('search.bikehire')) return 'ondemand-bikehire'
        else return false
      case 'ferry':
        if (hasPermission('search.ferry')) return 'ondemand-ferry'
        else return false
      default:
        return null
      }
    }
  },
  methods: {
    openURL,
    paramsValue (item) {
      let value
      switch (this.searchType) {
      case 'flight':
      case 'carclub':
      case 'bikehire':
      case 'bus':
      case 'tram':
        value = `${item.latitude},${item.longitude}`
        break
      case 'rental':
        value = item.attributes.branch_code
        break
      default:
        value = item.description
      }
      return value
    },
    paramsLabel (item) {
      let label

      switch (this.searchType) {
      case 'carclub':
        label = item.description
        break
      case 'rental':
        label = `${item.name} - ${item.description}`
        break
      default:
        label = item.name
        break
      }

      return label
    },
    setDepartures (item) {
      this.$store.dispatch('discovery/setTimetable', item)
    },
    async viewFerryTimetable (port) {
      console.log(getRoutes)
      const res = await getRoutes({ code: port })
      this.timetable = res.data
      this.$refs.timetableRef.open()
    }
  }
}
</script>
<style lang="stylus">
  .item
    height 56px
    border-bottom 1px solid lighten(black, 55)
    .q-btn
      width 96px

  .topBar
    height 50px
  .mobilityNearbyList
    z-index 10
    width 800px
    height 350px
    position absolute
    background-color white
    bottom 0
    right calc(50% - 400px)
    .q-btn
      font-size .75em
      padding 2px 4px 2px 4px

  .loadingContainer
    .spinner
      position absolute
      left calc(50% - 32px)
      top calc(50% - 32px)

  @media only screen and (max-width: 800px)
    .mobilityNearbyList
      width 100%
      right 0
</style>
