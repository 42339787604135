import http from './http'

export function query (query) {
  const result = []
  return http.get(`/location`, {
    params: { query }
  })
    .then(response => {
      response.data.forEach(a => {
        result.push({
          label: a.text,
          value: a.lookup,
          icon: 'home', // or 'directions_walk' or 'home'
          append: a.lookup,
          code: a.lookup,
          type: 'address', // or 'branch'
          longitude: a.longitude,
          latitude: a.latitude,
          place_id: a.place_id,
          color: 'red-5'
        })
      })
      return result
    })
}

export function details (lookup) {
  return http.get('/location/lookup', { params: { query: lookup } })
}
