import { hasRole } from 'utils/authentication'

const meta = {
  permission: 'search.drt'
}

const beforeEnter = (to, from, next) => {
  hasRole('guest') ? next('guest-mode') : next()
}

const form = {
  name: 'ondemand-drt',
  path: '/on-demand/drt',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-drt" */ 'pages/ondemand/drt/layout.vue')
  },
  meta: {
    ...meta,
    label: 'Dial M for Moray'
  },
  beforeEnter
}

const summary = {
  name: 'ondemand-drt-summary',
  path: '/on-demand/drt/summary',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-drt" */ 'pages/ondemand/drt/summary.vue')
  },
  meta: {
    ...meta,
    label: 'Dial M: Summary'
  },
  beforeEnter
}

export default { form, summary }
