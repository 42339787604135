/**
 * Optimised exports of the world countries data for use with
 * Quasar Select components.
 */

import json from 'world-countries/dist/countries.json'
import { orderBy } from 'lodash'

export const countries = orderBy(json, 'name.common').map(country => {
  return {
    label: country.name.common,
    value: country.cca2
  }
})

export const nationalities = orderBy(json, 'name.common').map(country => {
  return {
    label: country.demonyms.eng.m,
    value: country.cca2
  }
})
