import { Notify, colors } from 'quasar'
import i18n from 'i18n'

export const toTitleCase = str => {
  if (!str) return ''
  return str.slice(0, 1).toUpperCase() + str.slice(1)
}

export const flushQueue = (time = 0) => {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export const handleErrors = error => {
  if (error.data.errors) {
    for (const key in error.data.errors) {
      Notify.create({ message: i18n.t('error.custom', { error: error.data.errors[key] }) })
    }
  } else {
    const errorMsg = error.data?.detail?.[0] || 'Unexpected error'
    Notify.create({ message: i18n.t('error.custom', { error: errorMsg }) })
  }
}

export const derbyEmailValidation = (value) => /^[A-Za-z0-9._%+-]+@(unimail.)?derby.ac.uk$/.test(value)

export const createContrast = (hex) => {
  const { hexToRgb } = colors
  const { r, g, b } = hexToRgb(hex)
  return `rgb(${255 - r},${255 - g},${255 - b})`
}
