import Vue from 'vue'
import App from './App.vue'
import plugins from './plugins'
import router from './router'
import store from './store'
import i18n from './i18n'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import './registerServiceWorker'
/**
 * Config our vue instance
 */
Vue.config.performance = process.env.VUE_APP_PERFORMANCE

/**
 * Install anything in ./plugins automatically
 * they should follow https://vuejs.org/v2/guide/plugins.html
 *
 * Everything should really be a plugin...
 */
plugins(
  [
    'components',
    'google-analytics',
    'google-maps',
    'quasar',
    'vuelidate',
    'particles'
  ],
  { App, router, store, Vue }
)

Sentry.init({
  Vue,
  dsn: 'https://c10c0c86a5da4120b6617d2a2e688356@o987633.ingest.sentry.io/5944586',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'app.mobilleo', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

/**
 * Import global styles
 */
import 'styles/app.styl'

/**
 * Kicks off our Vue instance!
 */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#q-app')
