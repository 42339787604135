<template>
  <q-card class="primary-card col-12">
    <q-card-section class="row justify-between">
      <div class="header-content-type">
        <q-icon
          :name="icon()"
          :color="color()"
          size="40px"
          class="col-12"
        />
        <p :class="`text-${color()} text-bold text-uppercase col-12`">
          {{ title }}<br>
          <small>{{ description }}</small>
        </p>
        <m-image v-if="showCarclubLogo" :src="require('assets/enterprise_carclub.png')" height="30px" class="q-pl-sm" />
      </div>
      <div v-if="price" class="header-price">
        <small>{{ $t('price') }}</small>
        <p class="price-amount">
          {{ price.text }} {{ type === 'carclub' ? $t('per_hour') : '' }}
        </p>
      </div>
    </q-card-section>
    <q-card-section class="row justify-between">
      <div class="time-info">
        <p class="text-bold">
          {{ timeRangeString }}
        </p>
        <small>
          {{ duration.text }}
        </small>
      </div>
    </q-card-section>
    <q-separator />
    <q-card-section class="row justify-between">
      <div class="emissions row justify-between">
        <p class="emissions-text">
          <small>
            {{ co2.value }} <br>
            {{ $t('abbreviation.grams_per_kilometre') }}
          </small>
        </p>
        <img
          :src="require('assets/co2.svg')"
          height="46px"
        >
      </div>
      <div class="weather row justify-between">
        <div class="weather-text">
          <small>{{ weather.summary }}</small>
          <p class="text-bold">
            {{ weather.temperature.text }}
          </p>
        </div>
        <img
          :src="require(`assets/weather-icons/${weather.icon}.svg`)"
          height="46px"
        >
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import date from 'utils/date-time'
import store from '../../store'
export default {
  props: ['type', 'title', 'description', 'depart', 'arrive', 'price', 'duration', 'weather', 'co2', 'showCarclubLogo'],
  computed: {
    timeRangeString () {
      const depart = date.toCivilTime(this.depart.date)
      const arrive = date.toCivilTime(this.arrive.date)
      return `${depart} - ${arrive}`
    }
  },
  methods: {
    icon () {
      return store.getters.partner.smartSearch[this.type].icon
    },
    color () {
      return store.getters.partner.smartSearch[this.type].color || null
    }
  }
}
</script>

<style lang="stylus" scoped>

.header-content-type
  display flex

  p
    line-height 1.2
    margin-left 16px

.header-price
  color #747474
  small
    display block
    text-align right

  .price-amount
    font-weight 700

.time-info
  line-height 1.1

.weather-text
  text-align right
  margin-right 8px

.emissions-text
  line-height 1.1
  margin-top 6px
  margin-right 8px

</style>
