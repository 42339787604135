<template>
  <div class="overview row">
    <div class="col-5">
      {{ $t('global_budget') }}
    </div>
    <div
      class="col-6 text-dark"
    >
      {{ policy && policy.global_budget ? '£' + (policy.global_budget / 100) : $t('no_budget_set') }}
    </div>
    <div class="col-5">
      {{ $t('city_restrictions') }}
    </div>
    <div class="col-6">
      <div v-if="policy && policy.city_budgets">
        <div v-for="r in policy.city_budgets" :key="r.duration" class="row repeater">
          <div class="col-6 text-dark">
            {{ r.city.label }}
          </div>
          <div class="col-6 text-dark">
            £{{ r.budget / 100 }}
          </div>
        </div>
      </div>
      <div v-else class="text-dark">
        {{ $t('hotel.no_city_specific_settings') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['policy']
}
</script>

<style lang="stylus" scoped>
.overview > div, .repeater > div
  padding 6px 0

</style>
