<template>
  <peek-form :title="`${$tc('booking.booking')} ${$route.query.booking} - ${booking ? booking.status : ''}`" :footer="false">
    <q-page v-if="initialRequest" padding>
      <div class="booking-details">
        <q-banner v-if="booking.type === 'onwardtravel' && (booking.passenger_name === '' || booking.passenger_name == null)" rounded class="bg-warning">
          <q-icon name="info" />
          {{ $t('gdpr.reason') }}
        </q-banner>
        <m-group-title
          v-if="canSeeAdditionalInfo"
          :col="12"
          :label="$t('information')"
        />
        <extra-info v-if="canSeeAdditionalInfo" :request="initialRequest" :booking="booking" />
        <m-group-title
          :col="12"
          :label="$t('details')"
        />
        <q-card class="bg-white">
          <q-list v-for="(item, index) in booking.details" :key="index" highlight>
            <q-item>
              <q-item-section>
                <q-item-label
                  caption
                  v-text="item.label"
                />
                <q-item-label
                  label
                  v-text="item.text"
                />
              </q-item-section>
            </q-item>
            <q-separator />
          </q-list>
        </q-card>

        <m-group-title
          v-if="booking.notes.data[0] && !canShowBookingNotes"
          :col="12"
          :label="$t('additional_information')"
          style="margin-top: 15px;"
        />
        <q-card v-if="booking.notes.data[0] && !canShowBookingNotes" class="bg-white">
          <div
            v-for="note in formattedNotes.filter(n => n.parent_id === null)"
            :key="note.reference"
          >
            <m-editor-viewer v-if="formattedNotes.filter(n => n.parent_id === note.id)" :content="note.body" :style="formattedNotes.length > 1 ? 'padding-bottom: 0' : ''" />
            <m-editor-viewer v-else :content="note.body" />
            <q-list v-if="formattedNotes.filter(n => n.parent_id === note.id)">
              <q-item
                v-for="childNote in formattedNotes.filter(n => n.parent_id === note.id)"
                :key="childNote.id"
                class="bg-white"
                multiline
              >
                <q-item-section side top aria-hidden="true">
                  &#9584;
                </q-item-section>
                <q-item-section>
                  <q-item-label>
                    <small>{{ childNote.body }}</small>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </q-card>

        <!-- Cancellation Request Dialog -->
        <q-dialog v-model="showCancellationDialog">
          <q-card>
            <q-card-section>
              <q-item-label>
                {{ $t('confirm.cancellation') }}
              </q-item-label>
            </q-card-section>
            <q-card-actions align="right">
              <q-btn v-close-popup flat :label="$t('cancel')" color="secondary" />
              <q-btn v-close-popup flat :label="$t('confirm.confirm')" color="primary" @click="submitCancellationRequest" />
            </q-card-actions>
          </q-card>
        </q-dialog>

        <m-group-title :label="$t('related')" />
        <q-card class="bg-white text-dark other">
          <q-list no-border separator>
            <q-item v-if="canRequestCancellation">
              <q-item-section>
                <q-item-label label>
                  {{ $t('cancel') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('tooltip.booking_cancellation') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn flat color="negative" @click="showCancellationDialog = true">
                  {{ $t('cancel') }}
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item v-if="canSeeRequests">
              <q-item-section>
                <q-item-label label>
                  {{ $t('requests').charAt(0).toLocaleUpperCase() + $t('requests').slice(1) }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('tooltip.customer_request_booking_change') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn :to="{path: '/requests', query: { booking: $route.query.booking } }" flat color="primary">
                  {{ $t('view') }}
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label label>
                  {{ $tc('invoice.invoice', 2) }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('invoice_related_to_booking') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn :to="{hash: '/booking/invoices', query: { booking: $route.query.booking } }" flat color="primary">
                  {{ $t('view') }}
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label label>
                  {{ $t('documents') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('files_attached_to_booking') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn :to="{hash: '/booking/documents', query: { booking: $route.query.booking } }" flat color="primary">
                  {{ $t('view') }}
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item v-if="canShowBookingNotes">
              <q-item-section>
                <q-item-label label>
                  {{ $t('notes') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('notes_attached_to_booking') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn :to="{hash: '/booking/notes', query: { booking: $route.query.booking } }" flat color="primary">
                  {{ $t('view') }}
                </q-btn>
              </q-item-section>
            </q-item>

            <q-item v-if="canSeeActivityLog">
              <q-item-section>
                <q-item-label label>
                  {{ $t('activity_log') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('booking.record_of_changes') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn :to="{hash: '/activity', query: { type: 'bookings', reference: $route.query.booking } }" flat color="primary">
                  {{ $t('view') }}
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
      </div>
    </q-page>
  </peek-form>
</template>

<script>
import authentication from 'mixins/authentication'
import date from 'utils/date-time'
import peekForm from '../peekForm.vue'
import extraInfo from './extra-info.vue'
import { post as postCancellationRequest } from 'api/requests.js'
import { MGroupTitle, MEditorViewer } from 'components/'

export default {
  components: {
    MGroupTitle,
    MEditorViewer,
    peekForm,
    extraInfo
  },
  filters: {
    date (data) {
      return data ? date.toShortDateTime(data) : null
    }
  },
  mixins: [authentication],
  data () {
    return {
      booking: null,
      showCancellationDialog: false
    }
  },
  computed: {
    formattedNotes () {
      return this.booking.notes.data.map(note => note)
    },
    initialRequest () {
      return this.booking && this.booking.requests ? this.booking.requests.data[0] : null
    },
    canSeeActivityLog () {
      return this.hasPermission('can.view.anything') || this.hasRole('middleoffice.outsource')
    },
    canSeeRequests () {
      return this.hasPermission('can.view.anything') || this.hasRole('middleoffice.outsource')
    },
    canSeeAdditionalInfo () {
      return this.hasPermission('can.view.anything') || this.hasRole('middleoffice.outsource')
    },
    canShowBookingNotes () {
      return this.hasPermission('can.view.anything') || this.hasRole('middleoffice.outsource')
    },
    canRequestCancellation () {
      // Temporarily disable the cancellation button due to cancellation requests breaking the requests page
      return false
      // return this.hasPermission('can.view.anything') || this.hasRole('middleoffice.outsource')
    }
  },
  created () {
    this.booking = this.$store.getters['entities/bookings/find'](this.$route.query.booking)
    this.$store.dispatch('entities/bookings/loadBooking', this.$route.query.booking)
      .then(() => {
        this.booking = this.$store.getters['entities/bookings/find'](this.$route.query.booking)
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    submitCancellationRequest () {
      const body = {
        type: 'cancellation',
        content_type: this.booking.type,
        message: 'SDC Cancellation Request',
        contact_number: this.booking.phone_number || this.booking.requests.data[0].phone_number,
        booking: this.booking.reference,
        customer_reference: this.booking.reference
      }

      postCancellationRequest(body)
        .then(response => {
          this.$router.push({
            name: 'dashboard',
            params: {
              booking_reference: response.data.reference
            }
          })
        })
        .catch(e => {
          this.$q.notify({
            message: this.$t('fill_all_fields'),
            position: 'top-right'
          })
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-item
    padding 16px
  .q-card, .m-group-title
    margin 0 0 16px !important
</style>
