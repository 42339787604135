<template>
  <div id="card-carousel">
    <q-btn
      v-if="$q.platform.is.desktop && items && items.length"
      class="scroll-button left text-primary"
      icon="fas fa-chevron-left"
      @click="scrollLeft"
    />
    <div class="card-row" @scroll="handleScroll">
      <q-card
        v-for="(card, i) in items"
        :id="`card-${i}`"
        :key="`${card.latitude},${card.longitude},${i}`"
      >
        <div class="card-name">
          {{ isCarClub ? card.description : card.name }}
        </div>
        <m-icons-type
          :type="isCarClub ? 'enterprise' : searchType"
          size="19px"
          rounded
        />
        <div class="search-type">
          {{ $tc(`content_type.${searchType}`) }}
        </div>
        <div v-if="searchType === 'parking'" class="search-type parking-info">
          <q-icon name="fa fa-info-circle" class="light-blue-5" />
          {{ $t(`parking_restrictions`) }}
        </div>
        <div class="button-holder">
          <q-btn
            v-if="card.type === 'website'"
            unelevated
            align="center"
            color="primary"
            size="sm"
            :label="$t('website')"
            class="text-white q-mt-lg q-mr-sm"
            @click="openURL(card.attributes.url)"
          />
          <q-btn
            v-else-if="bookRoute(card)"
            unelevated
            align="center"
            icon="fas fa-ticket-alt"
            color="primary"
            size="sm"
            :label="$t('book')"
            class="text-white q-mt-lg q-mr-sm"
            :to="{
              name: bookRoute(card),
              params: {
                origin: {
                  label: paramsLabel(card),
                  value: paramsValue(card),
                  place_id: paramsValue(card)
                }
              }
            }"
          />
          <q-btn
            unelevated outline
            align="center"
            icon="fas fa-directions"
            color="primary"
            size="sm"
            tag="a"
            target="_blank"
            :label="$t('directions')"
            class="text-white q-mt-lg"
            @click="openURL(`https://www.google.com/maps/dir/${latLng.latitude+','+latLng.longitude}/${card.latitude},${card.longitude}/`)"
          />
        </div>
      </q-card>
      <loading-card v-if="loading" />
      <loading-card v-if="loading" />
      <loading-card v-if="loading" />
      <q-card v-if="showEmptyState" class="empty-state">
        <m-empty-state
          v-if="searchType==='ferry-soon'"
          padding-top="12px"
          icon="fas fa-ship"
          size="52px"
          :font-size="24"
          label="Booking Service available soon"
        />
        <m-empty-state
          v-else-if="searchType==='unibus'"
          padding-top="12px"
          icon="fas fa-bus"
          size="52px"
          :font-size="24"
          label="Booking Service available soon"
        />
        <m-empty-state
          v-else
          name="fas fa-map-marker-question"
          padding-top="0"
          size="52px"
          :font-size="30"
          :label="$t('nothing_found_nearby')"
        />
      </q-card>
    </div>
    <q-btn
      v-if="$q.platform.is.desktop && items && items.length"
      class="scroll-button right text-primary"
      icon="fas fa-chevron-right"
      @click="scrollRight"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MIconsType, MEmptyState } from 'components/'
import { openURL } from 'quasar'
import { hasPermission } from 'utils/authentication'
import loadingCard from './loadingCard.vue'

export default {
  components: { MIconsType, loadingCard, MEmptyState },
  data () {
    return {
      routes: {
        rail: 'trains'
      },
      timer: null
    }
  },
  computed: {
    ...mapGetters({
      items: 'discovery/items',
      loading: 'discovery/loading',
      searchType: 'discovery/searchType',
      curLocation: 'discovery/location',
      selected: 'discovery/selected',
      latLng: 'geolocate/latLng'
    }),
    showEmptyState () {
      if (this.loading) return false
      if (this.items?.length > 0) return false
      return true
    },
    isCarClub () {
      return this.searchType === 'carclub'
    }
  },
  watch: {
    selected (val) {
      if (val) {
        const i = this.items.findIndex(item => {
          return item.latitude === val.lat && item.longitude === val.lng
        })

        const selected = this.$root.$el.querySelector(`#card-${i}`)
        if (selected) selected.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },
  methods: {
    openURL,
    paramsValue (item) {
      let value
      switch (item.type) {
      case 'flight':
      case 'carclub':
      case 'bikehire':
      case 'bus':
      case 'tram':
        value = `${item.latitude},${item.longitude}`
        break
      case 'rental':
        value = item.attributes.branch_code
        break
      default:
        value = item.description
      }
      return value
    },
    paramsLabel (item) {
      let label

      switch (item.type) {
      case 'carclub':
        label = item.description
        break
      case 'rental':
        label = `${item.name} - ${item.description}`
        break
      default:
        label = item.name
        break
      }

      return label
    },
    bookRoute ({ type, attributes }) {
      switch (type) {
      case 'rental':
        if (hasPermission('search.rentals')) return 'ondemand-car-hire'
        else return false
      case 'rail':
        if (hasPermission('search.trains')) return 'ondemand-trains'
        else return false
      case 'bus':
        if (hasPermission('search.bus')) return 'ondemand-bus'
        else return false
      case 'tram':
        if (hasPermission('search.tram')) return 'ondemand-tram'
        else return false
      case 'flight':
        if (hasPermission('search.flights')) return 'ondemand-flights'
        else return false
      case 'carclub':
      case 'bp-c':
        if (hasPermission('search.carclub')) return 'ondemand-carclub'
        else return false
      case 'bikehire':
      case 'bp-b':
        if (hasPermission('search.bikehire')) return 'ondemand-bikehire'
        else return false
      case 'ferry':
        if (hasPermission('search.ferry')) return 'ondemand-ferry'
        else return false
      case 'parking':
        if (hasPermission('search.parking')) return 'ondemand-derby-parking-results'
        else return false
      case 'srf':
        if (hasPermission('search.srf')) return 'ondemand-srf'
      default:
        return null
      }
    },
    handleScroll (e) {
      if (this.loading) return
      if (this.$q.platform.is.desktop) return
      // Set & reset timeout so we only reposition after user stops scrolling
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        // Spread HTMLCollection of cards into an Array
        const children = [...e.target.children]

        // Filter finds the first card with a positive left margin
        const [ child ] = children.filter(child => {
          const { left } = child.getBoundingClientRect()
          return left > 0
        })

        if (child) {
          const i = child.id.split('-')[1]
          const { latitude, longitude } = this.items[i]
          this.$store.dispatch('discovery/setSelected', {
            id: child.id,
            lat: latitude,
            lng: longitude
          })
        }
      }, 150)
    },
    scrollRight () {
      const id = this.selected?.id || 'card-0'
      let i = Number(id.split('-')[1])
      if (i <= this.items.length - 2) {
        const { latitude, longitude } = this.items[++i]
        this.$store.dispatch('discovery/setSelected', {
          id: `card-${i}`,
          lat: latitude,
          lng: longitude
        })
      }
    },
    scrollLeft () {
      const id = this.selected?.id || 'card-0'
      let i = Number(id.split('-')[1])
      if (i >= 1) {
        const { latitude, longitude } = this.items[--i]
        this.$store.dispatch('discovery/setSelected', {
          id: `card-${i}`,
          lat: latitude,
          lng: longitude
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
#card-carousel
  min-height 140px
  padding 8px 0
  display flex
  position absolute
  bottom -60px
  right 0
  left 0
  z-index 2
  margin-bottom 5vh

#card-carousel
  @media (min-width 768px)
    width 84%
    left 8%

.card-row
  width 100%
  display flex
  padding 2px 0
  overflow-x scroll
  scroll-snap-type x mandatory
  -ms-overflow-style none
  scrollbar-width none
  &::-webkit-scrollbar
    display none

.q-card
  scroll-snap-align center
  background white
  height 100%
  min-width 300px
  max-width 300px
  margin 0 4px
  padding 5px 10px
  border-radius 15px
  display grid
  grid-template-columns auto 24px
  align-items start
  grid-template-areas "card-name type-icon" \ "search-type ." \ "buttons buttons"
  @media (min-width 768px)
    min-width 60%

.empty-state
  display flex
  align-items flex-start
  justify-content center
  width 100vw

.card-name
  color convert(brandColor)
  font-weight 500
  grid-area card-name
  font-size 95%

.search-type
  font-size 85%
  grid-area search-type

.button-holder
  grid-area buttons
  width 100%
  display flex
  justify-content flex-start

.q-btn
  width 105px
  & >>> .q-btn__wrapper
    padding 4px 8px

.scroll-button
  background rgba(255, 255, 255, 0.6)
  box-shadow none
  margin 2px 0
  width 35px
  &.left
    border-top-left-radius 15px
    border-bottom-left-radius 15px
  &.right
    border-bottom-right-radius 15px
    border-top-right-radius 15px

.parking-info
  position relative
  top 21px
  font-size: 70%
</style>
