var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"card-carousel"}},[(_vm.$q.platform.is.desktop && _vm.items && _vm.items.length)?_c('q-btn',{staticClass:"scroll-button left text-primary",attrs:{"icon":"fas fa-chevron-left"},on:{"click":_vm.scrollLeft}}):_vm._e(),_c('div',{staticClass:"card-row",on:{"scroll":_vm.handleScroll}},[_vm._l((_vm.items),function(card,i){return _c('q-card',{key:((card.latitude) + "," + (card.longitude) + "," + i),attrs:{"id":("card-" + i)}},[_c('div',{staticClass:"card-name"},[_vm._v(" "+_vm._s(_vm.isCarClub ? card.description : card.name)+" ")]),_c('m-icons-type',{attrs:{"type":_vm.isCarClub ? 'enterprise' : _vm.searchType,"size":"19px","rounded":""}}),_c('div',{staticClass:"search-type"},[_vm._v(" "+_vm._s(_vm.$tc(("content_type." + _vm.searchType)))+" ")]),(_vm.searchType === 'parking')?_c('div',{staticClass:"search-type parking-info"},[_c('q-icon',{staticClass:"light-blue-5",attrs:{"name":"fa fa-info-circle"}}),_vm._v(" "+_vm._s(_vm.$t("parking_restrictions"))+" ")],1):_vm._e(),_c('div',{staticClass:"button-holder"},[(card.type === 'website')?_c('q-btn',{staticClass:"text-white q-mt-lg q-mr-sm",attrs:{"unelevated":"","align":"center","color":"primary","size":"sm","label":_vm.$t('website')},on:{"click":function($event){return _vm.openURL(card.attributes.url)}}}):(_vm.bookRoute(card))?_c('q-btn',{staticClass:"text-white q-mt-lg q-mr-sm",attrs:{"unelevated":"","align":"center","icon":"fas fa-ticket-alt","color":"primary","size":"sm","label":_vm.$t('book'),"to":{
            name: _vm.bookRoute(card),
            params: {
              origin: {
                label: _vm.paramsLabel(card),
                value: _vm.paramsValue(card),
                place_id: _vm.paramsValue(card)
              }
            }
          }}}):_vm._e(),_c('q-btn',{staticClass:"text-white q-mt-lg",attrs:{"unelevated":"","outline":"","align":"center","icon":"fas fa-directions","color":"primary","size":"sm","tag":"a","target":"_blank","label":_vm.$t('directions')},on:{"click":function($event){return _vm.openURL(("https://www.google.com/maps/dir/" + (_vm.latLng.latitude+','+_vm.latLng.longitude) + "/" + (card.latitude) + "," + (card.longitude) + "/"))}}})],1)],1)}),(_vm.loading)?_c('loading-card'):_vm._e(),(_vm.loading)?_c('loading-card'):_vm._e(),(_vm.loading)?_c('loading-card'):_vm._e(),(_vm.showEmptyState)?_c('q-card',{staticClass:"empty-state"},[(_vm.searchType==='ferry-soon')?_c('m-empty-state',{attrs:{"padding-top":"12px","icon":"fas fa-ship","size":"52px","font-size":24,"label":"Booking Service available soon"}}):(_vm.searchType==='unibus')?_c('m-empty-state',{attrs:{"padding-top":"12px","icon":"fas fa-bus","size":"52px","font-size":24,"label":"Booking Service available soon"}}):_c('m-empty-state',{attrs:{"name":"fas fa-map-marker-question","padding-top":"0","size":"52px","font-size":30,"label":_vm.$t('nothing_found_nearby')}})],1):_vm._e()],2),(_vm.$q.platform.is.desktop && _vm.items && _vm.items.length)?_c('q-btn',{staticClass:"scroll-button right text-primary",attrs:{"icon":"fas fa-chevron-right"},on:{"click":_vm.scrollRight}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }