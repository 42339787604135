<template>
  <div class="overview row">
    <div class="col-5">
      {{ $t('train.class.preferred') }}
    </div>
    <div
      class="col-6 capitalize text-dark"
    >
      {{ policy && policy.preferred_ticket_classes ? policy.preferred_ticket_classes.map(p => p.label).join(', ') : $t('any') }}
    </div>
    <div class="col-5">
      {{ $t('railcard') }}
    </div>
    <div
      class="col-6 capitalize text-dark"
    >
      {{ peekUser.meta.railcard_name || $t('none') }}
    </div>
    <div class="col-5">
      {{ $t('global_budget') }}
    </div>
    <div
      class="col-6 text-dark"
    >
      {{ policy && policy.global_budget ? '£' + (policy.global_budget / 100) : $t('mobility_budget.unlimited') }}
    </div>
  </div>
</template>

<script>
import User from 'store/models/user'
export default {
  props: ['policy'],
  computed: {
    peekUser () {
      return User.find(this.$store.getters['entities/users/peekUser'])
    }
  }
}
</script>

<style lang="stylus" scoped>
.overview > div, .repeater > div
  padding 6px 0

</style>
