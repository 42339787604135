<template>
  <q-item class="salesLine text-center">
    <q-item-section side>
      <q-item-label caption>
        Quantity
      </q-item-label>
      <q-item-label>
        {{ line.quantity }}
      </q-item-label>
    </q-item-section>
    <q-item-section>
      <q-item-label caption>
        Description
      </q-item-label>
      <q-item-label>
        {{ line.description }}
      </q-item-label>
    </q-item-section>
    <q-item-section>
      <q-item-label caption>
        Unit Price
      </q-item-label>
      <q-item-label>
        {{ formatMoney(line.unitPrice) }}
      </q-item-label>
    </q-item-section>
    <q-item-section>
      <q-item-label caption>
        Tax Rate
      </q-item-label>
      <q-item-label>
        {{ line.unitPriceIncludesTax ? `Inclusive` : line.taxRate ? line.taxRate.label : undefined }}
      </q-item-label>
    </q-item-section>
    <q-item-section>
      <q-item-label caption>
        Gross amount
      </q-item-label>
      <q-item-label>
        {{ grossAmount }}
      </q-item-label>
    </q-item-section>
    <q-item-section side>
      <a v-if="showRemove" class="text-primary cursor-pointer" @click="$emit('removeLine', index)">
        Remove
      </a>
      <a class="text-primary cursor-pointer" @click="$emit('editLine', index)">
        Edit
      </a>
    </q-item-section>
  </q-item>
</template>
<script>
import creditTaxCalculations, { calculateGross } from 'mixins/creditTaxCalculations.js'
export default {
  mixins: [creditTaxCalculations],
  props: ['line', 'index', 'showRemove'],
  computed: {
    grossAmount () {
      return calculateGross(this.line).toFormat('$0.00')
    }
  }
}
</script>
<style lang="stylus" scoped>
.salesLine
  background-color #fafafa
  box-shadow 0px 4px 5px rgba(0, 0, 0, 0.125)
  &:hover
    background-color #efefef
</style>
