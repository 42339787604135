import http from './http'

export function conciergeRequest (params) {
  return http.post('requests', {
    'content_type': 'onwardtravel',
    type: 'booking',
    ...params
  })
}

export function cost_centres (slug) {
  slug = slug || 'raconwardtravel'
  return http.get(`organisations/${slug}/cost-centres`)
}

export function business_areas (slug) {
  slug = slug || 'raconwardtravel'
  return http.get(`organisations/${slug}/business_areas`)
}
