<template>
  <q-select
    v-model="railcard"
    :options="railcards"
    :label="$t('railcard')"
    :hint="$t('tooltip.railcard_applied_to_all')"
    clearable
  />
</template>
<script>
import { railcards } from 'api/train'
export default {
  props: ['value'],
  data () {
    return {
      railcards: []
    }
  },
  computed: {
    railcard: {
      get () {
        if (this.value.value) return this.value
        return null
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  created () {
    railcards().then(response => {
      this.railcards = response.data.map(railcard => {
        return {
          label: railcard.name,
          value: railcard.code
        }
      })
    })
  }
}
</script>
