import store from 'store'
/**
 * If the routes layout is login and someone is authed then redirect them
 * to the dashboard component.
 */
export default (to, from, next) => {
  if (to.matched.some(record => record.meta.layout === 'login') && store.getters.session.token) {
    return next({
      name: 'dashboard'
    })
  }
  next()
}
