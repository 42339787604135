import { cloneDeep } from 'lodash'

const initalState = {
  ondemand: [
    {
      icon: 'train',
      color: {
        icon: 'blue',
        marker: '#2196f3'
      },
      value: 'rail',
      label: 'Train'
    },
    {
      icon: 'plane',
      color: {
        icon: 'red',
        marker: '#f44336'
      },
      value: 'flight',
      label: 'Flight'
    },
    {
      icon: 'car',
      color: {
        icon: 'lime',
        marker: '#cddc39'
      },
      value: 'rental',
      label: 'Rental'
    },
    {
      icon: 'hotel',
      color: {
        icon: 'light-green',
        marker: '#607d8b'
      },
      value: 'hotel',
      label: 'Hotel'
    },
    {
      icon: 'taxi',
      color: {
        icon: 'black',
        marker: '#cddc39'
      },
      value: 'ridehailing',
      label: 'Taxi'
    },
    {
      icon: 'taxi',
      color: {
        icon: 'pink',
        marker: '#cddc39'
      },
      value: 'onwardtravel',
      label: 'Onward Travel'
    },
    {
      icon: 'cars',
      color: {
        icon: 'green',
        marker: '#4caf50'
      },
      value: 'carclub',
      label: 'Car Club'
    },
    {
      icon: 'bus',
      color: {
        icon: 'deep-purple',
        marker: '#673ab7'
      },
      value: 'bus',
      label: 'Bus'
    },
    {
      icon: 'subway',
      color: {
        icon: 'indigo',
        marker: '#3f51b5'
      },
      value: 'tram',
      label: 'Tram'
    },
    {
      icon: 'bicycle',
      color: {
        icon: 'deep-orange',
        marker: '#ff5722'
      },
      value: 'bikehire',
      label: 'Bike Hire'
    },
    {
      icon: 'bicycle',
      color: {
        icon: 'grey-9',
        marker: '#2a2626'
      },
      value: 'bicycle',
      label: 'Own Bike'
    },
    {
      icon: 'subway',
      color: {
        icon: 'blue-grey',
        marker: '#607d8b'
      },
      value: 'tube',
      label: 'Tube'
    },
    {
      icon: 'cars',
      color: {
        icon: 'grey-3',
        marker: '#4caf50'
      },
      label: 'Driving',
      value: 'driving'
    }
  ],
  walking: [
    {
      icon: 'walking',
      color: {
        icon: 'grey-3',
        marker: '#4caf50'
      },
      label: 'Walking',
      value: 'walking'
    }
  ],
  other: [
    {
      label: 'Fuel',
      value: 'fuel'
    },
    {
      label: 'Restaurant',
      value: 'restaurant'
    },
    {
      label: 'Theatre',
      value: 'theatre'
    },
    {
      label: 'Other',
      value: 'other'
    },
    {
      label: 'Eurostar',
      value: 'eurostar'
    },
    {
      label: 'Parking',
      value: 'parking'
    },
    {
      label: 'Chauffeur',
      value: 'chauffeur'
    },
    {
      label: 'Lounge',
      value: 'lounge'
    }
  ],
  gohi: [
    {
      asset: 'icons/dial-m-circle.svg',
      icon: 'car-bus',
      color: {
        icon: 'gohi-bus',
        marker: '#eb79fc'
      },
      value: 'drt',
      label: 'Dial M'
    },
    {
      icon: 'ship',
      color: {
        icon: 'gohi-ferry',
        marker: '#51626f'
      },
      value: 'ferry',
      label: 'Ferry'
    }
  ],
  derbygo: [
    {
      icon: 'train',
      color: {
        icon: 'blue',
        marker: '#2196f3'
      },
      value: 'rail',
      label: 'Train'
    },
    {
      icon: 'bus',
      color: {
        icon: 'deep-purple',
        marker: '#673ab7'
      },
      value: 'bus',
      label: 'Bus'
    }
  ]
}

const state = cloneDeep(initalState)

const getters = {
  all: state => [].concat(state.ondemand, state.other),
  ondemand: state => [].concat(state.ondemand, state.walking),
  mobilleo: state => state.ondemand.filter(type => !['onwardtravel'].includes(type.value)),
  kinto: state => state.ondemand.filter(type => !['onwardtravel', 'carclub'].includes(type.value)),
  bp: state => {
    const filteredTypes = state.ondemand.filter(type => !['onwardtravel', 'flight'].includes(type.value))

    return [].concat(filteredTypes, state.walking)
  },
  gohi: state => [].concat(state.ondemand, state.gohi).filter(type => !['onwardtravel', 'tram', 'tube'].includes(type.value)),
  derbygo: state => [].concat(state.derbygo),
  org: state => state.ondemand,
  other: state => state.other
}

export default {
  state,
  getters,
  namespaced: true
}
