import http from './http'
// import { searchData } from 'mocks/journey-planner'

export function smart (params) {
  return http.get(`/smartsearch`, { params })
}

export function getAvailableContents (params) {
  return http.get(`/smartsearch/contents`, { params })
}

export function getContextData (type, params) {
  return http.get(`/smartsearch/${type}`, { params })
}

export function contextual (token, type) {
  return http.get(`/smartsearch/${token}/${type}`)
}

export function text (params) {
  return http.get(`/search/text`, { params })
}

export function getTravelModes (params) {
  // return Promise.resolve({ data: searchData })
  return http.post('/journey-planner', params)
}
