<template>
  <peek-form
    :title="$tc('payment_card.card', 2)"
    :footer="false"
  >
    <card-item
      v-for="card in cards"
      :key="card.reference"
      :card="card"
      :selected="card.reference === selected"
      @select="select(card)"
      @delete-card="deleteCard"
    />
  </peek-form>
</template>

<script>
import peekForm from 'peeks/peekForm.vue'
import cardItem from 'pages/payments/card/cardItem.vue'
import { savedCards, deleteCard } from 'api/user'
import { mapGetters } from 'vuex'

export default {
  components: {
    peekForm,
    cardItem
  },
  data () {
    return {
      selected: null,
      cards: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  async beforeMount () {
    const cards = await savedCards(this.user)
    this.cards = cards.data
  },
  methods: {
    savedCards,
    select (card) {
      this.selected = card.reference
    },
    async deleteCard (reference) {
      try {
        await deleteCard(this.user, reference)

        // Adjust display to match user expectation
        this.cards = this.cards.filter(card => card.reference !== reference)

        this.$q.notify({
          message: this.$t('payment_card.removed'),
          color: 'positive',
          icon: 'far fa-check-double'
        })
      } catch (err) {
        this.$q.notify({
          message: this.$t('error.removing_card'),
          color: 'negative',
          icon: 'fas fa-times-cirlce'
        })
      }
    }
  }
}
</script>
