
import { index as getAllBookings, get, cancel } from 'api/bookings'
import Booking from 'store/models/booking'
import router from 'router'
import { Dialog } from 'quasar'
import loading from 'utils/loading'

function vm (b) {
  return {
    ...b,
    booked_at: b.booked_at.date,
    starts_at: b.starts_at ? b.starts_at.date : null
  }
}

const actions = {
  async loadAllBookings (context, filters) {
    try {
      const response = await getAllBookings(filters)
      const bookings = response.data
      await Booking.insert({ data: bookings.map(vm) })
      return response.meta.pagination
    } catch (error) {
      console.log(error)
    }
  },
  async loadOrganisationBookings (context, { organisation, filters }) {
    try {
      const response = await getAllBookings({
        ...filters,
        organisation
      })
      const bookings = response.data
      await Booking.insert({ data: bookings.map(vm) })
      return response.meta.pagination
    } catch (error) {
      console.log(error)
    }
  },
  async loadBooking (context, bookingReference) {
    try {
      const response = await get(bookingReference, { include: 'requests,notes' })
      const booking = response.data
      return await Booking.insertOrUpdate({
        data: vm(booking)
      })
    } catch (error) {
    }
  },
  async cancel ({ rootGetters }, bookingReference) {
    loading.start({
      message: 'Sending cancellation request',
      partnerSlug: rootGetters.partner.slug,
      spinnerColor: rootGetters.partner.hex
    })
    try {
      await cancel(bookingReference)
      router.push({
        name: 'dashboard',
        params: {
          booking_cancelled: true
        }
      })
      loading.stop()
    } catch (err) {
      loading.stop()
      Dialog.create({
        style: 'width: 700px; max-width: 80vw;',
        title: 'Automatic cancellation unavailable',
        message: 'Automatic cancellation is currently unavailable for this booking. To cancel this booking, a concierge request needs to be submitted.<br><br>Would you like to do that now?',
        cancel: true,
        persistent: true,
        html: true
      }).onOk(() => {
        router.push({
          query: {
            type: 'cancellation',
            booking: bookingReference
          },
          hash: '/concierge'
        })
      })
    }
  }
}

export default {
  actions
}
