import http from './http'

export function routeInfo (points, tolerance = 0) {
  const p = points.map(p => ({ lat: p.lat, lon: p.lng }))
  return http.post(`/directions/driving?percentage_tolerance=${tolerance}`, { points: p })
}

export function search (body) {
  return http.get('/search/ridehailing', {
    params: body
  })
}

export function additionalInfo (token, info) {
  return http.post(`/search/ridehailing/${token}`, info)
}
