import { isPermissionEnabled } from './permissions'
import { isOrganisationPolicyEnabled } from './organisation'
import store from 'store'

/**
 * Mutates content types into human-readable labels
 * @returns {object}
 */
export function mutableContentTypes () {
  return {
    carclub: 'Car Club',
    rail: 'Train',
    ridehailing: 'Taxi',
    bikehire: 'Bike Hire'
  }
}

/**
 * Takes a type and maps it to a permission
 * @param {string} type
 * @returns {string} Name of the permission to check
 */
export function typeToPermissionMapper (type) {
  switch (type) {
  case 'rail':
  case 'trains':
    return 'search.trains'
  case 'rental':
  case 'car-hire':
    return 'search.rentals'
  case 'flight':
    return 'search.flights'
  case 'ride-hailing':
  case 'ridehailing':
    return 'search.rides'
  case 'bp':
  case 'driving':
  case 'walking':
  case 'bicycle':
    return true
  default:
    return `search.${type}`
  }
}

/**
 * Takes a type and returns the name of the associated content type
 * @param {string} type
 * @returns {string} Name of the content type
 */
export function typeToContentTypeMapper (type) {
  switch (type) {
  case 'trains':
    return 'rail'
  case 'car-hire':
    return 'rental'
  case 'ride-hailing':
    return 'ridehailing'
  case 'flights':
    return 'flight'
  default:
    return type
  }
}

/**
 * Only allow the type to be enabled if passes all three gatekeepers: Permissions, Policy, and Preferences
 * @param {string} type
 * @param {Object} travelPreference user's travel preference for this type
 * @param {boolean} includePermissions Include check against user permissions. Default true
 * @param {boolean} includePolicy Include check against organisation policy. Default true
 * @param {boolean} includePreferences Include check against user preferences. Default true
 * @returns {boolean}
 */
export function isTypeEnabled (type, travelPreference, includePermissions = true, includePolicy = true, includePreferences = true) {
  if (['bp', 'ferry', 'unibus'].includes(type)) return true
  let permissionEnabled = includePermissions ? isPermissionEnabled(type) : true
  let preferencesEnabled = includePreferences ? !travelPreference?.hidden : true
  return permissionEnabled && preferencesEnabled
}

/**
 * Check if a content type is enabled or not on an organisation's travel policy
 * @param {string} type
 * @returns {boolean}
 */
export function isPolicyEnabled (type) {
  return store.state.authentication.organisation && store.state.authentication.organisation.attributes?.travel_policy
    ? isOrganisationPolicyEnabled(typeToContentTypeMapper(type), store.state.authentication.organisation.attributes.travel_policy)
    : true
}
