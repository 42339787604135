import { index, get, put, post, block, unblock, resetPasswordEmail } from 'api/individuals'
import _ from 'lodash'
import selects from './membersSelects'
import User from 'store/models/user'
import { Notify } from 'quasar'
import i18n from 'i18n'

const initialState = {
  selectedUserId: null,
  index: {},
  selects
}

const state = _.cloneDeep(initialState)

const getters = {
  getUser: state => id => {
    return _.cloneDeep(state.index[id])
  },
  getSelectedUser: state => {
    return _.cloneDeep((state.index[state.selectedUserId]))
  },
  getSelect: state => id => {
    return _.cloneDeep(id ? state.selects[id] : state.selects)
  }
}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  setSelectedUserId ({ commit }, userId) {
    commit('setSelectedUserId', userId)
  },
  loadUsers ({ commit }, { params }) {
    return index(params)
      .then(individuals => {
        commit('loadUsersSuccess', individuals.data)
        return individuals
      })
      .catch(e => {
        commit('loadUsersFailure', e)
      })
  },
  loadUser ({ commit }, { username, params }) {
    return get(username, params)
      .then(individual => {
        commit('loadUserSuccess', individual.data)
      })
      .catch(e => {
        commit('loadUserFailure', e)
      })
  },
  createUser ({ commit }, { email }) {
    return post({
      email
    })
      .then(individual => {
        commit('createUserSuccess', individual.data)
        Notify.create({
          message: i18n.t('user.created'),
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        commit('createUserFailure', e)
        Notify.create({
          message: i18n.t('error.user.not_created'),
          color: 'negative',
          icon: 'clear'
        })
      })
  },
  updateUser ({ commit }, user) {
    const username = user.username
    return put(username, user)
      .then(individual => {
        commit('updateUserSuccess', individual.data)
      })
      .catch(e => {
        commit('updateUserFailure', e)
      })
  },
  blockUser ({ commit }, { user }) {
    const username = user.username
    return block(username)
      .then(() => {
        Notify.create({
          message: i18n.t('user.blocked'),
          color: 'positive',
          icon: 'mdi-check'
        })
        commit('blockUserSuccess', username)
      })
      .catch(e => {
        Notify.create({
          message: i18n.t('error.user.not_blocked'),
          color: 'negative',
          icon: 'clear'
        })
        commit('blockUserFailure', e)
      })
  },
  unblockUser ({ commit }, { user }) {
    const username = user.username
    return unblock(username)
      .then(() => {
        commit('unblockUserSuccess', username)
        Notify.create({
          message: i18n.t('user.unblocked'),
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        commit('unblockUserFailure', e)
        Notify.create({
          message: i18n.t('error.user.not_unblocked'),
          color: 'negative',
          icon: 'clear'
        })
      })
  },
  resetUserPassword ({ commit }, { user }) {
    resetPasswordEmail(user.email)
      .then(() => {
        commit('resetUserPasswordSuccess', user)
        Notify.create({
          message: i18n.t('reset_password_email_sent'),
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        commit('resetUserPasswordFailure', e)
        Notify.create({
          message: i18n.t('error.password_reset_not_sent'),
          color: 'negative',
          icon: 'clear'
        })
      })
  }
}

const mutations = {
  reset (state) {
    for (var prop in state) {
      state[prop] = initialState[prop]
    }
  },
  setSelectedUserId (state, id) {
    state.selectedUserId = id
  },

  loadUsersSuccess (state, individuals) {
    const indexed = _.keyBy(individuals, m => m.username)
    User.insert({ data: individuals.map((i) => {
      return {
        ...i,
        meta: i.attributes
      }
    }) })
    state.index = { ...state.index, ...indexed }
  },
  loadUsersFailure (state, error) {
    throw Error(error)
  },

  loadUserSuccess (state, individual) {
    state.index = { ...state.index, [individual.username]: individual }
  },
  loadUserFailure (state, error) {
    throw Error(error)
  },

  createUserSuccess (state, individual) {
    state.index = { ...state.index, [individual.username]: individual }
  },
  createUserFailure (state, error) {
    throw Error(error)
  },

  updateUserSuccess (state, individual) {
    state.index = { ...state.index, [individual.username]: individual }
  },
  updateUserFailure (state, error) {
    throw error
  },

  blockUserSuccess (state, username) {
    const blockedUser = { ...state.index[username], is_blocked: true }
    state.index = { ...state.index, [blockedUser.username]: blockedUser }
  },
  blockUserFailure (state, error) {
    throw Error(error)
  },

  unblockUserSuccess (state, username) {
    const unblockedUser = { ...state.index[username], is_blocked: false }
    state.index = { ...state.index, [unblockedUser.username]: unblockedUser }
  },
  unblockUserFailure (state, error) {
    throw Error(error)
  },

  resetUserPasswordSuccess (state, user) {
    return user
  },
  resetUserPasswordFailure (state, error) {
    throw Error(error)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
