import { tickets } from 'api/bus'

const initial = {
  tickets: []
}

const state = initial

const getters = {
  tickets: (state) => state.tickets
}

const actions = {
  async getTickets ({ commit }) {
    const response = await tickets()
    commit('REQUEST_TICKETS_SUCCESS', response.data)
  }
}

const mutations = {
  REQUEST_TICKETS_SUCCESS (state, tickets) {
    state.tickets = tickets
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
