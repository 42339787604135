import authentication from 'mixins/authentication'

export default {
  mixins: [ authentication ],
  methods: {
    getPermissionByType (type = null) {
      if (type) {
        switch (type) {
        case 'rental':
          return 'search.rentals'
        case 'rail':
        case 'train':
          return 'search.trains'
        case 'flight':
          return 'search.flights'
        case 'ridehailing':
          return 'search.rides'
        case 'onwardtravel':
          return 'search.onwardtravel'
        case 'bus':
          return 'search.bus'
        case 'tram':
          return 'search.tram'
        case 'carclub':
          return 'search.carclub'
        case 'srf':
          return 'search.srf'
        }
      }
      return null
    },
    hasTypePermission (type = null) {
      if (type) {
        var permission = this.getPermissionByType(type)
        if (permission) return this.hasPermission(permission)
        return false
      }
      return false
    }
  }
}
