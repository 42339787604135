<template>
  <peek-form :title="$t('documents')" :footer="false">
    <div v-if="!documents">
      <q-inner-loading
        :visible="!documents"
      >
        <q-spinner-dots
          size="60"
        />
      </q-inner-loading>
    </div>
    <div v-else-if="documents.length">
      <div v-for="document in documents" :key="document.url">
        <document-card :document="document" />
        <q-separator />
      </div>
    </div>
    <div v-else>
      <m-empty-state
        icon="attach_file"
        style="margin: auto;"
      >
        {{ $t('no_documents_found') }}
      </m-empty-state>
    </div>
  </peek-form>
</template>

<script>
import peekForm from '../../peekForm.vue'
import { documents } from 'api/bookings'
import documentCard from './document-card'
export default {
  components: { documentCard, peekForm },
  data () {
    return {
      documents: null
    }
  },
  created () {
    this.getDocuments()
  },
  methods: {
    getDocuments () {
      documents(this.$route.query.booking)
        .then(documents => {
          this.documents = documents.data
        })
    }
  }
}
</script>

<style>

</style>
