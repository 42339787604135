import { Platform } from 'quasar'
import store from 'store/modules/ondemand'

const beforeEnter = (to, from, next) => {
  !store.state.stash.parameters ? next({ name: 'ondemand-car-hire' }) : next()
}

export const mobileForm = {
  name: 'ondemand-car-hire-mobile',
  path: '/on-demand/car-hire',
  meta: {
    label: 'Rental: Search'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-carhire" */ 'pages/ondemand/mobile/car-hire.vue')
  }
}

export const form = {
  name: 'ondemand-car-hire',
  path: '/on-demand/car-hire',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-carhire" */ 'pages/ondemand/car-hire/index.vue')
  },
  beforeEnter (to, from, next) {
    if (Platform.is.mobile) {
      return next({
        name: 'ondemand-car-hire-mobile',
        params: to.params
      })
    }
    next()
  }
}

export const results = {
  name: 'ondemand-car-hire-results',
  path: '/on-demand/car-hire/results',
  meta: {
    label: 'Rental: Vehicles'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-carhire" */ 'pages/ondemand/car-hire/results/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-carhire" */ 'pages/ondemand/car-hire/sidebar/index.vue')
  },
  beforeEnter
}

export const summary = {
  name: 'ondemand-car-hire-summary',
  path: '/on-demand/car-hire/:token',
  meta: {
    label: 'Rental: Summary'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-carhire" */ 'pages/ondemand/car-hire/summary/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-carhire" */ 'pages/ondemand/car-hire/sidebar/index.vue')
  },
  beforeEnter
}

export default { form, results, summary, mobileForm }
