<template>
  <q-dialog
    v-model="showDialog"
    :maximized="$q.platform.is.mobile"
    transition-show="jump-up"
    transition-hide="jump-down"
  >
    <q-card class="booking-complete bg-primary text-white text-center" square>
      <q-card-section class="full-height">
        <q-icon
          name="check_circle"
          :size="$q.platform.is.mobile ? '64px' : '128px'"
          class="text-center"
        />
        <h3>{{ $t('thank_you') }}</h3>
        <q-scroll-area :style="scrollAreaStyle">
          <h5>{{ $t(`loading.processing.${bookingType}`) }}</h5>
          <p v-if="!cancellation">
            {{ $t('booking_complete.email_details') }}
          </p>
          <h5 v-if="!cancellation && reference">
            {{ $t('booking_complete.reference', { partner: partnerLabel}) + reference }}
          </h5>
          <p v-if="!concierge && !cancellation">
            {{ $t(`booking_complete.${bookingType}_confirmed`) }}
          </p>
          <p>{{ $t('for_queries_contact_company', { company: partnerLabel}) }}: <br><strong>{{ email }}</strong></p>
        </q-scroll-area>
        <q-btn
          v-close-popup
          color="white"
          outline
          flat
          size="lg"
          class="full-width"
          @click="closePopup"
        >
          {{ $t('back_to_dashboard') }}
        </q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MBookingComplete',
  data () {
    return {
      concierge: false,
      cancellation: false,
      ticket: false,
      reference: null,
      showDialog: false
    }
  },
  computed: {
    ...mapGetters({
      partnerLabel: 'partner/label',
      email: 'partner/email'
    }),
    scrollAreaStyle () {
      return {
        height: this.$q.platform.is.mobile ? `${this.$q.screen.height * 0.4}px` : '25vh'
      }
    },
    bookingType () {
      if (this.concierge) return 'concierge'
      if (this.cancellation) return 'cancellation'
      if (this.ticket) return 'ticket'
      return 'booking'
    }
  },
  watch: {
    '$route.params.booking_reference': {
      immediate: true,
      handler (val) {
        this.reference = val
        if (val) this.$nextTick(() => { this.showDialog = true })
      }
    },
    '$route.params.concierge_reference': {
      immediate: true,
      handler (val) {
        this.concierge = true
        this.reference = val
        if (val) this.$nextTick(() => { this.showDialog = true })
      }
    },
    '$route.params.booking_cancelled': {
      immediate: true,
      handler (val) {
        this.cancellation = true
        this.reference = null
        if (val) this.$nextTick(() => { this.showDialog = true })
      }
    },
    '$route.params.content_type': {
      immediate: true,
      handler (val) {
        if (val === 'bus') this.ticket = true
        if (val) this.$nextTick(() => { this.showDialog = true })
      }
    },
    showDialog: {
      immediate: true,
      handler (val) {
        if (!val) {
          this.concierge = false
          this.cancellation = false
        }
      }
    }
  },
  methods: {
    closePopup () {
      this.reference = null
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="stylus" scoped>
.booking-complete
  text-shadow none
  width 500px
  padding 32px
  min-height 55vh
  @media (max-width: 768px)
    margin 0
    max-width 100vw
    min-height 100vh
h3
  font-weight bold
p
  padding-top 1em
.q-btn
  margin-top 2em
  border 1px solid currentColor
</style>
