export default ({ Vue }) => {
  /**
   * Grab all the components so we can loop through and register them all.
   */
  const componentContext = require.context('components', true, /index\.vue$/)

  /**
   * Loop through all the components and register them
   */
  componentContext.keys().forEach(fileName => {
    const config = componentContext(fileName)
    const name = fileName.replace('.', '').replace('index.vue', '').replace(/\//g, '')
    Vue.component(name, config.default || config)
  })
}
