import { invoices as getBookingInvoices } from 'api/bookings'
import { invoices as getIndividualInvoices } from 'api/individuals'
import { invoices as getMemberInvoices } from 'api/members'
import { invoices as getOrganisationInvoices } from 'api/organisations'
import { invoices as getAllInvoices, addPurchaseOrder } from 'api/invoices'
import Invoice from 'store/models/invoice'

const state = {}

const getters = {
  bookingInvoices: state => id => {
    const invoiceNumbers = Object.keys(state.data).filter(k => state.data[k].booking_references.includes(id))
    return invoiceNumbers.map(ref => state.data[ref])
  },
  individualInvoices: state => id => {
    const invoiceNumbers = Object.keys(state.data).filter(k => state.data[k].user === id)
    return invoiceNumbers.map(ref => state.data[ref])
  },
  memberInvoices: state => id => {
    const invoiceNumbers = Object.keys(state.data).filter(k => state.data[k].user === id)
    return invoiceNumbers.map(ref => state.data[ref])
  }
}

const actions = {
  async loadAllInvoices (context, filters) {
    try {
      const response = await getAllInvoices(filters)
      const invoices = response.data
      await Invoice.insert({ data: invoices })
      return response.meta.pagination
    } catch (error) {

    }
  },
  async loadBookingInvoices (context, { bookingReference, filters }) {
    try {
      const response = await getBookingInvoices(bookingReference, filters)
      const bookingInvoices = response.data
      await Invoice.insert({ data: bookingInvoices })
      return response.meta.pagination
    } catch (error) {

    }
  },
  async loadIndividualInvoices (context, { username, filters }) {
    try {
      const response = await getIndividualInvoices(username, filters)
      const memberInvoices = response.data.map(i => ({ ...i, user: username }))
      await Invoice.insert({ data: memberInvoices })
      return response.meta.pagination
    } catch (error) {

    }
  },
  async loadOrganisationInvoices (context, { slug, filters }) {
    try {
      const response = await getOrganisationInvoices(slug, filters)
      const orgInvoices = response.data.map(i => ({ ...i, organisation: slug }))
      await Invoice.insert({ data: orgInvoices })
      return response.meta.pagination
    } catch (error) {

    }
  },
  async loadMemberInvoices (context, { organisation, username, params }) {
    try {
      const response = await getMemberInvoices(organisation, username, params)
      const memberInvoices = response.data.map(i => ({ ...i, user: username }))
      await Invoice.insert({ data: memberInvoices })
      return response.meta.pagination
    } catch (error) {

    }
  },
  async addPurchaseOrder (context, { invoice, po }) {
    try {
      const updated = await addPurchaseOrder(invoice, po)
      const existing = Invoice.find(updated.number)
      await existing.$update(updated)
    } catch (error) {

    }
  }
}

export default {
  state,
  getters,
  actions
}
