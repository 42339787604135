<template>
  <peek-form
    :title="$t('onwardtravel.concierge')"
    :call-to-action="$t('concierge.send')"
    :loading="loading"
    :disable-call-to-action="disabled"
    class="concierge-peek"
    @save="submit"
  >
    <q-page padding>
      <m-group-title
        label="Travel Information"
      />
      <q-card>
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col-6">
              <q-item-label>
                {{ $t('pick_up_location') }}
              </q-item-label>
              <q-item-label caption>
                {{ origin }}
              </q-item-label>
            </div>
            <div class="col-6">
              <q-item-label>
                {{ $t('drop_off_location') }}
              </q-item-label>
              <q-item-label caption>
                {{ destination }}
              </q-item-label>
            </div>
            <div class="col-6">
              <q-item-label>
                {{ $t('number_of.passengers') }}
              </q-item-label>
              <q-item-label caption>
                {{ numberOfPassengers }}
              </q-item-label>
            </div>
            <div class="col-6">
              <q-item-label>
                {{ $t('time.drop_off') }}
              </q-item-label>
              <q-item-label v-if="asap" caption>
                ASAP
              </q-item-label>
              <q-item-label v-else caption>
                {{ time | date }}
              </q-item-label>
            </div>
            <div class="col-6">
              <q-item-label>
                {{ $t('onwardtravel.cost_per_mile.policy') }}
              </q-item-label>
              <q-item-label caption>
                {{ $n(Number(costPerMilePolicy / 100), 'currency') }}
              </q-item-label>
            </div>
            <div v-if="selectedVehicle" class="col-6">
              <q-item-label>
                {{ $t('onwardtravel.cost_per_mile.booking') }}
              </q-item-label>
              <q-item-label caption>
                {{ selectedVehicle.cost_per_mile.text }}
              </q-item-label>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <details-form />
    </q-page>
  </peek-form>
</template>
<script type="text/javascript">
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import peekForm from '../../peekForm.vue'
import authentication from 'mixins/authentication'
import date from 'utils/date-time'
import detailsForm from 'pages/ondemand/onwardtravel/details-form.vue'
import { conciergeRequest } from 'api/onwardtravel'
import { format } from 'quasar'
export default {
  name: 'ConciergeOnwardTravel',
  filters: {
    date: (value) => date.toMediumDateTime(value)
  },
  components: {
    peekForm,
    detailsForm
  },
  mixins: [authentication],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      mainPassenger: 'onwardtravel/mainPassenger',
      racDetails: 'onwardtravel/racDetails',
      origin: 'onwardtravel/originLabel',
      destination: 'onwardtravel/destinationLabel',
      time: 'onwardtravel/time',
      numberOfPassengers: 'onwardtravel/numberOfPassengers',
      bookingDetails: 'onwardtravel/bookingDetails',
      messageObject: 'onwardtravel/messageObject',
      selectedVehicle: 'onwardtravel/selectedVehicle'
    }),
    asap () {
      return date.getDateDiff(this.time, date.newDate()) <= 0
    },
    disabled () {
      this.$v.$touch()
      return this.$v.$error
    },
    costPerMilePolicy () {
      const org = this.$store.getters.authentication.organisation
      if (!org) return false
      const policy = org.attributes.transport_policy ? org.attributes.transport_policy.onwardtravel : false
      return policy ? policy.budget : false
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (this.$v.$error) {
        this.$q.notify({
          message: this.$t('error.default'),
          caption: this.$v.$error
        })
        return
      }
      this.loading = true

      let message = ''
      Object.keys(this.messageObject).forEach(key => {
        const value = this.messageObject[key]
        const title = format.capitalize(key.replace(/_/gm, ' '))
        if (value && !message.includes(value)) message = message + `${title}: ${value}` + '\n\n'
      })
      conciergeRequest({
        ...this.bookingDetails,
        message,
        required_by: this.time
      })
        .then(res => {
          this.$router.push({
            name: 'dashboard',
            params: {
              concierge_reference: res.data.reference
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  validations: {
    mainPassenger: {
      firstName: { required },
      surname: { required },
      mobile: { required }
    },
    racDetails: {
      customer_reference: { required },
      business_area: { required },
      cost_centre: { required },
      vehicle_type: { required },
      vehicle_registration: { required }
    }
  }
}
</script>
