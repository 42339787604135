export default {
  name: 'BP',
  slug: 'bp',
  title: 'BP Mobility Hub',
  email: 'bpmobilityhub@mobilleo.com',
  brandingLogo: 'bp-logo-icon-white.png',
  brandingIcon: '',
  token: JSON.parse(process.env.VUE_APP_SWITCH_URLS)['BP'],
  hex: '#009640',
  hasTravelType: false,
  hasReasonFor: true,
  spinnerType: 'arrow',
  appLinks: {
    android: 'https://play.google.com/store/apps/details?id=com.mobilleo.android.bpmobilityhub',
    ios: 'https://apps.apple.com/gb/app/bp-hub/id1537405591'
  },
  termsURL: 'https://mobilleo.s3.eu-west-2.amazonaws.com/bp/terms.pdf',
  privacyURL: 'https://mobilleo.s3.eu-west-2.amazonaws.com/privacy.pdf',
  pages: {
    login: {
      form: {
        class: null,
        welcomeMessage: {},
        email: {
          dark: false,
          color: 'black',
          filled: true,
          class: 'q-py-md col-12'
        },
        password: {
          dark: false,
          color: 'black',
          filled: true,
          class: 'q-py-md col-12'
        },
        signIn: {
          class: 'full-width q-my-md',
          color: 'login-green',
          size: 'lg'
        },
        skipLogin: {
          class: 'full-width q-my-md',
          color: 'login-green',
          size: 'lg'
        },
        forgotPassword: {
          class: 'full-width q-my-md'
        },
        validation: {
          class: 'space row col text-center text-red strong'
        },
        createButton: {
          class: 'full-width q-my-md',
          color: 'login-green',
          size: 'lg'
        },
        completeButton: {},
        verificationButton: {
          class: 'q-my-md mr-auto',
          color: 'login-green',
          align: 'center'
        }
      }
    },
    password: {
      email: {
        form: {
          class: 'request-reset bp-green',
          welcomeMessage: {
            class: 'text-h6 text-center text-weight-bold q-mt-md q-mb-xl'
          },
          email: {
            dark: false,
            color: null,
            filled: true
          },
          resetButton: {
            class: 'full-width q-my-md',
            color: 'login-green'
          },
          backButton: {
            class: 'full-width q-my-md bp-green',
            unelevated: true,
            flat: true
          }
        }
      },
      reset: {
        form: {
          class: null,
          welcomeMessage: {},
          email: {
            dark: false,
            color: null,
            filled: true
          },
          password: {
            dark: false,
            color: null,
            filled: true
          },
          passwordConfirmation: {
            dark: false,
            color: null
          },
          resetButton: {
            class: 'full-width q-my-md',
            color: 'login-green',
            size: 'lg',
            align: 'center'
          }
        },
        message: 'bp password-reset-text',
        link: 'bp password-reset-text'
      }
    },
    signup: {
      complete: {
        form: {
          class: 'row bp-green',
          welcomeMessage: {},
          email: {
            dark: false,
            color: 'black',
            filled: true,
            class: 'full-width'
          },
          organisationName: {
            dark: false,
            color: 'black',
            filled: true,
            class: 'full-width'
          },
          name: {
            dark: false,
            color: 'black',
            filled: true,
            class: 'full-width'
          },
          password: {
            dark: false,
            color: 'black',
            filled: true,
            class: 'full-width'
          },
          passwordConfirmation: {
            dark: false,
            color: 'black',
            filled: true,
            class: 'full-width'
          },
          completeButton: {
            class: 'full-width',
            size: 'lg',
            color: 'login-green'
          },
          skipLogin: {
            class: 'full-width q-my-md',
            color: 'login-green',
            size: 'lg'
          }
        }
      }
    },
    verification: {
      submitButton: {
        class: 'full-width'
      }
    },
    carclub: {
      button: {
        url: 'https://www.enterprisecarclub.co.uk/gb/en/programs/promotion/bp-mobility-hub.html',
        color: 'primary'
      }
    }
  },
  components: {
    mGmaps: {
      markers: [
        {
          position: {
            lat: 51.497566,
            lng: 0.005855
          },
          icon: 'bp-logo.png'
        }
      ]
    },
    mImageModal: {
      spinnerType: 'arrow',
      spinnerClass: 'loader'
    },
    mMobilityNearbyList: {
      spinnerType: 'arrow',
      spinnerClass: 'loader'
    },
    mMobileAppbar: {
      toolbarTitle: {
        img: {
          height: '44'
        }
      }
    }
  },
  contents: {
    driving: { showInSmartSearch: true },
    bicycle: { showInSmartSearch: true },
    walking: { showInSmartSearch: true },
    'bus': {
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-bus-routes',
      icon: {
        source: 'library',
        name: 'bus'
      },
      color: {
        quasar: 'deep-purple',
        hex: '#673ab7'
      }
    },
    'tram': {
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-tram-routes',
      icon: {
        source: 'library',
        name: 'subway'
      },
      color: {
        quasar: 'indigo',
        hex: '#3f51b5'
      }
    },
    'subway': {
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'subway'
      },
      color: {
        quasar: 'blue-grey',
        hex: '#607d8b'
      }
    },
    'rail': {
      aliases: ['trains', 'train'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-train-depart',
      icon: {
        source: 'library',
        name: 'train'
      },
      color: {
        quasar: 'blue',
        hex: '#2196f3'
      }
    },
    'bikehire': {
      aliases: null,
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'bicycle'
      },
      color: {
        quasar: 'grey-9',
        hex: '#2a2626'
      }
    },
    'carclub': {
      label: 'Car Club',
      aliases: null,
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'cars'
      },
      color: {
        quasar: 'green',
        hex: '#4caf50'
      }
    },
    'rental': {
      label: 'Rental',
      aliases: ['car-hire', 'rentals'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-car-hire-results',
      icon: {
        source: 'library',
        name: 'car'
      },
      color: {
        quasar: 'lime',
        hex: '#cddc39'
      }
    },
    'ridehailing': {
      label: 'Taxi',
      aliases: ['taxi', 'taxis'],
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: true,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'car'
      },
      color: {
        quasar: 'lime',
        hex: '#cddc39'
      }
    },
    'flight': {
      label: 'Airports',
      aliases: ['flights'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-flights-outbound',
      icon: {
        source: 'library',
        name: 'plane'
      },
      color: {
        quasar: 'red',
        hex: '#f44336'
      }
    },
    'hotel': {
      label: 'Hotel',
      aliases: ['accommodation'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-hotel',
      icon: {
        source: 'library',
        name: 'hotel'
      },
      color: {
        quasar: 'lime',
        hex: '#cddc39'
      }
    },
    'parking': {
      label: 'Parking',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: true,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'parking'
      },
      color: {
        quasar: 'blue',
        hex: '#2196f3'
      },
      type: 'parking'
    },
    'petrol': {
      label: 'Petrol',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'gas-pump'
      },
      color: {
        quasar: 'grey',
        hex: '#9e9e9e'
      }
    },
    'ev_station': {
      label: 'EV Station',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'charging-station'
      },
      color: {
        quasar: 'green-7',
        hex: '#43a047'
      }
    },
    'atm': {
      label: 'ATMs',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'money-bill-wave'
      },
      color: {
        quasar: 'green-5',
        hex: '#66bb6a'
      }
    },
    'car-wash': {
      label: 'Car wash',
      aliases: ['car_wash'],
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'car-wash'
      },
      color: {
        quasar: 'teal-7',
        hex: '#00897b'
      }
    },
    'car-repair': {
      label: 'Car repair',
      aliases: ['car_repair'],
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'car-mechanic'
      },
      color: {
        quasar: 'teal-3',
        hex: '#80cbc4'
      }
    },
    'restaurant': {
      label: 'Restaurants',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'utensils-alt'
      },
      color: {
        quasar: 'orange',
        hex: '#ff9800'
      }
    },
    'bar': {
      label: 'Bars',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'cocktail'
      },
      color: {
        quasar: 'purple',
        hex: '#9c27b0'
      }
    },
    'cafe': {
      label: 'Coffee',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'coffee'
      },
      color: {
        quasar: 'brown-8',
        hex: '#5d4037'
      }
    }
  },
  smartSearch: {
    'bikehire': {
      icon: 'directions_bike',
      color: 'deep-orange'
    },
    'walking': {
      icon: 'directions_walk',
      color: 'black'
    },
    'car_hire': {
      icon: 'directions_car',
      color: 'lime-5',
      route: 'ondemand-car-hire-results'
    },
    'rental': {
      icon: 'directions_car',
      color: 'lime-5',
      route: 'ondemand-car-hire-results'
    },
    'driving': {
      icon: 'directions_car',
      color: 'dark',
      route: null
    },
    'train': {
      icon: 'train',
      color: 'blue-8',
      route: 'ondemand-train-depart'
    },
    'rail': {
      icon: 'train',
      color: 'blue-8',
      route: 'ondemand-train-depart'
    },
    'flight': {
      icon: 'flight',
      color: 'red-8',
      route: 'ondemand-flights-outbound'
    },
    'accommodation': {
      icon: 'hotel',
      color: 'green',
      route: 'ondemand-hotel'
    },
    'hotel': {
      icon: 'hotel',
      color: 'green',
      route: 'ondemand-hotel'
    },
    'carclub': {
      icon: 'directions_car',
      color: 'green-8'
    },
    'other': {
      icon: 'directions_car',
      color: 'green-8'
    },
    'parking': {
      icon: 'local_parking',
      color: 'blue',
      route: null
    },
    'bus': {
      icon: 'directions_bus',
      color: 'purple',
      route: 'ondemand-bus-routes'
    },
    'tram': {
      icon: 'tram',
      color: 'indigo-4',
      route: 'ondemand-tram-routes'
    },
    'bicycle': {
      icon: 'directions_bike',
      color: 'grey-9'
    },
    'tube': {
      icon: 'directions_subway',
      color: 'orange',
      route: null
    },
    'ridehailing': {
      icon: 'local_taxi',
      color: 'black',
      route: 'ondemand-ride-hailing-results'
    }
  },
  mobility: [
    {
      icon: {
        resource: 'assets',
        name: 'bp-logo.svg'
      },
      color: {
        icon: 'grey-4',
        marker: 'green'
      },
      type: 'bp',
      label: 'bp hub',
      permission: '',
      pref: 'bp'
    },
    {
      icon: {
        resource: 'library',
        name: 'bus'
      },
      color: {
        icon: 'deep-purple',
        marker: '#673ab7'
      },
      type: 'bus',
      label: 'Bus',
      permission: 'search.bus',
      pref: 'bus'
    },
    {
      icon: {
        resource: 'library',
        name: 'subway'
      },
      color: {
        icon: 'indigo',
        marker: '#3f51b5'
      },
      type: 'tram',
      label: 'Tram',
      permission: 'search.tram',
      pref: 'tram'
    },
    {
      icon: {
        resource: 'library',
        name: 'subway'
      },
      color: {
        icon: 'blue-grey',
        marker: '#607d8b'
      },
      type: 'tube',
      label: 'Tube',
      permission: 'search.tube',
      pref: 'tube'
    }
  ],
  bookables: [
    // Train
    {
      icon: {
        resource: 'library',
        name: 'train'
      },
      color: {
        icon: 'blue',
        marker: '#2196f3'
      },
      type: 'trains',
      label: 'Train',
      permission: 'search.trains',
      pref: 'rail'
    },
    // Bike Hire
    {
      icon: {
        resource: 'library',
        name: 'bicycle'
      },
      color: {
        icon: 'gohi-bike',
        marker: '#ff6319'
      },
      type: 'bikehire',
      label: 'Bike hire',
      permission: 'search.bikehire',
      pref: 'bikehire'
    },
    // Car Club
    {
      icon: {
        resource: 'library',
        name: 'cars'
      },
      color: {
        icon: 'green',
        marker: '#4caf50'
      },
      type: 'carclub',
      label: 'Car Club',
      permission: 'search.carclub',
      pref: 'carclub'
    },
    // Taxi
    {
      icon: {
        resource: 'library',
        name: 'taxi'
      },
      color: {
        icon: 'yellow-12',
        marker: '#fee73b',
        text: 'grey-10'
      },
      type: 'ride-hailing',
      label: 'Taxi',
      permission: 'search.rides',
      pref: 'ridehailing',
      excludeFromDesktop: true
    },
    // Rental
    {
      icon: {
        resource: 'library',
        name: 'car'
      },
      color: {
        icon: 'gohi-car',
        marker: '#73af55'
      },
      type: 'car-hire',
      label: 'Rental',
      permission: 'search.rentals',
      pref: 'rental'
    },
    // Hotel
    {
      icon: {
        resource: 'library',
        name: 'hotel'
      },
      color: {
        icon: 'grey-7',
        marker: '#888'
      },
      type: 'hotel',
      label: 'Hotel',
      permission: 'search.hotel',
      pref: 'hotel',
      excludeFromDesktop: true
    }
  ],
  nearby: [
    {
      icon: {
        resource: 'library',
        name: 'parking'
      },
      color: {
        icon: 'blue',
        marker: '#2196f3'
      },
      type: 'parking',
      label: 'Parking'
    },
    {
      icon: {
        resource: 'library',
        name: 'gas-pump'
      },
      color: {
        icon: 'grey',
        marker: '#9e9e9e'
      },
      type: 'gas_station',
      label: 'Petrol'
    },
    {
      icon: {
        resource: 'library',
        name: 'charging-station'
      },
      color: {
        icon: 'green-7',
        marker: '#43a047'
      },
      type: 'ev_station',
      label: 'EV Station'
    },
    {
      icon: {
        resource: 'library',
        name: 'money-bill-wave'
      },
      color: {
        icon: 'green-5',
        marker: '#66bb6a'
      },
      type: 'atm',
      label: 'ATMs'
    },
    {
      icon: {
        resource: 'library',
        name: 'car-wash'
      },
      color: {
        icon: 'teal-7',
        marker: '#00897b'
      },
      type: 'car_wash',
      label: 'Car wash'
    },
    {
      icon: {
        resource: 'library',
        name: 'car-mechanic'
      },
      color: {
        icon: 'teal-3',
        marker: '#80cbc4'
      },
      type: 'car_repair',
      label: 'Car repair'
    },
    {
      icon: {
        resource: 'library',
        name: 'utensils-alt'
      },
      color: {
        icon: 'orange',
        marker: '#ff9800'
      },
      type: 'restaurant',
      label: 'Restaurants'
    },
    {
      icon: {
        resource: 'library',
        name: 'cocktail'
      },
      color: {
        icon: 'purple',
        marker: '#9c27b0'
      },
      type: 'bar',
      label: 'Bars'
    },
    {
      icon: {
        resource: 'library',
        name: 'coffee'
      },
      color: {
        icon: 'brown-8',
        marker: '#5d4037'
      },
      type: 'cafe',
      label: 'Coffee'
    }
  ]
}
