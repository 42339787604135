<template>
  <q-card
    class="row text-caption relative-position"
  >
    <div
      class="text-white text-uppercase text-center col-12 q-pa-sm"
      :class="{
        'bg-green-6': withinBudget,
        'bg-red-5': !withinBudget
      }"
    >
      {{ $t('mobility_budget.current_month') }}
    </div>
    <div v-if="budget" class="col-5 text-center q-pa-sm">
      <span class="text-bold">{{ $tc('budget.budget') }}</span> <br> {{ budget.text }}
    </div>
    <div v-else class="col-5 text-center q-pa-sm">
      <q-skeleton type="text" />
      <q-skeleton type="text" />
    </div>
    <div class="col-2 text-center q-pa-sm">
      <q-icon
        :name="withinBudget ? 'mdi-check' : 'mdi-close'"
        class="shadow-2"
        size="35px"
        :color="withinBudget ? 'positive' : 'red-8'"
        style="top: 0"
      />
    </div>
    <div v-if="spend" class="col-5 text-center q-pa-sm">
      <span class="text-bold">{{ $t('spend') }}</span> <br> {{ spend.text }}
    </div>
    <div v-else class="col-5 text-center q-pa-sm">
      <q-skeleton type="text" />
      <q-skeleton type="text" />
    </div>
  </q-card>
</template>

<script type="text/javascript">
export default {
  name: 'CurrentMonth',
  props: ['spend', 'budget', 'withinBudget'],
  computed: {
    monthly_budget () {
      return this.budget.value
    },
    current () {
      return this.format(this.amount)
    }
  },
  methods: {
    format (amount = 0) {
      return amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .text-white
    color: white !important
  .q-card
    margin: 16px
  .q-icon
    top 90px
    background-color #fff
    border-radius: 100%
    padding 8px
    z-index 1
  .q-skeleton
    width 50px
    margin auto
</style>
