<template>
  <div />
</template>

<script>
import loadScript from 'utils/loadScript'
export default {
  name: 'MPaypal',
  props: {
    pence: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      default: 'GBP',
      validator (value) {
        return [
          'AUD', 'CAD', 'CHF', 'CZK',
          'DKK', 'EUR', 'GBP', 'HKD',
          'HUF', 'JPY', 'NOK', 'NZD',
          'PLN', 'SEK', 'SGD', 'USD'
        ].includes(value)
      }
    },
    options: {
      type: Object,
      default () {
        return {
          label: 'checkout',
          size: 'responsive',
          shape: 'rect',
          color: 'gold'
        }
      }
    },
    experience: {
      type: Object,
      default () {
        return {
          input_fields: {
            no_shipping: 1
          }
        }
      }
    }
  },
  mounted () {
    loadScript(process.env.VUE_APP_PAYPAL_URL, () => {
      window.paypal.Button.render({
        client: {
          sandbox: process.env.VUE_APP_PAYPAL_SANDBOX,
          production: process.env.VUE_APP_PAYPAL_PRODUCTION
        },
        env: process.env.VUE_APP_PAYPAL_ENV,
        style: this.options,
        payment: this.payment,
        onAuthorize: this.authorized,
        commit: false,
        onCancel: this.cancelled,
        onClick: this.clicked
      }, this.$el)
    })
  },
  methods: {
    payment (data, actions) {
      this.$emit('payment', data, actions)
      return actions.payment.create({
        payment: {
          transactions: [
            {
              amount: { total: (this.pence / 100).toFixed(2), currency: this.currency }
            }
          ]
        }
      })
    },
    authorized (data, actions) {
      this.$emit('authorized', data, actions)
    },
    cancelled () {
      this.$emit('cancelled')
    },
    clicked () {
      this.$emit('click')
    }
  }
}
</script>
