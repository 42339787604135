import { Model } from '@vuex-orm/core'

export default class User extends Model {
  static entity = 'users'

  static primaryKey = 'username'

  static fields () {
    return {
      username: this.string(),
      display_name: this.string(),
      email: this.string(),
      picture: this.string(),
      organisation: this.attr({}),
      monthly_budget: this.attr({}),
      breakdown: this.attr([]),
      spend: this.attr({}),
      journeys: this.attr([]),
      bookings: this.attr([]),
      meta: this.attr({}),
      is_blocked: this.boolean(false),
      has_carclub: this.boolean(false),
      last_seen_at: this.attr({}),
      created_at: this.attr({}),
      updated_at: this.attr({}),
      employee_identifier: this.string(null).nullable(),
      department: this.string(null).nullable(),
      division: this.string(null).nullable(),
      cost_centre: this.string(null).nullable()
    }
  }

  get isInOrganisation () {
    return !!Object.keys(this.organisation).length
  }
}
