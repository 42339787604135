<template>
  <m-select-query
    v-model="user"
    v-bind="$attrs"
    :label="label"
    :query="autocomplete"
    autocomplete="off"
    clearable
  />
</template>

<script>
import { search } from 'api/users'
import { mapGetters } from 'vuex'

export default {
  props: {
    label: String,
    value: Object,
    maxRoleWeight: Number,
    organisation: String,
    role: String,
    error: Boolean
  },
  data () {
    return {
      term: null
    }
  },
  computed: {
    ...mapGetters({ userLookup: 'userLookup' }),
    user: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    clearCurrentSelection () {
      this.$emit('input', null)
    },
    autocomplete (terms) {
      const params = { query: terms }
      if (this.organisation) params.organisation = this.organisation
      return search(params)
        .then(response => {
          // Checks only for users, members and middleoffice, no bookings for backoffice and admins ;)

          var users = response.data

          if (this.role !== 'backoffice') {
            const preventPermissions = ['can.book.for.anyone']
            users = users.filter(u => !u.permissions.some(r => preventPermissions.includes(r)))
          }
          const filtered = users.filter(user => {
            var pass = !!user

            // Set user's role weight to compare against when max role weight is used (will be replaced with permission check eventually)
            user.roleWeight = user.roles.includes('middleoffice')
              ? 3
              : user.roles.includes('member') ? 2 : 1

            // Check if organistation filter prop is set, if user has an organisation, and if the name or slug of the organisation matches the filter
            if (this.organisation && (!user.organisation || (user.organisation && !(user.organisation.slug === this.organisation || user.organisation.name === this.organisation)))) {
              pass = false
            }

            // Check if max role weight filter prop is set, and if the user's role is within boundaries
            if (this.maxRoleWeight && user.roleWeight > this.maxRoleWeight) {
              pass = false
            }

            return pass
          })

          return filtered.map(user => {
            return {
              label: user.display_name,
              value: user.username,
              description: `Organisation: ${user.organisation ? user.organisation.name : 'Individual'}`,
              organisation: user.organisation ? user.organisation.slug : 'Individual',
              has_account: user.organisation ? user.organisation.has_account : false,
              permissions: user.permissions,
              avatar: user.picture,
              roleWeight: user.roleWeight,
              has_carclub: user.has_carclub
            }
          })
        })
    }
  }
}
</script>

<style>

</style>
