import authentication from 'mixins/authentication'
import store from 'store/modules/ride-hailing'
import { Platform } from 'quasar'

const meta = {
  permission: 'search.rides'
}

const beforeEnter = (to, from, next) => {
  !store.state.time ? next({ name: 'ondemand-ride-hailing' }) : next()
}

const form = {
  path: '/on-demand/ride-hailing',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-ridehailing" */ 'pages/ondemand/ride-hailing/layout.vue')
  },
  children: [
    {
      name: 'ondemand-ride-hailing',
      path: '/',
      components: {
        default: () => import(/* webpackChunkName: "ondemand-ridehailing" */ 'pages/ondemand/ride-hailing/search.vue')
      },
      meta: {
        ...meta,
        layout: { components: { appbar: false } },
        label: 'Taxi: Search'
      },
      beforeEnter: (to, from, next) => {
        authentication.methods.hasRole('middleoffice')
          ? Platform.is.desktop ? next({ name: 'ondemand-office-ride-hailing' }) : next()
          : next()
      }
    },
    {
      name: 'ondemand-ride-hailing-results',
      path: 'results',
      components: {
        default: () => import(/* webpackChunkName: "ondemand-ridehailing" */ 'pages/ondemand/ride-hailing/vehicles.vue')
      },
      meta: {
        ...meta,
        layout: { components: { appbar: false } },
        label: 'Taxi: Vehicles'
      },
      beforeEnter
    },
    {
      name: 'ondemand-ride-hailing-vehicle',
      path: 'vehicle',
      components: {
        default: () => import(/* webpackChunkName: "ondemand-ridehailing" */ 'pages/ondemand/ride-hailing/vehicle.vue')
      },
      meta: {
        ...meta,
        label: 'Taxi: Vehicle'
      },
      beforeEnter
    }
  ]
}

const summary = {
  name: 'ondemand-ride-hailing-summary',
  path: '/on-demand/ride-hailing/summary',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-ridehailing" */ 'pages/ondemand/ride-hailing/summary.vue')
  },
  meta: {
    ...meta,
    label: 'Taxi: Summary'
  },
  beforeEnter
}

export default { form, summary }
