import partners from 'mixins/partners'

const getPartnerFromHostname = () => {
  switch (window.location.host) {
  case JSON.parse(process.env.VUE_APP_PARTNER_HOSTNAMES)['Kinto']:
    return partners.kinto
  case JSON.parse(process.env.VUE_APP_PARTNER_HOSTNAMES)['BP']:
    return partners.bp
  case JSON.parse(process.env.VUE_APP_PARTNER_HOSTNAMES)['Go-Hi']:
    return partners.gohi
  case JSON.parse(process.env.VUE_APP_PARTNER_HOSTNAMES)['DerbyGO']:
    return partners.derbygo
  case JSON.parse(process.env.VUE_APP_PARTNER_HOSTNAMES)['Heart']:
    return partners.heart
  }
  return partners.mobilleo
}

export default getPartnerFromHostname
