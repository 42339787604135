<template>
  <q-header v-if="visible" elevated>
    <q-toolbar class="row">
      <div class="col-2">
        <q-btn
          v-if="showBackButton()"
          flat
          round
          dense
          icon="arrow_back"
          @click="goBack"
        />
      </div>
      <q-toolbar-title shrink class="col-8 text-center">
        <img
          v-if="pageName === 'Home'"
          :src="brandingLogo"
          :height="getComponentConfig.toolbarTitle.img.height || '32'"
          class="cursor-pointer"
        >
        <div v-else class="page-name">
          {{ pageName }}
        </div>
      </q-toolbar-title>
      <div class="col-2 text-right">
        <q-btn
          v-if="hasRole(['admin','backoffice']) && switchUrl"
          type="a"
          :href="switchUrl"
          class="btn-switch align-right row justify-end"
          :label="$t('switch')"
        />
        <q-btn
          v-if="canWeToggleRightSidebar"
          flat
          round
          dense
          icon="filter_list"
          @click="$root.$children[0].$refs.layout.$refs.right.show = !$root.$children[0].$refs.layout.$refs.right.show"
        />
      </div>
    </q-toolbar>
  </q-header>
</template>

<script type="text/javascript">
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'

export default {
  mixins: [authentication],
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      organisation: 'organisation',
      logo: 'partner/brandingLogo',
      components: 'partner/components'
    }),
    getComponentConfig () {
      return this.components.mMobileAppbar
    },
    brandingLogo () {
      return (this.organisation && this.organisation.brand && this.organisation.brand.logo) ? this.organisation.brand.logo + `?${new Date().getTime()}` : require(`assets/${this.logo}`)
    },
    switchUrl () {
      return process.env.VUE_APP_SWITCH_URL
        ? `${process.env.VUE_APP_SWITCH_URL}?switchertoken=${this.$store.getters.authentication.token}`
        : null
    },
    canWeToggleRightSidebar () {
      let allowedRoutes = [
        'ondemand-flights-outbound',
        'ondemand-hotel-availability',
        'Journeys'
      ]

      return allowedRoutes.includes(this.$route.name)
    },
    pageName () {
      return this.$route.meta.label
    }
  },
  methods: {
    showBackButton () {
      return ![
        'home',
        'profile',
        'dashboard',
        'Journeys',
        'Discovery',
        'book'
      ].includes(this.$route.name)
    },
    goBack () {
      const back = this?.$route?.meta?.back
      const saveParams = this?.$route?.meta?.saveParams
      if (back && saveParams) return this.$router.push({ name: back, params: this.$route.params.from })
      if (back) return this.$router.push({ name: back })
      return this?.$route?.meta?.label?.includes('Search') ? this.$router.push({ name: 'home' }) : this.$router.go(-1)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .btn-switch
    margin-right: 16px
  .q-toolbar
    min-height: 64px
    transition: background-color 250ms
    will-change: background-color
  .q-input
    max-width 768px
  .page-name
    font-size 90%
</style>
