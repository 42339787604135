import _ from 'lodash'
import Vue from 'vue'
import date from 'utils/date-time'
import { details as lookup } from 'api/location'
import { search, routeInfo, additionalInfo } from 'api/ride-hailing'

const getLookup = place => lookup(place).then(({ data: [{ latitude, longitude }] }) => ({ lat: latitude, lng: longitude }))

const initial = {
  origin: null,
  destination: null,
  time: null,
  numberOfPassengers: 3,
  routeInfo: null,
  routeInfoLoading: false,
  vehicleGroups: null,
  vehicles: null,
  providers: null,
  selectedVehicle: null,
  traveller: null,
  requester: null,
  racDetails: {
    business_area: null,
    cost_centre: null,
    vehicle_type: null,
    vehicle_registration: null
  },
  mainPassenger: {
    firstName: null,
    surname: null,
    mobile: null
  },
  driverNotes: null,
  internalNotes: null,

  lastSearch: null,
  searching: false,
  summary: null,

  journey: {
    name: null,
    reference: null
  },

  filters: {
    passengers: null,
    providers: null
  }
}

const state = _.cloneDeep(initial)

const getters = {
  origin: (state) => state.origin,
  destination: (state) => state.destination,
  originLabel: (state) => state.origin ? state.origin.label : null,
  destinationLabel: (state) => state.destination ? state.destination.label : null,
  time: (state) => state.time,
  formattedTime: (state) => date.toShortDateTime(state.time),
  routeInfo: (state) => state.routeInfo,
  routeInfoLoading: (state) => state.routeInfoLoading,
  vehicleGroups: (state) => state.vehicleGroups,
  vehicles: (state) => state.vehicles,
  providers: (state) => state.providers,
  selectedVehicle: (state) => state.selectedVehicle,
  traveller: state => state.traveller,
  requester: state => state.requester,
  numberOfPassengers: (state) => state.numberOfPassengers,

  searchParams (state) {
    return state.origin && state.destination && {
      origin: state.origin.value, // [state.origin.latlng.lat, state.origin.latlng.lng].join(),
      destination: state.destination.value, // [state.destination.latlng.lat, state.destination.latlng.lng].join(),
      time: date.toCivilDateTime(state.time),
      passengers: parseInt(state.numberOfPassengers),
      user: state.traveller ? state.traveller.value : state.requester.value,
      requester: state.requester.value
    }
  },

  lastSearch: state => state.lastSearch,
  searching: state => state.searching,
  changesMade (state, getters) {
    return (state.origin && state.destination && state.lastSearch) && (state.lastSearch.origin !== getters.searchParams.origin ||
           state.lastSearch.destination !== getters.searchParams.destination ||
           state.lastSearch.time !== getters.searchParams.time)
  },
  summary: state => state.summary,

  mainPassenger: state => state.mainPassenger,
  driverNotes: state => state.driverNotes,
  internalNotes: state => state.internalNotes,
  journey: state => state.journey,
  racDetails: state => state.racDetails,
  messageObject: (state, getters) => {
    return {
      passenger_name: `${state.mainPassenger.firstName} ${state.mainPassenger.surname}`,
      contact_email: state.requester.email,
      contact_number: state.mainPassenger.mobile,
      start_location: getters.originLabel,
      end_location: getters.destinationLabel,
      number_of_passengers: getters.numberOfPassengers,
      ...state.racDetails
    }
  },
  additionalInfo: state => {
    return {
      ...state.racDetails,
      passenger_name: `${state.mainPassenger.firstName} ${state.mainPassenger.surname}`,
      contact_email: state.requester.email,
      contact_number: state.mainPassenger.mobile,
      phone_number: state.mainPassenger.mobile,
      instructions: state.driverNotes,
      internal_notes: state.internalNotes,
      distance_in_miles: state.routeInfo.distance.miles
    }
  },
  bookingDetails: (state, getters) => {
    return {
      ...getters.additionalInfo,
      ...state.racDetails,
      start_location: getters.originLabel,
      end_location: getters.destinationLabel,
      number_of_passengers: getters.numberOfPassengers

    }
  },

  filters: state => state.filters
}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  setValue ({ commit }, { prop, payload }) {
    commit('setValue', { prop, payload })
  },
  setOrigin ({ commit, dispatch }, payload) {
    const location = payload
    if (!location.latlng || (!location.latlng.lat && !location.latlng.lng)) {
      const lookup = location.place_id || location.value

      getLookup(lookup)
        .then(latlng => {
          location.latlng = latlng
        })
        .finally(() => {
          commit('setValue', { prop: 'origin', payload: location })
          dispatch('getRouteInfo')
        })
    } else {
      commit('setValue', { prop: 'origin', payload: location })
      dispatch('getRouteInfo')
    }
  },
  setDestination ({ commit, dispatch }, payload) {
    const location = payload
    if (!location.latlng || (!location.latlng.lat && !location.latlng.lng)) {
      const lookup = location.place_id || location.value

      getLookup(lookup)
        .then(latlng => {
          location.latlng = latlng
        })
        .finally(() => {
          commit('setValue', { prop: 'destination', payload: location })
          dispatch('getRouteInfo')
        })
    } else {
      commit('setValue', { prop: 'destination', payload: location })
      dispatch('getRouteInfo')
    }
  },
  setRequester ({ commit }, payload) {
    commit('setValue', { prop: 'requester', payload })
  },
  setTraveller ({ commit }, payload) {
    commit('setValue', { prop: 'traveller', payload })
  },
  setProviders ({ commit }, payload) {
    commit('setValue', { prop: 'providers', payload })
    commit('setValue', {
      prop: 'filters',
      payload: {
        ...state.filters,
        ...payload
      }
    })
  },
  setMainPassenger ({ commit }, payload) {
    commit('setValue', { prop: 'mainPassenger', payload })
  },
  setJourney ({ commit }, payload) {
    commit('setValue', { prop: 'journey', payload })
  },
  search ({ commit, getters, dispatch }) {
    commit('setValue', { prop: 'searching', payload: true })
    commit('setValue', {
      prop: 'vehicles',
      payload: null
    })
    commit('setValue', {
      prop: 'vehicleGroups',
      payload: null
    })
    dispatch('setProviders', null)
    dispatch('saveLocations')
    return search(_.pickBy(getters.searchParams))
      .then(res => {
        commit('setValue', {
          prop: 'lastSearch',
          payload: getters.searchParams
        })
        commit('setValue', {
          prop: 'filters.passengers',
          payload: getters.lastSearch.passengers
        })
        res.data.forEach(group => {
          group.options.forEach(vehicle => {
            if (!group.cheapestVehiclePrice) group.cheapestVehiclePrice = vehicle.price.amount
            else if (group.cheapestVehiclePrice > vehicle.price.amount) group.cheapestVehiclePrice = vehicle.price.amount
          })
        })
        commit('setValue', {
          prop: 'vehicleGroups',
          payload: res.data
        })
      })
      .catch(e => {
        commit('setValue', {
          prop: 'vehicles',
          payload: []
        })
        return Promise.reject(e)
      })
      .finally(() => {
        commit('setValue', { prop: 'searching', payload: false })
      })
  },
  getRouteInfo ({ commit, getters, rootGetters }) {
    commit('setValue', { prop: 'routeInfoLoading', payload: true })
    commit('setValue', { prop: 'routeInfo', payload: null })
    const origin = getters.origin ? getters.origin.latlng : null
    const destination = getters.destination ? getters.destination.latlng : null
    if (!origin || !destination) {
      commit('setValue', { prop: 'routeInfoLoading', payload: false })
      return
    }

    const tolerance = rootGetters.organisation?.attributes?.content_config?.onwardtravel?.tolerance_in_percent || 10

    return routeInfo([origin, destination], tolerance)
      .then(res => {
        commit('setValue', { prop: 'routeInfo', payload: res.data })
      })
      .catch(res => {
        commit('setValue', { prop: 'routeInfo', payload: res.data })
      })
      .finally(() => {
        commit('setValue', { prop: 'routeInfoLoading', payload: false })
      })
  },
  saveAdditionalInfo ({ commit, getters }) {
    return additionalInfo(getters.selectedVehicle.token, getters.additionalInfo)
      .then(res => {
        commit('setValue', {
          prop: 'summary',
          payload: res.data
        })
      })
  },
  saveLocations ({ getters, dispatch }) {
    dispatch('addresses/saveRecentLocations', [getters.origin, getters.destination], { root: true })
  }
}

const mutations = {
  reset (state) {
    const empty = _.cloneDeep(initial)
    for (let prop in empty) {
      Vue.set(state, prop, empty[prop])
    }
  },
  setValue (state, { prop, payload }) {
    Vue.set(state, prop, payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
