<template>
  <q-field
    ref="field"
    :label="label"
    stack-label
    class="q-pb-none"
    :readonly="readonly"
    :rounded="rounded"
    :outlined="outlined"
    @click.native.stop="$refs.popup.show()"
    @keyup.native.enter="$refs.popup.show()"
  >
    <template v-slot:control>
      <div class="self-center full-width no-outline" tabindex="0">
        {{ displayValue }}
      </div>
    </template>
    <template v-slot:append>
      <q-icon color="primary" :name="useDropdown ? 'arrow_drop_down' : 'event'" class="cursor-pointer">
        <q-popup-proxy ref="popup" anchor="bottom right" self="center middle" @before-show="updatePicker">
          <q-time
            ref="picker"
            v-model="pickerValue"
            :min="min"
            :minute-step="minuteStep"
            mask="YYYY-MM-DD HH:mm"
          >
            <div class="row items-center justify-end q-gutter-sm">
              <q-btn v-close-popup :label="$t('cancel')" color="primary" outline />
              <q-btn v-close-popup :label="$t('confirm.confirm')" color="primary" @click="updateTime" />
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-field>
</template>

<script>
import date from 'utils/date-time'

export default {
  props: {
    label: String,
    value: [Object, Date, String],
    min: [Object, Date, String],
    minuteStep: {
      type: Number,
      default: 5
    },
    useDropdown: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: Boolean,
    rounded: Boolean,
    outlined: Boolean
  },
  data () {
    return ({
      pickerValue: ''
    })
  },
  computed: {
    displayValue () {
      return this.value ? date.toCivilTime(this.value) : null
    },
    showOverlay () {
      return window.innerWidth < 768
    }
  },
  created () {
    this.updatePicker()
  },
  methods: {
    updateTime () {
      this.$emit('input', this.pickerValue)
      this.$emit('change', this.pickerValue)
    },
    updatePicker () {
      if (typeof this.value === 'object') {
        this.pickerValue = date.toCivilDateTime(this.value)
      } else {
        this.pickerValue = this.value
      }
    },
    closePopup () {
      this.$refs.popup.hide()
      this.$refs.field.focus()
    }
  }
}
</script>

<style lang="stylus" scoped>
.popover
  width 600px

.time-field-mobile
  display flex
  display grid

.picker
  margin auto
  width 95%
</style>
