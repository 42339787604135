import Vue from 'vue'
import { Notify } from 'quasar'
import _ from 'lodash'
import { getUserAddresses, saveUserAddresses, deleteAddress } from 'api/address'
import i18n from 'i18n'

const initial = {
  recent: [],
  saved: []
}

const state = initial

const getters = {
  recent: (state) => state.recent,
  saved: state => state.saved
}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  saveRecentLocations ({ commit, getters }, locations) {
    const recent = getters.recent
    let newRecentLocations = [...recent]

    locations.forEach(loc => {
      if (loc.label !== 'Home' && loc.label !== 'Work') {
        newRecentLocations.unshift(loc)
      }
    })
    newRecentLocations = _.uniqBy(newRecentLocations, loc => loc.label)
    newRecentLocations = newRecentLocations.slice(0, 8)
    commit('saveRecentLocations', newRecentLocations)
  },
  async getSavedLocations ({ commit, rootGetters }) {
    try {
      const res = await getUserAddresses(rootGetters.user.username)
      const addresses = res.data
      const filteredAddresses = addresses.filter(address => !!address.address.lat)
      commit('add', { type: 'saved', addresses: filteredAddresses })
    } catch (err) {
      console.log(err)
    }
  },
  async updateAddresses ({ commit, dispatch }, address) {
    await commit('add', { type: 'saved', addresses: address })
    await dispatch('saveLocations')
  },
  async save ({ commit, dispatch, getters, rootGetters, state }, address) {
    try {
      await saveUserAddresses(rootGetters.user.username, address)
      Notify.create({
        type: 'positive',
        message: i18n.t('address_saved', { address: address.label })
      })
      await dispatch('getSavedLocations')
    } catch (err) {
      Notify.create({
        type: 'negative',
        message: i18n.t('error.address_not_saved', { address: address.label })
      })
      console.log(err)
    }
  },
  async deleteAddress ({ rootGetters, dispatch }, label) {
    try {
      await deleteAddress(rootGetters.user.username, label)
      await dispatch('getSavedLocations')
      Notify.create({
        type: 'positive',
        message: i18n.t('address_deleted', { address: label })
      })
    } catch (err) {
      Notify.create({
        type: 'negative',
        message: i18n.t('error.address_not_deleted', { address: label })
      })
      console.log(err)
    }
  }
}

const mutations = {
  saveRecentLocations (state, locs) {
    state.recent = locs
  },
  storeAddresses (state, addresses) {
    state.saved = addresses
  },
  reset (state) {
    state = initial
  },
  add (state, { type, addresses }) {
    Vue.set(state, type, addresses)
  },
  remove (state, index) {
    state.splice(index, 1)
  },
  edit (state, address, index) {
    state[index] = address
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
