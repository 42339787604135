import http from './http'

export function index (params) {
  return http.get('/users', { params })
}

export function get (username, params) {
  return http.get(`/users/${username}`, { params })
}

export function put (username, params) {
  return http.put(`/users/${username}`, params)
}

export function search (params) {
  return http.get('/users/search', { params })
}

export function verify (email) {
  return http.get(`/users/verify/resend/${email}`)
}
