import * as VueGoogleMaps from 'gmap-vue'

export default ({ Vue }) => {
  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_KEY,
      v: '3',
      libraries: 'places,geometry,localContext'
    },
    installComponents: true
  })
}
