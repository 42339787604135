<template>
  <peek-form
    :title="$t('profile_and_contact_information')"
    :footer-title="$t('concierge.some_info_will_be_visible')"
    @save="submit"
  >
    <q-page padding>
      <m-group-title :label="$t('your_profile')" />
      <q-card class="bg-white text-dark">
        <q-card-section>
          <q-input
            v-model="user.first_name"
            :label="$t('name.forename')"
            :error="$v.user.first_name.$error"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            @blur="$v.user.first_name.$touch"
          />
          <q-input
            v-model="user.last_name"
            :label="$t('name.surname')"
            :error="$v.user.last_name.$error"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            @blur="$v.user.last_name.$touch"
          />
        </q-card-section>
      </q-card>
      <m-group-title :label="$t('contact.information')" />
      <q-card class="bg-white text-black q-mt-md">
        <q-card-section>
          <q-input
            v-model="user.email"
            :label="$t('email')"
            :error="$v.user.email.$error"
            :hint="emailFieldHint"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            style="margin-bottom: 16px"
            @blur="$v.user.email.$touch"
          />
          <q-input
            v-model="user.mobile"
            :label="$t('mobile_phone')"
            :error="$v.user.mobile.$error"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            @blur="$v.user.mobile.$touch"
          />
        </q-card-section>
      </q-card>
      <template v-if="canSeeEmployerInformation">
        <m-group-title :label="$t('employer_information')" />
        <q-card>
          <q-card-section>
            <q-input
              v-model="user.employee_identifier"
              :error="$v.user.employee_identifier.$error"
              v-bind="employerFieldAttributes"
              :label="$t('employee_identifier')"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
            />
            <q-input
              v-model="user.department"
              :error="$v.user.department.$error"
              v-bind="employerFieldAttributes"
              :label="$t('department')"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
            />
            <q-input
              v-model="user.division"
              :error="$v.user.division.$error"
              v-bind="employerFieldAttributes"
              :label="$t('division')"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
            />
            <q-input
              v-model="user.cost_centre"
              :error="$v.user.cost_centre.$error"
              v-bind="employerFieldAttributes"
              :label="$t('cost_centre')"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
            />
          </q-card-section>
        </q-card>
      </template>
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import peekForm from 'peeks/peekForm.vue'
import authentication from 'mixins/authentication'

export default {
  components: {
    peekForm
  },
  mixins: [authentication],
  data () {
    return {
      user: {
        email: null,
        username: null,
        meta: {
          first_name: null,
          last_name: null,
          mobile: null
        }
      },
      refUser: null
    }
  },
  computed: {
    ...mapGetters({
      activeUser: 'entities/users/peekUser',
      selects: 'members/getSelect',
      authedUser: 'user',
      partner: 'partner'
    }),
    emailFieldHint () {
      return this.$t('changing_will_affect_all_devices', { partner: this.partner.name })
    },
    isModifyingSelf () {
      return this.user.username === this.authedUser.username
    },
    canEditEmployerInformation () {
      return this.hasPermission('organisation.members.update')
    },
    canSeeEmployerInformation () {
      return this.canEditEmployerInformation || this.refUser?.isInOrganisation
    },
    employerFieldAttributes () {
      return {
        disable: !this.canEditEmployerInformation,
        maxlength: 20,
        counter: this.canEditEmployerInformation
      }
    }
  },
  beforeMount () {
    this.$store.dispatch('entities/users/setPeekUser', {
      username: this.$route.query?.username || this.authedUser.username
    })
      .then((user) => {
        this.refUser = user
        this.user = {
          email: user.email,
          username: user.username,
          employee_identifier: user.employee_identifier,
          department: user.department,
          division: user.division,
          cost_centre: user.cost_centre,
          ...user.attributes || user.meta
        }
      })
  },
  validations: {
    user: {
      email: { required },
      first_name: { required },
      last_name: { required },
      mobile: {},
      employee_identifier: { maxLength: 20 },
      department: { maxLength: 20 },
      division: { maxLength: 20 },
      cost_centre: { maxLength: 20 }
    }
  },
  methods: {
    submit () {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.$store.dispatch('entities/users/updateUser', {
          ...this.user,
          attributes: this.user
        })
      } else {
        this.$q.notify({
          message: this.$t('check_validation'),
          timeout: 1500,
          type: 'error',
          icon: 'error',
          position: 'top-right'
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
</style>
