/**
 * Truthy. If there's an organisation in auth state AND a policy attached AND its disabled, return false, otherwise true
 * @param {string} type
 * @param {object} policy
 * @returns {boolean} True if the organisation has this policy enabled OR its not been set
 */
export function isOrganisationPolicyEnabled (type, policy) {
  if (policy.hasOwnProperty(type)) {
    return policy[type].enabled
  }

  return false
}
