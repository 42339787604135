<template>
  <div>
    <q-dialog
      v-model="dialogOpen"
      class="editor-dialog"
      :ok="$t('add_note')"
      :cancel="$t('cancel')"
      @ok="addNote"
    >
      <q-card style="width: 700px; max-width: 65vw">
        <m-editor
          v-model="newNoteContent"
          style="width: 700px; max-width: 65vw; min-height: 125px"
        />
        <q-card-actions align="right">
          <q-btn v-close-popup :label="$t('cancel')" flat />
          <q-btn v-close-popup :label="$t('add_note')" color="positive" flat @click.stop="addNote" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <span v-if="fab">
      <q-btn
        round
        size="lg"
        color="positive"
        class="fixed"
        icon="add_comment"
        style="right: 30px; bottom: 20px"
        @click="openDialog"
      />
    </span>
    <q-btn
      v-else
      text-color="black"
      color="light"
      class="labelled-btn"
      :label="$t('add_note')"
      style="width:182px;"
      @click="openDialog"
    />
  </div>
</template>

<script type="text/javascript">

export default {
  props: {
    fab: Boolean,
    create: Function
  },
  data () {
    return {
      dialogOpen: false,
      newNoteContent: '',
      notes: [],
      done: false
    }
  },
  methods: {
    openDialog () {
      this.dialogOpen = !this.dialogOpen
    },
    addNote () {
      return this.create(this.newNoteContent)
        .then(() => {
          this.newNoteContent = ''
          this.$emit('create')
        })
        .catch(this.updateError)
    },
    updateError ({ data }) {
      this.$q.notify({ color: 'negative', icon: 'clear', position: 'bottom', message: this.$t('error.updating_notes') })
    }
  }
}
</script>

<style lang="stylus">
.editor-dialog .modal-content
  width 600px
.editor-dialog .modal-body
  padding 0
.editor-dialog .modal-buttons
  padding 15px
.editor-dialog .modal-scroll
  max-height 500px
.ql-toolbar.ql-snow
  border-top 0!important
  border-right 0!important
  border-left 0!important
  padding 1rem 2rem
.editor-dialog .ql-container
  border-right 0!important
  border-left 0!important
.editor-dialog .ql-editor
  color black
  min-height 150px
  max-height 200px
  overflow auto
.editor-wrapper
  min-height 200px
svg
  display block
  margin auto
.labelled-btn
  margin-top 15px
</style>
