import http from './http'

export function getUserAddresses (username) {
  return http.get(`/users/${username}/addresses`)
}

export function saveUserAddresses (username, addresses) {
  return http.post(`/users/${username}/addresses`, addresses)
}

export function search (query) {
  return http.get('/address/search', { params: { query } })
}

export function get (reference, query) {
  return http.get(`/address/search/${reference}`, { params: { query } })
}

export function deleteAddress (username, label) {
  return http.delete(`/users/${username}/addresses/${label}`)
}
