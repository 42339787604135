<template>
  <q-dialog ref="dialog" persistent @hide="onDialogHide" @show="onShow">
    <q-card style="width: 700px; max-width: 80vw; height: 320px" class="relative-position">
      <q-card-section v-if="mode !== 'edit'" class="row items-center">
        <q-input
          v-model="label"
          label="Label"
          class="full-width"
          :rounded="$q.platform.is.mobile"
          :outlined="$q.platform.is.mobile"
        />
      </q-card-section>

      <q-card-section class="row items-center">
        <m-select-query
          v-model="addressSelect"
          :query="searchQuery"
          :label="$tc('address')"
          class="full-width"
          @selected="onSelect"
        />
      </q-card-section>

      <q-card-actions align="right" class="absolute" style="bottom: 0">
        <q-btn flat :label="$t('cancel')" color="primary" @click="onCancelClick" />
        <q-btn flat :label="$t('save.address')" color="primary" :disable="!canSave" @click="onOKClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import dialogueMixin from 'mixins/dialogue'
export default {
  mixins: [dialogueMixin],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    search: {
      type: Function,
      required: true
    },
    value: {
      type: Object,
      default: () => ({})
    },
    getAddress: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      default: 'add'
    }
  },
  data () {
    return ({
      label: '',
      canSave: false,
      address: {
        text: '',
        address: {}
      },
      addressSelect: {}
    })
  },
  methods: {

    onShow () {
      if (this.value) {
        // this.$set(this, 'label', this.value.address.label)
        this.label = this.value.address.label
        this.address = this.value
        this.addressSelect = { label: this.value.text }
        // this.value.address = this.editing.address
      } else if (this.mode === 'add') {
        this.label = ''
        this.address = {}
        this.addressSelect = null
      } else {
        this.label = ''
        this.address = {}
      }
    },
    onOKClick () {
      this.$emit('ok', this.address)
      this.hide()
    },
    async searchQuery (value) {
      const res = await this.search(value)
      return res.data.map(address => ({
        value: address.reference,
        label: address.text
      }))
    },
    async onSelect (value) {
      this.canSave = true
      const res = await this.getAddress(value.value, this.label)
      res.data.address.label = this.label
      this.address = res.data.address
    }
  }
}
</script>

<style lang="stylus">

</style>
