import Dinero from 'dinero.js'
export const taxOptions = [
  {
    label: 'Zero Rate',
    value: 'zero'
  },
  {
    label: 'Standard Rate',
    value: 'standard'
  },
  {
    label: 'Reduced Rate',
    value: 'reduced'
  },
  {
    label: 'Super-reduced Rate',
    value: 'superreduced'
  },
  {
    label: 'Parking Rate',
    value: 'parking'
  }
]

export const formatMoney = val => Dinero({ amount: Number(val), currency: 'GBP' }).toFormat('$0.00')

export const calculateGross = line => {
  const unitPrice = line.unitPrice
  const quantity = line.quantity
  const preGross = Dinero({ amount: unitPrice, currency: 'GBP' }).multiply(quantity)

  let gross
  if (line.unitPriceIncludesTax) {
    gross = preGross
  } else {
    gross = preGross.add(preGross.multiply(vatRates[line.taxRate.value]))
  }
  return gross
}

export const vatRates = {
  zero: 0,
  standard: 0.2,
  reduced: 0.05,
  superreduced: 0,
  parking: 0
}

export default {
  data () {
    return {
      taxOptions,
      vatRates
    }
  },
  methods: {
    formatMoney,
    calculateGross
  }
}
