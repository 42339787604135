export default {
  name: 'Heart',
  slug: 'heart',
  title: 'Heart Mobility',
  email: 'heart@mobilleo.com',
  brandingLogo: 'heart-logo-icon.svg',
  brandingIcon: '',
  token: JSON.parse(process.env.VUE_APP_SWITCH_URLS)['Heart'],
  hex: '#406e8e',
  hasTravelType: false,
  hasReasonFor: false,
  spinnerType: 'arrow',
  appLinks: {
    android:
      'https://play.google.com/store/apps/details?id=com.mobilleo.android.gohi',
    ios: 'https://apps.apple.com/us/app/go-hi/id1567227109'
  },
  termsURL: 'https://mobilleo.s3.eu-west-2.amazonaws.com/terms.pdf',
  privacyURL: 'https://mobilleo.s3.eu-west-2.amazonaws.com/privacy.pdf',
  pages: {
    login: {
      form: {
        class: 'login-form',
        email: {
          dark: false,
          color: 'primary',
          labelColor: 'primary',
          filled: false,
          class: 'login-field'
        },
        password: {
          dark: false,
          color: 'primary',
          labelColor: 'primary',
          filled: false,
          class: 'login-field'
        },
        signIn: {
          class: 'login-button login-white',
          color: 'primary',
          align: 'between',
          iconRight: 'fal fa-angle-right'
        },
        skipLogin: {
          class: 'login-button',
          color: 'primary',
          align: 'between',
          iconRight: 'fal fa-angle-right'
        },
        forgotPassword: {
          class: 'login-button text-primary',
          align: 'between',
          outline: true
        },
        validation: {
          class: 'space row col text-center text-negative strong'
        },
        createButton: {
          class: 'full-width q-my-md',
          color: 'login-green',
          size: 'lg'
        },
        completeButton: {},
        verificationButton: {
          class: 'login-button login-white',
          color: 'primary',
          align: 'center'
        }
      }
    },
    password: {
      email: {
        form: {
          class: 'login-form',
          welcomeMessage: {
            class: 'login-text'
          },
          email: {
            class: 'login-field',
            dark: false,
            color: 'primary',
            filled: false
          },
          resetButton: {
            class: 'login-button login-white',
            color: 'primary',
            text: 'Send password reset email',
            align: 'between',
            iconRight: 'fal fa-angle-right',
            size: 'md'
          },
          backButton: {
            class: 'login-button',
            align: 'between',
            flat: true,
            iconRight: 'fal fa-angle-right'
          }
        }
      },
      reset: {
        form: {
          class: 'login-form',
          welcomeMessage: {},
          email: {
            dark: false,
            color: 'white',
            filled: false
          },
          password: {
            dark: false,
            color: 'white',
            filled: false
          },
          passwordConfirmation: {
            dark: false,
            color: 'white',
            filled: false
          },
          resetButton: {
            class: 'login-button login-white',
            color: 'primary',
            align: 'between',
            flat: false,
            iconRight: 'fal fa-angle-right'
          }
        }
      }
    },
    signup: {
      complete: {
        form: {
          class: 'login-form full-width',
          welcomeMessage: {
            class: 'login-field'
          },
          email: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          organisationName: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          name: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          password: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          passwordConfirmation: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          completeButton: {
            class: 'login-button login-white',
            size: 'md',
            color: 'primary',
            align: 'between',
            iconRight: 'fal fa-angle-right'
          },
          skipLogin: {
            class: 'login-button',
            color: 'primary',
            size: 'md',
            align: 'between',
            iconRight: 'fal fa-angle-right'
          }
        }
      }
    },
    verification: {
      submitButton: {
        class: 'login-button'
      }
    },
    carclub: {
      button: {
        url:
          'https://www.enterprisecarclub.co.uk/gb/en/applicant/enrollment.html/contractName=GOHI/brand=enterprisecarclub/msg=Scotland/apptype=ind/ratePlanId=46/ratePlanName=Standard/promo=HITRANS#/home.html',
        color: 'primary'
      }
    }
  },
  components: {
    mGmaps: {
      markers: []
    },
    mImageModal: {
      spinnerType: 'arrow',
      spinnerClass: 'loader'
    },
    mMobilityNearbyList: {
      spinnerType: 'arrow',
      spinnerClass: 'loader'
    },
    mMobileAppbar: {
      toolbarTitle: {
        img: {}
      }
    }
  },
  contents: {
    driving: { showInSmartSearch: true },
    bicycle: { showInSmartSearch: true },
    walking: { showInSmartSearch: true },
    srf: { showInSmartSearch: true },
    bus: {
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-bus-routes',
      icon: {
        source: 'library',
        name: 'bus'
      },
      color: {
        quasar: 'deep-purple',
        hex: '#7d5cc6'
      }
    },
    rail: {
      aliases: ['trains', 'train'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-train-depart',
      icon: {
        source: 'library',
        name: 'train'
      },
      color: {
        quasar: 'blue',
        hex: '#4060af'
      }
    },
    bikehire: {
      aliases: null,
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'bicycle'
      },
      color: {
        quasar: 'grey-9',
        hex: '#ff6319'
      }
    },
    carclub: {
      label: 'Car Club',
      aliases: null,
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'cars'
      },
      color: {
        quasar: 'green',
        hex: '#4caf50'
      }
    },
    'rental': {
      label: 'Rental',
      aliases: ['car-hire', 'rentals'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-car-hire-results',
      icon: {
        source: 'library',
        name: 'car'
      },
      color: {
        quasar: 'lime',
        hex: '#73af55'
      }
    },
    ridehailing: {
      label: 'Taxi',
      aliases: ['taxi', 'taxis'],
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: true,
      showInNearby: false,
      route: null,
      icon: {
        source: 'library',
        name: 'car'
      },
      color: {
        quasar: 'lime',
        hex: '#cddc39'
      }
    },
    flight: {
      label: 'Airports',
      aliases: ['flights'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-flights-outbound',
      icon: {
        source: 'library',
        name: 'plane'
      },
      color: {
        quasar: 'red',
        hex: '#f9461c'
      }
    },
    hotel: {
      label: 'Hotel',
      aliases: ['accommodation'],
      showInBookNow: true,
      showInMobilityNearby: true,
      showInSmartSearch: true,
      showInNearby: false,
      route: 'ondemand-hotel',
      icon: {
        source: 'library',
        name: 'hotel'
      },
      color: {
        quasar: 'lime',
        hex: '#cddc39'
      }
    },
    parking: {
      label: 'Parking',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: true,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'parking'
      },
      color: {
        quasar: 'blue',
        hex: '#2196f3'
      },
      type: 'parking'
    },
    petrol: {
      label: 'Petrol',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'gas-pump'
      },
      color: {
        quasar: 'grey',
        hex: '#9e9e9e'
      }
    },
    ev_station: {
      label: 'EV Station',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'charging-station'
      },
      color: {
        quasar: 'green-7',
        hex: '#43a047'
      }
    },
    atm: {
      label: 'ATMs',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'money-bill-wave'
      },
      color: {
        quasar: 'green-5',
        hex: '#66bb6a'
      }
    },
    'car-wash': {
      label: 'Car wash',
      aliases: ['car_wash'],
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'car-wash'
      },
      color: {
        quasar: 'teal-7',
        hex: '#00897b'
      }
    },
    'car-repair': {
      label: 'Car repair',
      aliases: ['car_repair'],
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'car-mechanic'
      },
      color: {
        quasar: 'teal-3',
        hex: '#80cbc4'
      }
    },
    restaurant: {
      label: 'Restaurants',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'utensils-alt'
      },
      color: {
        quasar: 'orange',
        hex: '#ff9800'
      }
    },
    bar: {
      label: 'Bars',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'cocktail'
      },
      color: {
        quasar: 'purple',
        hex: '#9c27b0'
      }
    },
    cafe: {
      label: 'Coffee',
      aliases: null,
      showInBookNow: false,
      showInMobilityNearby: false,
      showInSmartSearch: false,
      showInNearby: true,
      route: null,
      icon: {
        source: 'library',
        name: 'coffee'
      },
      color: {
        quasar: 'brown-8',
        hex: '#5d4037'
      }
    }
  },
  smartSearch: {
    bikehire: {
      icon: 'directions_bike',
      color: 'deep-orange'
    },
    walking: {
      icon: 'directions_walk',
      color: 'black'
    },
    car_hire: {
      icon: 'directions_car',
      color: 'lime-5',
      route: 'ondemand-car-hire-results'
    },
    rental: {
      icon: 'directions_car',
      color: 'lime-5',
      route: 'ondemand-car-hire-results'
    },
    driving: {
      icon: 'directions_car',
      color: 'dark',
      route: null
    },
    train: {
      icon: 'train',
      color: 'blue-8',
      route: 'ondemand-train-depart'
    },
    rail: {
      icon: 'train',
      color: 'blue-8',
      route: 'ondemand-train-depart'
    },
    flight: {
      icon: 'flight',
      color: 'red-8',
      route: 'ondemand-flights-outbound'
    },
    accommodation: {
      icon: 'hotel',
      color: 'green',
      route: 'ondemand-hotel'
    },
    hotel: {
      icon: 'hotel',
      color: 'green',
      route: 'ondemand-hotel'
    },
    carclub: {
      icon: 'directions_car',
      color: 'green-8'
    },
    other: {
      icon: 'directions_car',
      color: 'green-8'
    },
    parking: {
      icon: 'local_parking',
      color: 'blue',
      route: null
    },
    bus: {
      icon: 'directions_bus',
      color: 'purple',
      route: 'ondemand-bus-routes'
    },
    bicycle: {
      icon: 'directions_bike',
      color: 'grey-9'
    },
    ridehailing: {
      icon: 'local_taxi',
      color: 'black',
      route: 'ondemand-ride-hailing-results'
    },
    srf: {
      icon: 'flight',
      color: 'grey-8',
      route: 'ondemand-srf-results'
    }
  },
  mobility: [],
  bookables: [
    {
      // Bus
      icon: {
        resource: 'library',
        name: 'bus'
      },
      color: {
        icon: 'gohi-bus',
        marker: '#7d5cc6'
      },
      type: 'bus',
      label: 'Bus',
      permission: 'search.bus',
      pref: 'bus'
    },
    {
      // HEART
      icon: {
        resource: 'library',
        name: 'plane'
      },
      color: {
        icon: 'gohi-bus',
        marker: '#eb79fc'
      },
      type: 'srf',
      label: 'SRF',
      permission: 'search.srf',
      pref: 'srf'
    },
    {
      // Ferry
      icon: {
        resource: 'library',
        name: 'ship'
      },
      color: {
        icon: 'gohi-ferry',
        marker: '#51626f'
      },
      type: 'ferry',
      label: 'Ferry',
      permission: 'search.ferry',
      pref: 'ferry'
    },
    {
      // Train
      icon: {
        resource: 'library',
        name: 'train'
      },
      color: {
        icon: 'gohi-train',
        marker: '#4060af'
      },
      type: 'trains',
      label: 'Train',
      permission: 'search.trains',
      pref: 'rail'
    },
    {
      // Flights
      icon: {
        resource: 'library',
        name: 'plane'
      },
      color: {
        icon: 'gohi-plane',
        marker: '#f9461c'
      },
      type: 'flights',
      label: 'Flights',
      permission: 'search.flights',
      pref: 'flight'
    },
    {
      // Rental
      icon: {
        resource: 'library',
        name: 'car'
      },
      color: {
        icon: 'gohi-car',
        marker: '#73af55'
      },
      type: 'car-hire',
      label: 'Rental',
      permission: 'search.rentals',
      pref: 'rental'
    },
    {
      // Car Club
      icon: {
        resource: 'library',
        name: 'cars'
      },
      color: {
        icon: 'green',
        marker: '#4caf50'
      },
      type: 'carclub',
      label: 'Car Club',
      permission: 'search.carclub',
      pref: 'carclub'
    },
    {
      // Bike Hire
      icon: {
        resource: 'library',
        name: 'bicycle'
      },
      color: {
        icon: 'gohi-bike',
        marker: '#ff6319'
      },
      type: 'bikehire',
      label: 'Bike hire',
      permission: 'search.bikehire',
      pref: 'bikehire'
    },
    {
      // Taxi
      icon: {
        resource: 'library',
        name: 'taxi'
      },
      color: {
        icon: 'yellow-12',
        marker: '#fee73b',
        text: 'grey-10'
      },
      type: 'ride-hailing',
      label: 'Taxi',
      permission: 'search.rides',
      pref: 'ridehailing',
      excludeFromDesktop: true
    },
    {
      // Hotel
      icon: {
        resource: 'library',
        name: 'hotel'
      },
      color: {
        icon: 'grey-7',
        marker: '#888'
      },
      type: 'hotel',
      label: 'Hotel',
      permission: 'search.hotel',
      pref: 'hotel',
      excludeFromDesktop: true
    }
  ],
  nearby: [
    {
      icon: {
        resource: 'library',
        name: 'parking'
      },
      color: {
        icon: 'blue',
        marker: '#2196f3'
      },
      type: 'parking',
      label: 'Parking'
    },
    {
      icon: {
        resource: 'library',
        name: 'gas-pump'
      },
      color: {
        icon: 'grey',
        marker: '#9e9e9e'
      },
      type: 'gas_station',
      label: 'Petrol'
    },
    {
      icon: {
        resource: 'library',
        name: 'charging-station'
      },
      color: {
        icon: 'green-7',
        marker: '#43a047'
      },
      type: 'ev_station',
      label: 'EV Station'
    },
    {
      icon: {
        resource: 'library',
        name: 'money-bill-wave'
      },
      color: {
        icon: 'green-5',
        marker: '#66bb6a'
      },
      type: 'atm',
      label: 'ATMs'
    },
    {
      icon: {
        resource: 'library',
        name: 'car-wash'
      },
      color: {
        icon: 'teal-7',
        marker: '#00897b'
      },
      type: 'car_wash',
      label: 'Car wash'
    },
    {
      icon: {
        resource: 'library',
        name: 'car-mechanic'
      },
      color: {
        icon: 'teal-3',
        marker: '#80cbc4'
      },
      type: 'car_repair',
      label: 'Car repair'
    },
    {
      icon: {
        resource: 'library',
        name: 'utensils-alt'
      },
      color: {
        icon: 'orange',
        marker: '#ff9800'
      },
      type: 'restaurant',
      label: 'Restaurants'
    },
    {
      icon: {
        resource: 'library',
        name: 'cocktail'
      },
      color: {
        icon: 'purple',
        marker: '#9c27b0'
      },
      type: 'bar',
      label: 'Bars'
    },
    {
      icon: {
        resource: 'library',
        name: 'coffee'
      },
      color: {
        icon: 'brown-8',
        marker: '#5d4037'
      },
      type: 'cafe',
      label: 'Coffee'
    }
  ]
}
