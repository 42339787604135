<template>
  <q-card
    :class="`text-${color}`"
    class="bg-white booking-card"
  >
    <q-item>
      <q-item-section avatar>
        <m-icons-type
          :size="size"
          :inverted="inverted"
          :rounded="rounded"
          :type="type"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label label class="text-left text-faded text-capitalize ellipsis-2-lines" v-text="label" />
      </q-item-section>
      <q-item-section side>
        <q-item-label label class="text-right text-faded text-capitalize text-bold" v-text="detail" />
      </q-item-section>
    </q-item>
  </q-card>
</template>

<script type="text/javascript">
import travelContents from 'mixins/travelContents'
export default {
  name: 'Dashboard',
  mixins: [travelContents],
  props: {
    label: String,
    detail: [String, Number],
    type: String,
    size: {
      type: String,
      default: '48px'
    },
    inverted: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    color () {
      return this.getTravelContentColor(this.type)
    }
  }
}
</script>

<style lang="stylus" scoped>
.label
  text-transform capitalize
.booking-card
  border-right 5px solid currentColor
  padding-bottom 8px
.booking-card > *
  display: flex;
  align-items: center;
  justify-content: flex-start;
</style>
