<template>
  <q-card id="invoice-card" class="no-shadow item-card" @click="downloadPdf">
    <q-item class="cursor-pointer">
      <context-menu
        :item="item"
        context-menu
        touch-position
        @addPo="n => $emit('addPo', n)"
      />
      <q-item-section :avatar="minify">
        <q-chip class="bg-transparent text-capitalize">
          <m-icons-avatar
            :icon="avatar.icon"
            :color="avatar.color"
            :inverted="true"
          />
          <q-tooltip v-if="minify">
            {{ avatar.title }}
          </q-tooltip>
          {{ minify ? '' : avatar.title }}
        </q-chip>
      </q-item-section>
      <q-item-section>
        <q-item-label>{{ item.number }}</q-item-label>
      </q-item-section>
      <q-item-section>
        <q-item-label>{{ item.raised_at.date | date }}</q-item-label>
      </q-item-section>
      <q-item-section>
        <q-item-label v-if="item.user">
          {{ item.user.display_name }}
        </q-item-label>
        <q-item-label v-else caption>
          {{ item.type.includes('purchase') ? $t('abbreviation.not_applicable') : $t('error.unable_to.find_traveller') }}
        </q-item-label>
      </q-item-section>
      <q-item-section v-if="hasPermission('can.book.for.anyone') || hasPermission('can.create.creditinvoices')">
        <q-item-label v-if="item.organisation">
          {{ item.organisation.name }}
        </q-item-label>
        <q-item-label v-else caption>
          {{ item.type.includes('purchase') ? $t('abbreviation.not_applicable') : $t('individual') }}
        </q-item-label>
      </q-item-section>
      <q-item-section>
        <q-item-label v-if="item.po_number" class="ellipsis">
          {{ item.po_number }}
        </q-item-label>
        <q-item-label v-else caption>
          -
        </q-item-label>
      </q-item-section>
      <q-item-section>
        <q-item-label>
          {{ item.gross_amount.text }}
        </q-item-label>
      </q-item-section>
      <q-btn icon="more_vert" text-color="primary" class="invBtn absolute-right" unelevated @click.stop>
        <context-menu :item="item" @addPo="n => $emit('addPo', n)" />
      </q-btn>
    </q-item>
  </q-card>
</template>
<script>
import date from 'utils/date-time'
import authentication from 'mixins/authentication'
import contextMenu from './context-menu'
import { getPdfLink } from 'api/invoices'

export default {
  components: {
    contextMenu
  },
  filters: {
    date (data) {
      return data ? date.toShortDate(data) : null
    }
  },
  mixins: [authentication],
  props: ['value', 'item', 'index'],
  data () {
    return {
      cardWidth: null
    }
  },
  computed: {
    avatar () {
      var output = {
        title: this.$t('unknown'),
        icon: 'help_outline',
        color: 'grey'
      }
      const type = this.item.type
      if (type.includes('credit')) {
        output = {
          ...output,
          title: type.includes('sales') ? this.$t('sales_credit') : this.$t('purchase_credit'),
          color: 'orange-7',
          icon: 'mdi-coins'
        }
      } else if (type === 'sales') {
        output = {
          title: this.$t('sales'),
          icon: 'mdi-currency-usd',
          color: 'green-7'
        }
      } else if (type === 'purchase') {
        output = {
          title: this.$t('purchase'),
          icon: 'mdi-cart-outline',
          color: 'blue-7'
        }
      }

      return output
    },
    minify () {
      return this.cardWidth ? this.cardWidth < 755 : false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.cardWidth = document.getElementById('invoice-card') ? document.getElementById('invoice-card').offsetWidth : null
    })
  },
  methods: {
    downloadPdf () {
      var invoiceTab = window.open('', '_blank')
      getPdfLink(this.item.number)
        .then(res => {
          invoiceTab.location.href = res.data.link
        })
    }
  }
}
</script>
<style lang="stylus" scoped>
.q-item
  padding 8px 16px!important
.card-container
  height 62px
  overflow hidden
.q-card
  height 52px
  outline 0.5px solid #e9e9e9
  display inline-block
  width calc(100% - 15px)
.col
  vertical-align bottom

.item-card
  background white
  font-size 15px
  line-height 18px
.hover .item-card
  background #e1e1e1
.invBtn
  margin auto
</style>
