import { cloneDeep } from 'lodash'
import getPartnerFromHostname from 'utils/partner'

const partner = getPartnerFromHostname()

const initial = {
  label: partner.name,
  consumerToken: partner.token,
  hasTravelType: false,
  hasReasonFor: true,
  ...partner
}

const state = cloneDeep(initial)
const getters = {
  consumerToken: state => state.consumerToken,
  label: state => state.label,
  email: state => state.email,
  brandingLogo: state => state.brandingLogo,
  pages: state => state.pages,
  components: state => state.components,
  mobility: state => state.mobility,
  bookables: state => state.bookables,
  nearby: state => state.nearby,
  hasTravelType: state => state.hasTravelType,
  hasReasonFor: state => state.hasReasonFor
}

const actions = {
  switchEnvironment ({ commit }, { token, environment }) {
    commit('SWITCH_ENV', { token, environment })
  }
}

const mutations = {
  SWITCH_ENV (state, payload) {
    state.consumerToken = payload.token
    state.label = payload.environment
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
