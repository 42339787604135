<template>
  <peek-form
    :title="$t('organisation.settings')"
    :footer="false"
  >
    <q-page padding>
      <m-group-title :label="$t('organisation.profile_details')" />
      <q-card class="bg-white text-dark">
        <q-list separator>
          <q-item>
            <q-item-section>
              <q-item-label label>
                {{ $t('profile_and_contact_information') }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('organisation.information') }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn :to="{hash: '/organisation/profile'}" flat color="primary">
                {{ $t('edit') }}
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>

      <template>
        <m-group-title :label="$t('personalisation')" />
        <q-card class="bg-white text-dark">
          <q-list separator>
            <q-item v-if="hasPermission('can.apply.organisation.brand')">
              <q-item-section>
                <q-item-label label>
                  {{ $t('branding') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('tooltip.customise_organisation') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn :to="{hash: '/organisation/branding'}" flat color="primary">
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item v-if="hasPermission('can.manage.labels')">
              <q-item-section>
                <q-item-label label>
                  {{ $tc('label.label', 2) }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('tooltip.create_labels') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn :to="{hash: '/organisation/labels'}" flat color="primary">
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
      </template>
    </q-page>
  </peek-form>
</template>
<script>
import peekForm from 'peeks/peekForm.vue'
import authentication from 'mixins/authentication'
export default {
  components: {
    peekForm
  },
  mixins: [authentication]
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
  .q-item
    padding 16px
</style>
