<template>
  <div v-if="!loading" class="bg-white q-px-sm row">
    <div class="col-6 offset-1">
      <h6>
        {{ item.destination }}
      </h6>
      <strong>{{ item.operator }}</strong> {{ item.info }}
    </div>
    <div class="col-4 text-right right-box">
      <div class="time-text">
        <svg height="15" width="15" style="margin:3px 2px 0 0">
          <g fill="none" stroke="grey">
            <path stroke-width="2" d="M5 3 Q0 8 5 14" class="curve-one" />
            <path stroke-width="2" d="M8 6 Q6 8 8 12" class="curve-two" />
            <path stroke-width="2" d="M10 9 Q12 8 10 10" fill="grey" class="curve-three" />
          </g>
        </svg>
        {{ item.time }}
      </div>
      <span :style="{color: onTime(item).color}">{{ onTime(item).text }}</span>
    </div>
    <q-separator class="q-my-sm full-width" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MTimetableCard',
  props: ['item'],
  computed: {
    ...mapGetters({
      loading: 'discovery/loading'
    })
  },
  methods: {
    trimDate (dateString) {
      return dateString.slice(11, 16)
    },
    onTime (item) {
      const { delay_info, delay, cancelled } = item
      if (cancelled) return { text: delay_info, color: '#D81c1C' }
      if (delay) return { text: delay_info, color: '#FF8400' }
      return { text: this.$t('on_time'), color: '#86C542' }
    }
  }
}
</script>

<style lang="stylus" scoped>
.right-box
  display flex
  flex-direction column
  justify-content flex-end
.time-text
  padding-bottom 5px
  display flex
  justify-content flex-end
.curve-one
  animation-name fade-in
  animation-duration 2.5s
  animation-iteration-count infinite
  animation-delay 1s
  opacity 0
.curve-two
  animation-name fade-in
  animation-duration 2.5s
  animation-iteration-count infinite
  animation-delay 0.5s
  opacity 0
.curve-three
  animation-name fade-in
  animation-duration 2.5s
  animation-iteration-count infinite
  opacity 0

@keyframes fade-in {
  from {opacity: 0}
  to {opacity: 1}
}
</style>
