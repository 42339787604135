const initial = {
  markers: [
  ],
  center: {
    lat: 53.836457,
    lng: -1.782097
  },
  zoom: 13,
  options: {
    gestureHandling: 'greedy',
    draggable: true,
    clickableIcons: false,
    disableDefaultUI: true,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }
    ]
  }
}

// Copy inital state over to the state var
const state = {
  ...initial
}

const getters = {
  getOptions: state => state.options,
  getCenterAsJson: state => state.center,
  getCenterAsUrlValue: state => `${state.center.lat},${state.center.lng}`,
  getZoom: state => state.zoom,
  getMarkers: state => state.markers
}

const actions = {
  setCenter ({ commit }, { lat, lng }) {
    commit('center', { lat, lng })
  },
  setZoom ({ commit }, { level }) {
    commit('zoom', level)
  },
  setOption ({ commit }, { option, value }) {
    commit('option', { option, value })
  },
  setMarkers ({ commit }, markers) {
    commit('markers', markers)
  },
  addMarkers ({ commit }, markers) {
    commit('updateMarkers', markers)
  },
  resetMarkers ({ commit }) {
    commit('resetMarkers')
  }
}

const mutations = {
  center (state, { lat, lng }) {
    state.center = { lat, lng }
  },
  zoom (state, level) {
    state.zoom = level
  },
  option (state, { option, value }) {
    state.options[option] = value
  },
  markers (state, markers) {
    state.markers = markers
  },
  updateMarkers (state, markers) {
    state.markers.push(markers)
  },
  resetMarkers (state) {
    state.markers = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
