<template>
  <q-select
    v-model="cost_centre"
    :options="availableCostCentres"
    :label="$t('rac.cost_centre')"
    :error="error"
    :error-message="errorMessage"
    v-bind="$attrs"
  />
</template>

<script>
import { cost_centres } from 'api/onwardtravel.js'
export default {
  name: 'MCostCentre',
  props: {
    value: String,
    slug: String,
    error: Boolean,
    errorMessage: String
  },
  data () {
    return {
      availableCostCentres: []
    }
  },
  computed: {
    cost_centre: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  created () {
    const org = this.$store.getters.authentication.organisation
    if (org && org.cost_centres) {
      this.availableCostCentres.concat(org.cost_centres)
    }

    cost_centres(this.slug || (org ? org.slug : null)).then(res => {
      res.data[0].forEach(c => {
        if (!this.availableCostCentres.includes(c)) this.availableCostCentres.push(c)
      })
    })
  }
}
</script>
