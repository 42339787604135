import _ from 'lodash'
import { details } from 'api/location'
import date from 'utils/date-time'

const getDetails = async (location) => {
  let lookup
  if (location.latLng) lookup = `${location.latLng.lat()},${location.latLng.lng()}`
  else if (location.value) lookup = location.value
  const { data: [result] } = await details(lookup)
  if (!result.label) result.label = result.addressLines.postcode
  return result
}

const initial = {
  origin: null,
  destination: null,
  selectionMode: 'origin',
  originField: {},
  destinationField: {},
  time: null,
  adults: 1,
  children: 0,
  wheelchairs: 0,
  concession: 0
}

const state = _.cloneDeep(initial)

const getters = {
  origin: state => state.origin,
  destination: state => state.destination,
  selectionMode: state => state.selectionMode,
  originField: state => state.originField,
  destinationField: state => state.destinationField,
  time: state => state.time,
  adults: state => state.adults,
  children: state => state.children,
  wheelchairs: state => state.wheelchairs,
  concession: state => state.concession,
  params: state => {
    return {
      origin: `${state.origin.lat},${state.origin.lng}`,
      destination: `${state.destination.lat},${state.destination.lng}`,
      pickup_time: date.toCivilDateTime(state.time),
      adults: state.adults,
      children: state.children,
      total_passengers: state.adults + state.children,
      wheelchair_users: state.wheelchairs,
      free_bus_pass: state.concession
    }
  }
}

const actions = {
  async setOrigin ({ commit }, origin) {
    const originData = await getDetails(origin)

    commit('origin', { lat: originData.latitude, lng: originData.longitude })
    commit('originField', originData)
  },
  async setDestination ({ commit }, destination) {
    const destinationData = await getDetails(destination)

    commit('destination', { lat: destinationData.latitude, lng: destinationData.longitude })
    commit('destinationField', destinationData)
  },
  setSelectionMode ({ commit }, mode) {
    commit('selectionMode', mode)
  },
  setTime ({ commit }, time) {
    commit('time', time)
  },
  setPassengers ({ commit }, { type, num }) {
    commit('passengers', { type, num })
  },
  reset ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  origin (state, latLng) {
    state.origin = latLng
  },
  destination (state, latLng) {
    state.destination = latLng
  },
  selectionMode (state, mode) {
    state.selectionMode = mode
  },
  originField (state, origin) {
    const { town, street, postcode } = origin.addressLines
    if (origin.label === 'Unnamed Road') {
      if (town && postcode) origin.label = `${town}, ${postcode}`
      else if (postcode) origin.label = `${street}, ${postcode}`
    }
    state.originField = origin
  },
  destinationField (state, destination) {
    const { town, street, postcode } = destination.addressLines
    if (destination.label === 'Unnamed Road') {
      if (town && postcode) destination.label = `${town}, ${postcode}`
      else if (postcode) destination.label = `${street}, ${postcode}`
    }
    state.destinationField = destination
  },
  time (state, time) {
    state.time = time
  },
  passengers (state, { type, num }) {
    state[type] = num
  },
  clear (state) {
    for (const key in state) {
      state[key] = _.cloneDeep(initial[key])
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
