<template>
  <div>
    <q-input
      id="search"
      v-model="term"
      dark
      dense
      standout
      :class="showSheet ? 'active' : 'inactive'"
      class="search"
      :placeholder="$t('search')"
      :stack-label="false"
      color="bg-primary"
      @input="search(term)"
      @focus="showSheet = true"
      @click.stop
    >
      <template v-slot:prepend>
        <q-icon name="search" />
      </template>
    </q-input>
    <MSearchResultList
      :show-sheet="showSheet"
      :close="close"
      :has-typed="hasTyped"
      :users="users"
      :bookings="bookings"
      :journeys="journeys"
      :requests="requests"
      :organisations="organisations"
      :searching="searching"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { text } from 'api/search'
export default {
  data () {
    return {
      term: '',
      searching: false,
      showSheet: false,
      results: {
        users: [],
        bookings: [],
        requests: [],
        organisations: [],
        journeys: []
      }
    }
  },
  computed: {
    users () {
      return this.results.users ? this.results.users.map(user => ({
        title: user.display_name,
        subTitle: user.organisation,
        email: user.email,
        icon: user.picture,
        user: user // Only here to work with amend/booking/cancellation code
      })) : []
    },
    bookings () {
      return this.results.bookings ? this.results.bookings.map(booking => ({
        title: booking.name,
        subTitle: booking.reference,
        icon: booking.type,
        reference: booking.reference
      })) : []
    },
    journeys () {
      return this.results.journeys ? this.results.journeys.map(journey => ({
        title: journey.name,
        subTitle: journey.reference,
        icon: journey.type,
        reference: journey.reference
      })) : []
    },
    requests () {
      return this.results.requests ? this.results.requests.map(request => ({
        title: request.reference,
        subTitle: request.type,
        icon: request.type,
        reference: request.reference
      })) : []
    },
    organisations () {
      return this.results.organisations ? this.results.organisations.map(organisation => ({
        title: organisation.name,
        subTitle: organisation.slug,
        icon: organisation.type,
        reference: organisation.slug
      })) : []
    },
    hasTyped () {
      return this.term.length > 2
    }
  },
  watch: {
    showSheet: {
      handler (val) {
        if (val) {
          document.body.classList.add('search-open')
        } else {
          document.body.classList.remove('search-open')
        }
      }
    }
  },
  methods: {
    search: _.debounce(function () {
      if (this.hasTyped) {
        this.searching = true
        this.results = {}
        text({ query: this.term }).then(res => {
          this.searching = false
          this.results = {
            ...res.data
          }
        })
      } else {
        this.results = {}
      }
    }, 350),
    close () {
      this.showSheet = false
    }
  }
}
</script>

<style lang="stylus" scoped>
@keyframes fade-in-bg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.search {
  background: alpha(white, 0.125);
  width: 40%;
  transition: width 0.3s;
  will-change: width;
  float: right;
  position: relative;
  z-index: 20;
  margin-top: 7px;

  &.active {
    width: calc(100vw - 20px);
  }

  &.inactive ::placeholder {
    color: #FFFFFF;
  }
}

@media (min-width: 499px) {
  .search {
    width: 250px;
  }
}

@media (min-width: 1200px) {
  .search {
    max-width: 660px;
  }
}
</style>

<style lang="stylus">
body.search-open {
  overflow: hidden;
}
</style>
