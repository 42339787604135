import { hasPermission, hasRole } from 'utils/authentication'

const beforeEnter = (to, from, next) => {
  (!hasPermission('can.book.for.anyone') && hasRole('guest')) ? next('guest-mode') : next()
}

export const index = {
  name: 'Journeys',
  path: '/journeys',
  meta: {
    label: 'Journeys'
  },
  components: {
    default: () => import(/* webpackChunkName: "journeys" */ 'pages/journeys/index.vue'),
    right: () => import(/* webpackChunkName: "journeys" */ 'pages/journeys/user-journeys/sidebar.vue')
  },
  beforeEnter
}

export const show = {
  name: 'journey-show',
  path: '/journeys/:reference',
  redirect: '/journeys/:reference/timeline',
  beforeEnter,
  components: {
    default: () => import(/* webpackChunkName: "journeys" */ 'pages/journeys/show/index.vue')
  },
  meta: {
    label: 'Your Journey'
  },
  children: [
    {
      path: 'timeline',
      components: {
        default: () => import(/* webpackChunkName: "journeys" */ 'pages/journeys/show/timeline/index.vue')
      }
    },
    {
      path: 'discovery/:type',
      components: {
        default: () => import(/* webpackChunkName: "journeys" */ 'pages/journeys/show/discovery/index.vue')
      }
    }
  ]
}

export default { index, show }
