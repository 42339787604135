<template>
  <q-layout view="lHr lpr lFr" class="layout-peek" container>
    <q-header>
      <q-toolbar :class="`shadow-2 text-dark bg-${bgColor} toolbar`">
        <q-btn icon="arrow_back" flat round dense @click="$router.back()" />
        <q-toolbar-title>
          {{ title }}
          <div v-if="subtitle" class="subtitle">
            {{ subtitle }}
          </div>
        </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-page-container style="overflow: auto; --webkit-overflow-scrolling: touch;">
      <q-scroll-area class="scrollarea" :style="`height: calc(100vh - ${ footer ? 132 : 64 }px);`">
        <slot />
      </q-scroll-area>
    </q-page-container>
    <q-footer v-if="footer">
      <q-toolbar text-color="light" class="q-pa-md bg-white shadow-2 footer-toolbar">
        <span class="text-body2 text-light ellipsis-2-lines">{{ footerTitle || $t('data_saved_securely') }}</span>
        <div class="footer-toolbar">
          <q-btn label="Cancel" outline color="primary" @click="$router.back()" />
          <q-btn :label="callToAction || $t('save.save')" :disable="disableCallToAction" :loading="loading" color="primary" class="q-ml-md" @click="$emit('save')" />
        </div>
      </q-toolbar>
    </q-footer>
  </q-layout>
</template>

<script>

export default {
  name: 'PeekForm',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    subtitle: {
      type: String,
      default: null
    },
    footer: {
      type: Boolean,
      default: true
    },
    footerTitle: {
      type: String,
      default: null
    },
    callToAction: {
      type: String,
      default: null
    },
    disableCallToAction: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bgColor () {
      return this.$q.platform.is.mobile ? 'primary' : 'white'
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-peek
  height 100vh

.scrollarea
  // overflow auto
  // --webkit-overflow-scrolling touch
  width 100%

.toolbar
  min-height 64px

.footer-toolbar
  display flex
  justify-content space-between

.subtitle
  font-size 75%
  white-space normal

</style>
