<template>
  <svg
    :width="size"
    :height="size"
    :class="classes"
    viewBox="0 0 100 100"
  >
    <g transform="scale(0.8) translate(-0.6, -0.6)">
      <g id="spinner">
        <path d="M50 15A35 35 0 1 0 74.787 25.213" fill="none" :stroke="color" stroke-width="8px" />
        <path d="M50 0L50 30L66 15L50 0" :fill="color" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MSpinnerArrow',
  props: {
    color: String,
    size: {
      type: [Number, String],
      default: '1rem'
    }
  },
  computed: {
    classes () {
      if (this.color) {
        return `text-${this.color}`
      }
      return 'text-white'
    }
  }
}
</script>

<style lang="stylus" scoped>
#spinner {
  animation: rotate 3s infinite linear;
  transform-origin: 50px 50px
}
  svg
    margin: initial
</style>
