<template>
  <div
    :class="`text-${color}`"
    class="line"
  />
</template>

<script>
export default {
  name: 'MRouteLine',
  props: {
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .line
    position relative
    margin: 0 8px
    height: 2px
    background-color currentColor
    &:before,
    &:after
      content ""
      position absolute
      width 10px
      height 10px
      background currentColor
      border-radius 100%
      top -4px
    &:before
      left 0
    &:after
      right 0
</style>
