const home = {
  name: 'home',
  path: '/home',
  component: () => import(/* webpackChunkName: "mobile" */ 'pages/home/index.vue'),
  meta: {
    layout: {
      name: 'mobile',
      bottomsheet: true,
      components: {
        appbar: 'MMobileAppbar',
        footer: 'MMobileFooter'
      }
    },
    label: 'Home'
  }
}

const search = {
  name: 'home-search',
  path: '/home/search/:type?/:mapConfig?',
  props: true,
  component: () => import(/* webpackChunkName: "mobile" */ 'pages/home/search/index.vue'),
  meta: {
    layout: {
      name: 'mobile',
      bottomsheet: true,
      components: {
        appbar: 'MMobileAppbar',
        footer: 'MMobileFooter'
      },
      label: ''
    },
    back: 'home'
  },
  beforeEnter (to, from, next) {
    const label = to.params.label || from.meta.label.split(':')[0]
    to.meta.label = label
    next()
  }
}

const cards = {
  name: 'home-cards',
  path: '/home/search/:type?/:mapConfig?',
  props: true,
  component: () => import(/* webpackChunkName: "mobile" */ 'pages/home/search/index-cards.vue'),
  meta: {
    layout: {
      name: 'mobile',
      bottomcards: true,
      components: {
        appbar: false,
        footer: 'MMobilityNearbyCards'
      },
      label: ''
    },
    back: 'home'
  },
  beforeEnter (to, from, next) {
    const label = to.params.label || from.meta.label.split(':')[0]
    to.meta.label = label
    next()
  }
}

const place = {
  name: 'home-place',
  path: '/home/place/:reference',
  component: () => import(/* webpackChunkName: "mobile" */ 'pages/home/place/index.vue'),
  meta: {
    layout: {
      name: 'mobile',
      components: {
        appbar: false,
        footer: false
      }
    }
  }
}

const details = {
  name: 'home-details',
  path: '/home/details/',
  component: () => import(/* webpackChunkName: "mobile" */ 'pages/home/details/index.vue'),
  meta: {
    layout: {
      name: 'mobile',
      bottomsheet: true,
      components: {
        appbar: 'MMobileAppbar',
        footer: 'MMobileFooter'
      }
    },
    label: 'Timetable',
    back: 'home-search',
    saveParams: true
  },
  beforeEnter (to, from, next) {
    to.meta.label = `${from.meta.label}: Timetable`
    next()
  }
}

export default { home, search, place, details, cards }
