<template>
  <peek-form
    :title="$t('activity')"
    :footer="false"
  >
    <m-infinite-activity
      :getter="load"
      height="calc(100vh - 64px)"
    />
  </peek-form>
</template>

<script>
import http from 'api/http'
import peekForm from '../peekForm.vue'

export default {
  components: { peekForm },
  methods: {
    async load (filters) {
      const type = this.$route.query.type
      const reference = this.$route.query.reference
      return http.get(`/${type}/${reference}/activity`, filters)
    }
  }
}
</script>
