<template>
  <peek-form v-if="modifyUser" :footer="false" :title="peekTitle">
    <q-page padding>
      <m-group-title :label="$t('account_details')" />
      <q-card class="bg-white text-dark">
        <q-list separator>
          <q-item>
            <q-item-section>
              <q-item-label label>
                {{ $t('profile_and_contact_information') }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('contact.methods_and_preferences') }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn :to="{hash: '/user-settings/personal', query: !isModifyingSelf ? { username: activeUser } : null}" flat color="primary">
                {{ $t('edit') }}
              </q-btn>
            </q-item-section>
          </q-item>
          <q-item v-if="isModifyingSelf">
            <q-item-section>
              <q-item-label label>
                {{ $t('update.profile_picture') }}
              </q-item-label>
              <q-item-label
                caption
              >
                {{ $t('tooltip.upload_profile_picture') }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn :to="{hash: '/user-settings/picture'}" flat color="primary">
                {{ $t('edit') }}
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>

      <m-group-title :label="$t('security')" />
      <q-card class="bg-white text-dark">
        <q-list separator>
          <q-item>
            <q-item-section>
              <q-item-label label>
                {{ isModifyingSelf ? $t('change_your_password') : $t('reset_persons_password', { name: modifyUser.meta.first_name }) }}
              </q-item-label>
              <q-item-label caption>
                {{ isModifyingSelf ? $t('tooltip.password_memorable_phrase') : $t('tooltip.email_name_password_reset', { name: modifyUser.meta.first_name }) }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn v-if="isModifyingSelf" :to="{hash: '/user-settings/password'}" flat color="primary">
                {{ $t('edit') }}
              </q-btn>
              <q-btn v-else flat color="warning" @click="userResetPassword">
                {{ $t('reset') }}
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import peekForm from 'peeks/peekForm.vue'
import User from 'store/models/user'

export default {
  components: {
    peekForm
  },
  computed: {
    ...mapGetters({
      activeUser: 'entities/users/peekUser',
      authedUser: 'user'
    }),
    modifyUser: {
      get () {
        return this.activeUser ? User.find(this.activeUser) : null
      }
    },
    isModifyingSelf () {
      if (!this.activeUser) return true
      return this.activeUser === this.authedUser.username
    },
    peekTitle () {
      return this.isModifyingSelf ? this.$t('account_settings') : this.$t('profile_for', { name: this.modifyUser.display_name })
    }
  },
  beforeMount () {
    const query = this.$route.query
    const username = query && query.username ? query.username : this.authedUser.username
    this.$store.dispatch('entities/users/setPeekUser', { username })
  }
}
</script>

<style lang="stylus" scoped>
  .loading
    display grid
    width 100%
    height 100%
    *
      margin auto
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
  .q-item
    padding 16px
</style>
