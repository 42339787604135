<template>
  <q-btn-dropdown flat no-caps menu-self="center middle" menu-anchor="center middle" :label="value | filter">
    <q-list>
      <q-item v-for="(option, key) in options" :key="key" v-close-popup clickable @click="$emit('input', option)">
        <q-item-section>
          <q-item-label>{{ option | filter }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script>
import { capitalize } from 'lodash'
export default {
  filters: {
    filter (value) {
      return capitalize(value)
    }
  },
  props: {
    value: String,
    options: Array
  }
}
</script>
