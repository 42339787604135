<template>
  <peek-form
    :title="`${amendMode ? 'Edit' : 'Create'} Role`"
    :loading="loading"
    :disable-call-to-action="disabled"
    @save="save()"
  >
    <q-page padding>
      <div v-if="!loading" class="column q-col-gutter-md">
        <div class="col">
          <m-group-title label="Role settings" />
          <q-card>
            <q-card-section>
              <q-input v-model="role.name" :label="$t('admin.roles.name')" />
              <q-input v-model="role.slug" :disable="amendMode" :label="$t('admin.roles.slug')" :rules="[v => !v.includes(' ') || $t('admin.roles.slug_rules')]" />
              <q-input v-model="role.level" :label="$t('admin.roles.level')" type="number" />
              <q-input v-model="role.description" :label="$t('admin.roles.description')" type="textarea" maxlength="255" />
            </q-card-section>
          </q-card>
        </div>
        <div class="col">
          <m-group-title :label="$t('admin.roles.attached_permissions')" />
          <q-card>
            <q-card-section>
              <attached-permissions v-model="role.permissions" />
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div v-else-if="loading" class="text-center">
        {{ $t('loading.loading') }}
      </div>
    </q-page>
  </peek-form>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import Role from 'store/models/role'
import peekForm from 'peeks/peekForm.vue'
import attachedPermissions from './attachedPermissions.vue'
export default {
  name: 'PeekSystemSettingsRolesForm',
  components: { peekForm, attachedPermissions },
  data () {
    return {
      role: {
        name: null,
        slug: null,
        description: null,
        permissions: [],
        level: 0
      },
      loading: false,
      submitting: false
    }
  },
  computed: {
    amendMode () {
      return !!this.$route.query.role
    },
    disabled () {
      this.$v.$touch()
      return this.$v.$error
    }
  },
  beforeMount () {
    if (this.amendMode) {
      this.loading = true
      var role = Role.find(this.$route.query.role)
      if (!role) {
        this.$store.dispatch('entities/roles/query', {
          slug: this.$route.query.role
        }).then((res) => {
          role = Role.find(res.slug)
          this.role = {
            ...role,
            permissions: role.permissions.map(perm => perm.slug)
          }
          this.loading = false
        })
      } else {
        this.role = {
          ...role,
          permissions: role.permissions.map(perm => perm.slug)
        }
        this.loading = false
      }
    }
  },
  methods: {
    save () {
      this.$v.$touch()
      if (this.$v.$error) {
        this.$q.notify(this.$t('error.default'))
        return
      }
      this.loading = true
      var method = this.amendMode ? 'update' : 'create'
      var payload = this.amendMode ? { slug: this.$route.query.role, role: this.role } : this.role
      this.$store.dispatch(`entities/roles/${method}`, payload)
        .then(() => {
          this.$router.go(-1)
        })
        .finally(() => {
          this.submitting = false
        })
    }
  },
  validations: {
    role: {
      name: { required },
      slug: { required },
      description: { required }
    }
  }
}
</script>
