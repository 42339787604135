<template>
  <peek-form v-if="modifyUser" :footer="false" :title="peekTitle">
    <q-page padding>
      <m-group-title :label="$t('account_details')" />
      <q-card class="bg-white text-dark">
        <q-list separator>
          <q-item>
            <q-item-section>
              <q-item-label label>
                {{ $t('profile_and_contact_information') }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('contact.methods_and_preferences') }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn :to="{hash: '/user-settings/personal', query: !isModifyingSelf ? { username: activeUser } : null}" flat color="primary">
                {{ $t('edit') }}
              </q-btn>
            </q-item-section>
          </q-item>
          <q-item v-if="isModifyingSelf">
            <q-item-section>
              <q-item-label label>
                {{ $t('update.profile_picture') }}
              </q-item-label>
              <q-item-label
                caption
              >
                {{ $t('tooltip.upload_profile_picture') }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn :to="{hash: '/user-settings/picture'}" flat color="primary">
                {{ $t('edit') }}
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>

      <m-group-title :label="$t('travel_policy')" />
      <q-card class="bg-white text-dark">
        <q-list separator>
          <q-item>
            <q-item-section>
              <q-item-label>
                {{ $t('travel_preferences') }}
              </q-item-label>
              <q-item-label caption>
                {{ isModifyingSelf ? $t('adjust_your_travel_preferences') : $t('adjust_persons_travel_preferences', { name: modifyUser.meta.first_name }) }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn :to="{hash: '/mobility-budget', query: !isModifyingSelf ? { username: activeUser } : null}" flat color="primary">
                {{ $t('edit') }}
              </q-btn>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>
                {{ $t('mobility') }}
              </q-item-label>
              <q-item-label caption>
                {{ isModifyingSelf ? $t('manage_mobility_budgets') : $t('manage_persons_mobility_budgets', { name: modifyUser.meta.first_name }) }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn :to="{hash: '/user-settings/budgets', query: !isModifyingSelf ? { username: activeUser } : null}" flat color="primary">
                {{ $t('edit') }}
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>

      <template v-if="isModifyingSelf">
        <m-group-title :label="$t('travel_documents')" />
        <q-card class="bg-white text-dark q-mt-md">
          <q-list>
            <q-item>
              <q-item-section v-if="modifyUser.meta.passport_information && modifyUser.meta.passport_information.passport_number">
                <q-item-label
                  label
                >
                  {{ $t('passport.passport') }} ({{ modifyUser.meta.passport_information.passport_issuing_country }})
                </q-item-label>
                <q-item-label
                  caption
                >
                  {{ $t('passport.expires_date', { num: modifyUser.meta.passport_information.passport_number, date: modifyUser.meta.passport_information.passport_expiry }) }}
                </q-item-label>
              </q-item-section>
              <q-item-section v-else>
                <q-item-label label>
                  {{ $t('passport.passport') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('no_passport') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn :to="{hash: '/user-settings/passport'}" flat color="primary">
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>

        <m-group-title :label="$tc('address', 2)" />
        <q-card class="bg-white text-dark">
          <q-list separator>
            <q-item>
              <q-item-section>
                <q-item-label label>
                  {{ $t('home_address') }}
                </q-item-label>
                <q-item-label
                  caption
                >
                  {{ savedLocations.home ? savedLocations.home.text : $t('update.home_address') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn
                  :to="{hash: '/user-settings/address', query: {label: $t('home')}}"
                  flat
                  color="primary"
                >
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label label>
                  {{ $t('work_address') }}
                </q-item-label>
                <q-item-label
                  caption
                >
                  {{ savedLocations.work ? savedLocations.work.text : $t('update.work_address') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn
                  :to="{hash: '/user-settings/address', query: {label: $t('work')}}"
                  flat
                  color="primary"
                >
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>

        <template v-if="showCarClub">
          <m-group-title :label="$t('memberships')" />
          <q-card class="bg-white text-dark">
            <q-list separator>
              <q-item>
                <q-item-section>
                  <q-item-label label>
                    {{ $t('content_type.carclub') }}
                  </q-item-label>
                  <q-item-label
                    class="text-faded text-body2"
                  >
                    {{ $t('carclub.credentials.set_here') }}
                  </q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-btn
                    :to="{hash: '/user-settings/carclub'}"
                    flat
                    color="primary"
                  >
                    {{ $t('edit') }}
                  </q-btn>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card>
        </template>
      </template>

      <template v-if="!isModifyingSelf && !(modifyUser.organisation && Object.keys(modifyUser.organisation).length)">
        <m-group-title :label="$t('invoicing')" />
        <q-card class="bg-white text-dark">
          <q-list separator>
            <q-item>
              <q-item-section>
                <q-item-label label>
                  {{ $t('custom_invoice_content_template') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('tooltip.customise_invoice_text') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn flat color="primary" :to="{hash: '/user-settings/invoicing'}">
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
      </template>

      <m-group-title :label="$t('security')" />
      <q-card class="bg-white text-dark">
        <q-list separator>
          <q-item v-if="!isModifyingSelf && hasRole('admin')">
            <q-item-section>
              <q-item-label>
                Manage system access
              </q-item-label>
              <q-item-label caption>
                Attach or Remove system level roles and/or permissions
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn flat color="primary" :to="{hash: '/user-settings/system-access', query: { username: activeUser }}">
                {{ $t('edit') }}
              </q-btn>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label label>
                {{ isModifyingSelf ? $t('change_your_password') : $t('reset_persons_password', { name: modifyUser.meta.first_name }) }}
              </q-item-label>
              <q-item-label caption>
                {{ isModifyingSelf ? $t('tooltip.password_memorable_phrase') : $t('tooltip.email_name_password_reset', { name: modifyUser.meta.first_name }) }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn v-if="isModifyingSelf" :to="{hash: '/user-settings/password'}" flat color="primary">
                {{ $t('edit') }}
              </q-btn>
              <q-btn v-else flat color="warning" @click="userResetPassword">
                {{ $t('reset') }}
              </q-btn>
            </q-item-section>
          </q-item>
          <q-item v-if="!isModifyingSelf">
            <q-item-section>
              <q-item-label>
                {{ modifyUser.is_blocked ? $t('unblock') : $t('block') }} {{ modifyUser.meta.first_name }}
              </q-item-label>
              <q-item-label caption>
                {{ modifyUser.is_blocked ? $t('unblock') : $t('block') }} {{ modifyUser.meta.first_name }} from accessing their account
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn flat color="negative" @click="userBlock(!modifyUser.is_blocked)">
                {{ modifyUser.is_blocked ? $t('unblock') : $t('block') }}
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import peekForm from 'peeks/peekForm.vue'
import { hasPermission, hasRole } from 'utils/authentication'
import date from 'utils/date-time'
import User from 'store/models/user'
import authentication from 'mixins/authentication'

export default {
  components: {
    peekForm
  },
  filters: {
    formattedDate: (val) => date.toShortDate(val)
  },
  mixins: [authentication],
  computed: {
    ...mapGetters({
      activeUser: 'entities/users/peekUser',
      authedUser: 'user',
      savedLocations: 'addresses/saved'
    }),
    modifyUser: {
      get () {
        return this.activeUser ? User.find(this.activeUser) : null
      }
    },
    isModifyingSelf () {
      if (!this.activeUser) return true
      return this.activeUser === this.authedUser.username
    },
    showCarClub () {
      console.log(this.$store.getters['partner'].slug)
      if (this.$store.getters['partner'].slug === 'derbygo') return false
      return hasRole('feature.carclub') && hasPermission('search.carclub')
    },
    peekTitle () {
      return this.isModifyingSelf ? this.$t('account_settings') : this.$t('profile_for', { name: this.modifyUser.display_name })
    }
  },
  beforeMount () {
    const query = this.$route.query
    const username = query && query.username ? query.username : this.authedUser.username
    this.$store.dispatch('entities/users/setPeekUser', { username })
  },
  methods: {
    userBlock (blocking = true) {
      this.$store.dispatch('entities/users/modifyBlockedState', { username: this.activeUser, blockingUser: blocking })
    },
    userResetPassword () {
      this.$q.dialog({
        title: this.$t('confirm.confirm'),
        message: this.$t('confirm.reset_password_name', { name: this.modifyUser.display_name }),
        ok: this.$t('confirm.confirm'),
        cancel: this.$t('cancel')
      }).onOk(() => {
        this.$store.dispatch('entities/users/resetPassword', { username: this.modifyUser.username })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .loading
    display grid
    width 100%
    height 100%
    *
      margin auto
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
  .q-item
    padding 16px
</style>
