<template>
  <m-index-list
    ref="scroller"
    :load="load"
    :items="activity"
    :item-height="57"
    :reset-items="() => {activity = []}"
    :height="height"
  >
    <template v-slot:item="{item}">
      <activity-card :item="item" />
    </template>
  </m-index-list>
</template>

<script>
import activityCard from './activity-card'
import mIndexList from '@/MIndexList'

export default {
  components: {
    mIndexList,
    activityCard
  },
  props: ['getter', 'height'],
  data () {
    return {
      activity: []
    }
  },
  methods: {
    async load (filters) {
      return this.getter(filters).then((res) => {
        this.activity = this.activity.concat(res.data.map((a, i) => {
          return {
            ...a,
            reference: this.activity.length + i
          }
        }))
        return res.meta.pagination
      })
    }
  }
}
</script>
