import i18n from 'i18n'

export default {
  main: {
    buttons: [
      {
        label: i18n.t('business_information'),
        icon: 'info',
        link: { hash: '/organisation' },
        roles: {
          is: 'middleoffice',
          not: 'middleoffice.outsource'
        }
      },
      {
        label: i18n.tc('member', 2),
        icon: 'users',
        link: { name: 'members' },
        roles: {
          is: 'middleoffice',
          not: 'middleoffice.outsource'
        }
      },
      {
        label: i18n.tc('journey.journey', 2),
        icon: 'map',
        link: { name: 'organisation-journeys' },
        roles: {
          is: 'middleoffice',
          not: 'middleoffice.outsource'
        }
      },
      {
        label: i18n.tc('manual_booking.label'),
        icon: 'layer-plus',
        link: { name: 'new-request-booking' },
        roles: {
          is: ['backoffice', 'middleoffice.outsource']
        }
      },
      {
        label: i18n.tc('booking.booking', 2),
        icon: 'receipt',
        link: { name: 'organisation-bookings' },
        roles: {
          is: 'middleoffice'
        }
      },
      {
        label: i18n.tc('journey.journey', 2),
        icon: 'map',
        link: { name: 'Journeys' },
        roles: {
          not: 'middleoffice'
        }
      },
      {
        label: i18n.tc('booking.booking', 2),
        icon: 'receipt',
        link: { name: 'bookings-index' },
        roles: {
          not: 'middleoffice'
        }
      },
      {
        label: i18n.tc('invoice.invoice', 2),
        icon: 'file-invoice-dollar',
        link: { name: 'organisation-invoices' },
        roles: {
          is: 'middleoffice',
          not: 'middleoffice.outsource'
        }
      },
      {
        label: i18n.t('reporting'),
        icon: 'file-spreadsheet',
        link: { name: 'Intelligence Reporting' },
        roles: {
          is: 'middleoffice',
          not: 'middleoffice.outsource'
        }
      },
      {
        label: i18n.t('requests'),
        icon: 'file-spreadsheet',
        link: { name: 'requests' },
        roles: {
          is: 'middleoffice.outsource'
        }
      }
    ]
  },
  myTravel: {
    sectionTitle: i18n.t('my_travel'),
    buttons: [
      {
        label: i18n.tc('journey.journey', 2),
        icon: 'map',
        link: { name: 'Journeys' },
        roles: {
          is: 'middleoffice'
        }
      },
      {
        label: i18n.tc('booking.booking', 2),
        icon: 'receipt',
        link: { name: 'bookings-index' },
        roles: {
          is: 'middleoffice'
        }
      },
      {
        label: i18n.t('transport_preferences'),
        icon: 'route',
        roles: {
          not: 'backoffice'
        },
        link: { hash: '/user-settings/travel-policy' },
        partner: {
          not: ['derbygo']
        }

      },
      {
        label: i18n.tc('mobility_budget.default'),
        icon: 'coins',
        link: { hash: '/mobility-budget' },
        partner: {
          not: ['derbygo']
        },
        roles: {
          is: ['member', 'middleoffice']
        }
      },
      {
        label: i18n.t('travel_documents'),
        icon: 'passport',
        link: { hash: '/user-settings/passport' },
        partner: {
          not: ['derbygo']
        }
      },
      {
        label: i18n.t('favourite_addresses'),
        icon: 'stars',
        link: { hash: '/user-settings/address-list' }
      },
      {
        label: i18n.tc('expense', 2),
        icon: 'pound-sign',
        link: { name: 'Expenses' },
        roles: {
          not: ['middleoffice', 'backoffice']
        }
      },
      {
        label: i18n.tc('payment_card.card', 2),
        icon: 'credit-card',
        link: { hash: '/user-settings/payments' }
      }
    ]
  },
  memberships: {
    sectionTitle: i18n.t('memberships'),
    buttons: [
      {
        label: i18n.t('content_type.carclub'),
        icon: 'cars',
        link: { hash: '/user-settings/carclub' },
        partner: {
          not: ['kinto', 'derbygo']
        }
      },
      {
        label: i18n.t('content_type.bikehire'),
        icon: 'bicycle',
        link: { hash: '/user-settings/bikehire' },
        partner: {
          not: ['derbygo']
        }
      }
    ]
  },
  support: {
    sectionTitle: i18n.t('support.support'),
    buttons: [
      {
        label: i18n.t('concierge.concierge'),
        icon: 'user-headset',
        link: { hash: '?/concierge', query: { type: 'question' } },
        roles: {
          not: 'backoffice'
        },
        partner: {
          not: ['derbygo']
        }
      },
      {
        label: 'Contact DerbyGO',
        icon: 'user-headset',
        link: { hash: '/contact' },
        roles: {
          not: 'backoffice'
        },
        partner: {
          not: ['mobilleo', 'bp', 'kinto', 'gohi', 'heart']
        }
      },
      {
        label: i18n.t('account_settings'),
        icon: 'user',
        link: { hash: '/profile/account-details' }
      }
    ]
  },
  policyManager: {
    sectionTitle: i18n.t('company_mobility_manager'),
    buttons: [
      {
        label: i18n.t('transport_policy'),
        icon: 'route',
        link: { hash: '/organisation/travel-policy' }
      },
      {
        label: i18n.tc('label.label', 2),
        icon: 'tags',
        link: { hash: '/organisation/labels' }
      },
      {
        label: i18n.t('meal_allowance'),
        icon: 'utensils',
        link: { hash: '/organisation/meal-allowance' }
      }
    ]
  }
}
