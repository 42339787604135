<template>
  <div>
    <!-- Depart button -->
    <depart-return
      aria-label="Departure Time"
      :value="departing"
      class="time-transport-return"
      :date-options="dateOptions"
      :time-options="timeOptions"
      @change="val => $emit('depart', val)"
      @navigateDate="val => $emit('navigateDate', val)"
    />
    <!-- Return button -->
    <depart-return
      v-if="showReturn"
      :value="returning"
      class="time-transport-return"
      :date-options="returnDateOptions"
      :time-options="timeOptions"
      :show-return="true"
      @change="val => $emit('return', val)"
      @navigateDate="val => $emit('navigateDate', val)"
    />
    <!-- Transport Preferences button -->
    <button
      v-if="showTransportPrefs"
      class="time-transport-return"
      @click="openTravelPrefs"
    >
      <q-icon name="fas fa-cog" color="primary" class="left-icon" />
      <div class="button-label">
        {{ $t('transport_preferences') }}
      </div>
      <div class="button-label" />
      <q-icon name="fas fa-angle-right" color="primary" class="right-icon" />
    </button>
    <!-- Extra info slot (used for DRT) -->
    <slot id="info" />
    <!-- Passengers button -->
    <button
      v-if="showPassengers"
      class="time-transport-return"
      @click="togglePassengerForm"
    >
      <q-icon name="fas fa-user" color="primary" class="left-icon" />
      <div class="button-label">
        {{ $tc('passenger', 2) }} ({{ totalPassengers }})
      </div>
      <div class="button-value">
        {{ passengerDisplay }}
      </div>
      <q-icon name="fas fa-angle-right" color="primary" class="right-icon" />
    </button>
    <!-- Passengers form -->
    <transition name="dropdown">
      <passenger-form
        v-if="showPassengerForm"
        :passengers="showPassengers"
        :railcard-options="railcardOptions"
        @update="passengers"
        @concessions="concessions"
      />
    </transition>
    <!-- SUBMIT -->
    <q-btn
      v-if="!isGuest"
      class="full-width q-ma-lg"
      label="Search"
      color="primary"
      size="lg"
      :disable="disabled"
      @click="submit"
    />
  </div>
</template>

<script>
import date from 'utils/date-time'
const { toCivilDate, newDate } = date
import departReturn from './depart-return.vue'
import passengerForm from './passenger-form.vue'

export default {
  components: { departReturn, passengerForm },
  props: {
    departing: String,
    returning: String,
    showTransportPrefs: {
      type: Boolean,
      default: true
    },
    showPassengers: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isGuest: {
      type: Boolean,
      default: false
    },
    dateOptions: {
      type: Function,
      default: value => {
        return value >= toCivilDate(newDate()).replace(/-/g, '/')
      }
    },
    timeOptions: {
      type: Function,
      default: () => true
    },
    showReturn: {
      type: Boolean,
      default: false
    },
    returnDateOptions: Function,
    railcardOptions: Array
  },
  data () {
    return {
      showPassengerForm: false
    }
  },
  computed: {
    passengerDisplay () {
      const { adults, children, infants, concession } = this.showPassengers
      const numAdults = this.$tc('num.adults', adults)
      const numChildren = children ? ', ' + this.$tc('num.children', children) : ''
      const numInfants = infants ? ', ' + this.$tc('num.infants', infants) : ''
      const numConcessions = concession ? ', ' + this.$tc('num.concessions', concession) : ''
      return numAdults + numChildren + numInfants + numConcessions
    },
    totalPassengers () {
      let total = 0
      for (const key in this.showPassengers) {
        total += this.showPassengers[key]
      }
      return total
    }
  },
  methods: {
    submit () {
      this.$emit('submit')
    },
    openTravelPrefs (event) {
      event.preventDefault()
      this.$router.push({ hash: '/user-settings/travel-policy' })
    },
    togglePassengerForm (event) {
      event.preventDefault()
      this.showPassengerForm = !this.showPassengerForm
    },
    passengers (val) {
      this.$emit('passengers', val)
    },
    concessions (val) {
      this.$emit('concessions', val)
    }
  }

}
</script>

<style lang="stylus" scoped>
.q-btn
  i
    font-size: 14px
    margin-right: 10px
.time-transport-return
  background-color white
  border none
  width 100%
  display grid
  grid-template-columns 20px auto auto 20px
  grid-column-gap 5px
  align-items center
  padding 10px 5px
.button-label, .left-icon
  justify-self start
.button-value, .right-icon
  justify-self end

.dropdown-enter
  height 0px

.dropdown-leave-to
  height 0px

.dropdown-enter-active
  transition height 0.5s

.dropdown-leave-active
  transition height 0.5s

</style>
