<template>
  <button @click="event => event.preventDefault()">
    <q-icon name="far fa-clock" color="primary" class="left-icon" />
    <div class="button-label">
      {{ showReturn ? $t('time.return') : $t('time.departure') }}
    </div>
    <div class="button-value">
      {{ timeDisplay }}
    </div>
    <q-icon name="fas fa-angle-right" color="primary" class="right-icon" />
    <q-popup-proxy
      persistent
      transition-show="scale"
      transition-hide="scale"
      @hide="onHide"
    >
      <q-date
        v-if="!step"
        v-model="proxy"
        :mask="'YYYY-MM-DD HH:mm'"
        :options="dateOptions"
        no-unset
        @navigation="val => $emit('navigateDate', val)"
      >
        <div class="row items-center justify-end q-gutter-sm">
          <q-btn v-close-popup :label="$t('cancel')" color="primary" outline />
          <q-btn :label="$t('confirm.ok')" color="primary" flat primary @click="saveDate" />
        </div>
      </q-date>

      <q-time
        v-else
        v-model="proxy"
        transition-show="scale"
        :options="notBeforeNow"
        :mask="'YYYY-MM-DD HH:mm'"
      >
        <div class="row items-center justify-end q-gutter-sm">
          <q-btn v-close-popup :label="$t('cancel')" color="primary" outline />
          <q-btn v-close-popup :label="$t('confirm.ok')" color="primary" flat primary @click="saveTime" />
        </div>
      </q-time>
    </q-popup-proxy>
  </button>
</template>

<script>
import date from 'utils/date-time'
const { toCivilDate, addToDate, toMediumDateTime, toCivilDateTime, newDate, toMediumShortDate } = date

export default {
  props: [ 'dateOptions', 'timeOptions', 'showReturn', 'value' ],
  data () {
    return {
      proxy: toCivilDateTime(addToDate(newDate(), { minutes: 5 })),
      edited: false,
      step: 0 // 0 show date. 1 show time,
    }
  },
  computed: {
    today () {
      return toCivilDate(newDate())
    },
    tomorrow () {
      return toCivilDate(addToDate(this.today, { days: 1 }))
    },
    timeDisplay () {
      if (!this.edited || !this.proxy) return this.$t('asap')
      const [day, time] = this.proxy.split(' ')
      if (day === this.today) return time ? this.$t('today_with_time', { time }) : this.$t('today')
      if (day === this.tomorrow) return time ? this.$t('tomorrow_with_time', { time }) : this.$t('tomorrow')
      return time ? toMediumDateTime(this.proxy) : toMediumShortDate(this.proxy)
    }
  },
  watch: {
    value (val) {
      this.proxy = val
      this.edited = true
    }
  },
  beforeMount () {
    this.proxy = this.value
    if (this.showReturn) this.edited = true
  },
  methods: {
    onHide () {
      this.step = 0
    },
    saveDate () {
      this.step = 1
      this.edited = true
      this.$emit('change', this.proxy)
    },
    saveTime () {
      this.edited = true
      this.$emit('change', this.proxy)
    },
    notBeforeNow (hour, minutes) {
      if (!this.timeOptions(hour, minutes)) return false
      const [day] = this.proxy.split(' ')
      const [proxyHour, proxyMinutes] = date.toCivilTime().split(':')
      if (day === this.today) {
        if (hour < proxyHour) return false
        if (hour > proxyHour) return true
        if (minutes !== null) {
          if (minutes < proxyMinutes) return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="stylus" scoped>
.button-label, .left-icon
  justify-self start
.button-value, .right-icon
  justify-self end
</style>
