<template>
  <peek-form
    :title="$tc('content_type.flight')"
    :subtitle="$t('mobility_budget.modify_preferences')"
    :footer-title="$t('tooltip.tailor_your_choices')"
    @save="submit"
  >
    <q-page padding>
      <m-group-title :label="$t('preferences')" />
      <q-card class="bg-white text-dark">
        <q-card-section>
          <q-select
            v-model="selectedOptions"
            :error="$v.policy.preferred_cabin_classes.$error"
            :options="cabinOptions"
            multiple
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            :emit-value="false"
            :label="this.$t('flight.class.preferred')"
            @input="(val) => {
              $v.policy.preferred_cabin_classes.$touch()
            }"
          />
        </q-card-section>
      </q-card>
      <m-group-title :label="$tc('budget.budget')" />
      <q-card class="bg-white">
        <q-card-section>
          <money-field
            v-model="policy.global_budget"
            :label="$t('global_budget')"
            :error="$v.policy.global_budget.$error"
            :hint="$t('permitted_cost.flights_total')"
            @blur="$v.policy.global_budget.$touch"
          />
        </q-card-section>
        <m-banner
          :title="$t('mobility_budget.increase_flight')"
          :message="$t('tooltip.override_global_budget')"
          borderless
        />
        <q-card-section>
          <div v-for="(item, index) in policy.duration_budgets" :key="index" class="row q-col-gutter-sm">
            <div class="col-5">
              <duration-field
                v-model="item.duration"
                :label="$t('duration')"
                :error="$v.policy.duration_budgets.$each.$iter[index].duration.$error"
                :hint="$t('duration_hours')"
                @blur="$v.policy.duration_budgets.$touch"
              />
            </div>
            <div class="col-5">
              <money-field
                v-model="item.budget"
                :label="$tc('budget.budget')"
                :error="$v.policy.duration_budgets.$each.$iter[index].budget.$error"
                :hint="$t('permitted_cost.flights')"
                @blur="$v.policy.duration_budgets.$touch"
              />
            </div>
            <div class="col-2" style="display: grid">
              <q-btn
                v-if="item.duration && item.budget"
                round
                color="primary"
                icon="delete"
                style="margin: auto"
                @click="() =>
                  removeDurationBudget(index)"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import User from 'store/models/user'
import { mapGetters } from 'vuex'
import { requiredIf } from 'vuelidate/lib/validators'
import peekForm from 'peeks/peekForm.vue'
import moneyField from '@/MFields/money'
import durationField from '@/MFields/duration'
export default {
  components: {
    peekForm,
    moneyField,
    durationField
  },
  data () {
    return {
      peekUser: null,
      policy: {
        preferred_cabin_classes: [],
        global_budget: '',
        duration_budgets: []
      },
      cabinOptions: [
        {
          label: this.$t('flight.class.economy'),
          value: 'Economy'
        },
        {
          label: this.$t('flight.class.premium_economy'),
          value: 'PremiumEconomy'
        },
        {
          label: this.$t('flight.class.business'),
          value: 'Business'
        },
        {
          label: this.$t('flight.class.first'),
          value: 'First'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activeUser: 'entities/users/peekUser'
    }),
    selectedOptions: {
      get () {
        const vm = this
        const selectedCabins = []
        this.cabinOptions.forEach(o => {
          if (vm.policy.preferred_cabin_classes.some(i => i.label === o.label)) {
            selectedCabins.push(o)
          }
        })
        return selectedCabins
      },
      set (val) {
        this.policy.preferred_cabin_classes = val
      }
    }
  },
  watch: {
    'policy.duration_budgets': {
      deep: true,
      handler (val) {
        const last = val[val.length - 1]
        if (last.budget) {
          this.addDurationBudget()
        }
      }
    }
  },
  created () {
    this.peekUser = User.find(this.activeUser)
    if (this.peekUser.meta.travel_policy && this.peekUser.meta.travel_policy.flights) {
      this.policy = {
        ...this.policy,
        ...JSON.parse(JSON.stringify(this.peekUser.meta.travel_policy.flights))
      }
    }

    if (!this.policy.duration_budgets.length) {
      this.addDurationBudget()
    }
  },
  validations: {
    policy: {
      preferred_cabin_classes: { },
      global_budget: { },
      duration_budgets: {
        $each: {
          duration: {
            requiredIf: requiredIf(vm => !!vm.budget)
          },
          budget: {
            requiredIf: requiredIf(vm => !!vm.duration)
          }
        }
      }
    }
  },
  methods: {
    addDurationBudget () {
      this.policy.duration_budgets.push({ duration: '', budget: '' })
    },
    removeDurationBudget (index) {
      this.policy.duration_budgets.splice(index, 1)
    },
    submit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$store.dispatch('entities/users/updateUser', {
          username: this.peekUser.username,
          attributes: {
            travel_policy: {
              ...this.peekUser.meta.travel_policy,
              flights: {
                ...this.policy,
                duration_budgets: this.policy.duration_budgets.filter(b => b.duration && b.budget)
              }
            }
          }
        }).then(() => {
          this.$router.back()
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
  .separator
    margin 18px 0 28px 0
</style>
