export default {
  methods: {
    getNiceName (type = null) {
      if (type) {
        switch (type) {
        case 'ridehailing':
          return 'ride hailing'
        case 'onwardtravel':
          return 'onward travel'
        default:
          return type
        }
      }
    }
  }
}
