<template>
  <div class="overview row">
    <div class="col-5">
      {{ $t('flight.class.preferred') }}
    </div>
    <div
      class="col-6 text-dark"
    >
      {{ policy && policy.preferred_cabin_classes ? policy.preferred_cabin_classes.map(p => p.label).join(', ') : $t('any') }}
    </div>
    <div class="col-5">
      {{ $t('global_budget') }}
    </div>
    <div
      class="col-6 text-dark"
    >
      {{ policy && policy.global_budget ? '£' + (policy.global_budget / 100) : $t('no_budget_set') }}
    </div>
    <div class="col-5">
      {{ $t('duration_restrictions') }}
    </div>
    <div class="col-6">
      <div v-if="policy && policy.duration_budgets">
        <div v-for="r in policy.duration_budgets" :key="r.duration" class="row repeater">
          <div class="col-6 text-dark">
            {{ $t('num.hours', { num: r.duration / 60 / 60 }) }}
          </div>
          <div class="col-6 text-dark">
            £{{ r.budget / 100 }}
          </div>
        </div>
      </div>
      <div v-else class="text-dark">
        {{ $t('flight.no_duration_specific_settings') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['policy']
}
</script>

<style lang="stylus" scoped>
.overview > div, .repeater > div
  padding 6px 0

</style>
