<template>
  <peek-form
    :title="$t('carclub.membership.information')"
    :footer-title="$t('carclub.details_checked')"
    :call-to-action="callToAction"
    :loading="loading"
    @save="submit"
  >
    <q-page padding>
      <m-group-title :label="$t('enterprise_car_club')" />
      <q-card class="bg-white text-dark">
        <q-card-section v-if="!userLookup.has_carclub">
          <q-input
            v-model="carclub.driver_id"
            :label="$t('driver_id')"
            autocomplete="off"
            :error="$v.carclub.driver_id.$error"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
          />
          <q-input
            v-model="carclub.driver_password"
            type="password"
            :label="$t('driver_password')"
            autocomplete="off"
            :error="$v.carclub.driver_password.$error"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
          />
          <p v-if="errorMessage" class="text-orange">
            {{ errorMessage }}
          </p>
        </q-card-section>
        <q-card-section v-else class="remove-club">
          <p>{{ $t('carclub.credentials.delete') }}</p>

          <p>{{ $t('carclub.update_details') }}</p>
        </q-card-section>
      </q-card>
      <div v-if="!userLookup.has_carclub" class="please-enter">
        <h6>{{ $t('carclub.membership.enter_details') }}</h6>
      </div>
    </q-page>
  </peek-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import peekForm from 'peeks/peekForm.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    peekForm
  },
  data () {
    return {
      errorMessage: null,
      loading: false,
      carclub: {
        supplier: 'CarShare',
        driver_id: null,
        driver_password: null
      }
    }
  },
  computed: {
    ...mapGetters({
      userLookup: 'userLookup'
    }),
    callToAction () {
      return this.userLookup.has_carclub ? this.$t('delete') : this.$t('save.save')
    }
  },
  methods: {
    submit () {
      if (this.userLookup.has_carclub) {
        this.deleteCredentials()
      } else {
        this.$v.carclub.driver_id.$touch()
        this.$v.carclub.driver_password.$touch()
        if (!this.$v.carclub.driver_id.$invalid && !this.$v.carclub.driver_password.$invalid) {
          this.loading = true
          this.errorMessage = null
          this.$store.dispatch('setCarClubCredentials', this.carclub)
            .then(() => {
              this.loading = false
              this.$q.notify({
                message: this.$t('carclub.credentials.validated'),
                timeout: 1500,
                type: 'positive',
                icon: 'check',
                position: 'top-right'
              })
              this.$router.go(-1)
            })
            .catch(e => {
              this.loading = false
              if (e.data.title === 'ValidationException') {
                this.errorMessage = this.$t('carclub.incorrect_details')
                this.$q.notify({
                  message: this.errorMessage,
                  timeout: 1500,
                  type: 'error',
                  icon: 'error',
                  position: 'top-right'
                })
              }
            })
        }
      }
    },
    deleteCredentials () {
      this.$store.dispatch('deleteCarClubCredentials')
        .then(() => {
          this.$q.notify({
            message: this.$t('carclub.credentials.removed'),
            timeout: 1500,
            type: 'positive',
            icon: 'check',
            position: 'top-right'
          })
        })
    }
  },
  validations: {
    carclub: {
      supplier: { required },
      driver_id: { required },
      driver_password: { required }
    }
  }
}

</script>

<style lang="stylus" scoped>
.remove-club
  text-align center
  display grid

  *
    margin auto
    margin-top: 10px

.please-enter
  display grid
  text-align center
  margin 10px 20px

.btn-delete
  margin-top 16px
</style>
