import { Platform } from 'quasar'
import store from 'store/modules/ondemand'
import getPartnerFromHostname from 'utils/partner'

const meta = {
  permission: 'search.bus'
}

const partner = getPartnerFromHostname()

const busRoutesComponents = partner.slug === 'gohi'
  ? {
    default: () => import(/* webpackChunkName: "ondemand-bus" */ 'pages/ondemand/bus/routes/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-bus" */ 'pages/ondemand/bus/sidebar/index.vue')
  }
  : {
    default: () => import(/* webpackChunkName: "ondemand-bus" */ 'pages/ondemand/bus/routes/index.vue')
  }

const beforeEnter = (to, from, next) => {
  !store.state.stash.parameters ? next({ name: 'ondemand-bus' }) : next()
}

export const mobileForm = {
  name: 'ondemand-bus-mobile',
  path: '/on-demand/bus',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-bus-mobile" */ 'pages/ondemand/mobile/buses.vue')
  },
  meta: {
    ...meta,
    label: 'Bus: Search'
  }
}

export const form = {
  name: 'ondemand-bus',
  path: '/on-demand/bus',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-bus" */ 'pages/ondemand/bus/index.vue')
  },
  beforeEnter (to, from, next) {
    if (Platform.is.mobile) {
      next({
        name: 'ondemand-bus-mobile',
        params: to.params
      })
    } else {
      next()
    }
  },
  meta
}

export const routes = {
  name: 'ondemand-bus-routes',
  path: '/on-demand/bus/routes',
  components: busRoutesComponents,
  meta: {
    ...meta,
    label: 'Bus: Routes'
  },
  beforeEnter
}

export const summary = {
  name: 'ondemand-bus-summary',
  path: '/on-demand/bus/summary',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-bus" */ 'pages/ondemand/bus/summary/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-bus" */ 'pages/ondemand/bus/sidebar/index.vue')
  },
  meta: {
    ...meta,
    label: 'Bus: Summary'
  }
}

export default { form, routes, summary, mobileForm }
