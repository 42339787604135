
import { index as getAllJourneys, get } from 'api/journeys'
import Journey from 'store/models/journey'
import { cloneDeep } from 'lodash'

function vm (j) {
  return {
    ...j,
    date: j.date.date
  }
}

const initial = {
  filter: 'upcoming',
  disableFilters: false,
  dateFilter: { start: null, end: null },
  typeFilter: []
}

const state = cloneDeep(initial)

const getters = {
  filter: state => state.filter,
  disableFilters: state => state.disableFilters,
  dateFilter: state => state.dateFilter,
  typeFilter: state => state.typeFilter
}

const actions = {
  async loadAllJourneys (context, filters) {
    try {
      const response = await getAllJourneys(filters)
      const journeys = response.data
      await Journey.insert({ data: journeys.map(vm) })
      return response.meta.pagination
    } catch (error) { }
  },
  async loadOrganisationJourneys (context, { organisation, filters }) {
    try {
      const response = await getAllJourneys({
        ...filters,
        organisation
      })
      const journeys = response.data
      await Journey.insert({ data: journeys.map(vm) })
      return response.meta.pagination
    } catch (error) { }
  },
  async loadJourney (context, journeyReference) {
    try {
      const response = await get(journeyReference)
      const journey = response.data
      return await Journey.insert({ data: vm(journey) })
    } catch (error) {
    }
  },
  setFilter ({ commit }, filter) {
    commit('saveFilter', filter)
  },
  disableFilters ({ commit }, disable) {
    commit('disableFilters', disable)
  },
  setDateFilter ({ commit }, dates) {
    commit('saveDateFilter', dates)
  },
  addTypeFilter ({ commit }, type) {
    commit('pushTypeFilter', type)
  },
  removeTypeFilter ({ commit }, type) {
    commit('reduceTypeFilter', type)
  }
}

const mutations = {
  saveFilter (state, filter) {
    state.filter = filter
  },
  disableFilters (state, disable) {
    state.disableFilters = disable
  },
  saveDateFilter (state, dates) {
    state.dateFilter = dates
  },
  pushTypeFilter (state, type) {
    state.typeFilter.push(type)
  },
  reduceTypeFilter (state, type) {
    state.typeFilter = state.typeFilter.filter(item => item !== type)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
