<template>
  <peek-form :title="$t('manage_mobility_budgets')" :loading="loading" @save="submit">
    <q-page padding>
      <m-group-title :label="$tc('budget.budget', 2)" />
      <q-card>
        <q-card-section>
          <money-field v-model="monthly_budget" :disable="loading" :label="$t('total_monthly_budget')" @input="changed = true" />
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>
<script>
import { mapGetters } from 'vuex'
import peekForm from 'peeks/peekForm.vue'
import User from 'store/models/user'
import moneyField from '@/MFields/money.vue'
export default {
  name: 'UserSettingsBudgets',
  components: { peekForm, moneyField },
  data () {
    return {
      monthly_budget: 0,
      loading: false,
      changed: false
    }
  },
  computed: {
    ...mapGetters({
      activeUser: 'entities/users/peekUser',
      authedUser: 'user'
    })
  },
  beforeMount () {
    this.loading = true
    const query = this.$route.query
    const username = query && query.username ? query.username : this.authedUser.username
    this.$store.dispatch('entities/users/setPeekUser', { username }).then(() => {
      this.monthly_budget = User.find(this.activeUser).monthly_budget.amount
      this.loading = false
    })
  },
  methods: {
    submit () {
      if (this.changed) {
        this.$store.dispatch('entities/users/updateMonthlyBudget', { username: this.activeUser, budget: this.monthly_budget })
          .then(() => {
            this.$router.go(-1)
          })
          .finally(() => {
            this.loading = false
          })
      } else this.$router.go(-1)
    }
  }
}
</script>
