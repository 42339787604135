import router from 'router'
import store from 'store'
import { Dialog } from 'quasar'

const api = {
  url: process.env.VUE_APP_API_URL || `${window.location.protocol}//api.${window.location.hostname.split('.').slice(1).join('.')}`,
  beforeEach: [
    (config) => {
      if (store.getters.session.token) {
        config.headers['Authorization'] = `Bearer ${store.getters.session.token}`
      }
      return config
    }
  ],
  afterEach: [
    (response) => {
      const authorisation = response.headers.get('authorization')
      if (authorisation) {
        let token = authorisation.split(' ')[1]
        store.dispatch('saveAuthToken', token)
      }
    }
  ],
  patch (url, data, config) {
    const constructedUrl = new URL(api.url + (url.charAt(0) === '/' ? url : '/' + url))
    return request(constructedUrl, {
      method: 'PATCH',
      body: JSON.stringify(data),
      ...config
    })
  },
  put (url, data, config) {
    const constructedUrl = new URL(api.url + (url.charAt(0) === '/' ? url : '/' + url))
    return request(constructedUrl, {
      method: 'PUT',
      body: JSON.stringify(data),
      ...config
    })
  },
  post (url, data = {}, config) {
    const constructedUrl = new URL(api.url + (url.charAt(0) === '/' ? url : '/' + url))
    return request(constructedUrl, {
      method: 'POST',
      body: data instanceof FormData ? data : JSON.stringify(data),
      ...config
    })
  },
  get (url, data = {}, config) {
    const constructedUrl = new URL(api.url + (url.charAt(0) === '/' ? url : '/' + url))
    if (data.params) {
      Object.keys(data.params).forEach(key => constructedUrl.searchParams.append(key, data.params[key]))
    }
    return request(constructedUrl, {
      method: 'GET',
      ...config
    })
  },
  delete (url, data, config) {
    const constructedUrl = new URL(api.url + (url.charAt(0) === '/' ? url : '/' + url))
    return request(constructedUrl, {
      method: 'DELETE',
      body: JSON.stringify(data),
      ...config
    })
  }
}

let refreshing = false

async function request (url, config = {}) {
  let requestOptions = {
    method: config.method || 'GET',
    headers: {
      'mob-client-token': store.getters['partner/consumerToken'],
      ...config.headers
    }
  }

  if (!(config.body instanceof FormData)) {
    requestOptions.headers['Content-Type'] = 'application/json'
  }

  if (config.body) {
    requestOptions.body = config.body
  }

  api.beforeEach.forEach(func => {
    requestOptions = func(requestOptions)
  })

  return fetch(url, requestOptions)
    .then(async (res) => {
      /**
       * If we get a 401, refresh the token and try again
       */
      if (res.status === 401 && store.getters.session.token) {
        /**
         * Cheap way of checking if we have already attempted a retry
         */
        if (refreshing) {
          store.dispatch('logout')
          router.push({ name: 'Logout' }, () => {
            Dialog.create({
              noBackdropDismiss: true,
              noEscDismiss: true,
              title: 'Session time-out',
              message: 'You have been logged out due to inactivity.',
              buttons: ['Ok']
            })
          })
          return Promise.reject(res)
        }
        refreshing = true
        res = await api.get('/auth/refresh')
        return request(url, requestOptions)
      }

      api.afterEach.forEach(func => {
        func(res)
      })

      if (res.status === 204) return null
      if (res.status >= 500) {
        const internalServerError = {
          data: {
            title: 'Whoops!',
            message: 'Something has gone wrong on our end, please try again later'
          },
          meta: {}
        }
        return Promise.reject(internalServerError)
      }

      /*
    * This calls text() if json() didn't work. I put this here because there was an if statement which was causing tests to fail.
    */
      if (res.headers.get('content-type').includes('text')) {
        return res.text().then(text => {
          return res.ok ? text : Promise.reject(text)
        })
      }

      return res.json().then(json => {
        return res.ok ? json : Promise.reject(json)
      })
    })
}

export default api
