import _ from 'lodash'

const initial = {
  stash: {},
  filters: {},
  activeFilters: {},
  booking_token: null,
  journey: {
    name: null,
    reference: null
  },
  booking: {
    reference: null
  },
  selectionList: [],
  currentField: '',
  airports: []
}

const state = _.cloneDeep(initial)

const getters = {
  stash: (state) => state.stash,
  filters: (state) => state.filters,
  activeFilters: (state) => state.activeFilters,
  bookingToken: (state) => state.booking_token,
  journey: (state) => state.journey,
  bookingReference: (state) => state.booking.reference,
  selectionList: state => state.selectionList,
  airports: state => state.airports
}

const actions = {
  stash: ({ commit }, payload) => {
    commit('stash', payload)
  },
  parameters: ({ commit }, payload) => {
    commit('parameters', payload)
  },
  stashProp: ({ commit }, { path, value }) => {
    commit('set', { path, value })
  },
  unstash: ({ commit }, payload) => commit('unstash', payload),
  booking_token: ({ commit }, value) => commit('set', {
    path: 'booking_token',
    value
  }),
  journey: ({ commit, state }, newJourney) => commit('journey', newJourney),
  filters: ({ commit, state }, newFilters) => commit('filters', newFilters),
  activeFilters: ({ commit, state }, newFilters) => commit('activeFilters', newFilters),
  booking_reference: ({ commit }, reference) => {
    commit('set', {
      path: 'booking',
      value: {
        reference
      }
    })
  }
}

const mutations = {
  journey (state, newJourney) {
    for (let f in state.journey) {
      state.journey[f] = newJourney[f]
    }
  },
  stash (state, newStash) {
    state.stash = { ...state.stash, ...newStash }
  },
  parameters (state, newParams) {
    state.stash.parameters = { ...state.stash.parameters, ...newParams }
  },
  filters (state, newFilters) {
    state.filters = newFilters
  },
  activeFilters (state, newFilters) {
    state.activeFilters = newFilters
  },
  unstash (state, { path }) {
    _.unset(state.stash, path)
  },
  set (state, { path, value }) {
    _.set(state, path, value)
  },
  unset (state, { path }) {
    _.unset(state, path)
  },
  clear (state) {
    for (let f in state) {
      state[f] = _.cloneDeep(initial[f])
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
