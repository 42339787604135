<template>
  <div>
    <m-group-title :label="$t('how_can_we_help')" class="q-mt-lg" />
    <q-card class="bg-white text-dark">
      <q-tabs
        v-model="request.content_type"
        narrow-indicator
        dense
        indicator-color="transparent"
        active-bg-color="transparent"
        active-color="primary"
        align="left"
        class="text-grey q-pt-md q-px-md"
        outside-arrows
        mobile-arrows
      >
        <q-tab v-for="queryType in queryTypes" :key="queryType.value" :ripple="false" :name="queryType.value" :label="queryType.label" />
      </q-tabs>
      <q-card-section>
        <div class="row">
          <div class="col-12 row">
            <q-input
              v-model="request.message"
              class="col-12 col-sm-8"
              :placeholder="$t('concierge.type_your_request')"
              autogrow
              :filled="!$q.platform.is.mobile"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
              :error="$v.request.message.$error"
            />
            <div
              class="bg-white col-12 col-sm-4 q-pa-md text-caption"
              style="margin-bottom: 28px"
              :class="{
                'order-first': $q.platform.is.mobile
              }"
            >
              <ul
                v-if="request.content_type === 'lounge'"
                style="margin:0; padding:0; margin-left: 10px"
              >
                <strong>{{ $t('concierge.tell_us') }}:</strong>
                <li>{{ $t('airport') }}</li>
                <li>{{ $t('concierge.flight_terminal') }}</li>
                <li>{{ $t('flight.number') }}</li>
                <li>{{ $t('number_of.people') }}</li>
                <li>{{ $t('arrival_time') }}</li>
                <li>{{ $t('concierge.other_preferences') }}</li>
              </ul>
              <ul
                v-if="request.content_type === 'chauffeur'"
                style="margin:0; padding:0; margin-left: 10px"
              >
                <strong>{{ $t('concierge.tell_us') }}:</strong>
                <li>{{ $t('pick_up_location') }}</li>
                <li>{{ $t('drop_off_location') }}</li>
                <li>{{ $t('date.date_time_of_pick_up') }}</li>
                <li>{{ $t('number_of.travellers') }}</li>
                <li>{{ $t('vehicle_type') }}</li>
                <li>{{ $t('concierge.other_preferences') }}</li>
              </ul>
              <ul
                v-if="request.content_type === 'parking'"
                style="margin:0; padding:0; margin-left: 10px"
              >
                <strong>{{ $t('concierge.tell_us') }}:</strong>
                <li>{{ $t('parking_location') }}</li>
                <li>{{ $t('arrival_date_and_time') }}</li>
                <li>{{ $t('date.date_time_of_travel') }}</li>
                <li>{{ $t('vehicle_registration_number') }}</li>
                <li>{{ $t('concierge.other_preferences') }}</li>
              </ul>
              <ul
                v-if="request.content_type === 'complex'"
                style="margin:0; padding:0; margin-left: 10px"
              >
                <strong>{{ $t('concierge.tell_us') }}:</strong>
                <li>{{ $tc('traveller', 2) }}</li>
                <li>{{ $tc('budget.budget') }}</li>
                <li>{{ $t('date.travel') }}</li>
                <li>{{ $t('concierge.special_requirements') }}</li>
              </ul>
              <ul
                v-if="request.content_type === 'other'"
                style="margin:0; padding:0; margin-left: 10px"
              >
                <strong>{{ $t('concierge.tell_us') }}:</strong>
                <li>{{ $t('concierge.restaurant_reservations') }}</li>
                <li>{{ $t('concierge.airbnb_booking') }}</li>
                <li>{{ $t('concierge.price_estimates') }}</li>
                <li>{{ $t('travel_questions') }}</li>
                <li>{{ $t('travel_tips') }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row q-col-gutter-sm">
          <div class="col-sm-6">
            <q-input
              v-model="request.contact_number"
              type="tel"
              :label="$t('concierge.contact_number')"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
              :error="$v.request.contact_number.$error"
            />
          </div>
          <div class="col-sm-6">
            <q-input
              v-model="request.contact_email"
              type="email"
              :label="$t('concierge.contact_email')"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
              :error="$v.request.contact_email.$error"
            />
          </div>
        </div>
      </q-card-section>
    </q-card>

    <m-group-title :label="$t('add_screenshot')" class="q-mt-lg" />
    <file-upload-card v-model="files" />

    <m-group-title :label="$t('additional_information')" class="q-mt-lg" />
    <q-card>
      <q-card-section>
        <div class="row q-col-gutter-sm">
          <div class="col-12">
            <user-lookup
              v-if="hasRole('middleoffice')"
              v-model="people.traveller"
              :label="$t('who_is_travelling')"
              :stack-label="true"
              :error="$v.people.traveller.$error"
            />
            <q-input
              v-model="request.reason_for"
              type="text"
              :label="$t('reason_for_travel')"
              :rounded="$q.platform.is.mobile"
              :outlined="$q.platform.is.mobile"
              stack-label
            />

            <div v-if="people.traveller" class="row q-col-gutter-sm">
              <div v-if="journeys.length" class="col">
                <q-select
                  v-model="journey.reference"
                  :options="journeys"
                  :label="$t('add_to_journey')"
                  map-options
                  emit-value
                  stack-label
                  filter
                  autofocus-filter
                  :rounded="$q.platform.is.mobile"
                  :outlined="$q.platform.is.mobile"
                  :error="$v.journey.reference.$error"
                />
              </div>
              <div v-if="journeys.length" class="col-sm-2">
                <p class="text-center vertical-middle or">
                  Or
                </p>
              </div>
              <div class="col">
                <q-input
                  v-model="journey.name"
                  :max-height="150"
                  :min-rows="1"
                  type="text"
                  :label="journeys.length ? $t('create_journey') : $t('journey.name')"
                  stack-label
                  :rounded="$q.platform.is.mobile"
                  :outlined="$q.platform.is.mobile"
                  :error="$v.journey.name.$error"
                />
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script type="text/javascript">
import { existingJourneys } from 'api/user'
import { pickBy } from 'lodash'
import { mapGetters } from 'vuex'
import http from 'api/http'
import fileUploadCard from './file-upload-card.vue'
import authentication from 'mixins/authentication'
import userLookup from '@/MFields/user-lookup'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'Concierge',
  components: {
    fileUploadCard,
    userLookup
  },
  mixins: [authentication],
  data () {
    return {
      journeys: [],
      request: {
        type: 'question',
        content_type: 'other',
        message: null,
        reason_for: null,
        contact_number: null,
        contact_email: null
      },
      files: [],
      people: {
        traveller: null,
        requester: null
      },
      journey: {
        name: null,
        reference: null
      },
      queryTypes: [
        {
          label: this.$t('concierge.general_query'),
          value: 'other'
        },
        {
          label: this.$t('airport_lounge'),
          value: 'lounge'
        },
        {
          label: this.$t('parking'),
          value: 'parking'
        },
        {
          label: this.$t('chauffeur'),
          value: 'chauffeur'
        },
        {
          label: this.$t('complex_journey'),
          value: 'complex'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    type () {
      return this.$route.query.type
    }
  },
  watch: {
    'people.traveller': {
      immediate: true,
      handler () {
        if (!this.people.traveller) return
        this.getJourneys()
      }
    },
    'journey.reference' (val) {
      if (val) this.journey.name = null
    },
    'journey.name' (val) {
      if (val) this.journey.reference = null
    }
  },
  created () {
    this.request.contact_email = this.user.email
    this.request.contact_number = this.user.meta.mobile

    this.people.requester = {
      label: this.user.display_name,
      value: this.user.username
    }

    if (!this.hasRole('middleoffice')) {
      this.people.traveller = {
        label: this.user.display_name,
        value: this.user.username
      }
    }
  },
  methods: {
    getJourneys () {
      this.journeys = []
      existingJourneys({ username: this.people.traveller.value }).then(
        response => {
          this.journeys = response.data.map(item => {
            return {
              label: item.name,
              sublabel: item.info,
              stamp: item.date.date,
              value: item.reference
            }
          })
        }
      )
    },
    submit () {
      this.$v.$touch()
      if (this.$v.$error) {
        this.$q.notify({
          message: this.$t('fill_all_fields'),
          position: 'top-right'
        })
        return
      }
      const body = {
        type: this.request.type !== 'other' ? 'booking' : this.request.type,
        content_type:
          this.request.content_type === 'complex'
            ? 'other'
            : this.request.content_type,
        requester: this.people.requester.value,
        user: this.people.traveller.value,
        message: this.request.message,
        contact_number: this.request.contact_number,
        contact_email: this.request.contact_email
      }

      if (this.journey.reference) {
        body.journey_reference = this.journey.reference
      } else if (this.journey.name) {
        body.journey_name = this.journey.name
      }

      if (this.request.reason_for) {
        body.reason_for = this.request.reason_for
      }

      let data = new FormData()
      Object.keys(pickBy(body)).forEach(k => {
        data.append(k, body[k])
      })

      if (this.files.length) {
        this.files.forEach(f => {
          data.append('files[]', f.file)
        })
      }

      http
        .post(`requests`, data)
        .then(response => {
          this.$router.push({
            name: 'dashboard',
            params: {
              booking_reference: response.data.reference
            }
          })
        })
        .catch(e => {
          this.$q.notify({
            message: this.$t('fill_all_fields'),
            position: 'top-right'
          })
        })
    }
  },
  validations: {
    request: {
      type: { required },
      content_type: { required },
      message: { required },
      contact_number: { required },
      contact_email: { required }
    },
    people: {
      requester: { required },
      traveller: { required }
    },
    journey: {
      name: {
        requiredIf: requiredIf(function () {
          return !this.journey.reference
        })
      },
      reference: {
        requiredIf: requiredIf(function () {
          return !this.journey.name
        })
      }
    }
  }
}
</script>

<style lang="stylus">
.or
  margin-top 20px

.concierge-peek .q-textarea .q-field__native
  min-height 260px

.q-tabs >>> .q-hoverable:hover > .q-focus-helper
  background transparent !important

.q-tabs__arrow
  &--left,
  &--right
    top: 16px !important
</style>
